const getModelEndpoint = () => {
  return '/model';
};
const getPatientEndpoint = () => {
  return '/patients';
};
const getTreatmentsEndpoint = () => {
  return '/treatments';
};
const getDiseaseInfosEndpoint = () => {
  return '/diseases-information';
};
const getDiseasesInfoDetailsEndpoint = () => {
  return '/diseases-information-details';
};
const getVisitsEndpoint = () => {
  return '/visits';
};
const getProfileEndpoint = () => {
  return '/profile';
};
const getSubscriptionEndpoint = () => {
  return '/subscription';
};
const getDrugSubstancesInfoEndpoint = () => {
  return '/drug-substances-info';
};
const getDrugSubstancesEndpoint = () => {
  return '/drug-substances';
};
const getIntegrationPatientInfo = () => {
  return '/integration/patient';
};
const getIntegrationVisitInfo = () => {
  return '/integration/visit';
};
const createStripePortalSessionEndpoint = () => {
  return '/customer-portal';
};
const trackEventEndpoint = () => {
  return '/crm/track';
};

const endpoints = {
  getModelEndpoint,
  getPatientEndpoint,
  getVisitsEndpoint,
  getProfileEndpoint,
  getSubscriptionEndpoint,
  getDiseaseInfosEndpoint,
  getDiseasesInfoDetailsEndpoint,
  getTreatmentsEndpoint,
  getDrugSubstancesInfoEndpoint,
  getDrugSubstancesEndpoint,
  getIntegrationPatientInfo,
  getIntegrationVisitInfo,
  createStripePortalSessionEndpoint,
  trackEventEndpoint,
};

export default endpoints;
