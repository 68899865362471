import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { integrationReducer } from '../reducers/integration/integration.reducer';
import {
  IntegrationState,
  IntegrationAction,
  IntegrationInitialState,
} from '../reducers/integration/integration.state';

const IntegrationContext = createContext<IntegrationState>(
  {} as IntegrationState
);
const IntegrationDispatchContext = createContext<Dispatch<IntegrationAction>>(
  () => {}
);

export const IntegrationProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(
    integrationReducer,
    IntegrationInitialState
  );

  return (
    <IntegrationContext.Provider value={state}>
      <IntegrationDispatchContext.Provider value={dispatch}>
        {children}
      </IntegrationDispatchContext.Provider>
    </IntegrationContext.Provider>
  );
};

export const useIntegrationContext = (): IntegrationState => {
  return useContext(IntegrationContext);
};

export const useIntegrationDispatch = () => {
  return useContext(IntegrationDispatchContext);
};
