import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { TemplatePage } from '../pages/TemplatePage';
import { ProfilePage } from '../pages/ProfilePage';
import {
  ROUTE_NOTIFICATIONS,
  ROUTE_PROFILE_INFO,
  ROUTE_SUBSCRIPTION,
} from '../config/routes.config';

export const ProfileRouter = () => {
  return (
    <Switch>
      <Route path={ROUTE_PROFILE_INFO}>
        <ProfilePage />
      </Route>
      <Route path={ROUTE_NOTIFICATIONS}>
        <TemplatePage title="Notifications" />
      </Route>
      <Route path={ROUTE_SUBSCRIPTION}>
        <TemplatePage title="Your subscription status" />
      </Route>
      <Redirect to={ROUTE_PROFILE_INFO} />
    </Switch>
  );
};
