import React from 'react';
import { CardAllPatients } from '../components/all-patients/CardAllPatients';
import { CardSearchPatient } from '../components/all-patients/CardSearchPatient';

export const AllPatientsPage = ({ isSelect }: { isSelect?: boolean }) => {
  return (
    <>
      <CardSearchPatient />
      <CardAllPatients isSelect={isSelect} />
    </>
  );
};
