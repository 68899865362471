export const countriesURL = 'model?modelName=Countries';
export const personTypesURL = 'model?modelName=PersonTypes';
export const integrationLoginURL = '/integration/login';
export const provetUrls = [];

export const noAuthUrls = [
  countriesURL,
  integrationLoginURL,
  personTypesURL,
  ...provetUrls,
] as const;

export type UrlNoAuth = (typeof noAuthUrls)[number];
