import {
  VeterinarianState,
  VeterinarianAction,
  DefaultVeterinarianState,
} from './veterinarian.state';

export const veterinarianReducer = (
  state: VeterinarianState,
  action: VeterinarianAction
): VeterinarianState => {
  switch (action.type) {
    case 'reset': {
      return { ...DefaultVeterinarianState };
    }

    case 'request': {
      return {
        ...state,
        isLoading: true,
      };
    }

    case 'failure': {
      return {
        ...state,
        error: action.error,
      };
    }

    case 'success': {
      let newState = {
        ...DefaultVeterinarianState,
      };
      if (action.responseData[0]) {
        newState = { ...newState, veterinarian: action.responseData[0] };
      }

      return newState;
    }

    case 'updateVeterinarian': {
      const newState = {
        ...DefaultVeterinarianState,
        veterinarian: action.veterinarian,
      };

      return newState;
    }

    default:
      return state;
  }
};
