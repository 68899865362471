import { DrugProduct } from '../../types/DrugProduct.type';
import { RequestAction } from '../../types/request.action.type';

export type DrugProductsState = {
  drugProductsObj: { [x: number]: DrugProduct[] };
  isLoading: boolean;
  error?: string;
};

export type DrugProductsAction =
  | RequestAction<DrugProduct[]>
  | {
      type: 'import';
      drugProducts: DrugProductsState;
    }
  | {
      type: 'resetAll';
    };

export const DrugProductsDefault: DrugProductsState = {
  isLoading: false,
  drugProductsObj: {},
};
