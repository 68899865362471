import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import {
  ROUTE_HOME,
  ROUTE_INTEGRATION,
  ROUTE_INTEGRATION_AUTH,
  ROUTE_INTEGRATION_LOGIN,
  ROUTE_PROFILE,
  ROUTE_PAYMENT,
  ROUTE_SUPPORT,
  ROUTE_TOOLS,
  ROUTE_TUTORIAL,
  ROUTE_WORK,
} from '../config/routes.config';
import {
  useSubscriptionContext,
  useSubscriptionDispatch,
} from '../contexts/SubscriptionContext';
import { WorkProvider } from '../contexts/WorkContext';
import { HomePage } from '../pages/HomePage';
import { IntegrationAuthentication } from '../pages/IntegrationAuthentication';
import { IntegrationLogin } from '../pages/IntegrationLogin';
import { PaymentPage } from '../pages/PaymentPage';
import { SupportPage } from '../pages/SupportPage';
import { TutorialPage } from '../pages/TutorialPage';
import { ProfileRouter } from '../routers/ProfileRouter';
import { ToolsRouter } from '../routers/ToolsRouter';
import { WorkRouter } from '../routers/WorkRouter';
import ErrorReportingTestPage from '../pages/ErrorReportingTestPage';
import ChatPage from '../pages/ChatPage';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const SubscriptionLayout = () => {
  window.dataLayer.push({
    event: 'Pageview',
    pagePath: window.location.pathname,
  });

  const location = useLocation();

  const { error: errorSubscription } = useSubscriptionContext();
  const dispatchSubscription = useSubscriptionDispatch();

  useEffect(() => {
    if (errorSubscription) {
      dispatchSubscription({ type: 'reset' });
    }
  }, [errorSubscription, dispatchSubscription]);

  return (
    <Switch>
      <Route exact path="/broken">
        <ErrorReportingTestPage />
      </Route>
      <Route exact path={ROUTE_HOME}>
        <HomePage />
      </Route>

      <Route path={ROUTE_WORK}>
        <WorkProvider>
          <WorkRouter />
        </WorkProvider>
      </Route>
      <Route path={ROUTE_TOOLS}>
        <ToolsRouter />
      </Route>
      <Route path={ROUTE_PROFILE}>
        <ProfileRouter />
      </Route>
      <Route path={ROUTE_SUPPORT}>
        <SupportPage />
      </Route>
      <Route path={ROUTE_TUTORIAL}>
        <TutorialPage />
      </Route>
      <Route exact path={ROUTE_INTEGRATION_AUTH}>
        <IntegrationAuthentication />
      </Route>
      <Route exact path={ROUTE_INTEGRATION_LOGIN}>
        <IntegrationLogin />
      </Route>
      <Route exact path={ROUTE_PAYMENT}>
        <PaymentPage />
      </Route>
      <Route exact path={'/chat'}>
        <ChatPage />
      </Route>
      {!location.pathname.includes(ROUTE_INTEGRATION) && (
        <Redirect to={ROUTE_HOME}></Redirect>
      )}
    </Switch>
  );
};
