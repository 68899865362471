import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import { DrugMgPerUnit } from '../../../types/DrugDosages.type';

export const DrugSelect = ({
  name = 'mgPerUnit',
  value,
  error,
  handleChange,
  viewSize = 'medium',
}: {
  name?: string;
  value: number | null;
  error: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  viewSize?: 'medium' | 'small';
}) => {
  return (
    <FormControl variant="outlined" fullWidth size={viewSize}>
      <Select
        variant="outlined"
        value={value}
        onChange={handleChange as unknown as () => React.ChangeEvent}
        error={error}
        name={name}
      >
        <MenuItem selected value={DrugMgPerUnit['mg/ml']}>
          mg/ml
        </MenuItem>
        <MenuItem value={DrugMgPerUnit['mg/tablet']}>mg/tablet</MenuItem>
        <MenuItem value={DrugMgPerUnit['mg/capsule']}>mg/capsule</MenuItem>
      </Select>
    </FormControl>
  );
};
