import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { drugProductsReducer } from '../reducers/drugProducts/drugProducts.reducer';
import {
  DrugProductsState,
  DrugProductsAction,
  DrugProductsDefault,
} from '../reducers/drugProducts/drugProducts.state';

const DrugProductsContext = createContext<DrugProductsState>(
  {} as DrugProductsState
);
const DrugProductsDispatch = createContext<Dispatch<DrugProductsAction>>(
  () => {}
);

export const DrugProductsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(
    drugProductsReducer,
    DrugProductsDefault
  );

  return (
    <DrugProductsContext.Provider value={state}>
      <DrugProductsDispatch.Provider value={dispatch}>
        {children}
      </DrugProductsDispatch.Provider>
    </DrugProductsContext.Provider>
  );
};

export const useDrugProducts = (): DrugProductsState => {
  return useContext(DrugProductsContext);
};

export const useDrugProductsDispatch = () => {
  return useContext(DrugProductsDispatch);
};
