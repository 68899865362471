import React from 'react';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { Diagnose, DiseaseInfo } from '../../types/Diagnose.type';

const useStyles = makeStyles()((theme: Theme) => ({
  highlight: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

export const DiseaseBreeds = ({
  diagnose,
  highlightBreedId,
}: {
  diagnose: Diagnose | DiseaseInfo;
  highlightBreedId?: number | undefined;
}) => {
  const { classes } = useStyles();

  return (
    <Typography
      variant="body1"
      style={{
        whiteSpace: 'pre-line',
        textAlign: 'left',
        marginBottom: '1em',
      }}
    >
      <strong>Common in breeds as: </strong>
      {diagnose.DiseaseBreeds.length === 0 && <span>-</span>}
      {diagnose.DiseaseBreeds.map((element, index) => {
        return [
          <span key={`${index}_space`}>{index === 0 ? '' : ', '}</span>,
          <span
            key={index}
            className={
              highlightBreedId && element.Breed.Id === highlightBreedId
                ? classes.highlight
                : undefined
            }
          >
            {element.Breed.Name}
          </span>,
        ];
      })}
    </Typography>
  );
};
