import { DiseasesAndTreatments } from '../components/DiseasesAndTreatments';
import React, { useEffect, useState } from 'react';
import { useRequestData } from '../hooks/useRequestData';
import {
  Symptom,
  SymptomType,
  SymptomTypeCategory,
} from '../types/Symptom.type';
import { useSymptoms, useSymptomsDispatch } from '../contexts/SymptomsContext';
import { useBreedsContext, useBreedsDispatch } from '../contexts/BreedsContext';

import {
  CauseOfDisease,
  DiseaseInfo,
  TreatmentInfo,
} from '../types/Diagnose.type';

import { Breed } from '../types/Breed.type';

import { sortBy, uniqBy } from 'lodash';
import API from '../api/Api';
import { useAuthUser } from '../contexts/AuthUserContext';

export const DiseasesAndTreatmentsPage = () => {
  useEffect(() => {
    API.trackEvent('diseases_and_treatments_page');
  }, []);
  const {
    isLoading: isLoadingData,
    error: errorData,
    categories,
    symptomTypes,
    symptoms,
  } = useSymptoms();

  const dispatchSymptoms = useSymptomsDispatch();
  const {
    isLoading: isLoadingBreeds,
    breeds,
    error: breedsError,
  } = useBreedsContext();

  const { signOut } = useAuthUser();

  const dispatchBreeds = useBreedsDispatch();

  const [diseaseInfos, setDiseaseInfos] = useState<DiseaseInfo[]>([]);
  const [isDiseaseInfoLoading, setIsDiseaseInfoLoading] = useState(false);
  const [isTreatmentInfoLoading, setIsTreatmentInfoLoading] = useState(false);
  const [causeOfDiseases, setCauseOfDiseases] = useState<CauseOfDisease[]>([]);
  const [treatmentInfos, setTreatmentInfos] = useState<TreatmentInfo[]>([]);
  const [animalTypeBreeds, setAnimalTypeBreeds] = useState<Breed[]>([]);

  const needBreeds = !breedsError && !isLoadingBreeds && breeds.length === 0;

  const needCategories =
    !errorData && !isLoadingData && categories.length === 0;

  const needTypes =
    !errorData &&
    !isLoadingData &&
    categories.length > 0 &&
    symptomTypes.length === 0;

  const needSymptoms =
    !errorData &&
    !isLoadingData &&
    categories.length > 0 &&
    symptomTypes.length > 0 &&
    symptoms.length === 0;

  useRequestData<Breed[]>({
    needTransport: needBreeds,
    dispatch: dispatchBreeds,
    method: 'get',
    params: { modelName: 'Breeds' },
  });

  useRequestData<SymptomTypeCategory[]>({
    needTransport: needCategories,
    dispatch: dispatchSymptoms,
    method: 'get',
    params: { modelName: 'SymptomTypeCategories' },
  });

  useRequestData<SymptomType[]>({
    needTransport: needTypes,
    dispatch: dispatchSymptoms,
    method: 'get',
    params: { modelName: 'SymptomTypes' },
  });

  useRequestData<Symptom[]>({
    needTransport: needSymptoms,
    dispatch: dispatchSymptoms,
    method: 'get',
    params: { modelName: 'Symptoms' },
  });

  const getDiseaseInfos = async (animalTypeId: string) => {
    const diseaseInfoResult: any = await API.getDiseaseInfos(animalTypeId);
    // Check if api is not returning NotAuthorizedException
    if (
      diseaseInfoResult.status === 404 &&
      diseaseInfoResult.data.error.code === 'NotAuthorizedException'
    ) {
      await signOut();
    } else {
      const diseaseInfos: DiseaseInfo[] = diseaseInfoResult.body;

      const allCauseOfDiseases: CauseOfDisease[] = diseaseInfos.flatMap(
        (diseaseInfo: DiseaseInfo) => {
          return diseaseInfo.Disease_Causes.map((diseaseCause) => {
            return diseaseCause.CauseOfDisease;
          });
        }
      );
      const causeOfDiseases = uniqBy(allCauseOfDiseases, (diseaseCause) => {
        return diseaseCause.Id;
      });

      setCauseOfDiseases(sortBy(causeOfDiseases, 'Name'));
      setDiseaseInfos(
        sortBy(diseaseInfos, (diseaseInfo: DiseaseInfo) => {
          return diseaseInfo.Disease.Name;
        })
      );
      setIsDiseaseInfoLoading(false);
    }
  };

  const getTreatmentInfo = async (animalTypeDiseaseId: number) => {
    setIsTreatmentInfoLoading(true);
    const treatmentsResult: any = await API.getTreatments(animalTypeDiseaseId);
    // Check if api is returning NotAuthorizedException and sign user out
    if (
      treatmentsResult.status === 404 &&
      treatmentsResult.data.error.code === 'NotAuthorizedException'
    ) {
      await signOut();
    } else {
      const responseTreatmentInfo: TreatmentInfo = treatmentsResult.body[0];
      responseTreatmentInfo['Treatments'] = sortBy(
        responseTreatmentInfo.Treatments,
        (treatment) => {
          return treatment.Order;
        }
      );
      setTreatmentInfos([...treatmentInfos, responseTreatmentInfo]);
      setIsTreatmentInfoLoading(false);
    }
  };

  const getBreedsByAnimalType = (animalTypeId: number) => {
    const filteredBreeds = breeds
      .filter((breed) => breed.AnimalTypeId === animalTypeId)
      .sort((a, b) => {
        const breedA = a.Name.toUpperCase(); // ignore upper and lowercase
        const breedB = b.Name.toUpperCase(); // ignore upper and lowercase
        if (breedA < breedB) {
          return -1;
        }
        if (breedA > breedB) {
          return 1;
        }

        // breeds must be equal
        return 0;
      });

    setAnimalTypeBreeds(filteredBreeds);
  };

  return (
    <div>
      <DiseasesAndTreatments
        diseaseInfos={diseaseInfos}
        getDiseaseInfos={getDiseaseInfos}
        symptomTypeCategories={categories}
        isDiseaseInfoLoading={isDiseaseInfoLoading}
        setIsDiseaseInfoLoading={setIsDiseaseInfoLoading}
        symptoms={symptoms}
        causeOfDiseases={causeOfDiseases}
        getBreedsByAnimalType={getBreedsByAnimalType}
        animalTypeBreeds={animalTypeBreeds}
        isLoadingBreeds={isLoadingBreeds}
        getTreatmentInfo={getTreatmentInfo}
        treatmentInfos={treatmentInfos}
        isTreatmentInfoLoading={isTreatmentInfoLoading}
      ></DiseasesAndTreatments>
    </div>
  );
};
