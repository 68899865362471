import { DoseParam, DoseParamWithOrder } from '../../types/Dose.params.type';
import { DoseParamsState, DoseParamsAction } from './dose.params.state';

const determineDoseParam = (
  toBeDetermined: DoseParam[] | DoseParamWithOrder[]
): toBeDetermined is DoseParam[] => {
  if (!('Order' in (toBeDetermined as DoseParam[])[0])) {
    return true;
  }
  return false;
};

const determineDoseParamWithOrder = (
  toBeDetermined: DoseParam[] | DoseParamWithOrder[]
): toBeDetermined is DoseParamWithOrder[] => {
  if ('Order' in (toBeDetermined as DoseParam[])[0]) {
    return true;
  }
  return false;
};

export const doseParamsReducer = (
  state: DoseParamsState,
  action: DoseParamsAction
): DoseParamsState => {
  switch (action.type) {
    case 'reset': {
      const newState = { ...state };
      if ('error' in newState) {
        delete newState.error;
      }

      return newState;
    }

    case 'request': {
      return {
        ...state,
        isLoading: true,
      };
    }

    case 'success': {
      if (
        action.options === 'TreatmentDurationPrefixes' &&
        determineDoseParam(action.responseData)
      ) {
        return {
          ...state,
          isLoading: false,
          treatmentDurationPrefixes: action.responseData,
        };
      } else if (
        action.options === 'DoseTypes' &&
        determineDoseParamWithOrder(action.responseData)
      ) {
        return {
          ...state,
          isLoading: false,
          doseTypes: action.responseData,
        };
      } else if (
        action.options === 'DosageIntervals' &&
        determineDoseParamWithOrder(action.responseData)
      ) {
        return {
          ...state,
          isLoading: false,
          dosageIntervals: action.responseData,
        };
      } else if (
        action.options === 'TreatmentDurations' &&
        determineDoseParamWithOrder(action.responseData)
      ) {
        let notSpecifiedDurationIndex = (
          action.responseData as Array<DoseParam | DoseParamWithOrder>
        )
          .map((prefix) => prefix.Name.toLowerCase())
          .indexOf('not specified');

        if (notSpecifiedDurationIndex === -1) {
          notSpecifiedDurationIndex = 0;
        }

        return {
          ...state,
          isLoading: false,
          treatmentDurations: action.responseData,
          notSpecifiedDurationIndex,
        };
      } else if (
        action.options === 'SummaryDrugUnits' &&
        determineDoseParamWithOrder(action.responseData)
      ) {
        return {
          ...state,
          isLoading: false,
          summaryDrugUnits: action.responseData,
        };
      } else if (
        action.options === 'SummaryResultDrugUnits' &&
        determineDoseParamWithOrder(action.responseData)
      ) {
        return {
          ...state,
          isLoading: false,
          summaryResultDrugUnits: action.responseData,
        };
      }

      return {
        ...state,
        isLoading: false,
      };
    }

    case 'failure':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
