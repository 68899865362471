import React from 'react';
import { DrugMgPerUnit } from '../../../types/DrugDosages.type';
import { ResultText } from '../SharedComponents/ResultText';

const getResultText = (mgPerUnit: DrugMgPerUnit) => {
  return mgPerUnit === DrugMgPerUnit['mg/ml']
    ? 'ml / administration'
    : mgPerUnit === DrugMgPerUnit['mg/tablet']
    ? 'tablet(s) / administration'
    : 'capsule(s) per administration';
};

export const ResultTextNumberOfUnits = ({
  mgPerUnit,
}: {
  mgPerUnit: DrugMgPerUnit;
}) => {
  return <ResultText>{getResultText(mgPerUnit)}</ResultText>;
};
