import { Subscription } from '../../types/Subscription.type';
import { RequestAction } from '../../types/request.action.type';

export type SubscriptionState = {
  subscription?: Subscription;
  isLoading: boolean;
  error?: string;
  errorMessage?: string;
};

export type SubscriptionAction = RequestAction<Subscription>;

export const SubscriptionInitialState: SubscriptionState = {
  isLoading: false,
};
