import { useCallback } from 'react';
import axios from 'axios';

import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../contexts/DiagnosesPageContext';

import {
  useTreatmentsPage,
  useTreatmentsPageDispatch,
} from '../contexts/TreatmentsPageContext';

import {
  useDosagesPage,
  useDosagesPageDispatch,
} from '../contexts/DosagesPageContext';

import { useWorkContext } from '../contexts/WorkContext';
import { usePatientInfoDispatch } from '../contexts/CurrentPatientInfoContext';
import { useAnimalTypesContext } from '../contexts/AnimalTypesContext';
import { usePatientListState } from '../contexts/PatientListContext';
import { useVisitsListState } from '../contexts/VisitsListContext';
import {
  ROUTE_CURRENT_VISIT,
  ROUTE_VISIT_DIAGNOSIS,
} from '../config/routes.config';
import { Patient } from '../types/Patient.type';
import { PatientVisit } from '../types/PatientVisit.type';
import {
  useDrugProducts,
  useDrugProductsDispatch,
} from '../contexts/DrugProductsContext';
import { DiagnosesPageState } from '../reducers/diagnosesPage/diagnoses.page.state';
import { TreatmentsPageState } from '../reducers/treatmentsPage/treatments.page.state';
import { DrugProductsState } from '../reducers/drugProducts/drugProducts.state';
import { DosagesPageState } from '../reducers/dosagesPage/dosages.page.state';

const VISIT_HISTORY_API_URL = '/visit-history';

type HistoryObject = {
  path: string;
  diagnosesPage: DiagnosesPageState;
  treatmentsPage: TreatmentsPageState;
  drugProducts: DrugProductsState;
  dosagesPage: DosagesPageState;
};

export const useWorkHistory = () => {
  const history = useHistory();
  const location = useLocation();
  const { setPatient, setVisit } = useWorkContext();
  const { animalTypesObj } = useAnimalTypesContext();
  const { patientsObj } = usePatientListState();
  const { visitsObj } = useVisitsListState();
  const dispatchPatientInfo = usePatientInfoDispatch();

  const { visitId }: any = useParams();

  const diagnosesPage = useDiagnosesPage();
  const dispatchPageDiagnoses = useDiagnosesPageDispatch();
  const treatmentsPage = useTreatmentsPage();
  const dispatchTreatmentsPage = useTreatmentsPageDispatch();
  const dosagesPage = useDosagesPage();
  const dispatchDosagesPage = useDosagesPageDispatch();
  const drugProducts = useDrugProducts();
  const dispatchDrugProducts = useDrugProductsDispatch();

  const resetDiagnosesPage = useCallback(() => {
    dispatchPageDiagnoses({
      type: 'resetAll',
    });
  }, [dispatchPageDiagnoses]);

  const resetTreatmentsPage = useCallback(() => {
    dispatchTreatmentsPage({
      type: 'reset',
    });
  }, [dispatchTreatmentsPage]);

  const resetDosagesPage = useCallback(() => {
    dispatchDosagesPage({ type: 'reset' });
  }, [dispatchDosagesPage]);

  const resetDrugProducts = useCallback(() => {
    dispatchDrugProducts({ type: 'resetAll' });
  }, [dispatchDrugProducts]);

  const resetAllPages = useCallback(() => {
    resetDiagnosesPage();
    resetTreatmentsPage();
    resetDosagesPage();
    resetDrugProducts();
  }, [
    resetDiagnosesPage,
    resetDosagesPage,
    resetTreatmentsPage,
    resetDrugProducts,
  ]);

  const getState = useCallback(
    async (visitId: string) => {
      try {
        const { data } = await axios.get<HistoryObject>(VISIT_HISTORY_API_URL, {
          params: { visitId },
        });
        return data;
      } catch (errorObject) {
        // TODO: Handle this with some clever way in the ui?
        history.goBack();
      }
    },
    [history]
  );

  const postState = useCallback(async (visitId: string, data: any) => {
    try {
      await axios.post(VISIT_HISTORY_API_URL, {
        visitId,
        data,
      });
    } catch (errorObject) {
      return; // TODO: Show error in the ui?
    }
  }, []);

  const exportState = useCallback(async () => {
    await postState(visitId, {
      diagnosesPage,
      treatmentsPage,
      dosagesPage,
      drugProducts,
      path: location.pathname,
    });
  }, [
    diagnosesPage,
    dosagesPage,
    drugProducts,
    location.pathname,
    postState,
    treatmentsPage,
    visitId,
  ]);

  const importState = useCallback(
    async (historyObject: HistoryObject) => {
      if (historyObject.diagnosesPage) {
        dispatchPageDiagnoses({
          type: 'import',
          diagnosesPage: historyObject.diagnosesPage,
        });
      } else {
        resetAllPages();
        return;
      }
      if (historyObject.treatmentsPage) {
        dispatchTreatmentsPage({
          type: 'import',
          treatmentsPage: historyObject.treatmentsPage,
        });
      } else {
        resetTreatmentsPage();
        resetDosagesPage();
        return;
      }
      if (historyObject.dosagesPage) {
        dispatchDosagesPage({
          type: 'import',
          dosagesPage: historyObject.dosagesPage,
        });
        dispatchDrugProducts({
          type: 'import',
          drugProducts: historyObject.drugProducts,
        });
        return;
      } else {
        resetDosagesPage();
        resetDrugProducts();
      }
    },
    [
      dispatchDosagesPage,
      dispatchPageDiagnoses,
      dispatchTreatmentsPage,
      dispatchDrugProducts,
      resetAllPages,
      resetDosagesPage,
      resetTreatmentsPage,
      resetDrugProducts,
    ]
  );

  const goToWorkPage = useCallback(
    async (
      patientId: string,
      visitId: string,
      homePage: boolean | undefined
    ) => {
      const patient = patientsObj![patientId];
      setPatient(patient);
      setVisit(visitsObj![visitId]);
      dispatchPatientInfo({
        type: 'setPatientInfo',
        patient,
        animalTypeName: animalTypesObj[patient.AnimalTypeId].Name,
      });

      const historyObject = await getState(visitId);

      await importState(historyObject!);

      if (historyObject && historyObject.path) {
        history.push(historyObject.path);
        return;
      }

      const path = generatePath(
        homePage ? ROUTE_VISIT_DIAGNOSIS : ROUTE_CURRENT_VISIT,
        { patientId, visitId }
      );

      history.push(path);
    },
    [
      animalTypesObj,
      dispatchPatientInfo,
      getState,
      history,
      patientsObj,
      setPatient,
      setVisit,
      visitsObj,
      importState,
    ]
  );

  const goToWorkPageFromAnywhere = useCallback(
    async (patient: Patient, visit: PatientVisit, ignorePath?: boolean) => {
      setPatient(patient);
      setVisit(visit);
      dispatchPatientInfo({
        type: 'setPatientInfo',
        patient,
        animalTypeName: animalTypesObj[patient.AnimalTypeId].Name,
      });

      const historyObject = await getState(String(visit.Id));

      await importState(historyObject!);

      if (ignorePath) {
        return;
      }

      if (historyObject && historyObject.path) {
        history.push(historyObject.path);
        return;
      }
      const path = generatePath(ROUTE_VISIT_DIAGNOSIS, {
        patientId: String(patient.Id),
        visitId: String(visit.Id),
      });
      history.push(path);
    },
    [
      animalTypesObj,
      dispatchPatientInfo,
      getState,
      history,
      setPatient,
      setVisit,
      importState,
    ]
  );

  return {
    resetAllPages,
    exportState,
    importState,
    goToWorkPage,
    goToWorkPageFromAnywhere,
  };
};
