import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { diagnosesPageReducer } from '../reducers/diagnosesPage/diagnoses.page.reducer';
import {
  DiagnosesPageState,
  DiagnosesPageAction,
  DiagnosesPageDefault,
} from '../reducers/diagnosesPage/diagnoses.page.state';

const DiagnosesPageContext = createContext<DiagnosesPageState>(
  {} as DiagnosesPageState
);
const DiagnosesPageDispatchContext = createContext<
  Dispatch<DiagnosesPageAction>
>(() => {});

export const DiagnosesPageProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(
    diagnosesPageReducer,
    DiagnosesPageDefault
  );

  return (
    <DiagnosesPageContext.Provider value={state}>
      <DiagnosesPageDispatchContext.Provider value={dispatch}>
        {children}
      </DiagnosesPageDispatchContext.Provider>
    </DiagnosesPageContext.Provider>
  );
};

export const useDiagnosesPage = (): DiagnosesPageState => {
  return useContext(DiagnosesPageContext);
};

export const useDiagnosesPageDispatch = () => {
  return useContext(DiagnosesPageDispatchContext);
};
