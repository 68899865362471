import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useWorkHistory } from '../../hooks/useWorkHistory';

export interface VisitRowProps {
  Date: string;
  Time: string | null;
  PatientName: string;
  VisitReason: string;
  PatientId: string;
  VisitId: string;
}

export const ListTodayVisits = ({
  list,
  noVisitsText,
  homePage,
  showDate = false,
  setVisitRowClicked,
}: {
  list: Array<VisitRowProps>;
  noVisitsText: string;
  homePage?: boolean;
  showDate?: boolean;
  setVisitRowClicked: (clicked: boolean) => void;
}) => {
  const { goToWorkPage } = useWorkHistory();

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('sm'));

  if (list.length === 0) {
    return (
      <Typography variant="h6" gutterBottom>
        {noVisitsText}
      </Typography>
    );
  }

  return (
    <List dense={true}>
      {list.map((visit) => {
        return (
          <div
            key={visit.VisitId}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setVisitRowClicked(true);
              goToWorkPage(visit.PatientId, visit.VisitId, homePage);
            }}
          >
            <ListItem
              style={{
                borderBottom: '1px solid lightgrey',
                width: '100%',
              }}
            >
              <ListItemAvatar>
                <ListItemText
                  primary={showDate ? visit.Date : visit.Time}
                  secondary={showDate ? visit.Time : undefined}
                  style={{
                    minWidth: matchesXS ? '75px' : '100px',
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                style={{
                  overflowWrap: matchesXS ? 'anywhere' : undefined,
                }}
                primary={visit.PatientName}
                secondary={visit.VisitReason}
              />
              <ListItemSecondaryAction>
                <ChevronRightIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
              </ListItemSecondaryAction>
            </ListItem>
          </div>
        );
      })}
    </List>
  );
};
