import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { breedsReducer } from '../reducers/breeds/breeds.reducer';
import {
  BreedsState,
  BreedsAction,
  BreedsInitialState,
} from '../reducers/breeds/breeds.state';

const BreedsContext = createContext<BreedsState>({} as BreedsState);
const BreedsDispatchContext = createContext<Dispatch<BreedsAction>>(() => {});

export const BreedsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(breedsReducer, BreedsInitialState);

  return (
    <BreedsContext.Provider value={state}>
      <BreedsDispatchContext.Provider value={dispatch}>
        {children}
      </BreedsDispatchContext.Provider>
    </BreedsContext.Provider>
  );
};

export const useBreedsContext = (): BreedsState => {
  return useContext(BreedsContext);
};

export const useBreedsDispatch = () => {
  return useContext(BreedsDispatchContext);
};
