import React from 'react';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import Typography from '@mui/material/Typography';
import { HtmlTooltip } from '../common/HtmlToolTip';

export default function SymptomsWithAddedInfoTooltip() {
  return (
    <>
      <HtmlTooltip
        title={
          <Typography color="inherit">
            Symptoms with additional information
          </Typography>
        }
        enterTouchDelay={0}
      >
        <NotificationImportantIcon style={{ fill: '#00cd87', marginLeft: 5 }} />
      </HtmlTooltip>
    </>
  );
}
