import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Grid,
  Card as CoreCard,
  CardActionArea,
  CardContent,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Button } from '../Button';
import { Card } from '../common/Card';
import { TextField } from '../registration/TextField';
import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

import { useAuthUser } from '../../contexts/AuthUserContext';
import { useRequestData } from '../../hooks/useRequestData';
import {
  useSubscriptionContext,
  useSubscriptionDispatch,
} from '../../contexts/SubscriptionContext';
import { Subscription } from '../../types/Subscription.type';
import Loader from '../Loader';
import {
  useVeterinarian,
  useVeterinarianDispatch,
} from '../../contexts/VeterinarianContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Redirect } from 'react-router';
import { ROUTE_HOME } from '../../config/routes.config';
import { VetProfile } from '../../types/Profile.types';
import { calcDifferenceToToday } from '../../utils/dateUtils';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';

declare const Stripe: any;

declare global {
  interface Window {
    dataLayer: any;
  }
}

const useStyles = makeStyles()((theme: Theme) => ({
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  box: {
    maxWidth: '650px',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: '90%',
  },
  text: {
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  testSubInfo: {
    color: theme.palette.primary.light,
    margin: theme.spacing(1),
  },
  textTransform: {
    textTransform: 'uppercase',
  },
  info: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  gridContainer: {
    display: 'flex',
    alignItems: 'center!important',
    flexBasis: '100%',
  },
  checkoutContainer: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexBasis: '100%',
    justifyContent: 'flex-start',
  },
  priceCard: {
    width: 'auto',
  },
  infoCard: {
    alignContent: 'center',
    width: '55%',
    [theme.breakpoints.only('xs')]: {
      width: 'auto',
    },
  },
  infoCardTitle: { fontFamily: 'Brandon Grotesque', textAlign: 'center' },
  infoCardText: {
    textAlign: 'center',
  },
  gridContainerShowInput: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    alignItems: 'center',
    marginTop: theme.spacing(50),
  },
  textButton: {
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  showToggleIcon: { marginLeft: theme.spacing(1) },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
    backgroundSize: 'contain',
  },
  accessCodeGrid: {
    [theme.breakpoints.only('xs')]: {
      borderTop: '3px solid ',
      borderTopColor: theme.palette.background.default,
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      borderLeft: '3px solid ',
      borderLeftColor: theme.palette.background.default,
    },
  },
  alert: {
    marginLeft: theme.spacing(1),
  },
  highlight: {
    paddingTop: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));

export const PaymentForm = () => {
  const {
    user: { userEmail },
  } = useAuthUser();

  const {
    veterinarian,
    isLoading: isUpdatingProfile,
    error: errorProfile,
  } = useVeterinarian();

  const veterinarianDispatch = useVeterinarianDispatch();

  const {
    subscription,
    isLoading: isLoadingSubscription,
    errorMessage: subscriptionError,
  } = useSubscriptionContext();
  const dispatchSubscription = useSubscriptionDispatch();

  const [confirmVeterinarian, setConfirmVeterinarian] = useState(false);
  const [showMoreInformation, setShowMoreInformation] = useState(false);
  const [postAccessCode, setPostAccessCode] = useState(false);
  const [accessCode, setAccessCode] = useState('');
  const [stripeKey, setStripeKey] = useState('');
  const [showAccessCodeAlert, setShowAccessCodeAlert] = useState(false);

  useEffect(() => {
    if (isLoadingSubscription) {
      setPostAccessCode(false);
    }
  }, [isLoadingSubscription]);

  useEffect(() => {
    const stripeKeyUrl = paymentURL + '?stripeKey=1';
    axios.get(stripeKeyUrl).then(
      (result: any) => {
        setStripeKey(result.data.StripeKey);
      },
      (error) => {
        console.error(error);
        throw new Error(
          'Something went wrong while creating the first subscription payment.'
        );
      }
    );
    return () => {
      setStripeKey('');
    };
  }, []);

  useEffect(() => {
    if (subscriptionError !== undefined && subscriptionError.length > 0) {
      setShowAccessCodeAlert(true);
    }
  }, [subscriptionError]);

  const { classes } = useStyles();

  const paymentURL = 'payment/request';

  const handlePayment = () => {
    const stripe = Stripe(stripeKey);
    const sessionUrl =
      paymentURL + `?email=${encodeURIComponent(userEmail || '')}`;
    window.dataLayer.push({
      event: 'purchase',
      value: 49.9,
    });
    axios.get(sessionUrl).then(
      (result: any) => {
        stripe.redirectToCheckout({ sessionId: result.data.Id });
      },
      (error) => {
        console.error(error);
        throw new Error(
          'Something went wrong while creating the first subscription payment.'
        );
      }
    );
  };

  const handleShowMoreToggle = (event: any) => {
    event.preventDefault();
    setShowMoreInformation(true);
  };

  const handleShowLessToggle = (event: any) => {
    event.preventDefault();
    setShowMoreInformation(false);
  };

  const getPrice = (symbol: boolean) => {
    switch (veterinarian?.CountryId) {
      case 233:
        return symbol ? '59,99 $' : '59,99 USD';
      case 232:
        return symbol ? '42,90 £' : '42,90 GBP';
      default:
        return symbol ? '49,90 €' : '49,90 EUR';
    }
  };

  useRequestData<Subscription>({
    needTransport: postAccessCode,
    dispatch: dispatchSubscription,
    url: '/subscription',
    method: 'post',
    data: { accessCode: accessCode },
  });

  const [profileData, setProfileData] = useState<{
    profile: VetProfile;
  } | null>(null);

  useEffect(() => {
    if (isUpdatingProfile) {
      setProfileData(null);
    }
  }, [isUpdatingProfile]);

  const needToUpdateProfile =
    Boolean(profileData) && !isUpdatingProfile && !errorProfile;

  useRequestData<VetProfile[]>({
    needTransport: needToUpdateProfile,
    dispatch: veterinarianDispatch,
    method: 'post',
    url: '/profile',
    data: profileData,
  });

  if (subscription?.hasSubscription && subscription.status !== 3) {
    return <Redirect to={ROUTE_HOME}></Redirect>;
  }

  return (
    <Card title="SUBSCRIPTION" pageView="drawerView">
      <Loader showLoader={isLoadingSubscription && !veterinarian} />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography component="div" variant="h4">
            Purchase your subscription
          </Typography>
          {veterinarian?.PersonTypeId ? (
            <>
              <form className={classes.form} noValidate autoComplete="off">
                {subscription?.status === 3 &&
                  calcDifferenceToToday(subscription.validUntil) >= 0 && (
                    <Typography className={classes.testSubInfo}>
                      <strong>
                        You can subscribe at any point during your free trial
                        and your subscription period will start after the trial
                      </strong>
                    </Typography>
                  )}
                <Typography className={classes.text}>
                  <strong>Product:</strong>
                  <br />
                  GekkoVet, internal medicine of cats and dogs module
                  <br />
                  <br />
                  <strong>Price:</strong>
                  <br />
                  {getPrice(false)} per month, exclusive of value added tax
                  (VAT), use tax or sales tax or similar taxes and payments. The
                  subscription is automatically renewed on monthly basis unless
                  the customer unsubscribes the order prior the new license
                  period.
                </Typography>
                <Grid container justifyContent="flex-start">
                  {showMoreInformation ? (
                    <div
                      className={classes.textButton}
                      onClick={(e: any) => handleShowLessToggle(e)}
                    >
                      <strong>Show less</strong>
                      <ExpandLessIcon className={classes.showToggleIcon} />
                    </div>
                  ) : (
                    <div
                      className={classes.textButton}
                      onClick={(e: any) => handleShowMoreToggle(e)}
                    >
                      <strong>Show more</strong>
                      <ExpandMoreIcon className={classes.showToggleIcon} />
                    </div>
                  )}
                </Grid>
                {showMoreInformation && (
                  <Typography className={classes.text}>
                    <strong>Payment:</strong>
                    <br />
                    Payment method, Credit card: Stripe&apos;s payment service,
                    where valid payment cards are Visa, Master Card or Amex. The
                    payment agreement is always between the customer and the
                    payment service provider.
                    <br />
                    <br />
                    <strong>Delivery time:</strong>
                    <br />
                    The product will be available to the customer instantly
                    after the payment. In case of delay, the subscription does
                    not start until the customer has the access to the GekkoVet.
                    <br />
                    <br />
                    <strong>Termination:</strong>
                    <br />
                    If the customer wants to terminate the subscription, it can
                    be done in GekkoVet in &quot;My Profile&quot; -section,
                    where &quot;Manage my billing&quot; option directs the
                    customer to Stripe payment platform. The termination will be
                    effective once the unsubscription is done and after the
                    ongoing license period (1 month) has lapsed. Please note,
                    the unsubscription shall be done prior the beginning of the
                    new license period.
                    <br />
                    <br />
                    <strong>Use of personal information:</strong>
                    <br />
                    By placing online orders, you accept our Terms of Use for
                    Personal Information, which can be found from the End User
                    License Agreement (EULA) at{' '}
                    <a
                      href="https://www.gekkovet.com/gekkocompass-eula"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.gekkovet.com/gekkocompass-eula
                    </a>{' '}
                    and Data Privacy policy at{' '}
                    <a
                      href="https://www.gekkovet.com/gekkocompass-data-privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.gekkovet.com/gekkocompass-data-privacy-policy
                    </a>
                    .
                    <br />
                    <br />
                    <strong>Common information:</strong>
                    <br />
                    These order conditions apply to any subscription or access
                    to GekkoVet. GekkoVet is a software owned by GekkoVet Oy
                    (Business ID: 2952142-4) and distributed by GekkoVet
                    International Oy (Business ID: 3219881-6). The customer is
                    bound by the End User License Agreement (EULA) valid at the
                    time of placing the order, which the customer must read and
                    accept prior placing the order. The End User License
                    Agreement (EULA) is available at{' '}
                    <a
                      href="https://www.gekkovet.com/gekkocompass-eula"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.gekkovet.com/gekkocompass-eula
                    </a>{' '}
                    and at GekkoVet software. GekkoVet Oy reserves the right to
                    change the End User License Agreement (EULA).
                    <br />
                    <br />
                    <strong>Company information:</strong>
                    <br />
                    GekkoVet Oy, Munkkisaarenkatu 8 B 35, FIN-00150 Helsinki,
                    Business ID: 2952142-4
                    <br />
                    GekkoVet International Oy, Munkkisaarenkatu 8 B 35,
                    FIN-00150 Helsinki, Business ID: 3219881-6
                  </Typography>
                )}
                <Grid
                  container
                  alignItems="center"
                  className={classes.gridContainer}
                >
                  <div className={classes.checkoutContainer}>
                    <CoreCard className={classes.priceCard} variant="outlined">
                      <CardActionArea disabled={true}>
                        <CardContent>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            GekkoVet monthly subscription
                          </Typography>
                          <Typography
                            style={{ fontFamily: 'Brandon Grotesque' }}
                            gutterBottom
                            variant="h5"
                            component="h2"
                          >
                            {getPrice(true)} / month
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </CoreCard>
                    <FormControlLabel
                      key={1}
                      control={
                        <Checkbox
                          checked={confirmVeterinarian}
                          onChange={(e) => {
                            setConfirmVeterinarian(!confirmVeterinarian);
                          }}
                          name={
                            'I confirm I am a certified veterinarian or a veterinary medicine student'
                          }
                          color="primary"
                        />
                      }
                      label={
                        'I confirm I am a certified veterinarian or a veterinary medicine student'
                      }
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={handlePayment}
                      disabled={!confirmVeterinarian}
                    >
                      Proceed to checkout
                    </Button>
                    <CoreCard className={classes.infoCard} variant="outlined">
                      <CardContent>
                        <Typography
                          className={classes.infoCardTitle}
                          gutterBottom
                          variant="h5"
                          component="h2"
                        >
                          PERSONAL DISCOUNT CODE?
                        </Typography>

                        <Typography
                          className={classes.infoCardText}
                          variant="body2"
                          component="p"
                        >
                          Personal discount code can be added once you proceed
                          to checkout. You will be forwarded to Stripe payment
                          system where you can input the personal discount code.
                          After going through the subscription payment process
                          you can unsubscribe any time at My Profile page in
                          GekkoVet.
                        </Typography>
                      </CardContent>
                    </CoreCard>
                  </div>
                </Grid>
              </form>
            </>
          ) : (
            <Alert color="error">
              Please go to your <Link to="/profile">profile page</Link> to
              select your role before subscribing.
            </Alert>
          )}
        </Grid>
        {veterinarian?.PersonTypeId ? (
          <Grid className={classes.accessCodeGrid} item xs={12} sm={6}>
            <Typography component="div" variant="h4">
              Activate your subscription
            </Typography>
            <Typography className={classes.text}>
              If you have an already paid subscription and you have received a
              clinic access code from e.g. your employer, please enter your
              clinic access code below
            </Typography>
            {showAccessCodeAlert && (
              <Alert
                onClose={() => {
                  setShowAccessCodeAlert(false);
                }}
                severity="error"
                className={classes.alert}
              >
                {subscriptionError}
              </Alert>
            )}
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" component="h2">
                Enter a clinic access code
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name={'AccessCode'}
                setValue={(e) => setAccessCode(e.value)}
                currentValue={accessCode}
                value={accessCode}
                label={'Clinic access code'}
                type="text"
                style={{ width: '50%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={!accessCode}
                onClick={() => setPostAccessCode(true)}
              >
                Redeem
              </Button>
            </Grid>
            <Typography className={classes.text}>
              If you are interested in purchasing GekkoVet subscriptions for
              your clinic, please contact{' '}
              {<span className={classes.highlight}>support@gekkovet.com</span>}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Card>
  );
};
