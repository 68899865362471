import React from 'react';
import { Grid } from '@mui/material';
import CardSymptomChecker from './CardSymptomChecker';
import CardDrugLibrary from './CardDrugLibrary';
import CardDiseaseLibrary from './CardDiseaseLibrary';

export default function ThreeFlows() {
  return (
    <Grid container>
      <Grid item xs={12} sm={6} lg={4}>
        <CardSymptomChecker />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <CardDiseaseLibrary />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <CardDrugLibrary />
      </Grid>
    </Grid>
  );
}
