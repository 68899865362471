import Typography from '@mui/material/Typography';
import React from 'react';
import {
  Diagnose,
  DiseaseInfo,
  DiseaseSymptom,
} from '../../types/Diagnose.type';
import Box from '@mui/material/Box';
import DialogSymptoms from '../diagnoses/DialogSymptoms';
import { SymptomTypeCategory } from '../../types/Symptom.type';

const determineDiagnose = (
  toBeDetermined: Diagnose | DiseaseInfo
): toBeDetermined is Diagnose => {
  if ((toBeDetermined as Diagnose).Propability) {
    return true;
  }
  return false;
};

export const DiseaseInformation = ({
  diagnose,
}: {
  diagnose: Diagnose | DiseaseInfo;
}) => {
  return (
    <Typography
      variant="body1"
      style={{
        whiteSpace: 'pre-line',
        textAlign: 'left',
        marginBottom: '0.5em',
      }}
    >
      <strong>Affected systems: </strong>{' '}
      {diagnose.Disease_AffectedSystems.map(
        (element) => element.AffectedSystem.Name.trim() // remove line breaks from end
      ).join(', ')}
    </Typography>
  );
};

export const DiseaseGeographicalAreas = ({
  diagnose,
}: {
  diagnose: Diagnose | DiseaseInfo;
}) => {
  if (determineDiagnose(diagnose)) {
    return (
      <Typography
        variant="body1"
        style={{
          whiteSpace: 'pre-line',
          textAlign: 'left',
          marginBottom: '0.5em',
        }}
      >
        <strong>Geographical area: </strong>{' '}
        {diagnose.GeographicalArea_AnimalTypeDiseases.map((element) => {
          return element.GeographicalArea.Name;
        }).join(', ')}
      </Typography>
    );
  }
  return null;
};

export const DiseaseCauses = ({
  diagnose,
}: {
  diagnose: Diagnose | DiseaseInfo;
}) => {
  return (
    <Typography
      variant="body1"
      style={{
        whiteSpace: 'pre-line',
        textAlign: 'left',
        marginBottom: '0.5em',
      }}
    >
      <strong>Causes: </strong>{' '}
      {diagnose.Disease_Causes.map(
        (element) => element.CauseOfDisease.Name
      ).join(', ')}
    </Typography>
  );
};

export const DiseaseSignalments = ({
  diagnose,
}: {
  diagnose: Diagnose | DiseaseInfo;
}) => {
  return (
    <Typography
      variant="body1"
      style={{
        whiteSpace: 'pre-line',
        textAlign: 'left',
        marginBottom: '0.5em',
      }}
    >
      <strong>Typical signalment: </strong>
      {diagnose.Disease_Signalments.length === 0 && '-'}
      {diagnose.Disease_Signalments.map(
        (element) => element.Signalment.Name
      ).join(', ')}
    </Typography>
  );
};

export const DiseaseSymptoms = ({
  categories,
  categorySymptoms,
  showAddButton = true,
  updateDifferentials,
}: {
  categories: SymptomTypeCategory[];
  categorySymptoms: { [x: number]: DiseaseSymptom[] };
  showAddButton?: boolean;
  updateDifferentials?: () => void;
}) => {
  return (
    <Box
      style={{
        whiteSpace: 'pre-line',
        textAlign: 'left',
        marginBottom: '1em',
      }}
    >
      <strong>SYMPTOMS</strong>
      {categories!.map((category) => (
        <DialogSymptoms
          key={category.Name}
          title={category.Name}
          symptomsList={categorySymptoms[category.Id]}
          showAddButton={showAddButton}
          updateDifferentials={updateDifferentials}
        />
      ))}
    </Box>
  );
};

type DiagnoseKeys = keyof DiseaseInfo;

type DiagnoseData = {
  title: string;
  key: DiagnoseKeys;
  newLine: boolean;
};

const data: DiagnoseData[] = [
  { title: 'GENERAL INFORMATION', key: 'Info', newLine: true },
  { title: 'HOW TO CONFIRM', key: 'HowToConfirm', newLine: true },
  { title: 'HOW TO EXCLUDE', key: 'HowToExclude', newLine: true },
];

export const MidInformation = ({
  diagnose,
}: {
  diagnose: Diagnose | DiseaseInfo;
}) => {
  return (
    <>
      {data.map((param) => {
        return [
          <Typography
            key={`${param.title}_title`}
            variant="body1"
            style={{
              whiteSpace: 'pre-line',
              textAlign: 'left',
              marginBottom: '1em',
            }}
          >
            <strong>{param.title}</strong>
          </Typography>,

          <Typography
            key={param.title}
            variant="body1"
            style={{
              whiteSpace: 'pre-line',
              textAlign: 'left',
              marginBottom: '1em',
            }}
          >
            {`${diagnose[param.key]}`}
          </Typography>,
        ];
      })}
    </>
  );
};
