import React from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Treatment } from '../../types/Diagnose.type';
import { useDosagesPage } from '../../contexts/DosagesPageContext';
import { DrugIndication } from './DrugIndication';
import { Sources } from '../common/Sources';

export const DosageContent = ({
  treatment,
  diseaseName,
  mobile,
}: {
  treatment: Treatment;
  diseaseName: string;
  mobile: boolean;
}) => {
  const { treatmentDosages, treatmentDosagesObj } = useDosagesPage();

  if (!treatmentDosages) {
    return null;
  }

  const drugSubstanceInfoArr = treatmentDosages.filter(
    (treatmentWithDosages) => treatmentWithDosages.TreatmentId === treatment.Id
  );

  const drugSubstanceInfo =
    drugSubstanceInfoArr.length > 0
      ? drugSubstanceInfoArr[0].Dosages[
          treatmentDosagesObj[treatment.DrugSubtance.Id]
        ]
      : null;

  if (!drugSubstanceInfo) {
    return (
      <AccordionDetails>
        <Container
          style={{
            paddingLeft: '0',
            paddingRight: '0',
          }}
        >
          <Typography
            align="left"
            style={{
              textAlign: 'left',
            }}
          >
            There is no info about this treatment and drug
          </Typography>
        </Container>
      </AccordionDetails>
    );
  }

  return (
    <AccordionDetails>
      <Container
        style={{
          paddingLeft: '0',
          paddingRight: '0',
        }}
      >
        <Typography
          align="left"
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          {drugSubstanceInfo.DrugSubstance_Animals.Info}
        </Typography>
        <Sources
          sources={
            drugSubstanceInfo.DrugSubstance_Animals.Source_DrugSubstanceAnimals
          }
        ></Sources>
        <Typography align="left" variant="h5">
          TREATMENT INFO
        </Typography>

        <Typography
          align="left"
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          {treatment.Info}
        </Typography>
        {drugSubstanceInfo.DrugSubstance_Doses.length === 0 && (
          <Typography
            align="left"
            style={{
              paddingTop: '1.5em',
              paddingBottom: '1.5em',
              fontWeight: 'bolder',
              fontSize: '1em',
            }}
          >
            There are no added dosages for this substance yet.
          </Typography>
        )}
        {drugSubstanceInfo.DrugSubstance_Doses.length > 0 && (
          <DrugIndication
            treatment={treatment}
            drugSubstanceInfo={drugSubstanceInfo}
            drugSubstanceId={treatment.DrugSubtance.Id}
            diseaseName={diseaseName}
            mobile={mobile}
          />
        )}
      </Container>
    </AccordionDetails>
  );
};
