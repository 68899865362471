import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import Typography from '@mui/material/Typography';
import { HtmlTooltip } from '../common/HtmlToolTip';

export default function ConditionTooltip() {
  return (
    <>
      <HtmlTooltip
        title={
          <Typography color="inherit">
            This is not final diagnosis but <b>{'a condition'}</b> which
            requires specific treatment.
          </Typography>
        }
      >
        <WarningIcon style={{ fill: '#ff9800' }} />
      </HtmlTooltip>
    </>
  );
}
