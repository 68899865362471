import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { useRequestData } from '../hooks/useRequestData';
import { veterinarianReducer } from '../reducers/veterinarian/veterinarian.reducer';
import {
  VeterinarianState,
  VeterinarianAction,
  DefaultVeterinarianState,
} from '../reducers/veterinarian/veterinarian.state';
import { VetProfile } from '../types/Profile.types';
import { useAuthUser } from './AuthUserContext';
import * as Sentry from '@sentry/react';

const VeterinarianContext = createContext<VeterinarianState>(
  {} as VeterinarianState
);
const VeterinarianDispatch = createContext<Dispatch<VeterinarianAction>>(
  () => {}
);

export const VeterinarianProvider = ({ children }: PropsWithChildren<{}>) => {
  const {
    user: { isAuthenticated },
  } = useAuthUser();
  const [state, dispatch] = useReducer(
    veterinarianReducer,
    DefaultVeterinarianState
  );

  useEffect(() => {
    if (!state.veterinarian) return Sentry.setUser(null);
    const { Email: email }: Sentry.User = state.veterinarian;
    Sentry.setUser({
      email,
    });
  }, [state]);

  const needTransport =
    isAuthenticated && !state.error && !state.isLoading && !state.veterinarian;

  useRequestData<VetProfile[]>({
    needTransport,
    dispatch,
    method: 'get',
    params: { modelName: 'Veterinarians' },
  });

  return (
    <VeterinarianContext.Provider value={state}>
      <VeterinarianDispatch.Provider value={dispatch}>
        {children}
      </VeterinarianDispatch.Provider>
    </VeterinarianContext.Provider>
  );
};

export const useVeterinarian = (): VeterinarianState => {
  return useContext(VeterinarianContext);
};

export const useVeterinarianDispatch = () => {
  return useContext(VeterinarianDispatch);
};
