import { VisitCreated } from '../components/VisitCreated';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useWorkContext } from '../contexts/WorkContext';
import {
  usePatientInfo,
  usePatientInfoDispatch,
} from '../contexts/CurrentPatientInfoContext';
import { useAnimalTypesContext } from '../contexts/AnimalTypesContext';

export const VisitCreatedPage = () => {
  const [patientLoaded, setPatientLoaded] = useState(false);
  const [visitLoaded, setVisitLoaded] = useState(false);
  const [breedLoaded, setBreedLoaded] = useState(false);
  const [animalTypeLoaded, setAnimalTypeLoaded] = useState(false);

  const {
    patient,
    getPatient,
    visit,
    getVisit,
    breed,
    getBreed,
    animalType,
    getAnimalType,
  } = useWorkContext();
  const { patientId, visitId }: any = useParams();
  const { animalTypesObj } = useAnimalTypesContext();
  const { Id: patientInfoId } = usePatientInfo();
  const dispatchPatientInfo = usePatientInfoDispatch();

  useEffect(() => {
    if (patient && patientInfoId !== patient.Id) {
      dispatchPatientInfo({
        type: 'setPatientInfo',
        patient,
        animalTypeName: animalTypesObj[patient.AnimalTypeId].Name,
      });
    }
  }, [animalTypesObj, dispatchPatientInfo, patient, patientInfoId]);

  if (patient === null && !patientLoaded) {
    getPatient(patientId);
    setPatientLoaded(true);
  }

  if (patient && !breedLoaded && !animalTypeLoaded && !visitLoaded) {
    if (patient.BreedId) {
      getBreed(patient.BreedId);
    }
    setBreedLoaded(true);
    getAnimalType(patient.AnimalTypeId);
    setAnimalTypeLoaded(true);
    getVisit(visitId);
    setVisitLoaded(true);
  }

  const viewBreed = breed ? breed : { Name: '' };

  return (
    <VisitCreated
      patient={patient}
      visit={visit}
      breed={viewBreed}
      animalType={animalType}
    />
  );
};
