import { AnimalType } from '../../types/AnimalType.type';
import { RequestAction } from '../../types/request.action.type';

export type AnimalTypeListState = {
  animalTypes: AnimalType[];
  animalTypesObj: { [x: string]: AnimalType };
  isLoading: boolean;
  error?: string;
};

export type AnimalTypeListAction = RequestAction<AnimalType[]>;

export const AnimalTypesInitialState: AnimalTypeListState = {
  isLoading: false,
  animalTypes: [],
  animalTypesObj: {},
};
