import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const DialogDeletePatient = ({
  patientName,
  open,
  handleConfirm,
  handleReject,
}: {
  patientName: string;
  open: boolean;
  handleConfirm: () => void;
  handleReject: () => void;
}) => {
  const descriptionElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleReject}
      scroll="paper"
      aria-labelledby="confirm-delete-patient-dialog-title"
      aria-describedby="confirm-delete-patient-dialog-description"
    >
      <DialogTitle id="confirm-delete-patient-dialog-title">
        Confirm to archive
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-confirm-delete-patient-dialog-description">
          Are you sure you want to archive the patient <b>{patientName}</b>?
          <br />
          After confirmation you <b>will not be able to see</b> the
          patient&apos;s or it&apos;s visits&apos; data anymore.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleReject}
          autoFocus
          color="primary"
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          style={{ backgroundColor: '#FF5A32', color: 'white' }}
        >
          ARCHIVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
