import React, { useState } from 'react';
import { CardTodayVisits } from '../components/home/CardTodayVisits';
import { CardOtherVisits } from '../components/visits/CardOtherVisits';
import Loader from '../components/Loader';

export const VisitsPage = () => {
  const [visitRowClicked, setVisitRowClicked] = useState(false);

  return (
    <>
      <Loader showLoader={visitRowClicked} />
      <CardTodayVisits setVisitRowClicked={setVisitRowClicked} />
      <CardOtherVisits setVisitRowClicked={setVisitRowClicked} />
    </>
  );
};
