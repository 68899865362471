import { Card } from '../common/Card';
import { PatientSummaryCard } from './PatientSummaryCard/PatientSummaryCard';
import { Grid } from '@mui/material';
import { VisitList } from './VisitList/VisitList';
import { CurrentPatientInfoState } from '../../reducers/currentPatientInfo/current.patient.info.state';
import { AddVisitButton } from './AddVisitButton';
import { PatientVisit } from '../../types/PatientVisit.type';
import { Patient } from '../../types/Patient.type';
import Loader from '../Loader';

export const PatientSummary = ({
  todayVisits,
  futureVisits,
  previousVisits,
  patientInfo,
  patient,
  visitLinkClicked,
  setVisitLinkClicked,
}: {
  todayVisits: PatientVisit[];
  futureVisits: PatientVisit[];
  previousVisits: PatientVisit[];
  patientInfo: CurrentPatientInfoState;
  patient: Patient;
  visitLinkClicked: boolean;
  setVisitLinkClicked: (clicked: true) => void;
}) => {
  return (
    <Card
      pageView="drawerView"
      title="PATIENT PAGE"
      style={{ textAlign: 'left' }}
    >
      <Loader showLoader={visitLinkClicked} />
      <Grid container style={{ marginBottom: '20px' }}>
        <Grid item xs={12} sm={6}>
          <PatientSummaryCard patientInfo={patientInfo} />
        </Grid>
      </Grid>
      <AddVisitButton patientId={patientInfo.Id} />
      <VisitList
        title="Today's visits"
        visits={todayVisits}
        patient={patient}
        setVisitLinkClicked={setVisitLinkClicked}
      />
      <VisitList
        title="Future visits"
        visits={futureVisits}
        patient={patient}
        setVisitLinkClicked={setVisitLinkClicked}
      />
      <VisitList
        title="Previous visits"
        visits={previousVisits}
        patient={patient}
        setVisitLinkClicked={setVisitLinkClicked}
      />
    </Card>
  );
};
