import React from 'react';
import { Treatment } from '../../types/Diagnose.type';
import { Typography } from '@mui/material';
import { getTreatmentTitle } from '../../utils/treatmentsUtils';

export const TreatmentCardTitle = ({ treatment }: { treatment: Treatment }) => {
  return (
    <Typography
      align="left"
      style={{
        fontSize: '10px',
        letterSpacing: '1.5px',
        fontWeight: 'bold',
      }}
    >
      {getTreatmentTitle(treatment)}
    </Typography>
  );
};
