import React, { useCallback } from 'react';
import List from '@mui/material/List';
import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

import { NavItemLink } from './NavItemLink';
import { NavItemContainer } from './NavItemContainer';
import { navItems } from './NavigationConfig';
import { useAuthUser } from '../../contexts/AuthUserContext';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: '100%',
    paddingBottom: '0',
  },
}));

export const SideNavigation = ({
  toggleNavOpen,
}: {
  toggleNavOpen?: () => void;
}) => {
  const { classes } = useStyles();
  const { signOut } = useAuthUser();

  const onLogOut = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      signOut();
    },
    [signOut]
  );

  return (
    <List component="nav" className={classes.root}>
      {navItems.map((item) => {
        if ('to' in item) {
          return (
            <NavItemLink
              key={item.title}
              {...item}
              {...(item.title === 'Logout' && { onLogOut })}
              {...(toggleNavOpen && { toggleNavOpen })}
            />
          );
        } else if ('children' in item) {
          return (
            <NavItemContainer
              key={item.title}
              {...item}
              {...(toggleNavOpen && { toggleNavOpen })}
            />
          );
        }

        return null;
      })}
    </List>
  );
};
