import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { treatmentsPageReducer } from '../reducers/treatmentsPage/treatments.page.reducer';
import {
  TreatmentsPageState,
  TreatmentsPageAction,
  TreatmentsPageDefault,
} from '../reducers/treatmentsPage/treatments.page.state';

const TreatmentsPageContext = createContext<TreatmentsPageState>(
  {} as TreatmentsPageState
);
const TreatmentsPageDispatchContext = createContext<
  Dispatch<TreatmentsPageAction>
>(() => {});

export const TreatmentsPageProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(
    treatmentsPageReducer,
    TreatmentsPageDefault
  );

  return (
    <TreatmentsPageContext.Provider value={state}>
      <TreatmentsPageDispatchContext.Provider value={dispatch}>
        {children}
      </TreatmentsPageDispatchContext.Provider>
    </TreatmentsPageContext.Provider>
  );
};

export const useTreatmentsPage = (): TreatmentsPageState => {
  return useContext(TreatmentsPageContext);
};

export const useTreatmentsPageDispatch = () => {
  return useContext(TreatmentsPageDispatchContext);
};
