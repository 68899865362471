import { notNullIsNumberValidator } from './validators';
import {
  DrugAmountState,
  DrugMgPerUnit,
  DrugStrengthState,
  DrugUnit,
  NumberOfUnitsState,
} from '../../types/DrugDosages.type';
import { CalculatedValues } from '../../types/DrugDosageTable.type';

export const getWeightInitialState = (value: string) => {
  return {
    value,
    error: false,
    validator: notNullIsNumberValidator,
  };
};

export const numberOfUnitsInitialState = ({
  strength = '',
  dose = '',
  unit = DrugMgPerUnit['mg/ml'],
  result = '',
}: CalculatedValues<DrugMgPerUnit>): NumberOfUnitsState => {
  return {
    strength: {
      value: strength,
      error: false,
      validator: notNullIsNumberValidator,
    },
    mgPerUnit: { value: unit, error: false },
    dose: { value: dose, error: false, validator: notNullIsNumberValidator },
    result,
  };
};

export const drugAmountInitialState = ({
  strength = '',
  dose = '',
  unit = DrugMgPerUnit['mg/ml'],
  result = '',
}: CalculatedValues<DrugMgPerUnit>): DrugAmountState => {
  return {
    strength: {
      value: strength,
      error: false,
      validator: notNullIsNumberValidator,
    },
    mgPerUnit: { value: unit, error: false },
    numberOfUnits: {
      value: dose,
      error: false,
      validator: notNullIsNumberValidator,
    },
    result,
  };
};

export const drugStrengthInitialState = ({
  strength = '',
  dose = '',
  unit = DrugUnit['ml'],
  result = '',
}: CalculatedValues<DrugUnit>): DrugStrengthState => {
  return {
    numberOfUnits: {
      value: strength,
      error: false,
      validator: notNullIsNumberValidator,
    },
    unit: { value: unit, error: false },
    dose: { value: dose, error: false, validator: notNullIsNumberValidator },
    result,
  };
};
