import React from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import { PatientInfo } from './PatientInfo';

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    marginTop: theme.spacing(3),
    marginRight: '10%',
    marginLeft: '5%',
    maxWidth: 'calc(100% - 15%)',
    overflow: 'auto',
  },
}));

export const PatientInfoWithWrapper = () => {
  const { classes } = useStyles();

  return (
    <Container className={classes.wrapper}>
      <PatientInfo />
    </Container>
  );
};
