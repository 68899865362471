import { PatientPageState, PatientPageAction } from './patientPageState';

export const patientPageReducer = (
  state: PatientPageState,
  action: PatientPageAction
): PatientPageState => {
  switch (action.type) {
    case 'setBreeds': {
      return { ...state, breeds: action.payload };
    }
    case 'setSaving': {
      return { ...state, isSaving: action.payload };
    }
    default:
      return state;
  }
};
