import { Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import React, { Fragment, useState } from 'react';
import {
  ROUTE_CURRENT_VISIT_SAVED,
  ROUTE_HOME,
  ROUTE_VISIT_DIAGNOSIS,
  ROUTE_VISIT_SUMMARY,
  ROUTE_VISIT_TREATMENTS,
} from '../../config/routes.config';
import { useAuthUser } from '../../contexts/AuthUserContext';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';
import { useDiagnosesPage } from '../../contexts/DiagnosesPageContext';
import { useDosagesPageDispatch } from '../../contexts/DosagesPageContext';
import { useDrugProductsDispatch } from '../../contexts/DrugProductsContext';
import { useIntegrationContext } from '../../contexts/IntegrationContext';
import { useTreatmentsPageDispatch } from '../../contexts/TreatmentsPageContext';
import { useWorkHistory } from '../../hooks/useWorkHistory';
import { Button } from '../Button';
import { ButtonTo } from '../common/ButtonTo';
import { FloatingActionBar } from '../FloatingActionBar';
import Loader from '../Loader';
import { Diagnose } from '../../types/Diagnose.type';
import API from '../../api/Api';

declare global {
  interface Window {
    dataLayer: any;
  }
}

const useStyles = makeStyles()((theme: Theme) => ({
  integrationButton: {
    marginRight: theme.spacing(2),
  },
}));

export const DiagnosesFloatingActionBar = () => {
  const { diagnoses, selectedDiagnoses, dontSelect, subsequentDiagnosesObj } =
    useDiagnosesPage();

  const [showLoader, setShowLoader] = useState(false);
  const { signOut } = useAuthUser();

  const { classes } = useStyles();

  const { isTestPatient } = usePatientInfo();

  const { exportState } = useWorkHistory();
  const mobile = useMediaQuery('(max-width:600px)');
  const dispatchTreatments = useTreatmentsPageDispatch();
  const dispatchDosages = useDosagesPageDispatch();
  const dispatchDrugProducts = useDrugProductsDispatch();

  const { integrationConnectionInfo, integrationInfo, integrationSource } =
    useIntegrationContext();

  let integrationCase = false;

  if (integrationConnectionInfo && integrationInfo && integrationSource) {
    integrationCase = true;
  }

  const handleSave = async () => {
    const selected = diagnoses
      ? diagnoses.filter(
          (diagnose: Diagnose) => selectedDiagnoses.indexOf(diagnose.Id) > -1
        )
      : [];

    window.dataLayer.push({
      event: 'selectedDiagnoses',
      diagnosisTotal: selected.length,
    });
    selected.map((selectedDiagnosis) => {
      window.dataLayer.push({
        event: 'selectedDiagnosis',
        diagnosisId: selectedDiagnosis.Id,
        diagnosisName: selectedDiagnosis.Disease.Name,
      });
      return true;
    });

    dispatchTreatments({ type: 'reset' });
    dispatchTreatments({
      type: 'setSubsequentDiseaseNames',
      subsequentDiagnosesObj,
    });
    dispatchDosages({ type: 'reset' });
    dispatchDrugProducts({ type: 'resetAll' });
    if (!isTestPatient) {
      setShowLoader(true);
      await exportState();
      setShowLoader(false);
    }
    (async () => {
      // send selected diagnoses with 10s interval to activecampaing using track
      for (const selectedDiagnosis of selected) {
        API.trackEvent('selectediagnose', selectedDiagnosis.Disease.Name);
        // wait for 10s
        await new Promise((resolve) => setTimeout(resolve, 10000));
      }
    })();
  };

  const handleIntegrationCaseClose = async () => {
    try {
      setShowLoader(true);
      await exportState();
      signOut();
      window.close();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FloatingActionBar>
      <Loader showLoader={showLoader} />
      <Grid item xs={12} container spacing={3}>
        {mobile ? (
          <Fragment>
            <Grid item xs={4}>
              <Typography align="left">
                {integrationCase ? (
                  <Button
                    onClick={handleIntegrationCaseClose}
                    title="Save & return"
                    color="primary"
                    variant="contained"
                    className={classes.integrationButton}
                  >
                    Save & return
                  </Button>
                ) : (
                  <ButtonTo
                    wait
                    handleClick={handleSave}
                    secondary
                    title={isTestPatient ? 'Close' : 'Save & close'}
                    toRoute={ROUTE_HOME}
                    iconName="home"
                    mobile={mobile}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography align="right">
                {!isTestPatient && (
                  <ButtonTo
                    wait
                    currentRoute={ROUTE_VISIT_DIAGNOSIS}
                    handleClick={handleSave}
                    secondary
                    title="Back to Visit"
                    toRoute={ROUTE_CURRENT_VISIT_SAVED}
                    iconName="back"
                    mobile={mobile}
                  />
                )}
                <ButtonTo
                  wait
                  currentRoute={ROUTE_VISIT_DIAGNOSIS}
                  disabled={selectedDiagnoses.length === 0 && !dontSelect}
                  handleClick={handleSave}
                  title="Continue"
                  toRoute={
                    dontSelect ? ROUTE_VISIT_SUMMARY : ROUTE_VISIT_TREATMENTS
                  }
                  iconName="next"
                  mobile={mobile}
                />
              </Typography>
            </Grid>
          </Fragment>
        ) : (
          <Fragment>
            <Grid item xs={12} sm={8}>
              <Typography align="left">
                {!isTestPatient && (
                  <ButtonTo
                    wait
                    currentRoute={ROUTE_VISIT_DIAGNOSIS}
                    handleClick={handleSave}
                    secondary
                    title="Back to Visit"
                    toRoute={ROUTE_CURRENT_VISIT_SAVED}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="right">
                {integrationCase ? (
                  <Button
                    onClick={handleIntegrationCaseClose}
                    title="Save & return"
                    color="primary"
                    variant="contained"
                    className={classes.integrationButton}
                  >
                    Save & return
                  </Button>
                ) : (
                  <ButtonTo
                    wait
                    handleClick={handleSave}
                    secondary
                    title={isTestPatient ? 'Close' : 'Save & close'}
                    toRoute={ROUTE_HOME}
                  />
                )}
                <ButtonTo
                  wait
                  currentRoute={ROUTE_VISIT_DIAGNOSIS}
                  disabled={selectedDiagnoses.length === 0 && !dontSelect}
                  handleClick={handleSave}
                  title="Continue"
                  toRoute={
                    dontSelect ? ROUTE_VISIT_SUMMARY : ROUTE_VISIT_TREATMENTS
                  }
                />
              </Typography>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </FloatingActionBar>
  );
};
