import { DrugSubstances } from '../components/DrugSubstances';
import { useCallback, useEffect, useState } from 'react';
import { DrugSubstance, DrugSubstanceInfo } from '../types/DrugDosages.type';
import API from '../api/Api';
import Loader from '../components/Loader';
import { sortBy } from 'lodash';
import { useAuthUser } from '../contexts/AuthUserContext';

export const DrugSubstancesPage = () => {
  useEffect(() => {
    API.trackEvent('drug_substances_search');
  }, []);
  const [drugSubstanceInfo, setDrugSubstanceInfo] =
    useState<DrugSubstanceInfo | null>(null);
  const { signOut } = useAuthUser();

  const [drugSubstances, setDrugSubstances] = useState<DrugSubstance[]>([]);
  const [loadedDrugSubstances, setLoadedDrugSubstances] = useState(false);
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const getDrugSubstanceInfo = async (
    animalTypeId: string,
    drugSubstanceId: string
  ) => {
    const response: any = await API.getDrugSubstancesInfos(
      animalTypeId,
      drugSubstanceId
    );
    // Check if api is returning NotAuthorizedException and sign user out
    if (
      response.status === 404 &&
      response.data.error.code === 'NotAuthorizedException'
    ) {
      await signOut();
    } else {
      setDrugSubstanceInfo(response.body);
      setIsSearchLoading(false);
    }
  };

  const getDrugSubstances = useCallback(
    async (animalTypeId?: any) => {
      const response: any = await API.getDrugSubstances(animalTypeId);
      // Check if api is returning NotAuthorizedException and sign user out
      if (
        response.status === 404 &&
        response.data.error.code === 'NotAuthorizedException'
      ) {
        await signOut();
      } else {
        const responseBody: DrugSubstance[] = response.body;
        setDrugSubstances(sortBy(responseBody, 'Name'));
        setPageIsLoading(false);
      }
    },
    [signOut]
  );

  const preloadDrugSubstances = useCallback(() => {
    if (!loadedDrugSubstances) {
      setLoadedDrugSubstances(true);
      getDrugSubstances();
    }
  }, [getDrugSubstances, loadedDrugSubstances]);

  useEffect(preloadDrugSubstances, [preloadDrugSubstances]);

  if (pageIsLoading) {
    return <Loader showLoader={true} />;
  }

  return (
    <DrugSubstances
      getDrugSubstanceInfo={getDrugSubstanceInfo}
      getDrugSubstances={getDrugSubstances}
      drugSubstanceInfo={drugSubstanceInfo}
      drugSubstances={drugSubstances}
      setIsSearchLoading={setIsSearchLoading}
      isSearchLoading={isSearchLoading}
    />
  );
};
