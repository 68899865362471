import React from 'react';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const DrugDosageInfoAccordion = ({
  drugDosageInfo,
  expanded = false,
}: {
  drugDosageInfo: string | null;
  expanded?: boolean;
}) => {
  if (!drugDosageInfo) {
    return null;
  }

  return (
    <Accordion
      defaultExpanded={expanded}
      style={{ margin: '5px 0', boxShadow: 'none' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="doseInfo"
        style={{ paddingLeft: '0' }}
      >
        <Typography style={{ fontSize: '14px' }}>Dosage information</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          align="left"
          style={{
            fontSize: '14px',
            whiteSpace: 'pre-line',
          }}
        >
          {drugDosageInfo}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
