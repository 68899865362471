import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import React, { Fragment, useEffect, useState } from 'react';
import {
  DiseaseInfo,
  Treatment,
  TreatmentInfo,
} from '../../types/Diagnose.type';
import { Treatments } from './Treatments';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  DoseAlternative,
  TreatmentDosages,
} from '../../types/DrugDosages.type';
import API from '../../api/Api';
import { DosagesInfo } from './DosagesInfo';
import Loader from '../Loader';
import { sortBy, uniqBy } from 'lodash';
import { DosageContainer } from '../dosages/DosageContainer';
import {
  CalculatedDose,
  DefaultCalculatedDose,
  DefaultDoseInstruction,
} from '../../reducers/dosagesPage/dosages.page.state';
import { useAnimalTypesContext } from '../../contexts/AnimalTypesContext';
import { useAuthUser } from '../../contexts/AuthUserContext';

enum UIState {
  treatments = 'TREATMENTS',
  dosages = 'DOSAGES',
  calculator = 'CALCULATOR',
}

export const DiseaseTreatmentsDialog = ({
  diseaseInfo,
  getTreatmentInfo,
  treatmentInfo,
}: {
  diseaseInfo: DiseaseInfo;
  getTreatmentInfo: (animalTypeDiseaseId: number) => void;
  treatmentInfo?: TreatmentInfo;
}) => {
  const mobile = useMediaQuery('(max-width:600px)');
  const { signOut } = useAuthUser();

  const [open, setOpen] = useState(false);
  const [treatmentsClicked, setTreatmentsClicked] = useState(false);
  const [uiState, setUiState] = useState(UIState.treatments);
  const { animalTypes } = useAnimalTypesContext();

  const [dosagesState, setDosagesState] = useState<{
    chosenTreatment?: Treatment;
    isLoading: boolean;
    error: string | null;
    treatmentDosages: TreatmentDosages | null;
  }>({
    chosenTreatment: undefined,
    isLoading: false,
    error: null,
    treatmentDosages: null,
  });

  const [calculatorState, setCalculatorState] = useState<{
    chosenDoseAlternative: DoseAlternative | null;
    currentCalculatedDoses: CalculatedDose[];
  }>({ chosenDoseAlternative: null, currentCalculatedDoses: [] });

  const [reload, setReload] = useState(false);

  const [selectedTreatmentSetId, setSelectedTreatmentSetId] =
    useState<number>(-1);

  const showTreatmentsView = () => {
    setUiState(UIState.treatments);
  };

  const showDosagesView = () => {
    setUiState(UIState.dosages);
  };

  const handleClose = () => {
    setUiState(UIState.treatments);
    setOpen(false);
  };

  const setCalculatorDoseAlternative = (doseAlternative: DoseAlternative) => {
    setUiState(UIState.calculator);

    const emptyCalculatedDosages = doseAlternative.DrugSubstanceDose_Phases.map(
      (dosePhase) => {
        return {
          ...DefaultCalculatedDose,
          dosePhaseId: dosePhase.Id,
          weight: '',
          doseInstruction: {
            ...DefaultDoseInstruction,
            doseTypeId: dosePhase.DoseType.Id,
          },
        };
      }
    );

    setCalculatorState({
      ...calculatorState,
      chosenDoseAlternative: doseAlternative,
      currentCalculatedDoses: emptyCalculatedDosages,
    });
  };

  const selectTreatmentSet = (treatmentSetId: number) => {
    setSelectedTreatmentSetId(treatmentSetId);
  };

  const treatmentSets = sortBy(
    uniqBy(treatmentInfo?.Treatments, (treatment) => {
      return treatment.TreatmentSet.Id;
    }).map((treatment) => {
      return treatment.TreatmentSet;
    }),
    'Order'
  );

  const fetchAndShowTreatmentDosages = async (
    treatmentId: number,
    animalTypeId: number,
    drugSubstanceId: number,
    chosenTreatment: Treatment
  ) => {
    if (treatmentId !== dosagesState.treatmentDosages?.TreatmentId) {
      try {
        setDosagesState({
          ...dosagesState,
          isLoading: true,
        });
        const resp: any = await API.getTreatmentDosages(
          String(treatmentId),
          String(animalTypeId),
          String(drugSubstanceId)
        );
        // Check if api is returning NotAuthorizedException and sign user out
        if (
          resp.status === 404 &&
          resp.data.error.code === 'NotAuthorizedException'
        ) {
          await signOut();
        } else {
          setDosagesState({
            ...dosagesState,
            treatmentDosages: resp.body[0],
            isLoading: false,
            chosenTreatment: chosenTreatment,
          });
          setUiState(UIState.dosages);
        }
      } catch (error) {
        console.log(error);
        setDosagesState({ ...dosagesState, isLoading: false });
      }
    }
    setUiState(UIState.dosages);
  };

  const handleCalculatedDose = (
    calculatedDose: CalculatedDose,
    index: number
  ) => {
    const newCalculatedDoses = [...calculatorState.currentCalculatedDoses];
    newCalculatedDoses.splice(index, 1, calculatedDose);

    setCalculatorState({
      ...calculatorState,
      currentCalculatedDoses: newCalculatedDoses,
    });
  };

  const getAnimalTypeName = () => {
    const animalType = animalTypes.find(
      (animalType) => animalType.Id === treatmentInfo?.AnimalTypeId
    );

    return animalType ? animalType.Name : '';
  };

  const getTreatmentName = () => {
    return dosagesState.chosenTreatment
      ? dosagesState.chosenTreatment.Name
      : '';
  };

  const getDrugSubstanceId = () => {
    return dosagesState.chosenTreatment
      ? dosagesState.chosenTreatment.DrugSubtance.Id
      : -1;
  };

  useEffect(() => {
    if (treatmentInfo && treatmentsClicked) {
      setOpen(true);
      setTreatmentsClicked(false);
    }
  }, [treatmentInfo, treatmentsClicked]);

  const getDialogTitle = (uiState: UIState) => {
    switch (uiState) {
      case UIState.treatments:
        return 'Treatments';

      case UIState.dosages:
        return (
          <Fragment>
            <IconButton onClick={showTreatmentsView} size="large">
              <ArrowBackIosIcon />
              <div>Back to treatments</div>
            </IconButton>
          </Fragment>
        );

      case UIState.calculator:
        return (
          <Fragment>
            <IconButton onClick={showDosagesView} size="large">
              <ArrowBackIosIcon />
              <div>Back to dosages</div>
            </IconButton>
          </Fragment>
        );
      default:
        break;
    }
  };

  const getDialogContent = (uiState: UIState) => {
    switch (uiState) {
      case UIState.treatments:
        return (
          <Fragment>
            {treatmentInfo && (
              <Treatments
                treatmentInfo={treatmentInfo}
                treatmentSets={treatmentSets}
                fetchAndShowTreatmentDosages={fetchAndShowTreatmentDosages}
                selectedTreatmentSetId={selectedTreatmentSetId}
                setSelectedTreatmentSetId={selectTreatmentSet}
                diseaseInfo={diseaseInfo}
              ></Treatments>
            )}
          </Fragment>
        );

      case UIState.dosages:
        return (
          <Fragment>
            {!dosagesState.isLoading &&
              (dosagesState.treatmentDosages?.Dosages[0] ? (
                <DosagesInfo
                  drugSubstanceInfo={dosagesState.treatmentDosages.Dosages[0]}
                  setCalculatorDoseAlternative={setCalculatorDoseAlternative}
                  mobile={mobile}
                  drugSubstanceName={
                    dosagesState.chosenTreatment?.DrugSubtance.Name
                  }
                />
              ) : (
                <h1>No dosages</h1>
              ))}
          </Fragment>
        );

      case UIState.calculator:
        return (
          <Fragment>
            {calculatorState.chosenDoseAlternative?.DrugSubstanceDose_Phases.map(
              (dosePhase, index) => {
                return (
                  <DosageContainer
                    key={dosePhase.Id}
                    animalTypeName={getAnimalTypeName()}
                    treatmentName={getTreatmentName()}
                    drugSubstanceId={getDrugSubstanceId()}
                    dosePhase={dosePhase}
                    calculatedDose={
                      calculatorState.currentCalculatedDoses[index]
                    }
                    index={index}
                    setCalculatedDose={handleCalculatedDose}
                    diseaseName={diseaseInfo.Disease.Name}
                    reload={reload}
                    resetReload={() => {
                      if (reload) setReload(false);
                    }}
                    mobile={mobile}
                    showDoseInstruction={false}
                  />
                );
              }
            )}
          </Fragment>
        );

      default:
    }
  };

  return (
    <div>
      <Box
        style={{
          whiteSpace: 'pre-line',
          textAlign: 'left',
          marginBottom: '1em',
          fontWeight: 'bold',
        }}
      >
        TREATMENTS
        {diseaseInfo.TreatmentsPublished === 1 && (
          <div>
            <Button
              color="primary"
              onClick={() => {
                if (!treatmentInfo) {
                  getTreatmentInfo(diseaseInfo.Id);
                  setTreatmentsClicked(true);
                } else {
                  setOpen(true);
                }
              }}
            >
              SHOW TREATMENTS
            </Button>
          </div>
        )}
        {diseaseInfo.TreatmentsPublished === 0 && (
          <Typography style={{ marginTop: '5px' }}>
            Treatments for this disease/condition not yet available.
          </Typography>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogTitle id="scroll-dialog-title">
          <Loader showLoader={dosagesState.isLoading} />
          {getDialogTitle(uiState)}
        </DialogTitle>
        <DialogContent>{getDialogContent(uiState)}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
