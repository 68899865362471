import { Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { Source_AnimalTypeDiseases } from '../../types/Diagnose.type';
import { SourcesDialog } from '../common/SourcesDialog';

export const DiseaseSourcesModal = ({
  sources,
}: {
  sources: Source_AnimalTypeDiseases[];
}) => {
  const [sourceModalOpen, setSourceModalOpen] = useState(false);

  const toggleSourceModal = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent> | null,
    open: boolean
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setSourceModalOpen(open);
  };

  return (
    <Fragment>
      {sources.length === 0 ? (
        <Typography>No sources</Typography>
      ) : (
        <Typography
          onClick={(event) => toggleSourceModal(event, true)}
          style={{
            color: '#00aa79',
            fontSize: '12px',
            letterSpacing: '0.09px',
            marginBottom: '16px',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          align="left"
        >
          Sources
        </Typography>
      )}
      {sourceModalOpen && (
        <SourcesDialog
          isOpen={sourceModalOpen}
          closeDialog={(event) => toggleSourceModal(event, false)}
          sources={sources}
        />
      )}
    </Fragment>
  );
};
