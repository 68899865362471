import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ErrorNoAuthUser } from '../components/ErrorNoAuthUser';
import { ConfirmationContainer } from '../components/registration/ConfirmationContainer';
import { RegistrationContainer } from '../components/registration/RegistrationContainer';

import Qs from 'qs';
import SignupParams from '../types/registration/SignupParams.type';

import * as Sentry from '@sentry/react';

export const RegistrationPage = () => {
  const { search } = useLocation();
  const { activationCode } = useMemo(() => {
    return Qs.parse(search, { ignoreQueryPrefix: true });
  }, [search]);
  const [userData, setUserData] = useState<SignupParams | undefined>(
    typeof activationCode === 'string' ? { activationCode } : undefined
  );
  const { pathname } = useLocation();

  return userData ? (
    <Sentry.ErrorBoundary fallback={ErrorNoAuthUser} key={pathname}>
      <ConfirmationContainer {...userData} />
    </Sentry.ErrorBoundary>
  ) : (
    <Sentry.ErrorBoundary fallback={ErrorNoAuthUser} key={pathname}>
      <RegistrationContainer setUserData={setUserData} />
    </Sentry.ErrorBoundary>
  );
};
