import { Card } from '../components/common/Card';
import { Typography, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useIsPilotEnviroment } from '../hooks/useIsPilotEnviroment';

export const SupportPage = () => {
  const mobile = useMediaQuery('(max-width:600px)');
  const pilotEnviroment = useIsPilotEnviroment();

  useEffect(() => {
    const script = document.createElement('script');
    const el = document.getElementById('policyContent');
    script.id = 'CookieDeclaration';
    script.src =
      window.location.host === 'compass.gekkovet.com'
        ? 'https://consent.cookiebot.com/9534c164-e1ce-4d75-82fa-d38e95c39723/cd.js'
        : 'https://consent.cookiebot.com/d91df632-e7ae-45f7-9510-b9bc61bf43e6/cd.js';
    script.async = true;
    script.type = 'text/javascript';

    el?.appendChild(script);

    return () => {
      el?.removeChild(script);
    };
  }, []);

  return (
    <div id="policyContentContainer">
      <Card
        pageView={mobile ? 'mobileView' : 'drawerView'}
        title="Support"
        style={{ paddingBottom: '0' }}
      >
        <Typography align="left">
          Do you have feedback or did you run into a problem? Contact our
          support at support@gekkovet.com. We will get back to you within 24
          hours.
        </Typography>
      </Card>
      {!pilotEnviroment && (
        <Card
          pageView={mobile ? 'mobileView' : 'drawerView'}
          title="Cookie policy"
          style={{ paddingBottom: '0' }}
        >
          <div
            className="MuiCardContent-root"
            id="policyContent"
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              height: '90%',
              maxWidth: '100vw',
            }}
          ></div>
        </Card>
      )}
    </div>
  );
};
