import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  ROUTE_NEW_VISIT,
  ROUTE_CURRENT_VISIT,
  ROUTE_CURRENT_VISIT_SAVED,
  ROUTE_VISIT_DIAGNOSIS,
} from '../config/routes.config';
import { VisitCreatedPage } from '../pages/VisitCreatedPage';
import { VisitPage } from '../pages/VisitPage';
import { DiagnoseRouter } from './DiagnoseRouter';

export const VisitRouter = () => {
  return (
    <Switch>
      <Route exact path={ROUTE_NEW_VISIT}>
        <VisitPage />
      </Route>

      <Route exact path={ROUTE_CURRENT_VISIT}>
        <VisitPage />
      </Route>

      <Route exact path={ROUTE_CURRENT_VISIT_SAVED}>
        <VisitCreatedPage />
      </Route>

      <Route path={ROUTE_VISIT_DIAGNOSIS}>
        <DiagnoseRouter />
      </Route>

      <Redirect to={ROUTE_NEW_VISIT} />
    </Switch>
  );
};
