import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { subscriptionReducer } from '../reducers/subscription/subscription.reducer';
import {
  SubscriptionState,
  SubscriptionAction,
  SubscriptionInitialState,
} from '../reducers/subscription/subscription.state';

const SubscriptionContext = createContext<SubscriptionState>(
  {} as SubscriptionState
);
const SubscriptionDispatchContext = createContext<Dispatch<SubscriptionAction>>(
  () => {}
);

export const SubscriptionProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(
    subscriptionReducer,
    SubscriptionInitialState
  );

  return (
    <SubscriptionContext.Provider value={state}>
      <SubscriptionDispatchContext.Provider value={dispatch}>
        {children}
      </SubscriptionDispatchContext.Provider>
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionContext = (): SubscriptionState => {
  return useContext(SubscriptionContext);
};

export const useSubscriptionDispatch = () => {
  return useContext(SubscriptionDispatchContext);
};
