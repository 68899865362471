import React, { useEffect, useState } from 'react';
import {
  usePatientListDispatch,
  usePatientListState,
} from '../../contexts/PatientListContext';
import { useRequestData } from '../../hooks/useRequestData';
import {
  InitialPatientSearchOptions,
  SearchPatientOptions,
} from '../../reducers/patientList/patient.list.state';
import { ANIMAL_TYPE_ID_UNDEFINED } from '../../types/AnimalType.type';
import { Male, Female } from '../../types/Gender.type';
import { Patient } from '../../types/Patient.type';
import Loader from '../Loader';
import { ListAllPatients } from './ListAllPatients';

const filterPatients = (
  list: Patient[],
  searchOptions: SearchPatientOptions
): Patient[] => {
  if (
    JSON.stringify(searchOptions) ===
    JSON.stringify(InitialPatientSearchOptions)
  ) {
    return list;
  }

  const partialPatient: Partial<Patient> = {
    ...(searchOptions.NameOrId !== '' && {
      NameOrId: searchOptions.NameOrId.toLowerCase(),
    }),
    ...(searchOptions.Identifier !== '' && {
      Identifier: searchOptions.Identifier.toLowerCase(),
    }),
    ...(searchOptions.Gender !== 'any' && {
      GenderId: searchOptions.Gender === 'male' ? Male : Female,
    }),
    ...(searchOptions.AnimalTypeId !== ANIMAL_TYPE_ID_UNDEFINED && {
      AnimalTypeId: searchOptions.AnimalTypeId,
    }),
  };

  const filtered = list.filter((patient) =>
    Object.entries(partialPatient).every(([key, value]) => {
      switch (key) {
        case 'GenderId':
          return patient.GenderId === value;

        case 'AnimalTypeId':
          return patient.AnimalTypeId === value;

        case 'NameOrId':
          return (
            (typeof value === 'string' &&
              patient.NameOrId?.toLowerCase().includes(value)) ||
            ''
          );

        case 'Identifier':
          return !patient.Identifier
            ? false
            : typeof value === 'string' &&
                patient.Identifier?.toLowerCase().includes(value);

        default:
          return false;
      }
    })
  );

  return filtered;
};

export const ListAllPatientsContainer = ({
  isSelect,
}: {
  isSelect?: boolean;
}) => {
  const { error, patients, isLoading, searchOptions } = usePatientListState();
  const dispatchPatients = usePatientListDispatch();

  const [filteredList, setFilteredList] = useState<Patient[]>();

  useRequestData<Patient[]>({
    needTransport: !error && !patients && !isLoading,
    dispatch: dispatchPatients,
    method: 'get',
    params: {
      modelName: 'Patients',
    },
  });

  useEffect(() => {
    if (patients) {
      setFilteredList(filterPatients(patients, searchOptions));
    }
  }, [patients, searchOptions]);

  if (error) {
    dispatchPatients({ type: 'reset' });
    throw error;
  }

  if (!patients) {
    return <Loader showLoader={true} />;
  }

  return (
    <ListAllPatients isSelect={isSelect} list={filteredList || patients} />
  );
};
