const getDate5yearsFromNow = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 5);
  return date.toISOString();
};

const TestPatientDogFemale = {
  Id: 1,
  AnimalTypeId: 2, // Dog
  GenderId: 2, // Female
  Neutered: 0,
  Weight: '20',
  NameOrId: 'Dog, female, intact',
  BreedId: 1248, // Dog Mixed breed
  PetOwnerId: 0,
  Identifier: 'identifier',
  DateOfBirth: getDate5yearsFromNow(),
  Disabled: 0,
  TestPatient: true,
};

const TestPatientDogFemaleNeutered = {
  Id: 2,
  AnimalTypeId: 2, // Dog
  GenderId: 2, // Female
  Neutered: 1,
  Weight: '20',
  NameOrId: 'Dog, female, neutered',
  BreedId: 1248, // Dog Mixed breed
  PetOwnerId: 0,
  Identifier: 'identifier',
  DateOfBirth: getDate5yearsFromNow(),
  Disabled: 0,
  TestPatient: true,
};

const TestPatientDogMale = {
  Id: 3,
  AnimalTypeId: 2, // Dog
  GenderId: 1, // Male
  Neutered: 0,
  Weight: '20',
  NameOrId: 'Dog, male, intact',
  BreedId: 1248, // Dog Mixed breed
  PetOwnerId: 0,
  Identifier: 'identifier',
  DateOfBirth: getDate5yearsFromNow(),
  Disabled: 0,
  TestPatient: true,
};

const TestPatientDogMaleNeutered = {
  Id: 4,
  AnimalTypeId: 2, // Dog
  GenderId: 1, // Male
  Neutered: 1,
  Weight: '20',
  NameOrId: 'Dog, male, neutered',
  BreedId: 1248, // Dog Mixed breed
  PetOwnerId: 0,
  Identifier: 'identifier',
  DateOfBirth: getDate5yearsFromNow(),
  Disabled: 0,
  TestPatient: true,
};

const TestPatientCatFemale = {
  Id: 5,
  AnimalTypeId: 1, // Cat
  GenderId: 2, // Female
  Neutered: 0,
  Weight: '5',
  NameOrId: 'Cat, female, intact',
  BreedId: 1249, // Cat Mixed breed
  PetOwnerId: 0,
  Identifier: 'identifier',
  DateOfBirth: getDate5yearsFromNow(),
  Disabled: 0,
  TestPatient: true,
};

const TestPatientCatFemaleNeutered = {
  Id: 6,
  AnimalTypeId: 1, // Cat
  GenderId: 2, // Female
  Neutered: 1,
  Weight: '5',
  NameOrId: 'Cat, female, neutered',
  BreedId: 1249, // Cat Mixed breed
  PetOwnerId: 0,
  Identifier: 'identifier',
  DateOfBirth: getDate5yearsFromNow(),
  Disabled: 0,
  TestPatient: true,
};

const TestPatientCatMale = {
  Id: 7,
  AnimalTypeId: 1, // Cat
  GenderId: 1, // Male
  Neutered: 0,
  Weight: '5',
  NameOrId: 'Cat, male, intact',
  BreedId: 1249, // Cat Mixed breed
  PetOwnerId: 0,
  Identifier: 'identifier',
  DateOfBirth: getDate5yearsFromNow(),
  Disabled: 0,
  TestPatient: true,
};

const TestPatientCatMaleNeutered = {
  Id: 8,
  AnimalTypeId: 1, // Cat
  GenderId: 1, // Male
  Neutered: 1,
  Weight: '5',
  NameOrId: 'Cat, male, neutered',
  BreedId: 1249, // Cat Mixed breed
  PetOwnerId: 0,
  Identifier: 'identifier',
  DateOfBirth: getDate5yearsFromNow(),
  Disabled: 0,
  TestPatient: true,
};

export const testPatients = [
  TestPatientDogFemale,
  TestPatientDogFemaleNeutered,
  TestPatientDogMale,
  TestPatientDogMaleNeutered,
  TestPatientCatFemale,
  TestPatientCatFemaleNeutered,
  TestPatientCatMale,
  TestPatientCatMaleNeutered,
];

export const getTestPatient = (id: number) => {
  return testPatients.find((patient) => patient.Id === id);
};
