import { Button } from '@mui/material';
import { generatePath, Link } from 'react-router-dom';
import { ROUTE_NEW_VISIT } from '../../config/routes.config';

export const AddVisitButton = ({ patientId }: { patientId: number }) => {
  const pathname = generatePath(ROUTE_NEW_VISIT, {
    patientId: patientId.toString(),
  });
  return (
    <Button
      variant="outlined"
      color="primary"
      style={{ marginBottom: '20px' }}
      component={Link}
      to={pathname}
    >
      Add a visit
    </Button>
  );
};
