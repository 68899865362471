import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button } from '../Button';
import { SelectSymptomByName } from './SelectSymptomByName';
import { SymptomOption } from '../../reducers/symptomsList/symptoms.state';
import { useDiagnosesPageDispatch } from '../../contexts/DiagnosesPageContext';
import Typography from '@mui/material/Typography';

export const SymptomByName = () => {
  const dispatch = useDiagnosesPageDispatch();

  const [symptomOption, setSymptomOption] =
    React.useState<SymptomOption | null>(null);

  const [inputValue, setInputValue] = useState('');

  const handleSelectSymptom = (symptom: SymptomOption | null) => {
    setSymptomOption(symptom);
  };

  const handleAddSymptom = () => {
    window.dataLayer.push({
      event: 'addedSymptom',
      symptomName: symptomOption!.Name,
    });
    dispatch({
      type: 'addSymptom',
      symptomId: symptomOption!.Id,
    });
    setSymptomOption(null);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle2" gutterBottom align="left">
          Search symptom by name (min. 2 characters)
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SelectSymptomByName
          value={symptomOption}
          onSelected={handleSelectSymptom}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      </Grid>

      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Button
          title="Add Symptom"
          color="primary"
          variant="contained"
          disabled={!symptomOption}
          onClick={handleAddSymptom}
        >
          ADD SYMPTOM
        </Button>
      </Grid>
    </Grid>
  );
};
