import { makeStyles } from 'tss-react/mui';
import { PropsWithChildren } from 'react';

const useStyles = makeStyles()(() => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      justifyContent: 'center',
      minHeight: 'calc(100% - 70px)',
    },
  };
});

export const MainContent = ({
  children,
  id,
}: PropsWithChildren<{ id?: string }>) => {
  const { classes } = useStyles();
  return (
    <main id={id} className={classes.root} role="main">
      {children}
    </main>
  );
};
