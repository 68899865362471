import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Card } from '../common/Card';
import DosageAccordionList from './DosageAccordionList';

export const CardDosages = () => {
  const mobile = useMediaQuery('(max-width:600px)');
  return (
    <Card
      title="DRUG DOSAGES"
      pageView="drawerView"
      withoutMarginBottom={!mobile}
      maxWidth
    >
      <Grid container>
        <Grid item xs={12}>
          <DosageAccordionList />
        </Grid>
      </Grid>
    </Card>
  );
};
