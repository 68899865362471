import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const DrugSubstanceAnimalInfoAccordion = ({
  drugSubstanceAnimalInfo,
  expanded = false,
}: {
  drugSubstanceAnimalInfo: string | null;
  expanded?: boolean;
}) => {
  if (!drugSubstanceAnimalInfo) {
    return null;
  }

  return (
    <Accordion
      defaultExpanded={expanded}
      style={{ margin: '5px 0', boxShadow: 'none' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="doseInfo"
        style={{ paddingLeft: '0' }}
      >
        <Typography style={{ fontSize: '14px' }}>
          Animal specific information
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          align="left"
          style={{
            fontSize: '14px',
            whiteSpace: 'pre-line',
          }}
        >
          {drugSubstanceAnimalInfo}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
