import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from 'tss-react/mui';

import bgImage from '../assets/images/bg.jpg';
import { ErrorInfo } from '../components/ErrorInfo';
import { DrawerNav } from '../components/side-navigation/DrawerNav';
import { Header } from '../components/top-navigation/Header';
import { useAuthUser } from '../contexts/AuthUserContext';
import { PatientListProvider } from '../contexts/PatientListContext';

import { SubscriptionLayout } from './SubscriptionLayout';
import { NoAuthLayout } from './NoAuthLayout';
import Loader from '../components/Loader';
import { ROUTE_HOME, ROUTE_PATIENTS } from '../config/routes.config';
import { useRequestData } from '../hooks/useRequestData';
import {
  useSubscriptionDispatch,
  useSubscriptionContext,
} from '../contexts/SubscriptionContext';
import { Subscription } from '../types/Subscription.type';
import { NoSubscriptionLayout } from './NoSubscriptionLayout';
import { VeterinarianProvider } from '../contexts/VeterinarianContext';
import * as Sentry from '@sentry/react';
import { CSSObject } from 'tss-react';
import API from '../api/Api';

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of MemberExpression.
const useStyles = makeStyles()((theme) => ({
  wrapper: {
    //height: '100vh',
    display: 'flex',
    //overflowY: 'hidden',
    minHeight: '100vh',
  },
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar as CSSObject,
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    paddingTop: '1px',
    paddingBottom: '1px',
    //overflowY: 'scroll',
    textAlign: 'center',
  },
  auth: {
    backgroundColor: 'rgba(210, 200, 195, 0.4)',
  },
  notAuth: {
    opacity: '0.8',
    backgroundImage: `url(${bgImage})`,
    backgroundPosition: 'top',
    backgroundSize: 'cover',
  },
  authWithBackgroundImg: {
    opacity: '0.8',
    backgroundImage: `url(${bgImage})`,
    backgroundPosition: 'top',
    backgroundSize: 'cover',
  },
}));

export const BaseLayout = () => {
  const {
    user: { isAuthenticated, error },
  } = useAuthUser();

  const { classes } = useStyles();

  const [drawerNavOpen, setDrawerNavOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setDrawerNavOpen((drawerNavOpen) => !drawerNavOpen);
  };
  const { subscription, isLoading: isLoadingSubscription } =
    useSubscriptionContext();
  const dispatchSubscription = useSubscriptionDispatch();

  useRequestData<Subscription>({
    needTransport: isAuthenticated && !isLoadingSubscription && !subscription,
    dispatch: dispatchSubscription,
    url: '/subscription',
    method: 'get',
  });

  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  useEffect(() => {
    const code = params.get('code');
    if (!code) {
      return;
    }
    API.trackEvent('login');
  }, [params]);

  const { pathname } = useLocation();

  const contentClass = () => {
    if (!isAuthenticated && error) {
      return classes.notAuth;
    }

    if (!subscription || !subscription.hasSubscription) {
      return classes.notAuth;
    }

    if (pathname === ROUTE_HOME || pathname === ROUTE_PATIENTS) {
      return classes.authWithBackgroundImg;
    }

    return classes.auth;
  };

  return (
    <div className={classes.wrapper}>
      <CssBaseline />
      <VeterinarianProvider>
        <div className={classes.root}>
          <Header toggleNavOpen={handleDrawerToggle} />

          {isAuthenticated && (
            <DrawerNav
              isOpen={drawerNavOpen}
              toggleNavOpen={handleDrawerToggle}
            />
          )}

          <div className={classes.contentWrapper}>
            <div className={classes.toolbar} />
            <div className={`${classes.content} ${contentClass()}`}>
              {!isAuthenticated ? (
                error ? (
                  <NoAuthLayout />
                ) : (
                  <Loader showLoader={true} />
                )
              ) : subscription ? (
                subscription.hasSubscription === false ? (
                  <Sentry.ErrorBoundary fallback={ErrorInfo}>
                    <NoSubscriptionLayout />
                  </Sentry.ErrorBoundary>
                ) : (
                  <PatientListProvider>
                    <Sentry.ErrorBoundary fallback={ErrorInfo} key={pathname}>
                      <SubscriptionLayout />
                    </Sentry.ErrorBoundary>
                  </PatientListProvider>
                )
              ) : (
                <Loader showLoader={!subscription} />
              )}
            </div>
          </div>
        </div>
      </VeterinarianProvider>
    </div>
  );
};
