import { Grid, MenuItem } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Country } from '../../types/country.type';
import { PersonType } from '../../types/PersonType.type';
import {
  ProfileBaseFieldName,
  profileBaseFieldNames,
} from '../../types/Profile.types';
import { ProfileBaseFormData } from '../../types/profile/profileForm.type';
import { CountrySelect } from '../common/CountrySelect';
import { TextField } from '../common/TextField';

export const ProfilePerson = ({
  countryList,
  personTypes,
  profileState,
  handleProfileChange,
}: {
  countryList: Country[];
  personTypes: PersonType[];
  profileState?: typeof ProfileBaseFormData;
  handleProfileChange: (props: {
    name: ProfileBaseFieldName;
    value: string;
  }) => void;
}) => {
  const makeElements = useCallback(() => {
    const fieldsElements: JSX.Element[] = [];
    if (profileState) {
      profileBaseFieldNames.forEach((name: string) => {
        const field = profileState[name];

        if (profileState[name].type === 'text') {
          fieldsElements.push(
            <Grid key={name} item xs={12} sm={6}>
              <TextField
                {...field}
                name={name}
                setValue={handleProfileChange}
                currentValue={field.value}
              />
            </Grid>
          );
        } else if (profileState[name].type === 'country') {
          fieldsElements.push(
            <Grid key={name} item xs={12} sm={6}>
              <CountrySelect
                {...field}
                name={name}
                setValue={handleProfileChange}
                currentValue={field.value}
                list={countryList}
              />
            </Grid>
          );
        } else if (profileState[name].type === 'personType') {
          fieldsElements.push(
            <Grid key={name} item xs={12} sm={6}>
              <TextField
                {...field}
                name={name}
                setValue={handleProfileChange}
                currentValue={field.value}
                select
              >
                {personTypes.map((pt) => (
                  <MenuItem key={pt.Id} value={pt.Id}>
                    {pt.Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          );
        }
      });
    }
    return fieldsElements;
  }, [countryList, handleProfileChange, profileState, personTypes]);

  const [fieldsElements, setFieldsElements] = useState<JSX.Element[]>(
    makeElements()
  );

  useEffect(() => {
    if (profileState) {
      setFieldsElements(makeElements());
    }
  }, [makeElements, profileState]);

  return (
    <Grid item xs={12} container direction="row" spacing={3}>
      {fieldsElements}
    </Grid>
  );
};
