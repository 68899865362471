import React, { useCallback, useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom';
import { ROUTE_AUTH } from '../../config/routes.config';
import Loader from '../Loader';
import ConfirmationForm from './ConfirmationForm';
import SignupParams from '../../types/registration/SignupParams.type';

type Status = 'initial' | 'validating' | 'confirmed';
type ErrorCode =
  | 'Invalid verification code provided, please try again.'
  | 'Invalid code provided, please request a code again.';

export const ConfirmationContainer = ({
  username: initialUsername,
  activationCode,
}: SignupParams) => {
  const [error, setError] = useState<ErrorCode | null>(null);
  const [state, setState] = useState<Status>('initial');
  const [code, setCode] = useState<string | null | undefined>(activationCode);
  const [username, setUsername] = useState<string | null | undefined>(
    initialUsername
  );

  const history = useHistory();

  const confirmEmail = useCallback(async () => {
    if (!username || !code) return;
    Auth.confirmSignUp(username, code).then(
      (_result) => {
        setState('confirmed');
      },
      (error) => {
        setError(error.message);
      }
    );
  }, [code, username]);

  useEffect(() => {
    if (code && username) {
      setState('validating');
      confirmEmail();
    }
  }, [code, confirmEmail, username]);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  useEffect(() => {
    if (state === 'confirmed') {
      history.push(ROUTE_AUTH, 'confirmed');
    }
  }, [history, state]);

  return (
    <>
      <ConfirmationForm
        setCode={setCode}
        userName={username}
        setUsername={setUsername}
        activationCode={activationCode}
      />
      <Loader showLoader={state !== 'initial'} />
    </>
  );
};
