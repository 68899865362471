import { Dispatch, useCallback, useEffect } from 'react';
import axios, { Method } from 'axios';
import { RequestAction } from '../types/request.action.type';
import { useAuthUser } from '../contexts/AuthUserContext';

export const defaultUrl = '/model';

export interface UrlParams {
  [x: string]: string | number | number[];
}

export const useRequestData = <T>({
  needTransport,
  dispatch,
  method,
  url = defaultUrl,
  params,
  data,
  dispatchOptions,
}: {
  needTransport: boolean;
  dispatch: Dispatch<RequestAction<T>>;
  method: Method;
  url?: string;
  params?: UrlParams;
  data?: any;
  dispatchOptions?: any;
}) => {
  const { signOut } = useAuthUser();

  const transport = useCallback(() => {
    if (
      method !== 'get' &&
      method !== 'post' &&
      method !== 'delete' &&
      method !== 'put'
    ) {
      throw new Error('method can be get / post / delete / put');
    }
    if (method === 'post' && !data) {
      throw new Error('data object is missing to post request');
    }

    dispatch({
      type: 'request',
      ...(dispatchOptions && { options: dispatchOptions }),
    });

    axios
      .request({
        method,
        url,
        ...(params && { params }),
        ...(method === 'post' && data && { data }),
        ...(method === 'delete' && data && { data }),
      })
      .then(
        (result) => {
          dispatch({
            type: 'success',
            responseData: result.data,
            ...(dispatchOptions && { options: dispatchOptions }),
          });
        },
        (error) => {
          if (error.code === 'NotAuthorizedException') {
            signOut();
          } else {
            dispatch({
              type: 'failure',
              error: error.response?.data
                ? error.response?.data
                : error.message
                ? error.message
                : 'Server problem :(',
              ...(dispatchOptions && { options: dispatchOptions }),
            });
          }
        }
      );
  }, [data, dispatch, signOut, dispatchOptions, method, params, url]);

  useEffect(() => {
    if (needTransport) {
      transport();
    }
  }, [transport, needTransport, url]);
};
