import { BreedsState, BreedsAction, BreedsInitialState } from './breeds.state';

export const breedsReducer = (
  state: BreedsState,
  action: BreedsAction
): BreedsState => {
  switch (action.type) {
    case 'reset': {
      return { ...BreedsInitialState };
    }

    case 'request': {
      return { isLoading: true, breeds: [] };
    }

    case 'success': {
      return {
        isLoading: false,
        breeds: action.responseData,
      };
    }

    case 'failure':
      return {
        isLoading: false,
        error: action.error,
        breeds: [],
      };

    default:
      return state;
  }
};
