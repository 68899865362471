import { Moment } from 'moment';
import { PatientVisit } from '../../types/PatientVisit.type';
import { RequestAction } from '../../types/request.action.type';

export type VisitsListState = {
  visits?: PatientVisit[];
  visitsObj?: { [x: string]: PatientVisit };
  isLoading: boolean;
  error?: string;
  fromDate?: Moment;
  toDate?: Moment;
  noVisitsText: string;
};

export const VisitsListDefault: VisitsListState = {
  isLoading: false,
  noVisitsText: '',
};

export type VisitsListAction =
  | RequestAction<PatientVisit[]>
  | {
      type: 'add';
      visit: PatientVisit;
    }
  | {
      type: 'update';
      visit: PatientVisit;
    }
  | {
      type: 'deleteVisitsForPatientId';
      patientId: number;
    }
  | {
      type: 'setDates';
      fromDate: Moment;
      toDate: Moment;
    };
