import React from 'react';
import { makeStyles } from 'tss-react/mui';
import DiagnoseAccordion from './DiagnoseAccordion';
import { useDiagnosesPage } from '../../contexts/DiagnosesPageContext';

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
});

export default function DiagnoseAccordionList({
  highlightBreedId,
  updateDifferentials,
}: {
  highlightBreedId?: number | undefined;
  updateDifferentials: () => void;
}) {
  const { classes } = useStyles();
  const { diagnoses, selectedDiagnoses } = useDiagnosesPage();

  return (
    <div className={classes.root}>
      {diagnoses?.map((diagnose) => (
        <DiagnoseAccordion
          checked={selectedDiagnoses.indexOf(diagnose.Id) > -1}
          key={diagnose.Id}
          diagnose={diagnose}
          highlightBreedId={highlightBreedId}
          updateDifferentials={updateDifferentials}
        />
      ))}
    </div>
  );
}
