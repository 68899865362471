import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';

export const DrugSubstanceComboBox = ({
  handleChange,
  drugSubstances,
  disabled,
  value,
  handleOnInputValueChange,
  inputValue,
}: {
  handleChange: any;
  drugSubstances: any[];
  disabled: boolean;
  value: number;
  inputValue: string;
  handleOnInputValueChange: any;
}) => {
  const getDrugSubstanceById = () => {
    const selectedDrugSubstance = drugSubstances.find((drugSubstance) => {
      return value === drugSubstance.Id;
    });
    return selectedDrugSubstance ? selectedDrugSubstance : -1;
  };

  return (
    <Autocomplete
      options={drugSubstances}
      getOptionLabel={(option) => (option.Name ? option.Name : '')}
      renderInput={(params) => (
        <TextField
          name="DrugSubstanceId"
          {...params}
          variant="outlined"
          label="Drug substance *"
        />
      )}
      value={getDrugSubstanceById()}
      onChange={(e, value) => {
        handleChange(value === null ? -1 : value.Id);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        handleOnInputValueChange(newInputValue);
      }}
      disabled={disabled}
    />
  );
};
