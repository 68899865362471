export enum DrugMgPerUnit {
  'mg/ml' = 1,
  'mg/tablet' = 2,
  'mg/capsule' = 3,
}

export enum DrugUnit {
  'ml' = 1,
  'tablet(s)' = 2,
  'capsule(s)' = 3,
}

export type CalculationUnit = DrugMgPerUnit | DrugUnit;

type Data = {
  value: string;
  error: boolean;
  validator: (value: string) => boolean;
};
type Result = string;
type MgPerUnit = { value: DrugMgPerUnit; error: false };

export type PatientWeightState = {
  value: string;
  error: boolean;
  validator: (value: string) => boolean;
};

export type CalculationType = 'numberOfUnits' | 'drugAmount' | 'drugStrength';

export type NumberOfUnitsState = {
  strength: Data;
  mgPerUnit: MgPerUnit;
  dose: Data;
  result: Result;
};

export type DrugAmountState = {
  strength: Data;
  mgPerUnit: MgPerUnit;
  numberOfUnits: Data;
  result: Result;
};

export type DrugStrengthState = {
  numberOfUnits: Data;
  unit: { value: DrugUnit; error: false };
  dose: Data;
  result: Result;
};

export type IdAndName = { Id: number; Name: string };

type DrugSubstance_Animals = {
  AnimalTypeId: number;
  DrugSubstanceId: number;
  Id: number;
  Info: string;
  Source_DrugSubstanceAnimals: Source_DrugSubstanceAnimals[];
};

export type DrugSubstanceDose_Phase = {
  DosageInterval: number | null;
  DosageIntervalMax: number | null;
  DosageIntervalTypeId: number;
  DosageIntervals: IdAndName;
  Dose: string;
  DoseAlternativeId: number;
  DoseMax: string | null;
  DoseType: IdAndName;
  DoseTypeId: number;
  DrugAdministrationRoute: IdAndName;
  DrugAdministrationRouteId: number;
  DrugUnit: IdAndName;
  DrugUnitId: number;
  Id: number;
  TreatmentDuration: number | null;
  TreatmentDurationMax: number | null;
  TreatmentDurationPrefix: IdAndName | null;
  TreatmentDurationTypeId: number;
  TreatmentDurations: IdAndName;
};

export type Source_DrugSubstanceAnimals = {
  Id: number;
  Source: {
    Id: number;
    Name: string;
  };
};

export type Source_DoseAlternatives = {
  Id: number;
  Source: {
    Id: number;
    Name: string;
  };
};

export type Source_DrugSubstanceDoses = {
  Id: number;
  Source: {
    Id: number;
    Name: string;
  };
};

export type DoseAlternative = {
  Id: number;
  DrugSubstanceDoseId: number;
  Source_DoseAlternatives: Source_DoseAlternatives[];
  DrugSubstanceDose_Phases: DrugSubstanceDose_Phase[];
};

export type DrugSubstance_Dose = {
  DosageSpecification: string | null;
  DoseAlternatives: DoseAlternative[];
  DrugIndication: IdAndName;
  DrugIndicationId: number;
  Source_DrugSubstanceDoses: Source_DrugSubstanceDoses[];
  Id: number;
  Info: string | null;
};

export type DrugSubstanceInfo = {
  DrugSubstance_Animals: DrugSubstance_Animals;
  DrugSubstance_Doses: DrugSubstance_Dose[];
};

export type TreatmentDosages = {
  TreatmentId: number;
  Dosages: DrugSubstanceInfo[];
};

export type DrugSubstance = {
  Id: number;
  Name: string;
  ATCCode: string;
  ATCCode2: string;
  ATCCode3: string;
  ATCCode4: string;
};
