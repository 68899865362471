import Auth from '@aws-amplify/auth';
import { SignUpParams } from '@aws-amplify/auth/lib-esm/types';
import React, { useCallback, useEffect, useState } from 'react';
import { Veterinarian } from '../../types/Veterinarian.type';
import Loader from '../Loader';
import { RegistrationForm } from './RegistrationForm';

declare global {
  interface Window {
    dataLayer: any;
  }
}

type Status = 'initial' | 'creating';

const EmailExists = 'An account with the given email already exists.';
const NetworkError = 'Network error';
const InsertingNewUserError =
  'PreSignUp failed with error An error occurred inserting the new user..';

type ErrorCode =
  | typeof EmailExists
  | typeof NetworkError
  | typeof InsertingNewUserError;

const initialVeterinarian: Veterinarian = {
  FirstName: '',
  Name: '',
  Email: '',
  Password: '',
  CountryId: '',
  VetNumber: '',
  Entrepreneur: undefined,
  VATId: '',
  Address: '',
  City: '',
  PostalCode: '',
  State: '',
  IsPublic: false,
  TermsOfServiceIsAccepted: false,
  PersonTypeId: 1,
  WhatsNewSeen: '',
};

export const RegistrationContainer = ({
  setUserData,
}: {
  setUserData: (value: SignUpParams) => void;
}) => {
  const [state, setState] = useState<Status>('initial');
  const [error, setError] = useState<ErrorCode | null>(null);
  const [veterinarian, setVeterinarian] =
    useState<Veterinarian>(initialVeterinarian);

  const createUser = useCallback(async () => {
    Auth.signUp({
      username: veterinarian.Email,
      password: veterinarian.Password,
      attributes: {
        email: veterinarian.Email,
        name: veterinarian.FirstName,
        family_name: veterinarian.Name,
      },
      clientMetadata: {
        FirstName: veterinarian.FirstName,
        Name: veterinarian.Name,
        CountryId: veterinarian.CountryId,
        VetNumber: veterinarian.VetNumber,
        IsPublic: veterinarian.IsPublic ? 'true' : 'false',
        PersonTypeId: veterinarian.PersonTypeId + '',
        TermsOfServiceIsAccepted: veterinarian.TermsOfServiceIsAccepted
          ? 'true'
          : 'false',
      },
    }).then(
      (_user) => {
        setUserData({
          username: veterinarian.Email,
          password: veterinarian.Password,
        });
      },
      (error) => {
        if (error.code === 'UserLambdaValidationException') {
          setError(EmailExists);
        } else {
          setError(error.message);
        }
      }
    );
  }, [veterinarian, setUserData]);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  useEffect(() => {
    if (state === 'initial' && veterinarian.Email) {
      setState('creating');
      createUser();
      window.dataLayer.push({
        event: 'registerUser',
      });
    }
  }, [createUser, state, veterinarian.Email, veterinarian.CountryId]);

  return (
    <>
      <RegistrationForm setUserData={setVeterinarian} />
      <Loader showLoader={state === 'creating'} />
    </>
  );
};
