import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Backdrop from '@mui/material/Backdrop';
import GekkoSpinner from '../assets/spinners/GekkoSpinner';

const useStyles = makeStyles()(() => ({
  backdrop: {
    zIndex: 99,
    color: '#fff',
  },
}));

type LoaderProps = {
  className?: string;
  spinnerClassName?: string;
  showLoader: boolean;
};

export const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const { classes } = useStyles();

  return (
    <Backdrop
      className={props.className ? props.className : classes.backdrop}
      open={props.showLoader}
    >
      <GekkoSpinner
        className={props.spinnerClassName ? props.spinnerClassName : undefined}
      ></GekkoSpinner>
    </Backdrop>
  );
};

export default Loader;
