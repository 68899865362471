import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { SourcesDialog } from './SourcesDialog';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import {
  Source_DoseAlternatives,
  Source_DrugSubstanceAnimals,
  Source_DrugSubstanceDoses,
} from '../../types/DrugDosages.type';
import {
  Source_AnimalTypeDiseases,
  Source_DiseaseSymptoms_AddedInfo,
  Source_Treatments,
} from '../../types/Diagnose.type';

interface StyleParams {
  areSources: boolean;
  marginBottom: boolean;
}

const useStyles = makeStyles<StyleParams>()(
  (theme: Theme, { areSources, marginBottom }) => ({
    sourceLink: {
      color: areSources ? theme.palette.primary.main : 'gray',
      fontSize: '12px',
      letterSpacing: '0.09px',
      marginBottom: marginBottom ? '16px' : 0,
      textDecoration: areSources ? 'underline' : 'inherit',
      cursor: areSources ? 'pointer' : 'inherit',
    },
  })
);

export const Sources = ({
  sources,
  marginBottom = true,
}: {
  sources:
    | Source_Treatments[]
    | Source_DoseAlternatives[]
    | Source_DrugSubstanceDoses[]
    | Source_AnimalTypeDiseases[]
    | Source_DrugSubstanceAnimals[]
    | Source_DiseaseSymptoms_AddedInfo[];
  marginBottom?: boolean;
}) => {
  const areSources = sources ? sources.length > 0 : false;
  const { classes } = useStyles({ areSources, marginBottom });
  const [sourceModalOpen, setSourceModalOpen] = useState(false);

  return (
    <>
      {areSources && (
        <>
          <Grid item xs={12}>
            <Typography
              onClick={areSources ? () => setSourceModalOpen(true) : (f) => f}
              className={classes.sourceLink}
              align="left"
            >
              Sources
            </Typography>
          </Grid>
          {sourceModalOpen && (
            <SourcesDialog
              isOpen={sourceModalOpen}
              closeDialog={() => setSourceModalOpen(false)}
              sources={sources}
            />
          )}
        </>
      )}
    </>
  );
};
