import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PatientNavigation } from '../components/PatientNavigation';
import {
  ROUTE_DIAGNOSE_HOME,
  ROUTE_NEW_PATIENT,
  ROUTE_PATIENT_SUMMARY,
  ROUTE_PATIENTS,
  ROUTE_VISITS,
} from '../config/routes.config';
import { AllPatientsPage } from '../pages/AllPatientsPage';
import { TemplatePage } from '../pages/TemplatePage';
import { VisitsPage } from '../pages/VisitsPage';
import { PatientRouter } from './PatientRouter';
import { PatientSummaryPage } from '../pages/PatientSummaryPage';
import DiagnoseHomePage from '../pages/DiagnoseHomePage';

export const WorkRouter = () => {
  return (
    <>
      <PatientNavigation />
      <Switch>
        <Route path={ROUTE_PATIENT_SUMMARY}>
          <PatientSummaryPage />
        </Route>
        <Route path={ROUTE_PATIENTS}>
          <AllPatientsPage />
        </Route>
        <Route path={ROUTE_VISITS}>
          <VisitsPage />
        </Route>
        <Route path={ROUTE_VISITS}>
          <TemplatePage title="All visits page" />
        </Route>

        <Route path={ROUTE_DIAGNOSE_HOME}>
          <DiagnoseHomePage />
        </Route>

        <Route path={ROUTE_NEW_PATIENT}>
          <PatientRouter />
        </Route>
        <Redirect to={ROUTE_PATIENTS} />
      </Switch>
    </>
  );
};
