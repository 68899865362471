import React, { useState } from 'react';
import { Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDiagnosesPageDispatch } from '../../contexts/DiagnosesPageContext';
import {
  useTreatmentsPage,
  useTreatmentsPageDispatch,
} from '../../contexts/TreatmentsPageContext';
import { Diagnose } from '../../types/Diagnose.type';
import { Button } from '../Button';
import ConditionTooltip from '../diagnoses/ConditionTooltip';
import DiagnoseConfirmSwitch from '../diagnoses/DiagnoseConfirmSwitch';
import { SourcesDialog } from '../common/SourcesDialog';
import { TreatmentsContent } from './TreatmentsContent';

const Accordion = withStyles(MuiAccordion, (_theme, _params, classes) => ({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    [`&.${classes.expanded}`]: {
      margin: 'auto',
    },
  },
  expanded: {},
}));

export default function DiagnoseAccordion({
  diagnose,
}: {
  diagnose: Diagnose;
}) {
  const { treatmentsObj, treatmentInfos } = useTreatmentsPage();
  const dispatchPageDiagnoses = useDiagnosesPageDispatch();
  const dispatchTreatments = useTreatmentsPageDispatch();

  const [sourceModalOpen, setSourceModalOpen] = useState(false);

  const toggleSourceModal = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent> | null,
    open: boolean
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setSourceModalOpen(open);
  };

  const treatmentInfo =
    diagnose.Id in treatmentsObj
      ? treatmentInfos![treatmentsObj[diagnose.Id]]
      : null;

  const handleDeselect = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();

    const deselectTreatmentIds: number[] = treatmentInfo
      ? treatmentInfo.Treatments.map((treatment) => treatment.Id)
      : [];

    dispatchPageDiagnoses({
      type: 'setDiagnoseSelected',
      checked: false,
      diagnoseId: diagnose.Id,
    });

    dispatchTreatments({
      type: 'deselectTreatments',
      deselectTreatmentIds,
    });
  };

  return (
    <Accordion square TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label={`Expand treatment ${diagnose.Id}`}
        aria-controls={`treatment-diagnose-content-${diagnose.Id}`}
        id={`treatment-diagnose-header-${diagnose.Id}`}
      >
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12} sm={10} container>
            <Grid item xs={12}>
              <Typography align="left">
                {diagnose.Disease.Name} &nbsp;&nbsp;&nbsp;
                {diagnose.Condition === 1 && <ConditionTooltip />}
              </Typography>
            </Grid>

            <Grid item xs={12} container>
              {!(diagnose.Id in treatmentsObj) && (
                <Grid item xs={2}>
                  <Typography
                    onClick={(event) => toggleSourceModal(event, true)}
                    style={{
                      color: 'gray',
                      fontSize: '12px',
                      letterSpacing: '0.09px',
                      marginBottom: '16px',
                    }}
                    align="left"
                  >
                    No sources
                  </Typography>
                </Grid>
              )}
              {diagnose.Id in treatmentsObj && (
                <Grid item xs={2}>
                  <Typography
                    onClick={(event) => toggleSourceModal(event, true)}
                    style={{
                      color: '#00aa79',
                      fontSize: '12px',
                      letterSpacing: '0.09px',
                      marginBottom: '16px',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    align="left"
                  >
                    Sources
                  </Typography>
                  {sourceModalOpen && (
                    <SourcesDialog
                      isOpen={sourceModalOpen}
                      closeDialog={(event) => toggleSourceModal(event, false)}
                      sources={
                        treatmentInfos![treatmentsObj[diagnose.Id]]
                          .Source_AnimalTypeDiseases
                      }
                    />
                  )}
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} container>
              <Grid item xs={12}>
                <DiagnoseConfirmSwitch diagnoseId={diagnose.Id} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={2}>
            <Button
              title="Deselect"
              variant="contained"
              onClick={handleDeselect}
              style={{
                color: '#01AA70',
                backgroundColor: 'white',
                border: '1px solid rgba(0, 0, 0, 0.2)',
                padding: '0.2em 0.5em',
              }}
            >
              Deselect
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>
      <TreatmentsContent treatmentInfo={treatmentInfo} />
    </Accordion>
  );
}
