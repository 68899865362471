import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { gaListReducer } from '../reducers/geographicalAreasList/geographicalAreas.list.reducer';
import {
  GAListState,
  GAListAction,
  GAListDefault,
} from '../reducers/geographicalAreasList/geographicalAreas.list.state';

const GAListContext = createContext<GAListState>({} as GAListState);
const GAListDispatch = createContext<Dispatch<GAListAction>>(() => {});

export const GAListProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(gaListReducer, GAListDefault);

  return (
    <GAListContext.Provider value={state}>
      <GAListDispatch.Provider value={dispatch}>
        {children}
      </GAListDispatch.Provider>
    </GAListContext.Provider>
  );
};

export const useGeographicalAreas = (): GAListState => {
  return useContext(GAListContext);
};

export const useGeographicalAreasDispatch = () => {
  return useContext(GAListDispatch);
};
