import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  ROUTE_CURRENT_PATIENT,
  ROUTE_NEW_PATIENT,
  ROUTE_NEW_VISIT,
} from '../config/routes.config';
import { PatientPage } from '../pages/PatientPage';
import { VisitRouter } from './VisitRouter';
import { PatientPageProvider } from '../contexts/PatientPageContext';

export const PatientRouter = () => {
  return (
    <Switch>
      <Route exact path={ROUTE_NEW_PATIENT}>
        <PatientPageProvider>
          <PatientPage />
        </PatientPageProvider>
      </Route>
      <Route exact path={ROUTE_CURRENT_PATIENT}>
        <PatientPageProvider>
          <PatientPage />
        </PatientPageProvider>
      </Route>
      <Route path={ROUTE_NEW_VISIT}>
        <VisitRouter />
      </Route>

      <Redirect to={ROUTE_NEW_PATIENT} />
    </Switch>
  );
};
