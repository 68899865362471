import { Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { Fragment, useState } from 'react';
import {
  ROUTE_HOME,
  ROUTE_VISIT_DOSAGES,
  ROUTE_VISIT_SUMMARY,
  ROUTE_VISIT_TREATMENTS,
} from '../../config/routes.config';
import { useAuthUser } from '../../contexts/AuthUserContext';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';
import { useDosagesPageDispatch } from '../../contexts/DosagesPageContext';
import { useDrugProductsDispatch } from '../../contexts/DrugProductsContext';
import { useIntegrationContext } from '../../contexts/IntegrationContext';
import { useWorkHistory } from '../../hooks/useWorkHistory';
import { Button } from '../Button';
import { ButtonTo } from '../common/ButtonTo';
import { FloatingActionBar } from '../FloatingActionBar';
import Loader from '../Loader';

const useStyles = makeStyles()((theme: Theme) => ({
  integrationButton: {
    marginRight: theme.spacing(2),
  },
}));

export const DosageFloatingActionBar = () => {
  const { exportState } = useWorkHistory();
  const mobile = useMediaQuery('(max-width:600px)');
  const dispatchDosages = useDosagesPageDispatch();
  const dispatchDrugProducts = useDrugProductsDispatch();
  const { isTestPatient } = usePatientInfo();

  const { classes } = useStyles();

  const [showLoader, setShowLoader] = useState(false);
  const { signOut } = useAuthUser();

  const { integrationConnectionInfo, integrationInfo, integrationSource } =
    useIntegrationContext();

  let integrationCase = false;

  if (integrationConnectionInfo && integrationInfo && integrationSource) {
    integrationCase = true;
  }

  const handleGoToTreatments = () => {
    dispatchDosages({ type: 'reset' });
    dispatchDrugProducts({ type: 'resetAll' });
    if (!isTestPatient) {
      exportState();
    }
  };

  const handleSave = () => {
    if (!isTestPatient) {
      exportState();
    }
  };

  const handleIntegrationCaseClose = async () => {
    try {
      setShowLoader(true);
      await exportState();
      signOut();
      window.close();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FloatingActionBar>
      <Loader showLoader={showLoader} />
      <Grid item xs={12} container spacing={3}>
        {mobile ? (
          <Fragment>
            <Grid item xs={4}>
              <Typography align="left">
                {integrationCase ? (
                  <Button
                    onClick={handleIntegrationCaseClose}
                    title="Save & return"
                    color="primary"
                    variant="contained"
                    className={classes.integrationButton}
                  >
                    Save & return
                  </Button>
                ) : (
                  <ButtonTo
                    handleClick={handleSave}
                    title={isTestPatient ? 'Close' : 'Save & close'}
                    toRoute={ROUTE_HOME}
                    secondary
                    iconName="home"
                    mobile={mobile}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography align="right">
                <ButtonTo
                  currentRoute={ROUTE_VISIT_DOSAGES}
                  handleClick={handleGoToTreatments}
                  secondary
                  title="Back to treatments"
                  toRoute={ROUTE_VISIT_TREATMENTS}
                  iconName="back"
                  mobile={mobile}
                />
                <ButtonTo
                  currentRoute={ROUTE_VISIT_DOSAGES}
                  handleClick={handleSave}
                  title="Continue"
                  toRoute={ROUTE_VISIT_SUMMARY}
                  iconName="next"
                  mobile={mobile}
                />
              </Typography>
            </Grid>
          </Fragment>
        ) : (
          <Fragment>
            <Grid item xs={12} sm={8}>
              <Typography align="left">
                <ButtonTo
                  currentRoute={ROUTE_VISIT_DOSAGES}
                  handleClick={handleGoToTreatments}
                  secondary
                  title="Back to treatments"
                  toRoute={ROUTE_VISIT_TREATMENTS}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="right">
                {integrationCase ? (
                  <Button
                    onClick={handleIntegrationCaseClose}
                    title="Save & return"
                    color="primary"
                    variant="contained"
                    className={classes.integrationButton}
                  >
                    Save & return
                  </Button>
                ) : (
                  <ButtonTo
                    handleClick={handleSave}
                    title={isTestPatient ? 'Close' : 'Save & close'}
                    toRoute={ROUTE_HOME}
                    secondary
                  />
                )}
                <ButtonTo
                  currentRoute={ROUTE_VISIT_DOSAGES}
                  handleClick={handleSave}
                  title="Continue"
                  toRoute={ROUTE_VISIT_SUMMARY}
                />
              </Typography>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </FloatingActionBar>
  );
};
