const localAppUrl = 'http://localhost:3002';
const localAPIUrl = 'http://localhost:4000';

const devAppUrl = 'http://localhost:3002';
const devAPIUrl = 'https://tobei64vva.execute-api.eu-west-1.amazonaws.com';

// const stagingAppUrl = 'https://d2f93ist3sc02v.cloudfront.net';
// const stagingAPIUrl = 'https://tobei64vva.execute-api.eu-west-1.amazonaws.com';

// const pilotAppUrl = 'https://pilot.gekkovet.com';
// const pilotAPIUrl = 'https://j2mpaliga4.execute-api.eu-west-1.amazonaws.com';

// const prodAppUrl = 'https://compass.gekkovet.com';
// const prodAPIUrl = 'https://j2mpaliga4.execute-api.eu-west-1.amazonaws.com';

const localApi = false;

const local = {
  REGION: 'eu-west-1',
  IDP_DOMAIN: 'auth.dev-gekkovet.com',
  USER_POOL_ID: 'eu-west-1_3FfwkxCBy',
  USER_POOL_CLIENT_ID: '5uht8qos77kbq7spjq78tpv37v',
  REDIRECT_SIGN_IN: localAppUrl,
  REDIRECT_SIGN_OUT: localAppUrl,
  AUTH_COOKIE_DOMAIN: localAppUrl,
  API_URL: localAPIUrl,
  API_VERSION: '',
};

const dev = {
  REGION: 'eu-west-1',
  IDP_DOMAIN: 'auth.dev-gekkovet.com',
  USER_POOL_ID: 'eu-west-1_3FfwkxCBy',
  USER_POOL_CLIENT_ID: '5uht8qos77kbq7spjq78tpv37v',
  REDIRECT_SIGN_IN: devAppUrl,
  REDIRECT_SIGN_OUT: devAppUrl,
  AUTH_COOKIE_DOMAIN: devAppUrl,
  API_URL: devAPIUrl,
  API_VERSION: 'v1',
};

const prod = {
  REGION: 'eu-west-1',
  IDP_DOMAIN: process.env.REACT_APP_IDP_DOMAIN,
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
  USER_POOL_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT,
  REDIRECT_SIGN_IN: process.env.REACT_APP_CLOUDFRONT_BASE_URL,
  REDIRECT_SIGN_OUT: process.env.REACT_APP_CLOUDFRONT_BASE_URL,
  AUTH_COOKIE_DOMAIN: process.env.REACT_APP_CLOUDFRONT_BASE_URL,
  API_URL: process.env.REACT_APP_APIGW_BASE_URL,
  API_VERSION: 'v1',
};

export const awsConfig =
  process.env.NODE_ENV === 'production' ? prod : localApi ? local : dev;
