import React, { Reducer, useReducer } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { UNDEFINED_ID } from '../../config/constant.params';
import { DrugProduct } from '../../types/DrugProduct.type';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { makeStyles } from 'tss-react/mui';

type ProductState = {
  open: boolean;
  selectedId?: string;
};

type ProductAction =
  | { type: 'selected'; selectedId: string }
  | { type: 'confirmed' }
  | { type: 'closed' };

const initialState = {
  open: false,
};

const productStateReducer: Reducer<ProductState, ProductAction> = (
  state,
  action
): ProductState => {
  switch (action.type) {
    case 'selected': {
      return {
        open: true,
        selectedId: action.selectedId,
      };
    }
    case 'confirmed': {
      return {
        open: false,
      };
    }
    case 'closed': {
      return { ...initialState };
    }
    default:
      return state;
  }
};

const useStyles = makeStyles()(() => ({
  checkboxLabel: {
    fontSize: '1em',
  },
}));

export const DrugProductsList = ({
  products,
  drugProductId,
  setDrugProductId,
}: {
  products: DrugProduct[];
  drugProductId: number | null;
  setDrugProductId: (value: number | null) => void;
}) => {
  const { classes } = useStyles();
  const [state, dispatch] = useReducer(productStateReducer, initialState);

  const handleClose = () => {
    dispatch({ type: 'closed' });
    setDrugProductId(null);
  };

  const confirmProduct = () => {
    setDrugProductId(parseInt(state.selectedId!));
    dispatch({ type: 'confirmed' });
  };

  const handleSelect = (event: React.ChangeEvent<{ value: string }>) => {
    products.map((product) => {
      if (product && product.Id === parseInt(event.target.value)) {
        window.dataLayer.push({
          event: 'selectedProduct',
          productName: product.TradeName,
        });
      }
      return true;
    });
    dispatch({ type: 'selected', selectedId: event.target.value });
  };

  // const preventDefault = (event: React.SyntheticEvent) =>
  //   event.preventDefault();

  return (
    <Container>
      <RadioGroup
        aria-label="Product"
        name="Product"
        value={
          drugProductId ? drugProductId.toString() : UNDEFINED_ID.toString()
        }
        onChange={handleSelect}
      >
        <Grid container>
          {products.map((product) => (
            <Container key={product.Id}>
              <Grid item xs={12}>
                <FormControlLabel
                  classes={{
                    label: classes.checkboxLabel,
                  }}
                  value={product.Id.toString()}
                  control={<Radio />}
                  label={`${product.TradeName} ${
                    product.Strength
                  } ${product.DrugUnit.Name.toLowerCase().trim()}/${product.PharmaceuticalForm.Name.toLowerCase().trim()}`}
                />
              </Grid>
              {product.Info ? (
                <Link
                  href={product.Info}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                  underline="hover"
                >
                  Registration information
                </Link>
              ) : (
                'No product info available'
              )}
            </Container>
          ))}
        </Grid>
      </RadioGroup>
      <Dialog
        open={state.open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              style={{
                marginTop: '1em',
              }}
            >
              Please read carefully the registered information on when and how
              to use this product. To choose this product please CONFIRM below
              that you have read the information and made the decision as a
              veterinarian to choose this product.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'flex-start',
            marginLeft: '1em',
            marginBottom: '1em',
          }}
        >
          <Button
            onClick={handleClose}
            style={{
              color: '#00cd87',
              backgroundColor: 'white',
              marginRight: '1em',
            }}
            variant="contained"
          >
            Close
          </Button>
          <Button
            onClick={confirmProduct}
            color="primary"
            autoFocus
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
