import React from 'react';
import { DrugSubstanceInfo } from '../../types/DrugDosages.type';
import { DrugDosageTable } from '../DrugDosageTable/TableComponents';
import { Treatment } from '../../types/Diagnose.type';

export const DrugIndication = ({
  treatment,
  drugSubstanceInfo,
  drugSubstanceId,
  diseaseName,
  mobile,
}: {
  treatment: Treatment;
  drugSubstanceInfo: DrugSubstanceInfo;
  drugSubstanceId: number;
  diseaseName: string;
  mobile: boolean;
}) => {
  return (
    <DrugDosageTable
      treatmentId={treatment.Id}
      drugSubstanceInfo={drugSubstanceInfo}
      drugSubstanceName={treatment.DrugSubtance.Name}
      isInteractive={true}
      diseaseName={diseaseName}
      mobile={mobile}
    />
  );
};
