import {
  CAPSULE_NAME,
  MCG_UNIT,
  MG_UNIT,
  ML_NAME,
  NUMBER_MCG_IN_MG,
  TABLET_NAME,
} from '../config/constant.params';
import { useDoseParams } from '../contexts/DoseParamsContext';
import { useDrugProducts } from '../contexts/DrugProductsContext';
import { DrugMgPerUnit } from '../types/DrugDosages.type';
import { DrugProduct } from '../types/DrugProduct.type';

export const useSelectedProduct = (
  drugSubstanceId: number,
  drugProductId: number | null
) => {
  const { drugProductsObj } = useDrugProducts();
  const { summaryDrugUnits, summaryResultDrugUnits } = useDoseParams();

  let drugProduct: DrugProduct | undefined;
  let productStrength = 0;
  let mgStrength = 0;
  let matchToMg = false;
  let matchToMcg = false;
  let mgStrengthUnitStr = 'mg/ml';
  let mgStrengthUnit = DrugMgPerUnit['mg/ml'];
  let summaryDrugUnitId = 1;
  let summaryResultDrugUnitId = 1;

  if (drugProductId) {
    drugProduct = drugProductsObj[drugSubstanceId].find(
      (product) => product.Id === drugProductId
    );
    const unitName = drugProduct!.PharmaceuticalForm.Name.trim().toLowerCase();
    let quantityName = '';
    if (unitName.includes(ML_NAME)) {
      quantityName = ML_NAME;
    } else if (unitName.includes(TABLET_NAME)) {
      quantityName = TABLET_NAME;
    } else if (unitName.includes(CAPSULE_NAME)) {
      quantityName = CAPSULE_NAME;
    }

    productStrength = +drugProduct!.Strength;
    mgStrength = +drugProduct!.Strength;

    const drugUnit = drugProduct!.DrugUnit.Name.toLowerCase().trim();
    matchToMg = drugUnit === MG_UNIT || drugUnit === MCG_UNIT;
    matchToMcg = drugUnit === MCG_UNIT;

    if (matchToMcg) {
      mgStrength = productStrength / NUMBER_MCG_IN_MG;
    }
    if (matchToMg && quantityName !== '') {
      mgStrengthUnitStr = `${MG_UNIT}/${quantityName}`;
      mgStrengthUnit =
        DrugMgPerUnit[mgStrengthUnitStr as keyof typeof DrugMgPerUnit];
    }

    const drugUnitName = `${drugUnit}/${quantityName}`;
    const summaryDrugUnit = summaryDrugUnits.find(
      (summaryDrugUnit) => summaryDrugUnit.Name === drugUnitName
    );
    if (summaryDrugUnit) {
      summaryDrugUnitId = summaryDrugUnit.Id;
    }

    const summaryResultDrugUnit = summaryResultDrugUnits.find(
      (summaryResultDrugUnit) =>
        summaryResultDrugUnit.Name.replace('(s)', '').replace('(es)', '') ===
        quantityName
    );
    if (summaryResultDrugUnit) {
      summaryResultDrugUnitId = summaryResultDrugUnit.Id;
    }
  }

  return [
    mgStrength,
    productStrength,
    mgStrengthUnitStr,
    mgStrengthUnit,
    summaryDrugUnitId,
    summaryResultDrugUnitId,
  ];
};
