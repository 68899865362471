import React from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Diagnose } from '../../types/Diagnose.type';
import DiagnoseConfirmSwitch from './DiagnoseConfirmSwitch';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  DiseaseInformation,
  DiseaseCauses,
  DiseaseSignalments,
  MidInformation,
  DiseaseSymptoms,
  DiseaseGeographicalAreas,
} from './DiseaseInformation';
import { DiseaseBreeds } from './DiseaseBreeds';
import { useSymptoms } from '../../contexts/SymptomsContext';
import { DiseaseSourcesModal } from './DiseaseSourcesModal';

export default function DiagnoseAccordionDetails({
  diagnose,
  highlightBreedId,
  updateDifferentials,
}: {
  diagnose: Diagnose;
  highlightBreedId?: number | undefined;
  updateDifferentials: () => void;
}) {
  const { categories } = useSymptoms();

  return (
    <AccordionDetails>
      <Grid container>
        <Grid item xs={12}>
          <DiagnoseConfirmSwitch
            diagnoseId={diagnose.Id}
            style={{ paddingLeft: '5.5%', paddingBottom: '1em' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>
            {diagnose.Source_AnimalTypeDiseases && (
              <DiseaseSourcesModal
                sources={diagnose.Source_AnimalTypeDiseases}
              />
            )}
            <DiseaseInformation diagnose={diagnose} />
            <DiseaseCauses diagnose={diagnose} />
            <DiseaseSignalments diagnose={diagnose} />
            <DiseaseBreeds
              diagnose={diagnose}
              highlightBreedId={highlightBreedId}
            />
            <DiseaseGeographicalAreas diagnose={diagnose} />
            <MidInformation diagnose={diagnose} />
            <DiseaseSymptoms
              categories={categories}
              categorySymptoms={diagnose.categorySymptoms!}
              showAddButton={true}
              updateDifferentials={updateDifferentials}
            />
          </Box>
        </Grid>
      </Grid>
    </AccordionDetails>
  );
}
