import React, { useState } from 'react';
import { Card } from '../common/Card';
import { SelectTestPatient } from './SelectTestPatient';
import { testPatients } from '../../config/testPatients';
import { UNDEFINED_ID } from '../../config/constant.params';
import { Button } from '../Button';
import { Grid, Typography, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTE_VISIT_DIAGNOSIS } from '../../config/routes.config';
import { useAnimalTypesContext } from '../../contexts/AnimalTypesContext';
import { usePatientInfoDispatch } from '../../contexts/CurrentPatientInfoContext';
import { useWorkHistory } from '../../hooks/useWorkHistory';

const useStyles = makeStyles()((theme: Theme) => ({
  quickDiagnoseButton: {
    marginTop: theme.spacing(2),
  },
  infoTextFlexContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  listItem: {
    paddingLeft: theme.spacing(1),
  },
}));

export const QuickDiagnose = () => {
  const { classes } = useStyles();
  const history = useHistory();

  const { resetAllPages } = useWorkHistory();

  const [testPatientId, setTestPatientId] = useState<number>(UNDEFINED_ID);

  const { animalTypesObj } = useAnimalTypesContext();

  const dispatchPatientInfo = usePatientInfoDispatch();

  const setTestPatientAndForward = () => {
    const testPatient = testPatients.find(
      (patient) => patient.Id === testPatientId
    );

    resetAllPages();

    dispatchPatientInfo({
      type: 'setPatientInfo',
      patient: testPatient!,
      animalTypeName: animalTypesObj[testPatient!.AnimalTypeId].Name,
    });

    const pathname = generatePath(ROUTE_VISIT_DIAGNOSIS, {
      patientId: 'test',
      visitId: 'test',
    });

    history.push(pathname);
  };

  return (
    <Card
      title="CHOOSE'N'GO DIAGNOSTICS"
      pageView="drawerView"
      withoutMarginBottom
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <div className={classes.infoTextFlexContainer}>
            <Typography align="left">
              Please note that if you use pre-defined patients:{' '}
            </Typography>
            <Typography className={classes.listItem} align="left">
              - Data cannot be saved
            </Typography>
            <Typography className={classes.listItem} align="left">
              - Breed will not affect the differential diagnoses list
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectTestPatient
            name="Select test patient"
            list={testPatients}
            value={testPatientId}
            setValue={setTestPatientId}
            viewSize="small"
          ></SelectTestPatient>
          <Button
            title="Diagnose"
            color="primary"
            variant="contained"
            className={classes.quickDiagnoseButton}
            disabled={testPatientId === UNDEFINED_ID}
            onClick={setTestPatientAndForward}
          >
            Diagnose
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};
