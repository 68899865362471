import React from 'react';
import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../../contexts/DiagnosesPageContext';
import SuspectedConfirmedSwitch from './SuspectedConfirmedSwitch';

export default function DiagnoseConfirmSwitch({
  diagnoseId,
  style,
}: {
  diagnoseId: number;
  style?: any;
}) {
  const { excludedDiagnoses, confirmedDiagnoses } = useDiagnosesPage();
  const dispatchPageDiagnoses = useDiagnosesPageDispatch();

  const isExcluded = excludedDiagnoses.indexOf(diagnoseId) > -1;
  const isConfirmed = confirmedDiagnoses.indexOf(diagnoseId) > -1;

  const handleChange = (diagnoseId: number, checked: boolean) => {
    if (!isExcluded) {
      dispatchPageDiagnoses({
        type: 'setDiagnoseConfirmed',
        checked,
        diagnoseId,
      });
    }
  };

  return (
    <SuspectedConfirmedSwitch
      Id={diagnoseId}
      handleSwitch={handleChange}
      isConfirmed={isConfirmed}
      style={style}
    />
  );
}
