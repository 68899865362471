import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { visitsListReducer } from '../reducers/visitsList/visits.list.reducer';
import {
  VisitsListState,
  VisitsListAction,
  VisitsListDefault,
} from '../reducers/visitsList/visits.list.state';

const VisitsListContext = createContext<VisitsListState>({} as VisitsListState);
const VisitsListDispatchContext = createContext<Dispatch<VisitsListAction>>(
  () => {}
);

export const VisitsListProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(visitsListReducer, {
    ...VisitsListDefault,
  });

  return (
    <VisitsListContext.Provider value={state}>
      <VisitsListDispatchContext.Provider value={dispatch}>
        {children}
      </VisitsListDispatchContext.Provider>
    </VisitsListContext.Provider>
  );
};

export const useVisitsListState = (): VisitsListState => {
  return useContext(VisitsListContext);
};

export const useVisitsListDispatch = () => {
  return useContext(VisitsListDispatchContext);
};
