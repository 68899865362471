import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { Source_Treatments } from '../../types/Diagnose.type';
import { SourcesDialog } from '../common/SourcesDialog';
import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

interface StyleParams {
  areSources: boolean;
}

const useStyles = makeStyles<StyleParams>()((theme: Theme, { areSources }) => ({
  sourceLink: {
    color: areSources ? theme.palette.primary.main : 'gray',
    fontSize: '12px',
    letterSpacing: '0.09px',
    marginBottom: '16px',
    textDecoration: areSources ? 'underline' : 'inherit',
    cursor: areSources ? 'pointer' : 'inherit',
  },
}));

export const TreatmentSources = ({
  treatmentSources,
  isInteractive,
}: {
  treatmentSources: Source_Treatments[];
  isInteractive: boolean;
}) => {
  const areSources = treatmentSources.length > 0;

  const { classes } = useStyles({ areSources });
  const [sourceModalOpen, setSourceModalOpen] = useState(false);

  return (
    <>
      {isInteractive && <Grid item xs={1}></Grid>}
      <Grid item xs={isInteractive ? 11 : 12}>
        <Typography
          onClick={areSources ? () => setSourceModalOpen(true) : (f) => f}
          className={classes.sourceLink}
          align="left"
        >
          Sources
        </Typography>
      </Grid>
      {sourceModalOpen && (
        <SourcesDialog
          isOpen={sourceModalOpen}
          closeDialog={() => setSourceModalOpen(false)}
          sources={treatmentSources}
        />
      )}
    </>
  );
};
