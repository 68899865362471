import {
  validateCountry,
  validateEmail,
  validateName,
  validatePassword,
  validateVatId,
} from '../../utils/validators';
import { FormData } from '../form.type';

export const registrationFieldNames = [
  'FirstName',
  'Name',
  'Email',
  'Country',
  'PersonTypeId',
  'Password',
  'RepeatPassword',
];

export const entrepreneurFieldNames = [
  'VATId',
  'Address',
  'PostalCode',
  'City',
  'State',
];

export type EntrepreneurFieldName = (typeof entrepreneurFieldNames)[number];

export type RegistrationFieldName = (typeof registrationFieldNames)[number];

export type EntrepreneurFieldType = 'text';

export type RegistrationFieldType =
  | 'text'
  | 'country'
  | 'password'
  | 'repeatPassword'
  | 'personType'
  | 'vat'
  | 'hidden';

export const EntrepreneurFormData: FormData<
  EntrepreneurFieldName,
  EntrepreneurFieldType
> = {
  VATId: {
    type: 'text',
    label: 'VAT identification number',
    value: '',
    required: true,
    helperText:
      'For VAT purposes we ask you kindly to enter your VAT identification number, your business id or tax number',
    validator: validateVatId,
    error: false,
  },
  Address: {
    type: 'text',
    label: 'Address',
    value: '',
    required: true,
    validator: validateVatId,
    error: false,
  },
  PostalCode: {
    type: 'text',
    label: 'Zip/Postal code',
    value: '',
    required: true,
    validator: validateVatId,
    error: false,
  },
  City: {
    type: 'text',
    label: 'City',
    value: '',
    required: true,
    validator: validateVatId,
    error: false,
  },
  State: {
    type: 'text',
    label: 'State',
    value: '',
    error: false,
  },
};

export const RegistrationFormData: FormData<
  RegistrationFieldName,
  RegistrationFieldType
> = {
  FirstName: {
    type: 'text',
    label: 'First Name',
    value: '',
    required: true,
    validator: validateName,
    error: false,
  },
  Name: {
    type: 'text',
    label: 'Last Name',
    value: '',
    required: true,
    validator: validateName,
    error: false,
  },
  Email: {
    type: 'text',
    label: 'Email',
    value: '',
    required: true,
    validator: validateEmail,
    error: false,
  },
  Password: {
    type: 'password',
    label: 'Password',
    value: '',
    required: true,
    validator: validatePassword,
    helperText: 'Min 6 characters with lower and upper letters, and numbers',
    error: false,
  },
  RepeatPassword: {
    type: 'repeatPassword',
    label: 'Repeat',
    value: '',
    errorText: 'Passwords does not match',
    required: true,
    error: false,
  },
  Country: {
    type: 'country',
    label: 'Country',
    value: '',
    required: true,
    validator: validateCountry,
    error: false,
  },
  VetNumber: {
    type: 'text',
    label: 'Vet Number',
    value: '',
  },
  PersonTypeId: {
    type: 'personType',
    label: 'Role',
    value: '1',
  },
  WhatsNewSeen: {
    type: 'hidden',
    value: '',
    label: '',
  },
};
