import {
  MainOrSubSymptom,
  ParentSymptom,
  SymptomOption,
} from '../reducers/symptomsList/symptoms.state';
import { Diagnose, DiseaseInfo, DiseaseSymptom } from '../types/Diagnose.type';
import {
  Symptom,
  SymptomType,
  SymptomTypeCategory,
} from '../types/Symptom.type';

export function getCombinedName(
  symptomsList: Symptom[],
  symptom: Symptom,
  name: string
) {
  let combinedName = `${symptom.Name}${name === '' ? '' : '\n'}${name}`;

  if (symptom.MainSymptomId) {
    const mainSymptom = symptomsList.find(
      (element) => element.Id === symptom.MainSymptomId
    );

    if (mainSymptom) {
      combinedName = getCombinedName(symptomsList, mainSymptom, combinedName);
    }
  }

  return combinedName;
}

export function findParentObj(
  symptoms: Symptom[],
  symptomsObj: { [x: string]: number },
  parent: ParentSymptom,
  symptomId: number
): ParentSymptom | null {
  if (symptomId in parent) {
    return parent;
  } else {
    const mainSymptomId = symptoms[symptomsObj[symptomId]].MainSymptomId;
    if (!mainSymptomId) {
      return null;
    }
    return findParentObj(symptoms, symptomsObj, parent, mainSymptomId);
  }
}

export const arrangeSymptomsByCategory = (
  categories: SymptomTypeCategory[],
  diagnose: Diagnose | DiseaseInfo
) => {
  const categoryIds = Array<number>();
  const categorySymptoms = {} as { [x: number]: DiseaseSymptom[] };

  categories.forEach((category) => {
    categoryIds.push(category.Id);
    categorySymptoms[category.Id] = [];
  });

  const sortedSymptoms = sortSymptomsByIncidence(diagnose.DiseaseSymptoms);

  sortedSymptoms.forEach((diseaseSymptom) => {
    const categoryId = diseaseSymptom.Symptom.SymptomType.SymptomTypeCategoryId;

    categorySymptoms[categoryId].push(diseaseSymptom);
  });
  return categorySymptoms;
};

export const getSelectOptions = (
  obj: ParentSymptom | undefined,
  arr: string[][],
  index: number
): ParentSymptom | undefined => {
  if (!obj || index === arr.length || arr[index][0] === '') {
    return obj;
  } else {
    return getSelectOptions(obj[arr[index][0]].children, arr, index + 1);
  }
};

export const getSubSymptomId = (
  myId: number,
  arr: number[],
  item: MainOrSubSymptom
) => {
  if (item.children) {
    Object.entries(item.children).forEach(([key, value]) => {
      getSubSymptomId(parseInt(key), arr, value);
    });
  } else {
    arr.push(myId);
  }
};

export const sortCategories = (arr: SymptomTypeCategory[]) => {
  return arr.sort((a, b) => {
    const aData = a.Order;
    const bData = b.Order;
    return aData > bData ? 1 : bData > aData ? -1 : 0;
  });
};

export const sortTypes = (arr: SymptomType[]) => {
  return arr.sort((a, b) => {
    const aData1 = a.SymptomTypeCategoryId;
    const bData1 = b.SymptomTypeCategoryId;
    const aData2 = a.Name.toLowerCase();
    const bData2 = b.Name.toLowerCase();
    return aData1 > bData1
      ? 1
      : bData1 > aData1
      ? -1
      : aData2 > bData2
      ? 1
      : bData2 > aData2
      ? -1
      : 0;
  });
};

export const sortSymptomsByName = (
  arr: SymptomOption[],
  ids: {
    [x: string]: number;
  }
) => {
  return arr.sort((a, b) => {
    const aData1 = ids[a.Id];
    const bData1 = ids[b.Id];
    const aData2 = a.Name.toLowerCase();
    const bData2 = b.Name.toLowerCase();
    return aData1 > bData1
      ? 1
      : bData1 > aData1
      ? -1
      : aData2 > bData2
      ? 1
      : bData2 > aData2
      ? -1
      : 0;
  });
};

export const sortSymptomsByIncidence = (arr: DiseaseSymptom[]) => {
  return arr.sort((a, b) => {
    const aName = a.Symptom.Name.toLowerCase();
    const bName = b.Symptom.Name.toLowerCase();
    const aIncidenceId = a.Incidence.Id;
    const bIncidenceId = b.Incidence.Id;
    return aIncidenceId > bIncidenceId
      ? 1
      : bIncidenceId > aIncidenceId
      ? -1
      : aName > bName
      ? 1
      : bName > aName
      ? -1
      : 0;
  });
};

export const splitSymptomNameInTwoLines = (symptomName: string) => {
  const splitIndex = symptomName.search(/\n/) + 1;
  let firstLine = symptomName.substr(0, splitIndex);
  firstLine = firstLine.substring(firstLine.indexOf(': ') + 2);
  const secondLine = symptomName.slice(splitIndex).replaceAll('\n', ', ');

  return { firstLine, secondLine };
};
