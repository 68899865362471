import { Fragment, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Card } from './common/Card';
import GekkoSpinnerVink2 from '../assets/spinners/GekkoSpinnerVink2';
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import MuiTableCell from '@mui/material/TableCell';
import { withStyles } from 'tss-react/mui';
import { generatePath, Link, useHistory } from 'react-router-dom';
import {
  ROUTE_CURRENT_PATIENT,
  ROUTE_CURRENT_VISIT,
  ROUTE_HOME,
} from '../config/routes.config';
import Loader from './Loader';
import {
  formatDbDateStringToUi,
  formatDbTimeStringToUi,
  formatGenderIdToUi,
} from '../utils/dateUtils';
import { Patient } from '../types/Patient.type';
import { PatientVisit } from '../types/PatientVisit.type';
import { AnimalType } from '../types/AnimalType.type';
import { Breed } from '../types/Breed.type';
import { useLocale } from '../hooks/useLocale';
import { usePatientInfoDispatch } from '../contexts/CurrentPatientInfoContext';
import EditIcon from '@mui/icons-material/Edit';
import { useUiStateDispatch } from '../contexts/uiState.context';
import { convertKgToLbs } from '../utils/insertPatientUtils';
import { LOCALE_US } from '../config/constant.params';
import { useWorkHistory } from '../hooks/useWorkHistory';
import { useIntegrationContext } from '../contexts/IntegrationContext';

declare global {
  interface Window {
    dataLayer: any;
  }
}

const TableCell = withStyles(MuiTableCell, {
  root: {
    borderBottom: 'none',
  },
});

export const VisitCreated = ({
  patient,
  visit,
  breed,
  animalType,
}: {
  patient: Patient | null;
  visit: PatientVisit | null;
  breed: Breed | { Name: string } | null;
  animalType: AnimalType | null;
}) => {
  const history = useHistory();
  const locale = useLocale();
  const dispatchPatientInfo = usePatientInfoDispatch();
  const dispatchUiState = useUiStateDispatch();
  const { integrationConnectionInfo, integrationInfo, integrationSource } =
    useIntegrationContext();

  let integrationCase = false;

  if (integrationConnectionInfo && integrationInfo && integrationSource) {
    integrationCase = true;
  }

  const [loader, setLoader] = useState(false);

  const { goToWorkPageFromAnywhere } = useWorkHistory();

  // TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
  const useStyles = makeStyles()(() => ({
    root: {
      width: '100px',
      height: '100px',
    },
    savedText: {
      fontSize: '24px',
      fontWeight: 'bold',
    },
    tableTitle: {
      fontSize: '21px',
      fontWeight: 'bold',
      textAlign: 'left',
      paddingLeft: '16px',
    },
    diagnoseButton: { width: '170px', marginTop: '34px' },
    homeButton: { marginTop: '10px', width: '170px' },
    vinkSpinner: { height: '70px', width: '70px' },
  }));
  const { classes } = useStyles();

  if (!patient || !visit || !breed || !animalType) {
    return <Loader showLoader={true} />;
  }

  const patientRows = [
    { name: 'Name', value: patient.NameOrId },
    { name: 'Species', value: animalType.Name },
    { name: 'Breed', value: breed.Name },
    {
      name: 'Weight',
      value:
        navigator.language === LOCALE_US
          ? `${convertKgToLbs(patient.Weight)} lbs`
          : `${patient.Weight} kg`,
    },
    {
      name: 'Date of birth',
      value: formatDbDateStringToUi(patient.DateOfBirth, locale),
    },
    { name: 'Gender', value: formatGenderIdToUi(patient.GenderId) },
  ];

  const visitRows = [
    { name: 'Date', value: formatDbDateStringToUi(visit.Date, locale) },
    {
      name: 'Time',
      value: formatDbTimeStringToUi(visit.Time, locale),
    },
    { name: 'Visit Reason', value: visit.VisitReason },
    { name: 'Info', value: visit.Info },
  ];

  const PatientTable = () => {
    return (
      <TableContainer>
        <Table size="small" aria-label="Patient Table">
          <TableBody>
            {patientRows.map((row) => (
              <TableRow key={row.name}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const VisitTable = () => {
    return (
      <TableContainer>
        <Table size="small" aria-label="Visit Table">
          <TableBody>
            {visitRows.map((row) => (
              <TableRow key={row.name}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const goToHomePage = () => {
    history.push(ROUTE_HOME);
  };

  const goToDiagnosePatient = () => {
    setLoader(true);
    dispatchPatientInfo({
      type: 'setBreedName',
      breedName: breed.Name,
    });
    goToWorkPageFromAnywhere(patient, visit, false);
  };

  const patientPath = generatePath(ROUTE_CURRENT_PATIENT, {
    patientId: patient.Id.toString(),
  });

  const visitPath = generatePath(ROUTE_CURRENT_VISIT, {
    patientId: patient.Id.toString(),
    visitId: visit.Id.toString(),
  });

  const handleRedirectToEditPatient = () => {
    dispatchUiState({
      type: 'setRedirectToCreatedVisit',
      currentVisitIds: { patientId: patient.Id, visitId: visit.Id },
    });
  };

  window.dataLayer.push({
    event: 'newVisit',
  });

  return (
    <Fragment>
      <Loader showLoader={loader}></Loader>
      <Card title="test" pageView="drawerView" headerHidden={true}>
        <Grid item container>
          <Grid
            item
            xs={12}
            style={{ marginBottom: '64px', marginTop: '60px' }}
          >
            <GekkoSpinnerVink2
              className={classes.vinkSpinner}
            ></GekkoSpinnerVink2>
            <h1 className={classes.savedText}>Visit saved successfully!</h1>
          </Grid>

          <Grid item xs={12} sm={6} style={{ marginBottom: '25px' }}>
            <Grid item container>
              <Grid item md={1} lg={2} />
              <Grid item sm={12} md={10} lg={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} style={{ textAlign: 'left' }}>
                    <Typography
                      variant="h1"
                      className={classes.tableTitle}
                      style={{
                        display: 'inline',
                        marginBottom: '10px',
                      }}
                    >
                      Patient
                    </Typography>
                    <Button
                      color="primary"
                      startIcon={<EditIcon />}
                      component={Link}
                      to={patientPath}
                      onClick={handleRedirectToEditPatient}
                      style={{ margin: '0 0 6px 24px', padding: 0 }}
                    >
                      Edit
                    </Button>
                    <PatientTable></PatientTable>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginBottom: '25px' }}>
            <Grid item container>
              <Grid item md={1} lg={2} />
              <Grid item sm={12} md={10} lg={8}>
                <Grid container spacing={3} justifyContent="space-between">
                  <Grid item xs={12} style={{ textAlign: 'left' }}>
                    <Typography
                      variant="h1"
                      className={classes.tableTitle}
                      style={{
                        display: 'inline',
                        marginBottom: '10px',
                      }}
                    >
                      Visit
                    </Typography>
                    <Button
                      color="primary"
                      startIcon={<EditIcon />}
                      style={{ margin: '0 0 6px 24px', padding: 0 }}
                      component={Link}
                      to={visitPath}
                    >
                      Edit
                    </Button>
                    <VisitTable></VisitTable>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {integrationCase && (
              <Typography>
                Patient & Visit loaded from {integrationSource}, continue
                diagnostics from diagnose patient button
              </Typography>
            )}
            <Button
              className={classes.diagnoseButton}
              variant="contained"
              color="primary"
              onClick={goToDiagnosePatient}
            >
              DIAGNOSE PATIENT
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.homeButton}
              variant="contained"
              onClick={goToHomePage}
            >
              HOME
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  );
};
