import React, { useState } from 'react';
import Loader from '../components/Loader';
import ThreeFlows from '../components/home/ThreeFlows';
declare global {
  interface Window {
    dataLayer: any;
  }
}

export const HomePage = () => {
  window.dataLayer.push({
    event: 'loginSuccessful',
    extraData: 'test data',
  });

  const [visitRowClicked] = useState(false);

  return (
    <>
      <Loader showLoader={visitRowClicked} />
      <ThreeFlows />
    </>
  );
};
