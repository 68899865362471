import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSymptoms } from '../../contexts/SymptomsContext';
import Typography from '@mui/material/Typography';
import { SymptomOption } from '../../reducers/symptomsList/symptoms.state';

const useStyles = makeStyles()((theme: Theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    width: '100%',
    textAlign: 'left',
  },
}));

export const SelectSymptomByName = ({
  value,
  onSelected,
  inputValue,
  setInputValue,
}: {
  value: SymptomOption | null;
  onSelected: (value: SymptomOption | null) => void;
  inputValue: string;
  setInputValue: (value: string) => void;
}) => {
  const { classes } = useStyles();
  const { subSymptomOptions } = useSymptoms();

  return (
    <Autocomplete
      className={classes.formControl}
      id="selectSymptomByName"
      options={subSymptomOptions}
      getOptionLabel={(symptom) =>
        symptom.Name.substring(symptom.Name.indexOf(': ') + 2).replaceAll(
          '\n',
          ', '
        )
      }
      value={value}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      filterOptions={(options) =>
        inputValue.length < 2
          ? []
          : options.filter((option) =>
              option.Name.toLowerCase().includes(inputValue.toLowerCase())
            )
      }
      noOptionsText="No results"
      onChange={(event: any, newValue: SymptomOption | null) => {
        onSelected(newValue);
      }}
      renderOption={(props, option) => (
        <li {...props}>
          <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
            {option.Name}
          </Typography>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          variant="outlined"
          placeholder="Search symptom"
          style={{ whiteSpace: 'pre-line' }}
        />
      )}
    />
  );
};
