import {
  DosagesPageAction,
  DosagesPageDefault,
  DosagesPageState,
} from './dosages.page.state';

export const dosagesPageReducer = (
  state: DosagesPageState,
  action: DosagesPageAction
): DosagesPageState => {
  switch (action.type) {
    case 'reset': {
      return { ...DosagesPageDefault };
    }

    case 'request': {
      return { ...state, isLoading: true };
    }

    case 'success': {
      const treatmentDosagesObj: { [x: number]: number } = {};
      action.responseData.forEach((treatmentWithDosages) => {
        treatmentWithDosages.Dosages.forEach((dosage, index) => {
          treatmentDosagesObj[dosage.DrugSubstance_Animals.DrugSubstanceId] =
            index;
        });
      });

      return {
        ...state,
        isLoading: false,
        treatmentDosages: action.responseData,
        treatmentDosagesObj,
      };
    }

    case 'failure': {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }

    case 'import': {
      return {
        ...action.dosagesPage,
      };
    }

    case 'setDrugDosageSelect': {
      const selectedDrugDosages = { ...state.selectedDrugDosages };

      const isSelectedDoseAlternative =
        action.treatmentId in selectedDrugDosages &&
        action.drugSubstanceId in selectedDrugDosages[action.treatmentId] &&
        action.doseAlternativeId in
          selectedDrugDosages[action.treatmentId][action.drugSubstanceId];
      if (action.checked && !isSelectedDoseAlternative) {
        if (!(action.treatmentId in selectedDrugDosages)) {
          selectedDrugDosages[action.treatmentId] = {};
        }
        if (
          !(action.drugSubstanceId in selectedDrugDosages[action.treatmentId])
        ) {
          selectedDrugDosages[action.treatmentId][action.drugSubstanceId] = {};
        }
        if (
          !(
            action.doseAlternativeId in
            selectedDrugDosages[action.treatmentId][action.drugSubstanceId]
          )
        ) {
          selectedDrugDosages[action.treatmentId][action.drugSubstanceId][
            action.doseAlternativeId
          ] = [];
        }
      }

      if (action.checked) {
        selectedDrugDosages[action.treatmentId][action.drugSubstanceId][
          action.doseAlternativeId
        ] = action.calculatedDoses!;
      } else {
        if (isSelectedDoseAlternative) {
          delete selectedDrugDosages[action.treatmentId][
            action.drugSubstanceId
          ][action.doseAlternativeId];
          if (
            Object.keys(
              selectedDrugDosages[action.treatmentId][action.drugSubstanceId]
            ).length === 0
          ) {
            delete selectedDrugDosages[action.treatmentId][
              action.drugSubstanceId
            ];
          }
          if (
            Object.keys(selectedDrugDosages[action.treatmentId]).length === 0
          ) {
            delete selectedDrugDosages[action.treatmentId];
          }
        }
      }

      return {
        ...state,
        selectedDrugDosages,
      };
    }

    case 'setDeletedDrugAlternative': {
      return {
        ...state,
        deletedDrugAlternative: action.deletedDrugAlternative,
      };
    }

    case 'resetDeletedDrugAlternative': {
      const newState = { ...state };
      if ('deletedDrugAlternative' in newState) {
        delete newState.deletedDrugAlternative;
      }

      return newState;
    }

    default:
      return state;
  }
};
