import React, { createContext, useReducer, Dispatch } from 'react';
import {
  patientReducer,
  PatientActions,
  PatientState,
  symptomsReducer,
  SymptomsActions,
  SymptomsState,
  profileReducer,
  ProfileActions,
  ProfileState,
} from '../reducers/Reducers';
import useApi from '../hooks/useApi';
import { AppParamsProvider } from './AppParamsContext';

type ProviderProps = {
  children: any;
};

type InitialStateType = {
  patientsState: PatientState;
  symptomsState: SymptomsState;
  profileState: ProfileState;
};

const initialState: InitialStateType = {
  patientsState: { patients: [] },
  symptomsState: {
    symptoms: [],
    symptomTypes: [],
    symptomTypeCategories: [],
    geographicalAreas: [],
  },
  profileState: {
    profile: {},
    subscription: {},
    specialities: [],
    vetSpecialities: [],
    vetAnimalTypes: [],
    loading: false,
  },
};

interface IStateContext {
  state: InitialStateType;
  dispatch: Dispatch<PatientActions | SymptomsActions | ProfileActions>;
}

const AppContext = createContext<IStateContext>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = (
  { patientsState, symptomsState, profileState }: InitialStateType,
  action: PatientActions | SymptomsActions | ProfileActions
) => ({
  patientsState: patientReducer(patientsState, action),
  symptomsState: symptomsReducer(symptomsState, action, () => {}),
  profileState: profileReducer(profileState, action, () => {}),
});

const AppProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const api = useApi(state, dispatch);
  return (
    <AppContext.Provider value={api}>
      <AppParamsProvider>{children}</AppParamsProvider>
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
