import {
  Symptom,
  SymptomType,
  SymptomTypeCategory,
} from '../../types/Symptom.type';
import { RequestAction } from '../../types/request.action.type';

export type SymptomOption = { Id: number; Name: string };

export type MainOrSubSymptom = { Name: string; children?: ParentSymptom };

export type ParentSymptom = {
  [x: string]: MainOrSubSymptom;
};

export type SymptomsState = {
  categories: SymptomTypeCategory[];
  categoriesObj: { [x: string]: number };

  symptomTypes: SymptomType[];
  symptomTypesObj: { [x: string]: number };

  symptoms: Symptom[];
  symptomsObj: { [x: string]: number };

  symptomsByType: ParentSymptom;
  subSymptomOptions: SymptomOption[];

  isLoading: boolean;
  error?: string;
};

export const SymptomsDefault: SymptomsState = {
  categories: [],
  categoriesObj: {},
  symptomTypes: [],
  symptomTypesObj: {},
  symptoms: [],
  symptomsObj: {},
  symptomsByType: {},
  subSymptomOptions: [],
  isLoading: false,
};

export type SymptomsAction = RequestAction<
  Symptom[] | SymptomType[] | SymptomTypeCategory[]
>;
