import { Moment } from 'moment';
import { NO_VISITS_TEXT } from '../../config/constant.params';
import { PatientVisit } from '../../types/PatientVisit.type';
import {
  formatDateToRequest,
  formatDbDateStringToUi,
} from '../../utils/dateUtils';
import {
  VisitsListState,
  VisitsListAction,
  VisitsListDefault,
} from './visits.list.state';

const sortByTime = (arr: PatientVisit[]) => {
  return arr.sort((a, b) =>
    a.Date > b.Date
      ? 1
      : b.Date > a.Date
      ? -1
      : a.Time > b.Time
      ? 1
      : b.Time > a.Time
      ? -1
      : 0
  );
};

const addVisitToState = (state: VisitsListState, visit: PatientVisit) => {
  const visitsObj = { ...state.visitsObj };
  visitsObj[visit.Id] = visit;

  const visits = sortByTime([...state.visits!, visit]);
  return {
    ...state,
    isLoading: false,
    visits,
    visitsObj,
  };
};

const makeNoVisitsStr = (
  visits: PatientVisit[],
  fromDate: Moment | undefined,
  toDate: Moment | undefined,
  locale: string
) => {
  return visits.length > 0
    ? ''
    : fromDate && toDate
    ? `${NO_VISITS_TEXT} in period: ${formatDbDateStringToUi(
        formatDateToRequest(fromDate),
        locale
      )} - ${formatDbDateStringToUi(formatDateToRequest(toDate), locale)}`
    : `${NO_VISITS_TEXT} for today`;
};

export const visitsListReducer = (
  state: VisitsListState,
  action: VisitsListAction
): VisitsListState => {
  switch (action.type) {
    case 'reset': {
      return {
        ...VisitsListDefault,
        isLoading: false,
        ...(state.fromDate && { fromDate: state.fromDate }),
        ...(state.toDate && { toDate: state.toDate }),
      };
    }

    case 'request': {
      return {
        ...VisitsListDefault,
        isLoading: true,
        ...(state.fromDate && { fromDate: state.fromDate }),
        ...(state.toDate && { toDate: state.toDate }),
      };
    }

    case 'success': {
      const visitsObj: { [x: string]: PatientVisit } = {};
      action.responseData.forEach((visit: PatientVisit) => {
        visitsObj[visit.Id] = visit;
      });

      const visits = sortByTime(action.responseData);
      const noVisitsText = makeNoVisitsStr(
        visits,
        state.fromDate,
        state.toDate,
        action.options
      );

      return {
        ...state,
        isLoading: false,
        visits,
        visitsObj,
        noVisitsText,
      };
    }

    case 'failure': {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }

    case 'add': {
      if (!state.visits) {
        return state;
      }
      if (action.visit.Date !== formatDateToRequest(new Date())) {
        return state;
      }
      if (!state.visitsObj || action.visit.Id.toString() in state.visitsObj) {
        return state;
      }

      return addVisitToState(state, action.visit);
    }

    case 'update': {
      if (!state.visits) {
        return state;
      }

      if (action.visit.Date === formatDateToRequest(new Date())) {
        if (action.visit.Id in state.visitsObj!) {
          const visits = state.visits?.map((visit) => {
            if (visit.Id === action.visit.Id) {
              return { ...action.visit };
            }
            return visit;
          });
          const visitsObj = {
            ...state.visitsObj,
            [action.visit.Id]: action.visit,
          };

          return {
            ...state,
            visits,
            visitsObj,
          };
        } else {
          return addVisitToState(state, action.visit);
        }
      } else {
        if (action.visit.Id in state.visitsObj!) {
          const visits = [
            ...state.visits.filter((visit) => visit.Id !== action.visit.Id),
          ];
          const visitsObj = { ...state.visitsObj };
          delete visitsObj[action.visit.Id];

          const noVisitsText =
            visits.length === 0 ? `${NO_VISITS_TEXT} for today` : '';
          return {
            ...state,
            visits,
            visitsObj,
            noVisitsText,
          };
        } else {
          return state;
        }
      }
    }

    case 'deleteVisitsForPatientId': {
      if (!state.visits) {
        return state;
      }
      const deletedVisitsIds: number[] = [];
      const visits = [
        ...state.visits.filter((visit) => {
          const isDeletedPatient = visit.PatientId === action.patientId;
          if (isDeletedPatient) {
            deletedVisitsIds.push(visit.Id);
          }
          return !isDeletedPatient;
        }),
      ];

      const visitsObj = { ...state.visitsObj };

      deletedVisitsIds.forEach((Id) => {
        if (Id in visitsObj) {
          delete visitsObj[Id];
        }
      });

      const noVisitsText =
        visits.length === 0 ? `${NO_VISITS_TEXT} for today` : '';

      return {
        ...state,
        visits,
        visitsObj,
        noVisitsText,
      };
    }

    case 'setDates': {
      return {
        ...state,
        fromDate: action.fromDate,
        toDate: action.toDate,
      };
    }

    default:
      return state;
  }
};
