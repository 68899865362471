import Grid from '@mui/material/Grid';
import React from 'react';
import { useDiagnosesPage } from '../../contexts/DiagnosesPageContext';
import {
  useTreatmentsPage,
  useTreatmentsPageDispatch,
} from '../../contexts/TreatmentsPageContext';
import { useRequestData } from '../../hooks/useRequestData';
import { TreatmentInfo } from '../../types/Diagnose.type';
import { Card } from '../common/Card';
import Loader from '../Loader';
import DiagnoseAccordionList from './DiagnoseAccordionList';
import { SubsequentDiseasesList } from './SubsequentDiseasesList';

export const CardSelectedDiagnoses = () => {
  const { selectedDiagnoses } = useDiagnosesPage();
  const { isLoading, error, treatmentInfos } = useTreatmentsPage();
  const dispatchTreatmentsPage = useTreatmentsPageDispatch();
  const needTreatments =
    !error && !isLoading && !treatmentInfos && selectedDiagnoses.length > 0;

  useRequestData<TreatmentInfo[]>({
    needTransport: needTreatments,
    dispatch: dispatchTreatmentsPage,
    method: 'get',
    url: '/treatments',
    params: { animalTypeDiseaseIds: selectedDiagnoses.join(',') },
  });

  if (error) {
    dispatchTreatmentsPage({ type: 'reset' });
    throw error;
  }

  if (isLoading) {
    return <Loader showLoader={true} />;
  }

  return (
    <Card
      title="SELECTED DIAGNOSES"
      pageView="drawerView"
      withoutMarginBottom
      maxWidth
    >
      <Grid container>
        <Grid item xs={12}>
          <DiagnoseAccordionList />
          <SubsequentDiseasesList canSelect={true} />
        </Grid>
      </Grid>
    </Card>
  );
};
