import { Typography } from '@mui/material';

export const VisitTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography
      style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '0' }}
    >
      {children}
    </Typography>
  );
};
