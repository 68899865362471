import React from 'react';
import Grid from '@mui/material/Grid';
import { Treatment, TreatmentSet } from '../../types/Diagnose.type';
import { TreatmentProtocolCard } from '../diseases-and-treatments/TreatmentProtocolCard';
import { UNDEFINED_ID } from '../../config/constant.params';
import { useTreatmentsPage } from '../../contexts/TreatmentsPageContext';
import { TreatmentsInfo } from './TreatmentsInfo';

export const TreatmentsListMobile = ({
  canSelect,
  treatments,
  selectedProtocolId,
  setSelectedProtocolId,
  treatmentSetTreatments,
}: {
  canSelect: boolean;
  treatments: Treatment[];
  selectedProtocolId: number;
  setSelectedProtocolId: (value: number) => void;
  treatmentSetTreatments: Treatment[] | null;
}) => {
  const { selectedTreatmentsObj, selectedTreatments: selectedTreatmentIds } =
    useTreatmentsPage();
  const handleTreatmentClick = (treatmentId: number) => {
    selectedProtocolId === treatmentId
      ? setSelectedProtocolId(UNDEFINED_ID)
      : setSelectedProtocolId(treatmentId);
  };

  const treatmentsSet: { [x: string]: TreatmentSet } = {};

  treatments.forEach(
    (treatment) =>
      (treatmentsSet[treatment.TreatmentSet.Name] = treatment.TreatmentSet)
  );

  return (
    <Grid container>
      {Object.entries(treatmentsSet).map(([key, treatmentSet]) => {
        const selectedTreatments: Treatment[] = [];
        selectedTreatmentIds.forEach((treatmentId) => {
          if (
            selectedTreatmentsObj[treatmentId].TreatmentSet.Id ===
            treatmentSet.Id
          ) {
            selectedTreatments.push(selectedTreatmentsObj[treatmentId]);
          }
        });

        return (
          <Grid key={key} item>
            <TreatmentProtocolCard
              onClick={handleTreatmentClick}
              selected={treatmentSet.Id === selectedProtocolId}
              treatmentSet={treatmentSet}
              selectedTreatments={selectedTreatments}
            />
            {treatmentSet.Id === selectedProtocolId && (
              <TreatmentsInfo
                treatmentSetTreatments={treatmentSetTreatments}
                canSelect={canSelect}
              />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};
