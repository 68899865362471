import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PatientsIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g transform="translate(0.000000, 1.000000)">
        <path
          d="M8.5,0 C6.84314575,0 5.5,1.34314575 5.5,3 C5.5,4.65685425 6.84314575,6 8.5,6 C10.1568542,6 11.5,4.65685425 11.5,3 C11.5,1.34314575 10.1568542,0 8.5,0 Z M8.5,2 C9.05228475,2 9.5,2.44771525 9.5,3 C9.5,3.55228475 9.05228475,4 8.5,4 C7.94771525,4 7.5,3.55228475 7.5,3 C7.5,2.44771525 7.94771525,2 8.5,2 Z"
          id="Oval"
          fill="#444444"
          fillRule="nonzero"
        ></path>
        <path
          d="M15.5,0 C13.8431458,0 12.5,1.34314575 12.5,3 C12.5,4.65685425 13.8431458,6 15.5,6 C17.1568542,6 18.5,4.65685425 18.5,3 C18.5,1.34314575 17.1568542,0 15.5,0 Z M15.5,2 C16.0522847,2 16.5,2.44771525 16.5,3 C16.5,3.55228475 16.0522847,4 15.5,4 C14.9477153,4 14.5,3.55228475 14.5,3 C14.5,2.44771525 14.9477153,2 15.5,2 Z"
          id="Oval-Copy-3"
          fill="#444444"
          fillRule="nonzero"
        ></path>
        <path
          d="M21,5 C19.3431458,5 18,6.34314575 18,8 C18,9.65685425 19.3431458,11 21,11 C22.6568542,11 24,9.65685425 24,8 C24,6.34314575 22.6568542,5 21,5 Z M21,7 C21.5522847,7 22,7.44771525 22,8 C22,8.55228475 21.5522847,9 21,9 C20.4477153,9 20,8.55228475 20,8 C20,7.44771525 20.4477153,7 21,7 Z"
          id="Oval-Copy"
          fill="#444444"
          fillRule="nonzero"
        ></path>
        <path
          d="M3,5 C1.34314575,5 0,6.34314575 0,8 C0,9.65685425 1.34314575,11 3,11 C4.65685425,11 6,9.65685425 6,8 C6,6.34314575 4.65685425,5 3,5 Z M3,7 C3.55228475,7 4,7.44771525 4,8 C4,8.55228475 3.55228475,9 3,9 C2.44771525,9 2,8.55228475 2,8 C2,7.44771525 2.44771525,7 3,7 Z"
          id="Oval-Copy-2"
          fill="#444444"
          fillRule="nonzero"
        ></path>
        <path
          d="M12.5,7 C8.95883189,7 6.05280512,10.0647945 3.67612275,15.9537162 C3.4268557,16.5713478 3.33575943,17.2414469 3.41110025,17.9032072 C3.66099412,20.0981637 5.64293693,21.6749485 7.83789344,21.4250546 L9.66918076,21.2247341 L11.0825545,21.0878779 L11.9395529,21.0219885 L12.1995753,21.0079186 L12.4118553,21.0008801 L12.6025681,21.0012049 L12.84981,21.0108415 L13.3251414,21.0433529 L13.9245623,21.0975145 L14.914343,21.2033739 L15.7977368,21.307976 L16.453041,21.3897032 C17.0331605,21.4633349 17.6223692,21.4088743 18.179164,21.2301576 C20.282601,20.5550088 21.4404573,18.30252 20.7653086,16.1990831 L20.6413962,15.8201024 C18.7127307,10.0321289 16.031136,7 12.5,7 Z M12.5,9 C14.9442274,9 17.0565816,11.3884781 18.7422086,16.4470435 L18.8626814,16.8155081 C19.1985731,17.862033 18.6196462,18.9882749 17.5679302,19.3258485 C17.2895334,19.4152066 16.9949297,19.4424369 16.7048706,19.4056211 L15.7007761,19.2815992 L14.5534153,19.1513789 L13.8346124,19.0800448 L13.2356904,19.0312584 L12.7552175,19.0049999 L12.3919865,19.0009954 L12.1389699,19.0089613 L11.6663399,19.035863 L11.0809743,19.0807321 L10.1231577,19.1685535 L8.63710821,19.3234135 L7.61234474,19.4378135 C6.51418001,19.5628384 5.52321004,18.7744471 5.39826329,17.6769704 C5.36059293,17.3460908 5.406141,17.0110417 5.53077435,16.7022263 C7.63548795,11.4871863 9.99382882,9 12.5,9 Z"
          id="Path-4"
          fill="#444444"
          fillRule="nonzero"
        ></path>
      </g>
    </SvgIcon>
  );
};

export default PatientsIcon;
