import { Grid, MenuItem } from '@mui/material';
import { Button } from '../Button';
import { Card } from '../common/Card';
import Checkbox from '@mui/material/Checkbox';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { RegistrationFormData } from '../../types/registration/registrationForm.type';
import {
  RegistrationFieldName,
  registrationFieldNames,
} from '../../types/registration/registrationForm.type';
import { TextField } from './TextField';
import { PasswordField } from './PasswordField';
import { CountrySelect } from '../common/CountrySelect';
import axios from 'axios';
import { Country } from '../../types/country.type';
import { countriesURL, personTypesURL } from '../../config/urls.noAuth';
import { Veterinarian } from '../../types/Veterinarian.type';
import Loader from '../Loader';
import { useMediaQuery } from '@mui/material';
import { useIsPilotEnviroment } from '../../hooks/useIsPilotEnviroment';
import { useAuthUser } from '../../contexts/AuthUserContext';
import { PersonType } from '../../types/PersonType.type';

type CountryStatus = 'start' | 'fetch' | 'success' | 'failure';

const STUDENT_BLACKLIST_DOMAIN = [
  'gmail.com',
  'hotmail.com',
  'yahoo.com',
  'outlook.com',
  'msn.com',
  'aol.com',
  'icloud.com',
];

const STUDENT_PERSON_TYPE_IDS = [3];

const useStyles = makeStyles()((theme: Theme) => ({
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '90%',
    },
  },
  box: {
    maxWidth: '650px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: '90%',
  },
  title: {
    [theme.breakpoints.up('xs')]: {
      //width: '90%',
      //marginLeft: 'auto',
      //marginRight: 'auto',
      marginLeft: '16px',
    },
    [theme.breakpoints.only('xs')]: {
      marginLeft: '0px',
    },
    textAlign: 'left',
  },
  textTransform: {
    textTransform: 'uppercase',
  },
  info: {
    [theme.breakpoints.up('xs')]: {
      marginLeft: '8px',
      marginRight: 'auto',
    },
    [theme.breakpoints.only('xs')]: {
      marginLeft: '0px',
      paddingRight: '20px',
    },
    textAlign: 'justify',
  },
  gridContainer: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '0',
    },
  },
  button: {
    margin: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      marginLeft: '0px',
    },
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
    backgroundSize: 'contain',
  },
  titleContainer: {
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  loginLink: {
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '0px',
    },
  },
  eulaContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'flex-start',
      textAlign: 'left',
    },
  },
  checkbox: { paddingLeft: '0px', paddingTop: '0px' },
  buttonContainer: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));

export const RegistrationForm = ({
  setUserData,
}: {
  setUserData: (value: Veterinarian) => void;
}) => {
  const { classes } = useStyles();

  const pilotEnviroment = useIsPilotEnviroment();
  const { signIn } = useAuthUser();

  const [countyStatus, setCountryStatus] = useState<CountryStatus>('start');
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [personTypes, setPersonTypes] = useState<PersonType[]>([]);

  const [state, setState] = useState(RegistrationFormData);

  const [eulaChecked, setEulaChecked] = React.useState(false);

  const handleEulaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEulaChecked(event.target.checked);
  };

  const matches = useMediaQuery('(min-width:600px)');

  const checkFormValid = () => {
    // If some of the basic fields are empty return false
    if (
      registrationFieldNames.some(
        (name) => state[name].required && state[name].value === ''
      )
    ) {
      return false;
    }
    const [, domain] = state.Email.value.split('@');
    if (
      STUDENT_PERSON_TYPE_IDS.indexOf(Number(state.PersonTypeId.value)) > -1 &&
      STUDENT_BLACKLIST_DOMAIN.indexOf(domain) > -1
    ) {
      setState((prevstate) => {
        return {
          ...prevstate,
          Email: {
            ...prevstate.Email,
            error: true,
            helperText:
              'Please use your university provided email address when registering as a student or university staff',
          },
        };
      });
      return false;
    }
    return true;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isFormValid = checkFormValid();

    if (isFormValid) {
      //TODO: add a Field to check if user wants to be public or not
      const veterinarian: Veterinarian = {
        FirstName: state.FirstName.value,
        Name: state.Name.value,
        Email: state.Email.value,
        Password: state.Password.value,
        CountryId: state.Country.value,
        VetNumber: state.VetNumber.value,
        PersonTypeId: Number(state.PersonTypeId.value),
        IsPublic: true,
        TermsOfServiceIsAccepted: true,
        WhatsNewSeen: state.WhatsNewSeen.value,
      };
      setUserData(veterinarian);
    } else {
      // If form is not valid set errors to the fields where information is missing
      registrationFieldNames.forEach((name) => {
        if (state[name].required && state[name].value === '') {
          setState((prevState) => {
            return {
              ...prevState,
              [name]: { ...prevState[name], error: true },
            };
          });
        }
      });
    }
  };

  useLayoutEffect(() => {
    if (countyStatus === 'start') {
      setCountryStatus('fetch');
      axios.get<Country[]>(countriesURL).then(
        (result) => {
          setCountryList(result.data);
          setCountryStatus('success');
        },
        (error) => {
          console.log('error', error);
          setCountryStatus('failure');
        }
      );
    }
  }, [countyStatus]);

  useEffect(() => {
    axios
      .get<PersonType[]>(personTypesURL)
      .then((result) => setPersonTypes(result.data))
      .catch((e) => {
        console.error('Unable to fetch person types', e);
      });
  }, []);

  const clearErrorFromState = (name: string): void => {
    setState((prevState) => {
      return { ...prevState, [name]: { ...prevState[name], error: false } };
    });
  };

  const handleChange = ({
    name,
    value,
  }: {
    name: RegistrationFieldName;
    value: string;
  }) => {
    clearErrorFromState(name);
    if (name === 'Country') {
      const data = value.split(',');
      setState((state) => {
        if (state[name].value !== value) {
          return {
            ...state,
            Country: { ...state.Country, value: data[0] || '' },
          };
        }
        return state;
      });
    } else {
      setState((state) => {
        if (state[name].value !== value) {
          return { ...state, [name]: { ...state[name], value } };
        }
        return state;
      });
    }
  };

  if (countyStatus === 'fetch') {
    return <Loader showLoader={true} />;
  }

  const textFieldStyle = matches
    ? undefined
    : {
        marginLeft: '0px',
        width: '100%',
      };

  return (
    <Card
      title="REGISTER AS A NEW USER"
      pageView={matches ? 'noDrawerView' : 'mobileView'}
      withoutMarginBottom={!matches}
    >
      <div className={classes.titleContainer}>
        <p className={classes.title}>Already registered? </p>
        <Button
          type="button"
          color="primary"
          onClick={() => signIn()}
          className={classes.loginLink}
        >
          LOGIN HERE
        </Button>
      </div>
      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12}>
            <p className={classes.info}>
              Please fill out the basic information. After that we can continue
              with the subscription plan and finally you can start diagnosing
              your patients by using your very own GekkoVet.
            </p>
          </Grid>
          {pilotEnviroment && (
            <Grid item xs={12}>
              <p className={classes.info}>
                In this pilot usage period we are gathering anonymized traffic
                information for analysing the usage of GekkoVet. If you continue
                using GekkoVet as a pilot user, you’ll accept this as a part of
                general terms of use. More information:{' '}
                <a
                  href="https://www.gekkovet.com/gekkocompass-data-privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.gekkovet.com/gekkocompass-data-privacy-policy
                </a>{' '}
              </p>
            </Grid>
          )}

          {registrationFieldNames.map((name: string) => {
            const field = state[name];
            if (state[name].type === 'text') {
              return (
                <Grid key={name} item xs={12}>
                  <TextField
                    {...field}
                    name={name}
                    setValue={handleChange}
                    currentValue={field.value}
                    style={textFieldStyle}
                  />
                </Grid>
              );
            } else if (state[name].type === 'country') {
              return (
                <Grid key={name} item xs={12}>
                  <CountrySelect
                    {...field}
                    name={name}
                    setValue={handleChange}
                    currentValue={field.value}
                    list={countryList}
                  />
                </Grid>
              );
            } else if (state[name].type === 'password') {
              return (
                <Grid key={name} item xs={12}>
                  <PasswordField
                    {...field}
                    name={name}
                    setValue={handleChange}
                    currentValue={field.value}
                    style={textFieldStyle}
                  />
                </Grid>
              );
            } else if (state[name].type === 'repeatPassword') {
              return (
                <Grid key={name} item xs={12}>
                  <PasswordField
                    {...field}
                    validator={(value: string) =>
                      value !== '' && value === state.Password.value
                        ? ''
                        : 'wrong'
                    }
                    name={name}
                    setValue={handleChange}
                    currentValue={field.value}
                  />
                </Grid>
              );
            } else if (field.type === 'personType') {
              return (
                <>
                  <Grid item xs={12}>
                    <TextField
                      {...field}
                      select
                      name={name}
                      setValue={handleChange}
                      currentValue={field.value}
                      helperText={
                        STUDENT_PERSON_TYPE_IDS.indexOf(Number(field.value)) >
                        -1
                          ? 'When registering as a veterinary student or university staff member, please use your university provided email address.'
                          : undefined
                      }
                    >
                      {personTypes.map((pt) => (
                        <MenuItem key={pt.Id} value={pt.Id}>
                          {pt.Name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </>
              );
            } else {
              return null;
            }
          })}
          <Grid item xs={12}>
            <div className={classes.eulaContainer}>
              <Checkbox
                checked={eulaChecked}
                onChange={handleEulaChange}
                color="default"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                className={classes.checkbox}
              />
              <p style={{ marginTop: '0px' }}>
                Accept END USER LICENSE AGREEMENT (EULA)
              </p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttonContainer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={!eulaChecked}
              >
                Register
              </Button>
              <a
                href="https://www.gekkovet.com/gekkocompass-eula"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                >
                  READ EULA
                </Button>
              </a>
            </div>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};
