import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export type Diagnose = {
  disease_name: string;
  diagnose_id: number;
  probability: number;
};

const useStyles = makeStyles()(() => ({
  tableBody: {
    backgroundColor: '#fff',
  },
}));

const DiagnoseTable = ({ diagnoses }: { diagnoses: Diagnose[] }) => {
  const { classes } = useStyles();
  return (
    <>
      <p>Based on the symptoms the following diseases should be considered:</p>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Disease</TableCell>
            <TableCell>Probability</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {diagnoses.map((d, idx) => (
            <TableRow key={idx}>
              <TableCell>{d.disease_name}</TableCell>
              <TableCell>{d.probability}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default DiagnoseTable;
