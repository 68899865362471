import { TextField as MuiTextField } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FieldProps, ExtraFieldProps } from '../../types/form.type';

export function TextField<T extends string>({
  name,
  label,
  required = false,
  helperText = ' ',
  validator,
  currentValue = '',
  setValue,
  disabled = false,
  placeholder = '',
  error,
  select,
  children,
  style = { width: '100%' },
}: FieldProps<T> & ExtraFieldProps<T> & { name: T; disabled?: boolean }) {
  const [state, setState] = useState(() => ({
    value: currentValue,
    error: '',
  }));
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = (event.target.value + '').trimStart();

    const valueNoEndSpace = (event.target.value + '').trimEnd();
    const error = validator ? validator(valueNoEndSpace) : '';

    setState(() => ({
      value,
      error: '',
    }));
    if (valueNoEndSpace !== currentValue) {
      setValue({ name, value: error === '' ? value : '' });
    }
  };

  const handleFocus = () => {
    setState((value) => ({
      ...value,
      error: '',
    }));
  };

  const handleBlur = () => {
    const error = validator ? validator(state.value) : '';
    setState((state) => ({
      ...state,
      error,
    }));
    if (state.value !== currentValue) {
      setValue({ name, value: error === '' ? state.value : '' });
    }
  };

  useEffect(() => {
    if (
      state.value !== currentValue &&
      state.value === '' &&
      state.error === ''
    ) {
      setState({ error: '', value: currentValue });
    }
  }, [currentValue, state.error, state.value]);

  return (
    <MuiTextField
      required={required}
      id={name}
      label={label}
      onChange={handleChange}
      variant="outlined"
      value={state.value}
      onFocus={handleFocus}
      onBlur={handleBlur}
      helperText={state.error === '' ? helperText : state.error}
      error={state.error === '' ? error : true}
      size="small"
      inputProps={{ style: { fontSize: '1em' } }}
      disabled={disabled}
      style={style}
      select={select}
      placeholder={placeholder}
    >
      {children}
    </MuiTextField>
  );
}
