import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { withStyles } from 'tss-react/mui';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DiagnoseAccordionDetails from './DiagnoseAccordionDetails';
import { Typography } from '@mui/material';
import { Diagnose } from '../../types/Diagnose.type';
import ConditionTooltip from './ConditionTooltip';
import IconButton from '@mui/material/IconButton';
import BlockIcon from '@mui/icons-material/Block';
import ReplayIcon from '@mui/icons-material/Replay';
import Tooltip from '@mui/material/Tooltip';
import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../../contexts/DiagnosesPageContext';
import { SubsequentDiagnose } from '../../reducers/diagnosesPage/diagnoses.page.state';
import { useSymptoms } from '../../contexts/SymptomsContext';
import { splitSymptomNameInTwoLines } from '../../utils/symptomsUtils';
import SymptomsWithAddedInfoTooltip from './SymptomsWithAddedInfoTooltip';

const Accordion = withStyles(MuiAccordion, (_theme, _params, classes) => ({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    [`&.${classes.expanded}`]: {
      margin: 'auto',
    },
  },
  expanded: {},
}));

const useStyles = makeStyles()((theme: Theme) => ({
  column1: {
    flexBasis: '3%',
    textAlign: 'left',
    [theme.breakpoints.only('xs')]: {
      marginRight: '10px',
    },
  },
  column2: {
    flexBasis: '92%',
    textAlign: 'left',
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    paddingTop: '11px',
  },
  column3: {
    flexBasis: '5%',
  },
}));

export default function DiagnoseAccordion({
  diagnose,
  checked,
  highlightBreedId,
  updateDifferentials,
}: {
  diagnose: Diagnose;
  checked: boolean;
  highlightBreedId?: number | undefined;
  updateDifferentials: () => void;
}) {
  const { classes } = useStyles();
  const { excludedDiagnoses } = useDiagnosesPage();
  const dispatchPageDiagnoses = useDiagnosesPageDispatch();
  const { symptoms, symptomsObj } = useSymptoms();

  const addedInfoDiseaseSymptoms = diagnose.DiseaseSymptoms.some(
    (diseaseSymptom) => diseaseSymptom.ReferenceText !== null
  );

  const isExcluded = excludedDiagnoses.indexOf(diagnose.Id) > -1;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    event.stopPropagation();

    const subsequentDiagnoses: SubsequentDiagnose[] = [];
    if (checked) {
      diagnose.categorySymptoms![4].forEach((s) => {
        const EquivalentToDiseaseId = s.Symptom.EquivalentToDiseaseId;
        const nameLines = splitSymptomNameInTwoLines(
          symptoms[symptomsObj[s.Symptom.Id]].combinedName!
        );

        if (EquivalentToDiseaseId) {
          subsequentDiagnoses.push({
            Name: nameLines.secondLine,
            EquivalentToDiseaseId,
          });
        }
      });
    }

    if (!isExcluded) {
      dispatchPageDiagnoses({
        type: 'setDiagnoseSelected',
        checked,
        diagnoseId: diagnose.Id,
        ...(checked && { subsequentDiagnoses }),
      });
    }
  };

  const handleExclude = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    dispatchPageDiagnoses({
      type: 'toggleExcludeDiagnose',
      diagnoseId: diagnose.Id,
    });
  };

  return (
    <Accordion
      square
      style={{
        backgroundColor: isExcluded ? 'lightgrey' : 'inherit',
        color: isExcluded ? 'grey' : 'inherit',
      }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
      >
        <FormControlLabel
          style={{ marginRight: 0 }}
          onFocus={(event) => event.stopPropagation()}
          onClick={(event) => event.stopPropagation()}
          control={
            <Checkbox
              checked={checked}
              onChange={handleChange}
              name={`checked_${diagnose.Id}`}
              color="primary"
            />
          }
          label=""
        />
        <div className={classes.column1}>
          <Typography className={classes.wrapIcon}>
            <b>{diagnose.Propability} </b>
          </Typography>
        </div>

        <Typography className={`${classes.column2} ${classes.wrapIcon}`}>
          {diagnose.Disease.Name}
          &nbsp;&nbsp;&nbsp;
          {diagnose.Condition === 1 && <ConditionTooltip />}
          {addedInfoDiseaseSymptoms && <SymptomsWithAddedInfoTooltip />}
        </Typography>
        {isExcluded ? (
          <Tooltip title="Include" placement="left">
            <IconButton
              aria-label="Include"
              edge="end"
              onClick={handleExclude}
              size="large"
            >
              <ReplayIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Exclude" placement="left">
            <IconButton
              aria-label="Exclude"
              edge="end"
              onClick={handleExclude}
              size="large"
            >
              <BlockIcon />
            </IconButton>
          </Tooltip>
        )}
      </AccordionSummary>
      <DiagnoseAccordionDetails
        diagnose={diagnose}
        highlightBreedId={highlightBreedId}
        updateDifferentials={updateDifferentials}
      />
    </Accordion>
  );
}
