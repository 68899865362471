import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  ThemeProvider,
  StyledEngineProvider,
  ThemeOptions,
  createTheme,
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import { AppProvider } from './contexts/Context';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Layout from './pms-integration/Layout';

import { AuthUserProvider } from './contexts/AuthUserContext';

import API from '../src/api/ApiLayer';

// read vendor from query string and choose the right theme
const searchParams = new URLSearchParams(window.location.search);
console.log('searchParams', searchParams);
const vendor = searchParams.get('vendor');

const themes: { [key: string]: Partial<ThemeOptions> } = {
  provet: {
    spacing: 8,
    typography: {
      h4: {
        marginTop: '1em',
      },
      h5: {
        marginTop: '1em',
      },
    },
  },
};

const theme = themes[vendor || 'provet'];

function App() {
  useEffect(() => {
    const state = JSON.stringify({
      redirect_to: window.location.href,
      redirect_uri: `${window.location.origin}/pms/oauth2/callback`,
    });
    API.getRequest('/provet/oauth2/redirect', { state })
      .then((res) => {
        console.log('res', res);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);
  return (
    <AuthUserProvider>
      <BrowserRouter basename="/pms">
        <CssBaseline />
        <ThemeProvider theme={createTheme(theme)}>
          <StyledEngineProvider>
            <AppProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Switch>
                  <Route path="/oauth2/callback" component={() => <div />} />
                  <Route component={Layout} />
                </Switch>
              </LocalizationProvider>
            </AppProvider>
          </StyledEngineProvider>
        </ThemeProvider>
      </BrowserRouter>
    </AuthUserProvider>
  );
}

export default App;
