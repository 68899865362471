import React from 'react';
import Grid from '@mui/material/Grid';
import { useDiagnosesPageDispatch } from '../../contexts/DiagnosesPageContext';
import { useSymptoms } from '../../contexts/SymptomsContext';
import {
  ParentSymptom,
  SymptomOption,
} from '../../reducers/symptomsList/symptoms.state';
import { getSelectOptions } from '../../utils/symptomsUtils';
import { Button } from '../Button';
import { SelectSymptomByType } from './SelectSymptomByType';
import Typography from '@mui/material/Typography';

const sortNames = (arr: SymptomOption[]) => {
  return arr.sort((a, b) => {
    const aData2 = a.Name.toLowerCase();
    const bData2 = b.Name.toLowerCase();
    return aData2 > bData2 ? 1 : bData2 > aData2 ? -1 : 0;
  });
};

export const SymptomByType = () => {
  const { symptomsByType, symptomTypes } = useSymptoms();
  const dispatch = useDiagnosesPageDispatch();

  const [state, setState] = React.useState<{
    idsList: string[][];
    optionsList: Array<ParentSymptom | null>;
  }>({ idsList: [['none']], optionsList: [symptomsByType] });

  const handleSelectSymptom = (id: string[] | string, index: number) => {
    let idsList = [...state.idsList];
    idsList.splice(index, 1, Array.isArray(id) ? id : [id]);
    idsList = idsList.slice(0, index + 1);

    const optionsList = [...state.optionsList].slice(0, index + 1);
    const options = getSelectOptions(symptomsByType, idsList, 0);
    if (options) {
      optionsList.push(options);
      idsList.push(['none']);
    }

    setState({ idsList, optionsList });
  };

  const handleAddSymptom = () => {
    let symptomNameForEvent = '';
    state.idsList.map((id, pos) => {
      if (id) {
        const mainGroupWrapper = state.optionsList[pos];
        if (mainGroupWrapper) {
          const symptom = mainGroupWrapper[parseInt(id[0])];
          const name =
            state && state.optionsList && symptom !== null ? symptom.Name : '';
          symptomNameForEvent += name + ' ';
        }
      }
      return true;
    });
    window.dataLayer.push({
      event: 'addedSymptom',
      symptomName: symptomNameForEvent,
    });
    state.idsList[state.idsList.length - 1].forEach((id) => {
      dispatch({
        type: 'addSymptom',
        symptomId: parseInt(id),
      });
    });
    setState({ idsList: [['none']], optionsList: [symptomsByType] });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle2" gutterBottom align="left">
          Search symptom by type
        </Typography>
      </Grid>

      {state.idsList.map((id, index) => {
        if (state.optionsList[index]) {
          let options: SymptomOption[] = [];
          let hasChildOptions = false;

          if (index === 0) {
            options = symptomTypes.map((element) => ({
              Id: element.Id,
              Name: state.optionsList[index]![element.Id].Name,
            }));
            hasChildOptions = true;
          } else if (state.optionsList[index]) {
            options = Object.entries(state.optionsList[index]!).map(
              ([key, value]) => ({
                Id: parseInt(key),
                Name: value.Name,
              })
            );
            options = sortNames(options);
            hasChildOptions = Object.values(state.optionsList[index]!).some(
              (itm) => {
                return itm.children && Object.keys(itm.children).length;
              }
            );
          }

          return (
            <Grid key={index} item xs={12}>
              <SelectSymptomByType
                id={state.idsList[index]}
                setId={(id) => handleSelectSymptom(id, index)}
                label="Select symptom"
                options={options}
                multiple={!hasChildOptions}
                labelId={`select-symptom-${index}`}
              />
            </Grid>
          );
        } else {
          return null;
        }
      })}

      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Button
          title="Add Symptom"
          color="primary"
          variant="contained"
          disabled={
            state.idsList[state.idsList.length - 1][0] === 'none' ||
            state.idsList.length === 1
          }
          onClick={handleAddSymptom}
        >
          ADD SYMPTOM
        </Button>
      </Grid>
    </Grid>
  );
};
