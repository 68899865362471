import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()(() => ({
  title: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    lineHeight: '1.71',
    letterSpacing: '0.1px',
    textTransform: 'uppercase',
    marginBottom: '1.5em',
  },
}));

export const CalculatorTitle = ({
  title = 'Calculate dosage for patient',
}: {
  title?: string;
}) => {
  const { classes } = useStyles();

  return <Typography className={classes.title}>{title}</Typography>;
};
