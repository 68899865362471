import { Dialog, DialogContent, Typography } from '@mui/material';
import React from 'react';
import {
  Source_AnimalTypeDiseases,
  Source_DiseaseSymptoms_AddedInfo,
  Source_Treatments,
} from '../../types/Diagnose.type';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {
  Source_DoseAlternatives,
  Source_DrugSubstanceAnimals,
  Source_DrugSubstanceDoses,
} from '../../types/DrugDosages.type';

export const SourcesDialog = ({
  sources,
  isOpen,
  closeDialog,
}: {
  sources:
    | Source_Treatments[]
    | Source_DoseAlternatives[]
    | Source_DrugSubstanceDoses[]
    | Source_AnimalTypeDiseases[]
    | Source_DrugSubstanceAnimals[]
    | Source_DiseaseSymptoms_AddedInfo[];
  isOpen: boolean;
  closeDialog: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>Sources</DialogTitle>
      <DialogContent dividers={true}>
        {sources.map((source) => {
          return (
            <Typography key={source.Id} style={{ marginBottom: '1em' }}>
              {source.Source.Name}
            </Typography>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
