import Grid from '@mui/material/Grid';
import React from 'react';
import { useDrugProducts } from '../../contexts/DrugProductsContext';
import { DrugProductsList } from './DrugProductsList';

export const DrugProductsContainer = ({
  drugSubstanceId,
  drugProductId,
  setDrugProductId,
}: {
  drugSubstanceId: number;
  drugProductId: number | null;
  setDrugProductId: (drugProductId: number | null) => void;
}) => {
  const { drugProductsObj } = useDrugProducts();

  return (
    <>
      <Grid item xs={12} style={{ textAlign: 'left', fontWeight: 'bold' }}>
        Products
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'left' }}>
        <DrugProductsList
          products={drugProductsObj[drugSubstanceId] || []}
          drugProductId={drugProductId}
          setDrugProductId={setDrugProductId}
        />
      </Grid>
    </>
  );
};
