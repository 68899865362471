import React, { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import { CardSelectedDiagnoses } from '../components/treatments/CardSelectedDiagnoses';
import { CardSubsequentDiseases } from '../components/treatments/CardSubsequentDiseases';
import { PatientInfoWithWrapper } from '../components/common/PatientInfoWithWrapper';
import { TreatmentsFloatingActionBar } from '../components/treatments/TreatmentsFloatingActionBar';
import { UNDEFINED_ID } from '../config/constant.params';
import { ROUTE_VISIT_DIAGNOSIS } from '../config/routes.config';
import { usePatientInfo } from '../contexts/CurrentPatientInfoContext';
import { useDiagnosesPage } from '../contexts/DiagnosesPageContext';
import { useWorkContext } from '../contexts/WorkContext';
import { MainContent } from '../layouts/MainContent';
import API from '../api/Api';

export const TreatmentsPage = () => {
  useEffect(() => {
    API.trackEvent('visit_treatments_page');
  }, []);
  const { pathname } = useLocation();

  const {
    Id,
    breedId,
    breedName,
    isLoading: isLoadingBreed,
    error: errorBreed,
    isTestPatient,
  } = usePatientInfo();
  const { patient } = useWorkContext();
  const { selectedDiagnoses } = useDiagnosesPage();

  const currentUrlParams = matchPath<{
    patientId: string;
    visitId: string;
  }>(pathname, {
    path: ROUTE_VISIT_DIAGNOSIS,
  });
  const patientId = currentUrlParams?.params.patientId;

  const noPatient =
    selectedDiagnoses &&
    Id !== parseInt(patientId!) &&
    !patient &&
    !isTestPatient;

  const noPatientInfo = selectedDiagnoses && patient && Id !== patient.Id;

  const noBreedName =
    selectedDiagnoses &&
    !isLoadingBreed &&
    !errorBreed &&
    breedId !== UNDEFINED_ID &&
    breedName === '';

  if (noPatient || noPatientInfo || noBreedName || isLoadingBreed) {
    return <Loader showLoader={true} />;
  }

  return (
    <MainContent>
      <PatientInfoWithWrapper />
      <CardSelectedDiagnoses />
      <CardSubsequentDiseases />
      <TreatmentsFloatingActionBar />
    </MainContent>
  );
};
