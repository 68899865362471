import React, { useEffect, useState } from 'react';
import {
  usePatientListDispatch,
  usePatientListState,
} from '../../contexts/PatientListContext';
import {
  useVisitsListDispatch,
  useVisitsListState,
} from '../../contexts/VisitsListContext';
import { useLocale } from '../../hooks/useLocale';
import { useRequestData } from '../../hooks/useRequestData';
import { Patient } from '../../types/Patient.type';
import { PatientVisit } from '../../types/PatientVisit.type';
import {
  formatDateToRequest,
  formatDbDateStringToUi,
  formatDbTimeStringToUi,
} from '../../utils/dateUtils';
import Loader from '../Loader';
import { ListTodayVisits, VisitRowProps } from './ListTodayVisits';

export const ListTodayVisitsContainer = ({
  homePage,
  setVisitRowClicked,
}: {
  homePage: boolean;
  setVisitRowClicked: (clicked: boolean) => void;
}) => {
  const locale = useLocale();

  const [todayVisitsRows, setTodayVisitsRows] =
    useState<Array<VisitRowProps> | null>(null);

  const {
    error: errorVisits,
    visits,
    isLoading: isLoadingVisits,
    noVisitsText,
  } = useVisitsListState();
  const dispatchVisits = useVisitsListDispatch();

  const {
    error: errorPatients,
    patientsObj,
    isLoading,
  } = usePatientListState();
  const dispatchPatients = usePatientListDispatch();

  useRequestData<Patient[]>({
    needTransport: !errorPatients && !patientsObj && !isLoading,
    dispatch: dispatchPatients,
    method: 'get',
    params: {
      modelName: 'Patients',
    },
  });

  useRequestData<PatientVisit[]>({
    needTransport:
      !errorVisits && !isLoadingVisits && !visits && Boolean(patientsObj),
    dispatch: dispatchVisits,
    method: 'get',
    params: {
      modelName: 'PatientVisits',
      Date: formatDateToRequest(new Date()),
    },
  });

  useEffect(() => {
    if (patientsObj && visits) {
      const visitsRows: Array<VisitRowProps> = visits.map((visit) => {
        return {
          Date: formatDbDateStringToUi(visit.Date, locale),
          Time: formatDbTimeStringToUi(visit.Time, locale),
          PatientName:
            patientsObj[visit.PatientId] &&
            patientsObj[visit.PatientId].NameOrId // TODO: remove this when DB is cleaned
              ? patientsObj[visit.PatientId].NameOrId!
              : 'Unknown',
          VisitReason: visit.VisitReason,
          PatientId: visit.PatientId.toString(),
          VisitId: visit.Id.toString(),
        };
      });
      setTodayVisitsRows(visitsRows);
    }
  }, [locale, patientsObj, visits]);

  if (errorPatients) {
    dispatchPatients({ type: 'reset' });
    throw errorPatients;
  }

  if (errorVisits) {
    dispatchVisits({ type: 'reset' });
    throw errorVisits;
  }

  if (!patientsObj || !todayVisitsRows) {
    return <Loader showLoader={true} />;
  }

  return (
    <ListTodayVisits
      list={todayVisitsRows}
      homePage={homePage}
      noVisitsText={noVisitsText}
      setVisitRowClicked={setVisitRowClicked}
    />
  );
};
