import { DrugMgPerUnit, DrugUnit } from './DrugDosages.type';

export type DrugSubstanceRowData = {
  Id: number;
  drugIndication: string;
  dosageSpecification: string | null;
  alternativeRows: AlternativeRow[];
  Info: string | null;
};

export type AlternativeRow = {
  id: number;
  type: string;
  route: string;
  dose: string;
  interval: string;
  duration: string;
};

export type CalculatedValues<T> = {
  strength: string;
  unit: T;
  dose: string;
  result: string;
};

export const DefaultWithDrugMgPerUnit: CalculatedValues<DrugMgPerUnit> = {
  strength: '',
  dose: '',
  unit: DrugMgPerUnit['mg/ml'],
  result: '',
};

export const DefaultWithDrugUnit: CalculatedValues<DrugUnit> = {
  strength: '',
  dose: '',
  unit: DrugUnit['ml'],
  result: '',
};
