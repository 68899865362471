import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { VisitTitle } from './VisitTitle';
import React from 'react';
import {
  formatDbDateStringToUi,
  formatDbTimeStringToUi,
} from '../../../utils/dateUtils';
import { useLocale } from '../../../hooks/useLocale';
import { PatientVisit } from '../../../types/PatientVisit.type';
import { Patient } from '../../../types/Patient.type';
import { useWorkHistory } from '../../../hooks/useWorkHistory';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const VisitList = ({
  title,
  visits,
  patient,
  setVisitLinkClicked,
}: {
  title: string;
  visits: PatientVisit[];
  patient: Patient;
  setVisitLinkClicked: (clicked: true) => void;
}) => {
  const locale = useLocale();
  const { goToWorkPageFromAnywhere } = useWorkHistory();
  return (
    <Box style={{ marginBottom: '15px' }}>
      <VisitTitle>{title}</VisitTitle>
      {visits.length === 0 && (
        <Typography variant="body2" style={{ marginTop: '5px' }}>
          No visits
        </Typography>
      )}
      {visits.length > 0 &&
        visits.map((visit) => {
          return (
            <div
              key={visit.Id}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVisitLinkClicked(true);
                goToWorkPageFromAnywhere(patient, visit);
              }}
            >
              <List>
                <ListItem
                  alignItems="flex-start"
                  style={{
                    padding: '0 0 15px 0',
                    borderBottom: '1px solid lightgrey',
                  }}
                >
                  <ListItemAvatar>
                    <div style={{ marginRight: '20px', textAlign: 'right' }}>
                      <Typography variant="body2">
                        {formatDbDateStringToUi(visit.Date, locale)}
                      </Typography>
                      <Typography variant="body2">
                        {formatDbTimeStringToUi(visit.Time, locale)}
                      </Typography>
                    </div>
                  </ListItemAvatar>
                  <ListItemText
                    primary={visit.VisitReason}
                    primaryTypographyProps={{ variant: 'body2' }}
                    style={{ paddingTop: '4px' }}
                  />
                  <ListItemSecondaryAction>
                    <ChevronRightIcon
                      style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>
          );
        })}
    </Box>
  );
};
