import { Treatment, TreatmentInfo } from '../../types/Diagnose.type';
import { RequestAction } from '../../types/request.action.type';
import { SubsequentDiagnose } from '../diagnosesPage/diagnoses.page.state';

export type TreatmentsPageState = {
  isLoading: boolean;
  error?: string;
  treatmentInfos?: TreatmentInfo[];
  treatmentsObj: { [x: number]: number };
  subsequentTreatmentInfos?: TreatmentInfo[];
  subsequentTreatmentsObj: { [x: number]: number };
  selectedTreatments: number[];
  selectedTreatmentsObj: { [x: number]: Treatment };
  subsequentDiseaseNames: { [x: number]: string };
  subsequentDiseaseIds: number[];
  selectedSubsequentDiseases: number[];
  confirmedSubsequentDiseases: number[];
};

export const TreatmentsPageDefault: TreatmentsPageState = {
  isLoading: false,
  treatmentsObj: {},
  subsequentTreatmentsObj: {},
  selectedTreatments: [],
  selectedTreatmentsObj: {},
  subsequentDiseaseNames: {},
  subsequentDiseaseIds: [],
  selectedSubsequentDiseases: [],
  confirmedSubsequentDiseases: [],
};

export type TreatmentsPageAction =
  | RequestAction<TreatmentInfo[]>
  | {
      type: 'import';
      treatmentsPage: TreatmentsPageState;
    }
  | {
      type: 'setTreatmentSelected';
      checked: boolean;
      treatment: Treatment;
    }
  | {
      type: 'deselectTreatments';
      deselectTreatmentIds: number[];
    }
  | {
      type: 'setSubsequentDiseaseSelected';
      checked: boolean;
      diseaseId: number;
    }
  | {
      type: 'setSubsequentDiseaseConfirmed';
      checked: boolean;
      diseaseId: number;
    }
  | {
      type: 'setSubsequentDiseaseNames';
      subsequentDiagnosesObj: {
        [x: number]: SubsequentDiagnose[];
      };
    };
