import { useMemo } from 'react';

/**
 * useLocale is used to resolve supported locale.
 * The locale is used for an example to format dates and times for USA market.
 * Developers should add new supported locales to the switch case.
 */
export const useLocale = () => {
  return useMemo(() => {
    let browserLocale;
    if (window.navigator.languages && window.navigator.languages[0]) {
      browserLocale = window.navigator.languages[0];
    }

    if (window.navigator.language) {
      browserLocale = window.navigator.language;
    }

    switch (browserLocale) {
      case 'en-US': {
        return browserLocale;
      }
      default: {
        break;
      }
    }

    return 'en-GB';
  }, []);
};
