import React from 'react';
import { CardDosages } from '../components/dosages/CardDosages';
import { DosageFloatingActionBar } from '../components/dosages/DosageFloatingActionBar';
import { PatientInfoWithWrapper } from '../components/common/PatientInfoWithWrapper';
import { MainContent } from '../layouts/MainContent';
import Loader from '../components/Loader';
import {
  useDoseParams,
  useDoseParamsDispatch,
} from '../contexts/DoseParamsContext';
import { useRequestData } from '../hooks/useRequestData';
import { DoseParam, DoseParamWithOrder } from '../types/Dose.params.type';

export const DosagesPage = () => {
  const {
    doseTypes,
    dosageIntervals,
    treatmentDurations,
    treatmentDurationPrefixes,
    summaryDrugUnits,
    summaryResultDrugUnits,
    error,
    isLoading,
  } = useDoseParams();
  const dispatchDoseParams = useDoseParamsDispatch();

  useRequestData<DoseParamWithOrder[]>({
    needTransport: !error && !isLoading && doseTypes.length === 0,
    dispatch: dispatchDoseParams,
    method: 'get',
    params: { modelName: 'DoseTypes' },
    dispatchOptions: 'DoseTypes',
  });

  useRequestData<DoseParamWithOrder[]>({
    needTransport:
      !error &&
      !isLoading &&
      doseTypes.length > 0 &&
      dosageIntervals.length === 0,
    dispatch: dispatchDoseParams,
    method: 'get',
    params: { modelName: 'DosageIntervals' },
    dispatchOptions: 'DosageIntervals',
  });

  useRequestData<DoseParamWithOrder[]>({
    needTransport:
      !error &&
      !isLoading &&
      doseTypes.length > 0 &&
      dosageIntervals.length > 0 &&
      treatmentDurations.length === 0,
    dispatch: dispatchDoseParams,
    method: 'get',
    params: { modelName: 'TreatmentDurations' },
    dispatchOptions: 'TreatmentDurations',
  });

  useRequestData<DoseParam[]>({
    needTransport:
      !error &&
      !isLoading &&
      doseTypes.length > 0 &&
      dosageIntervals.length > 0 &&
      treatmentDurations.length > 0 &&
      treatmentDurationPrefixes.length === 0,
    dispatch: dispatchDoseParams,
    method: 'get',
    params: { modelName: 'TreatmentDurationPrefixes' },
    dispatchOptions: 'TreatmentDurationPrefixes',
  });

  useRequestData<DoseParamWithOrder[]>({
    needTransport:
      !error &&
      !isLoading &&
      doseTypes.length > 0 &&
      dosageIntervals.length > 0 &&
      treatmentDurations.length > 0 &&
      treatmentDurationPrefixes.length > 0 &&
      summaryDrugUnits.length === 0,
    dispatch: dispatchDoseParams,
    method: 'get',
    params: { modelName: 'SummaryDrugUnits' },
    dispatchOptions: 'SummaryDrugUnits',
  });

  useRequestData<DoseParamWithOrder[]>({
    needTransport:
      !error &&
      !isLoading &&
      doseTypes.length > 0 &&
      dosageIntervals.length > 0 &&
      treatmentDurations.length > 0 &&
      treatmentDurationPrefixes.length > 0 &&
      summaryDrugUnits.length > 0 &&
      summaryResultDrugUnits.length === 0,
    dispatch: dispatchDoseParams,
    method: 'get',
    params: { modelName: 'SummaryResultDrugUnits' },
    dispatchOptions: 'SummaryResultDrugUnits',
  });

  if (error) {
    dispatchDoseParams({ type: 'reset' });
    throw error;
  }

  return (
    <MainContent>
      <PatientInfoWithWrapper />
      <CardDosages />
      <Loader showLoader={isLoading} />
      <DosageFloatingActionBar />
    </MainContent>
  );
};
