import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { DoseParam } from '../../types/Dose.params.type';
import { makeStyles } from 'tss-react/mui';
import { UNDEFINED_ID } from '../../config/constant.params';

const useStyles = makeStyles()(() => ({
  listItem: {
    whiteSpace: 'normal',
  },
}));

export const SelectDoseParam = ({
  name,
  value,
  setValue,
  list,
  viewSize = 'medium',
  withEmptyLine = false,
}: {
  name: string;
  value: number;
  setValue: (name: string, value: number) => void;
  list: DoseParam[];
  viewSize?: 'medium' | 'small';
  withEmptyLine?: boolean;
}) => {
  const { classes } = useStyles();

  const handleChange = (event: { target: { value: string } }) => {
    setValue(
      name,
      event.target.value === '' ? UNDEFINED_ID : Number(event.target.value)
    );
  };

  return (
    <FormControl
      variant="outlined"
      style={{ width: '100%', margin: 0 }}
      size={viewSize}
    >
      <Select
        variant="standard"
        name={name}
        value={value === UNDEFINED_ID ? '' : value.toString()}
        onChange={handleChange}
      >
        {withEmptyLine && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {list.map((item) => {
          return (
            <MenuItem
              key={item.Id}
              value={item.Id}
              className={classes.listItem}
            >
              {item.Name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
