export const ROUTE_HOME = '/';
export const ROUTE_AUTH = '/login';
export const ROUTE_INTEGRATION = '/integration';
export const ROUTE_REGISTER = '/register';
export const ROUTE_PAYMENT = '/subscription';
export const ROUTE_PAYMENT_SUCCESS = '/paymentSuccess';
export const ROUTE_PAYMENT_ERROR = '/paymentError';

export const ROUTE_INTEGRATION_AUTH = `${ROUTE_INTEGRATION}/:integrationSource`;
export const ROUTE_INTEGRATION_LOGIN = `${ROUTE_INTEGRATION}/sign-in/:authChallenge`;

export const ROUTE_WORK = '/work';
export const ROUTE_PATIENTS = `${ROUTE_WORK}/patients`;
export const ROUTE_VISITS = `${ROUTE_WORK}/visits`;
export const ROUTE_DIAGNOSE_HOME = `${ROUTE_WORK}/diagnosehome`;

export const ROUTE_NEW_PATIENT = `${ROUTE_WORK}/patient`;
export const ROUTE_CURRENT_PATIENT = `${ROUTE_NEW_PATIENT}/:patientId`;
export const ROUTE_PATIENT_SUMMARY = `${ROUTE_WORK}/patient-summary/:patientId`;
export const ROUTE_NEW_VISIT = `${ROUTE_CURRENT_PATIENT}/visit`;
export const ROUTE_CURRENT_VISIT = `${ROUTE_NEW_VISIT}/:visitId`;
export const ROUTE_CURRENT_VISIT_SAVED = `${ROUTE_CURRENT_VISIT}/saved`;

export const ROUTE_VISIT_DIAGNOSIS = `${ROUTE_CURRENT_VISIT}/diagnoses`;
export const ROUTE_VISIT_TREATMENTS = `${ROUTE_VISIT_DIAGNOSIS}/treatments`;
export const ROUTE_VISIT_DOSAGES = `${ROUTE_VISIT_TREATMENTS}/dosages`;
export const ROUTE_VISIT_SUMMARY = `${ROUTE_VISIT_DOSAGES}/summary`;

export const ROUTE_TOOLS = '/tools';
export const ROUTE_DISEASES_BREEDS = `${ROUTE_TOOLS}/diseases/breeds`;
export const ROUTE_DISEASES_SIGNALMENT = `${ROUTE_TOOLS}/diseases/signalment`;
export const ROUTE_DISEASES_ORGANS = `${ROUTE_TOOLS}/diseases/organs`;

export const ROUTE_TREATMENTS = `${ROUTE_TOOLS}/treatments`;
export const ROUTE_DRUGS = `${ROUTE_TOOLS}/drugs`;
export const ROUTE_CALCULATOR = `${ROUTE_TOOLS}/calculator`;

export const ROUTE_PROFILE = '/profile';
export const ROUTE_PROFILE_INFO = `${ROUTE_PROFILE}/info`;
export const ROUTE_NOTIFICATIONS = `${ROUTE_PROFILE}/notifications`;
export const ROUTE_SUBSCRIPTION = `${ROUTE_PROFILE}/subscription`;
export const ROUTE_SUPPORT = '/support';
export const ROUTE_TUTORIAL = '/tutorial';

export const ROUTE_SYMPTOMS = '/symptoms';
