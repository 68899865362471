import React from 'react';
import { makeStyles } from 'tss-react/mui';

import eyeSpinner from '../images/gekko_eye_spinning.gif';

const useStyles = makeStyles()(() => ({
  root: {
    width: '100px',
    height: '100px',
  },
}));

type Props = {
  className?: string;
};

const GekkoSpinner: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles();

  return (
    <img
      className={props.className ? props.className : classes.root}
      src={eyeSpinner}
      alt="loading..."
    />
  );
};

export default GekkoSpinner;
