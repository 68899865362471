import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { UNDEFINED_ID } from '../../config/constant.params';
import { TestPatient } from '../../types/Patient.type';

const useStyles = makeStyles()(() => ({
  listItem: {
    whiteSpace: 'normal',
  },
}));

export const SelectTestPatient = ({
  name,
  value,
  setValue,
  list,
  viewSize = 'medium',
}: {
  name: string;
  value: number;
  setValue: (value: number) => void;
  list: TestPatient[];
  viewSize?: 'medium' | 'small';
}) => {
  const { classes } = useStyles();

  const handleChange = (event: { target: { value: string } }) => {
    setValue(
      event.target.value === '' ? UNDEFINED_ID : Number(event.target.value)
    );
  };

  return (
    <FormControl
      variant="outlined"
      style={{ width: '100%', margin: 0 }}
      size={viewSize}
    >
      <InputLabel id="select-test-patient-label">
        Choose test patient
      </InputLabel>
      <Select
        variant="outlined"
        labelId="select-test-patient-label"
        name={name}
        value={value === UNDEFINED_ID ? '' : value.toString()}
        label="Select test patient"
        onChange={handleChange}
        defaultValue=""
      >
        {list.map((item) => {
          return (
            <MenuItem
              key={item.Id}
              value={item.Id}
              className={classes.listItem}
            >
              {item.NameOrId}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
