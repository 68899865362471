import React from 'react';
import {
  useGeographicalAreas,
  useGeographicalAreasDispatch,
} from '../../contexts/GeographicalAreasContext';
import { useRequestData } from '../../hooks/useRequestData';
import { DiagnosesFilterOptions } from '../../pages/DiagnosesPage';
import { GeographicalArea } from '../../types/GeographicalArea.type';
import Loader from '../Loader';
import { CardAddedSymptoms } from './CardAddedSymptoms';

export const CardAddedSymptomsContainer = ({
  setDiagnosesFilterOptions,
}: {
  setDiagnosesFilterOptions: (value: DiagnosesFilterOptions) => void;
}) => {
  const { error, geographicalAreas, isLoading } = useGeographicalAreas();
  const dispatch = useGeographicalAreasDispatch();

  useRequestData<GeographicalArea[]>({
    needTransport: !error && !isLoading && geographicalAreas.length === 0,
    dispatch: dispatch,
    method: 'get',
    params: { modelName: 'GeographicalAreas' },
  });

  if (error) {
    dispatch({ type: 'reset' });
    throw error;
  }

  if (geographicalAreas.length === 0) {
    return <Loader showLoader={true} />;
  }

  return (
    <CardAddedSymptoms setDiagnosesFilterOptions={setDiagnosesFilterOptions} />
  );
};
