import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import React from 'react';
import { IdAndName } from '../../types/DrugDosages.type';

export const ProfileExpertise = ({
  items,
  selectedIds,
  setIdChange,
}: {
  items: IdAndName[];
  selectedIds: number[];
  setIdChange: (checked: boolean, Id: number) => void;
}) => {
  const handleSpecialitiesChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    Id: number
  ) => {
    event.stopPropagation();
    setIdChange(checked, Id);
  };

  return (
    <Grid item xs={12} container direction="row">
      {items.map((item) => {
        return (
          <Grid
            item
            key={item.Name}
            xs={12}
            sm={6}
            style={{ textAlign: 'left' }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedIds.indexOf(item.Id) > -1}
                  onChange={(event, checked) =>
                    handleSpecialitiesChange(event, checked, item.Id)
                  }
                  name={item.Name}
                  color="primary"
                />
              }
              label={item.Name}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
