import React from 'react';
import { DrugUnit } from '../../../types/DrugDosages.type';
import { ResultText } from '../SharedComponents/ResultText';

const getResultText = (unit: DrugUnit) => {
  return unit === DrugUnit['ml']
    ? 'mg/ml'
    : unit === DrugUnit['tablet(s)']
    ? 'mg/tablet'
    : 'mg/capsule';
};

export const ResultTextStrength = ({ unit }: { unit: DrugUnit }) => {
  return <ResultText>{getResultText(unit)}</ResultText>;
};
