import { ANIMAL_TYPE_ID_UNDEFINED } from '../../types/AnimalType.type';
import { GenderValueString } from '../../types/Gender.type';
import { Patient } from '../../types/Patient.type';
import { RequestAction } from '../../types/request.action.type';

export interface SearchPatientOptions {
  AnimalTypeId: number;
  NameOrId: string;
  Identifier: string;
  Gender: GenderValueString;
}

export const InitialPatientSearchOptions: SearchPatientOptions = {
  AnimalTypeId: ANIMAL_TYPE_ID_UNDEFINED,
  NameOrId: '',
  Identifier: '',
  Gender: 'any',
};

export type PatientListState = {
  patients?: Patient[];
  patientsObj?: { [x: string]: Patient };
  isLoading: boolean;
  error?: string;
  searchOptions: SearchPatientOptions;
};

export type PatientListAction =
  | RequestAction<Patient[]>
  | {
      type: 'add';
      patient: Patient;
    }
  | {
      type: 'update';
      patient: Patient;
    }
  | {
      type: 'delete';
      patientId: number;
    }
  | {
      type: 'setSearchOptions';
      searchOptions: SearchPatientOptions;
    }
  | {
      type: 'resetSearchOptions';
    };
