import React from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTE_NEW_PATIENT, ROUTE_PATIENTS } from '../../config/routes.config';
import { Button } from '../Button';
import { Card } from '../common/Card';
import { ListTodayVisitsContainer } from './ListTodayVisitsContainer';
import { useWorkContext } from '../../contexts/WorkContext';

const TITLE = ["TODAY'S PATIENTS", "TODAY'S VISITS"];

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'stretch',
  },
  container: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 1 auto',
    display: 'flex',
  },
  list: {
    flex: '1 1 auto',
    overflowY: 'scroll',
  },
}));

export const CardTodayVisits = ({
  homePage = false,
  setVisitRowClicked,
}: {
  homePage?: boolean;
  setVisitRowClicked: (clicked: boolean) => void;
}) => {
  const { classes } = useStyles();
  const { setVisit } = useWorkContext();

  return (
    <Card
      title={homePage ? TITLE[0] : TITLE[1]}
      pageView="drawerView"
      withoutMarginBottom
    >
      <Container className={classes.wrapper}>
        <Grid container className={classes.container} spacing={1}>
          <Grid container item className={classes.content}>
            <Grid item className={classes.list}>
              <ListTodayVisitsContainer
                homePage={homePage}
                setVisitRowClicked={setVisitRowClicked}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-start" spacing={3}>
            {homePage && (
              <Grid item>
                <Button
                  title="New patient"
                  color="primary"
                  variant="contained"
                  component={RouterLink}
                  to={ROUTE_NEW_PATIENT}
                >
                  New patient
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                title="New visit"
                color="primary"
                variant="contained"
                component={RouterLink}
                to={ROUTE_PATIENTS}
                onClick={() => {
                  setVisit(null);
                }}
              >
                New visit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
};
