import { Typography } from '@mui/material';

export const ResultText = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
      }}
    >
      <Typography>{children}</Typography>
    </div>
  );
};
