import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';
import {
  useTreatmentsPage,
  useTreatmentsPageDispatch,
} from '../../contexts/TreatmentsPageContext';
import { useRequestData } from '../../hooks/useRequestData';
import { TreatmentInfo } from '../../types/Diagnose.type';
import { Card } from '../common/Card';
import Loader from '../Loader';
import { SubsequentDiseasesList } from './SubsequentDiseasesList';

export const CardSubsequentDiseases = () => {
  const { AnimalTypeId } = usePatientInfo();
  const {
    isLoading,
    error,
    treatmentInfos,
    subsequentTreatmentInfos,
    subsequentDiseaseIds,
  } = useTreatmentsPage();
  const dispatchTreatmentsPage = useTreatmentsPageDispatch();
  const mobile = useMediaQuery('(max-width:600px)');

  const needTreatments =
    !error &&
    !isLoading &&
    Boolean(treatmentInfos) &&
    subsequentDiseaseIds.length > 0 &&
    !subsequentTreatmentInfos;

  useRequestData<TreatmentInfo[]>({
    needTransport: needTreatments,
    dispatch: dispatchTreatmentsPage,
    method: 'get',
    url: '/treatments',
    params: {
      diseaseIds: subsequentDiseaseIds.join(','),
      animalType: AnimalTypeId,
    },
    dispatchOptions: 'subsequentDiagnoses',
  });

  if (error) {
    dispatchTreatmentsPage({ type: 'reset' });
    throw error;
  }

  if (isLoading) {
    return <Loader showLoader={true} />;
  }

  return (
    <Card
      title="POSSIBLE SUBSEQUENT CONDITIONS"
      pageView="drawerView"
      withoutMarginBottom={!mobile}
      maxWidth
    >
      <Grid container>
        <Grid item xs={12}>
          <SubsequentDiseasesList canSelect={false} />
        </Grid>
      </Grid>
    </Card>
  );
};
