import { SubscriptionState, SubscriptionAction } from './subscription.state';

export const subscriptionReducer = (
  state: SubscriptionState,
  action: SubscriptionAction
): SubscriptionState => {
  switch (action.type) {
    case 'reset': {
      return {
        ...state,
        isLoading: false,
        error: undefined,
        subscription: undefined,
      };
    }

    case 'request': {
      return { ...state, isLoading: true, subscription: undefined };
    }

    case 'success': {
      return {
        ...state,
        isLoading: false,
        subscription: action.responseData,
      };
    }

    case 'failure': {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        errorMessage: action.error.message,
      };
    }
    default:
      return state;
  }
};
