import { Avatar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AccountCircle } from '@mui/icons-material';
import DiagnoseTable from './DiagnoseTable';
import React from 'react';

const useStyles = makeStyles()({
  chatMessage: {
    display: 'flex',
    flexDirection: 'row',
  },
  chatMessageRight: {
    flexDirection: 'row-reverse',
  },
  bubble: {
    marginTop: '1em',
    borderRadius: '0.5em',
    padding: '0.5em',
    maxWidth: '75%',
    textAlign: 'left',
  },
  leftBubble: {
    borderTopLeftRadius: '0',
  },
  rightBubble: {
    borderTopRightRadius: '0',
  },
  triangleLeft: {
    marginTop: '1em',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '20px 0 0 20px',
    borderColor: 'transparent transparent transparent #fff',
  },
  triangleRight: {
    marginTop: '1em',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 0 20px 20px',
    borderColor: 'transparent transparent #fff transparent',
  },
  processing: {
    opacity: '0.5',
  },
});

const ChatMessage = ({
  direction = 'left',
  color,
  from,
  avatar,
  message,
  processing,
}: {
  direction: 'left' | 'right';
  color: string;
  from: string;
  avatar?: string;
  message: string;
  processing?: boolean;
}) => {
  const { classes } = useStyles();
  return (
    <div
      className={`${classes.chatMessage} ${
        direction === 'right' ? classes.chatMessageRight : ''
      }`}
    >
      <Avatar src={avatar}>{avatar ? null : <AccountCircle />}</Avatar>
      <div
        style={{ backgroundColor: color }}
        className={`${
          direction === 'right' ? classes.triangleRight : classes.triangleLeft
        } ${processing ? classes.processing : ''}`}
      >
        &nbsp;
      </div>
      <div
        style={{ backgroundColor: color }}
        className={`${classes.bubble} ${
          direction === 'left' ? classes.leftBubble : classes.rightBubble
        } ${processing ? classes.processing : ''}`}
      >
        <MessageContent message={message} />
      </div>
    </div>
  );
};

const MessageContent = ({ message }: { message: string }) => {
  try {
    const msg = JSON.parse(message);
    if (Array.isArray(msg) && msg[0].diagnose_id) {
      return <DiagnoseTable diagnoses={msg} />;
    }
    return null;
  } catch (e) {
    return <p>{message}</p>;
  }
};

export default ChatMessage;
