import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DrugsIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g transform="translate(-1.000000, 0.000000)">
        <path
          d="M17,2 C19.7614237,2 22,4.23857625 22,7 L22,16 C22,18.7614237 19.7614237,21 17,21 C14.2385763,21 12,18.7614237 12,16 L12,7 C12,4.23857625 14.2385763,2 17,2 Z M17,4 C15.4023191,4 14.0963391,5.24891996 14.0050927,6.82372721 L14,7 L14,16 C14,17.6568542 15.3431458,19 17,19 C18.5976809,19 19.9036609,17.75108 19.9949073,16.1762728 L20,16 L20,7 C20,5.34314575 18.6568542,4 17,4 Z"
          id="Rectangle"
          fill="#444444"
          fillRule="nonzero"
          transform="translate(17.000000, 11.500000) rotate(-25.000000) translate(-17.000000, -11.500000) "
        ></path>
        <path
          d="M8,9 C11.3137085,9 14,11.6862915 14,15 C14,18.3137085 11.3137085,21 8,21 C4.6862915,21 2,18.3137085 2,15 C2,11.6862915 4.6862915,9 8,9 Z M8,11 C5.790861,11 4,12.790861 4,15 C4,17.209139 5.790861,19 8,19 C10.209139,19 12,17.209139 12,15 C12,12.790861 10.209139,11 8,11 Z"
          id="Rectangle-Copy"
          fill="#444444"
          fillRule="nonzero"
          transform="translate(8.000000, 15.000000) rotate(25.000000) translate(-8.000000, -15.000000) "
        ></path>
        <polygon
          id="Path-2"
          fill="#444444"
          fillRule="nonzero"
          transform="translate(8.072716, 15.033908) rotate(25.000000) translate(-8.072716, -15.033908) "
          points="13.0620406 14.0339081 13.0620406 16.0339081 3.08339184 16.0339081 3.08339184 14.0339081"
        ></polygon>
        <polygon
          id="Path-2-Copy"
          fill="#444444"
          fillRule="nonzero"
          transform="translate(17.072716, 12.033908) rotate(-25.000000) translate(-17.072716, -12.033908) "
          points="22.0620406 11.0339081 22.0620406 13.0339081 12.0833918 13.0339081 12.0833918 11.0339081"
        ></polygon>
      </g>
    </SvgIcon>
  );
};

export default DrugsIcon;
