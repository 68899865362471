import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { FieldProps, ExtraFieldProps } from '../../types/form.type';
import { Country } from '../../types/country.type';
import { useMediaQuery } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

export function CountrySelect<T extends string>({
  name,
  label,
  required,
  setValue,
  list,
  currentValue,
  error,
}: FieldProps<T> & ExtraFieldProps<T> & { name: T; list: Country[] }) {
  let found = null;
  if (currentValue) {
    found = list.find((x) => x.Id === parseInt(currentValue));
  }

  const [showError, setShowError] = useState(false);
  const { classes } = useStyles();
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <Autocomplete
      id="Country"
      value={found}
      onChange={(_event: any, newValue: Country | null) => {
        setShowError(false);
        setValue({
          name,
          value: !newValue ? '' : `${newValue.Id}`,
        });
      }}
      size="small"
      options={list}
      classes={{
        option: classes.option,
      }}
      className={classes.root}
      autoHighlight
      getOptionLabel={(option) => option.Name}
      renderOption={(props, option) => <li {...props}>{option.Name}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          error={showError || error}
          required={required}
          label={label}
          style={
            matches ? { width: '100%' } : { marginLeft: '0px', width: '100%' }
          }
          onBlur={() =>
            currentValue ? setShowError(false) : setShowError(true)
          }
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
