import React from 'react';
import { withStyles } from 'tss-react/mui';
import { makeStyles } from 'tss-react/mui';
import Menu, { MenuProps } from '@mui/material/Menu';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';

import { menuItems } from './MenuItemsConfig';
import MenuItemLink from './MenuItemLink';

import eyeLogo from '../../assets/images/gekko_eye_logo.png';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useAuthUser } from '../../contexts/AuthUserContext';

const StyledMenu = withStyles(
  (props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ),
  {
    paper: {
      border: '1px solid #d3d4d5',
    },
  }
);

const useStyles = makeStyles()((theme) => ({
  eyeMenuButton: {
    width: '34px',
  },
  listItemIcon: {
    color: theme.palette.common.white,
  },
  title: {
    marginLeft: '10px',
    marginRight: '10px',
    color: 'black',
    fontFamily: 'Arial',
    fontSize: '16px',
  },
  userBox: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  text: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: '0.1px',
    color: 'rgba(0, 0, 0, 0.6)',
    paddingTop: '2px',
  },
  listItem: {
    paddingTop: '1%',
    paddingBottom: '1%',
  },
}));

const StyledMenuItem = withStyles(MenuItem, (theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.common.white,
      '& .MuiListItemText-primary': {
        color: theme.palette.common.black,
      },
    },
  },
}));

export const UserMenu = (props: { username: string | undefined }) => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { signOut } = useAuthUser();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        edge="start"
        className={classes.userBox}
        color="inherit"
        aria-label="menu"
        onClick={handleClick}
        size="large"
      >
        <Hidden smDown>
          <Typography className={classes.title}>{props.username}</Typography>
        </Hidden>
        <img src={eyeLogo} className={`${classes.eyeMenuButton}`} alt="logo" />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <List component="div" disablePadding>
          {menuItems.map((item) => {
            return (
              <StyledMenuItem key={item.title}>
                <MenuItemLink handleClose={handleClose} {...item} />
              </StyledMenuItem>
            );
          })}
          <StyledMenuItem>
            <ListItem
              button
              onClick={signOut}
              alignItems="flex-start"
              className={classes.listItem}
            >
              <ListItemText primary="Log out" className={classes.text} />
            </ListItem>
          </StyledMenuItem>
        </List>
      </StyledMenu>
    </div>
  );
};

export default UserMenu;
