import React from 'react';
import { CardSummary } from '../components/summary/CardSummary';
import { MainContent } from '../layouts/MainContent';

export const SummaryPage = () => {
  return (
    <MainContent>
      <CardSummary />
    </MainContent>
  );
};
