import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { PropsWithChildren } from 'react';
import { FlexGrowSpacer } from './FlexGrowSpacer';

const useStyles = makeStyles()((theme: Theme) => {
  const themeSpacing1 = theme.spacing(1);
  const padding = `${Number(themeSpacing1) / 2}px ${themeSpacing1}px`;
  return {
    root: {
      alignItems: 'center',
      backgroundColor: '#ffffff',
      borderRadius: '6px',
      bottom: themeSpacing1,
      boxShadow:
        '0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14)',
      display: 'flex',
      height: 'auto',
      justifyContent: 'center',
      margin: 'auto',
      padding,
      position: 'sticky',
      width: 'calc(100% - 48px)',
      [theme.breakpoints.only('xs')]: {
        width: '100%',
        borderRadius: '0px',
        bottom: '0',
        left: '0',
        height: '56px',
        marginBottom: '0px',
        position: 'fixed',
      },
    },
  };
});

export const FloatingActionBar = ({ children }: PropsWithChildren<{}>) => {
  const { classes } = useStyles();
  return (
    <>
      <FlexGrowSpacer />
      <div className={classes.root}> {children} </div>
    </>
  );
};
