import React from 'react';
import Grid from '@mui/material/Grid';
import { Card } from '../common/Card';
import { PatientInfo } from '../common/PatientInfo';
import { SymptomByName } from './SymptomByName';
import { SymptomByType } from './SymptomByType';
import { Theme, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  dividerText: {
    marginTop: theme.spacing(3),
  },
}));

export const CardSymptoms = ({ id }: { id?: string }) => {
  const { classes } = useStyles();

  return (
    <Card
      id={id}
      title="ADD A SYMPTOM"
      pageView="drawerView"
      maxWidth
      withoutMarginBottom
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PatientInfo />
        </Grid>
        <Grid item xs={12} sm={5}>
          <SymptomByName />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="h4" className={classes.dividerText}>
            OR
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <SymptomByType />
        </Grid>
      </Grid>
    </Card>
  );
};
