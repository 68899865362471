import { useMemo } from 'react';

/**
 * usePilotEnviroment is used check if app is running in pilot enviroment.
 */
export const useIsPilotEnviroment = () => {
  return useMemo(() => {
    const enviroment = window.location.host;

    return enviroment === 'pilot.gekkovet.com' ? true : false;
  }, []);
};
