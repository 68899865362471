import {
  IntegrationData,
  ProvetIntegrationInfo,
  ProvetLoginResponse,
} from '../../types/Integration.type';
import { RequestAction } from '../../types/request.action.type';

export type IntegrationState = {
  integrationConnectionInfo?: ProvetIntegrationInfo;
  integrationInfo?: IntegrationData;
  integrationSource?: string;
  loginLink?: string;
  isLoading: boolean;
  error?: string;
};

export type IntegrationAction =
  | RequestAction<ProvetLoginResponse>
  | { type: 'setIntegrationInfo'; integrationInfo: IntegrationData }
  | { type: 'setIntegrationSource'; integrationSource: string };

export const IntegrationInitialState: IntegrationState = {
  isLoading: false,
};
