import React from 'react';
import { withStyles } from 'tss-react/mui';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const HtmlTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(6),
    padding: '2em',
    border: '1px solid #dadde9',
  },
}));

export const ProbabilityTooltip = () => {
  return (
    <>
      <HtmlTooltip
        placement="left-start"
        title={
          <>
            <Typography color="inherit">
              Probability increases as symptoms and breed match the diagnosis.
              Symptoms are scored based on frequency: 10 points for symptoms
              always occurring, 5 points for usually occurring, and 3 points for
              possibly occurring.
            </Typography>
          </>
        }
      >
        <HelpIcon
          color="primary"
          fontSize="small"
          style={{ cursor: 'pointer' }}
        />
      </HtmlTooltip>
    </>
  );
};
