import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useAnimalTypesContext } from '../../contexts/AnimalTypesContext';
import { ANIMAL_TYPE_ID_UNDEFINED } from '../../types/AnimalType.type';

export const SpeciesSelect = ({
  error,
  species,
  handleChange,
  viewSize = 'medium',
}: {
  error: boolean;
  species: number;
  handleChange: any;
  viewSize?: 'medium' | 'small';
}) => {
  const { animalTypes } = useAnimalTypesContext();

  return (
    <FormControl
      error={error}
      variant="outlined"
      style={{ width: '100%', margin: 0 }}
      size={viewSize}
    >
      <InputLabel>Species*</InputLabel>
      <Select
        variant="outlined"
        name="AnimalTypeId"
        value={species}
        onChange={handleChange}
        label="Species*"
        displayEmpty
      >
        <MenuItem value={ANIMAL_TYPE_ID_UNDEFINED}>
          <em>Select species</em>
        </MenuItem>
        {animalTypes.map((speciesData) => {
          return (
            <MenuItem key={speciesData.Id} value={speciesData.Id}>
              {speciesData.Name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
