import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid';
import { CalculatorRowTitle } from './CalculatorRowTitle';

export const CalculatorRowTitleGridItem = ({
  children,
}: PropsWithChildren<{}>) => {
  return (
    <Grid item xs={12} style={{ paddingBottom: '0px', paddingTop: '26px' }}>
      <CalculatorRowTitle>{children}</CalculatorRowTitle>
    </Grid>
  );
};
