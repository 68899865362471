import React from 'react';
import { Container, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Card } from '../common/Card';
import { Button } from '../Button';
import { MenuBook } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const useStyles = makeStyles()((theme: Theme) => ({
  continueButton: {
    marginTop: theme.spacing(2),
  },
  infoTextAlignment: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  containerText: {
    height: 150,
  },
}));

export default function CardDiseaseLibrary() {
  const { classes } = useStyles();
  return (
    <Card
      title="Diseases & Treatments"
      pageView="drawerView"
      withoutMarginBottom
      headerCentered
      hoverEffect
    >
      <Grid container>
        <Grid item xs={12}>
          <MenuBook sx={{ fontSize: 72 }} />
        </Grid>
        <Grid item xs={12} className={classes.infoTextAlignment}>
          <Container fixed className={classes.containerText}>
            <Typography align="center">
              Search for diseases and their treatments
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={12}>
          <Button
            to="/tools/treatments"
            component={Link}
            title="Continue"
            color="primary"
            variant="contained"
            className={classes.continueButton}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}
