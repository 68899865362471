import { Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Treatment, TreatmentSet } from '../../types/Diagnose.type';
import React from 'react';
import { splitProtocolNameInTwoLines } from '../../utils/treatmentsUtils';
import { getTreatmentName } from '../../utils/summaryUtils';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles()({
  root: {
    border: '1px solid transparent',
    marginBottom: '1em',
    '&:hover': {
      border: 'solid 1px #00cd87',
    },
  },
  title: {
    color: '#00aa79',
    fontWeight: 'bold',
    fontSize: '14px',
    fontFamily: 'Arial',
    letterSpacing: '1.15px',
    textTransform: 'uppercase',
  },
  subTitle: { fontSize: '12px', fontWeight: 'bold' },
  cardContent: {
    paddingBottom: '16px!important',
    width: '100%',
  },
  treatmentName: {
    fontFamily: 'Arial',
    fontSize: '10px',
    fontWeight: 'bold',
    textAlign: 'left',
    display: 'inline',
    position: 'relative',
    bottom: '4px',
  },
  checkIcon: {
    display: 'inline',
    height: '18px',
    width: '18px',
    marginRight: '5px',
  },
});

export const TreatmentProtocolCard = ({
  treatmentSet,
  onClick,
  selected,
  selectedTreatments,
}: {
  treatmentSet: TreatmentSet;
  onClick: (treatmentId: number) => void;
  selected: boolean;
  selectedTreatments: Treatment[];
}) => {
  const { classes } = useStyles();
  const nameContent = splitProtocolNameInTwoLines(treatmentSet.Name);

  return (
    <Card
      className={classes.root}
      style={
        selected
          ? {
              backgroundColor: 'rgba(0, 205, 135, 0.08)',
              border: 'solid 1px #00cd87',
            }
          : {}
      }
      onClick={() => {
        onClick(treatmentSet.Id);
      }}
    >
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title} align="left">
          {nameContent.title}
        </Typography>
        <Typography className={classes.subTitle} align="left">
          {nameContent.subTitle}
        </Typography>
        {selectedTreatments.map((selectedTreatment) => (
          <div
            key={selectedTreatment.Id}
            style={{ textAlign: 'left', marginTop: '8px' }}
          >
            <CheckIcon className={classes.checkIcon} color="primary" />
            <Typography className={classes.treatmentName}>
              {getTreatmentName(selectedTreatment)}
            </Typography>
          </div>
        ))}
      </CardContent>
    </Card>
  );
};
