import React, { PropsWithChildren, useContext } from 'react';
import { useWork } from '../hooks/useWork';
import { PatientVisit, IntegrationVisit } from '../types/PatientVisit.type';
import { Patient, IntegrationPatient } from '../types/Patient.type';
import { Breed } from '../types/Breed.type';
import { AnimalType } from '../types/AnimalType.type';
import { Diagnose } from '../types/Diagnose.type';
import {
  IntegrationData,
  ProvetIntegrationInfo,
} from '../types/Integration.type';

const WorkContext = React.createContext<{
  patient: Patient | null;
  integrationPatient: IntegrationPatient | null;
  getPatient: (id: number) => void;
  getIntegrationPatient: (
    integrationSource: string,
    integratinConnectionInfo: ProvetIntegrationInfo,
    integrationInfo: IntegrationData
  ) => void;
  setPatient: (patient: Patient) => void;
  visit: PatientVisit | null;
  integrationVisit: IntegrationVisit | null;
  getVisit: (id: number) => void;
  getIntegrationVisit: (
    integrationSource: string,
    integratinConnectionInfo: ProvetIntegrationInfo,
    integrationInfo: IntegrationData
  ) => void;
  setVisit: (visit: PatientVisit | null) => void;
  breed: Breed | null;
  getBreed: (id: number) => void;
  animalType: AnimalType | null;
  getAnimalType: (id: number) => void;
  setDiagnoses: (diagnoses: Diagnose[]) => void;
}>({
  patient: null,
  integrationPatient: null,
  getPatient: (id: number) => {},
  getIntegrationPatient: (
    integrationSource: string,
    integratinConnectionInfo: ProvetIntegrationInfo,
    integrationInfo: IntegrationData
  ) => {},
  setPatient: (patient: Patient) => {},
  visit: null,
  integrationVisit: null,
  getVisit: (id: number) => {},
  getIntegrationVisit: (
    integrationSource: string,
    integratinConnectionInfo: ProvetIntegrationInfo,
    integrationInfo: IntegrationData
  ) => {},
  setVisit: (visit: PatientVisit | null) => {},
  breed: null,
  getBreed: (id: number) => {},
  animalType: null,
  getAnimalType: (id: number) => {},
  setDiagnoses: (diagnoses: Diagnose[]) => {},
});

export const WorkProvider = ({ children }: PropsWithChildren<{}>) => {
  const value = useWork();

  return <WorkContext.Provider value={value}>{children}</WorkContext.Provider>;
};

export const useWorkContext = () => {
  return useContext(WorkContext);
};
