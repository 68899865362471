import Typography from '@mui/material/Typography';
import React from 'react';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';

export const PatientInfo = () => {
  const patientInfo = usePatientInfo();
  if (patientInfo.name === '') {
    return null;
  }
  return (
    <Typography variant="subtitle2" gutterBottom align="left">
      {patientInfo.isTestPatient ? 'Test patient' : patientInfo.name}
      {', '}
      {patientInfo.animalTypeName}
      {', '}
      {patientInfo.genderName}
      {', '}
      {patientInfo.neutered ? 'neutered' : 'intact'}
      {', '}
      {patientInfo.weightStr}
      {', '}
      {patientInfo.patientAge}
      {patientInfo.breedName && ', '}
      {patientInfo.breedName}
    </Typography>
  );
};
