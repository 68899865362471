import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CurrentPatientInfoState } from '../../../reducers/currentPatientInfo/current.patient.info.state';
import { convertKgToLbs } from '../../../utils/insertPatientUtils';
import { EditPatientButton } from './EditPatientButton';
import { useLocale } from '../../../hooks/useLocale';
import { formatDbDateStringToUi } from '../../../utils/dateUtils';
import { DeletePatientButton } from './DeletePatientButton';

const useStyles = makeStyles()({
  root: {},
  subTitle: {
    fontSize: '10px',
    fontWeight: 'bold',
    marginBottom: '4px',
  },
  bodyText: { fontSize: '14px' },
});

function capitalizeFirstLetter(s: string) {
  return s[0].toUpperCase() + s.slice(1);
}

export const PatientSummaryCard = ({
  patientInfo,
}: {
  patientInfo: CurrentPatientInfoState;
}) => {
  const locale = useLocale();
  const { classes } = useStyles();

  return (
    <div>
      <Card className={classes.root}>
        <CardContent>
          <Typography
            style={{
              fontSize: '10px',
              fontWeight: 'bold',
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            {patientInfo.animalTypeName.toUpperCase()}
            {patientInfo.breedName
              ? `, ${patientInfo.breedName.toUpperCase()}`
              : ''}
          </Typography>
          <Typography style={{ fontSize: '20px' }}>
            {patientInfo.name}
          </Typography>
          <Typography
            style={{
              color: 'rgba(0, 0, 0, 0.6)',
              fontSize: '14px',
              marginBottom: '20px',
            }}
          >
            {capitalizeFirstLetter(patientInfo.genderName)}
          </Typography>
          <Grid container style={{ marginBottom: '20px' }}>
            <Grid item xs={6}>
              <Typography className={classes.subTitle}>WEIGHT</Typography>
              <Typography className={classes.bodyText}>
                {patientInfo.weight} kg / {convertKgToLbs(patientInfo.weight)}{' '}
                lbs
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.subTitle}>
                DATE OF BIRTH
              </Typography>
              <Typography className={classes.bodyText}>
                {patientInfo.dateOfBirth
                  ? formatDbDateStringToUi(
                      patientInfo.dateOfBirth,
                      locale
                    ).replaceAll('.', '/')
                  : ''}
              </Typography>
            </Grid>
          </Grid>
          {patientInfo.identifier && (
            <React.Fragment>
              <Typography className={classes.subTitle}>IDENTIFIER</Typography>
              <Typography className={classes.bodyText}>
                {patientInfo.identifier}
              </Typography>
            </React.Fragment>
          )}
        </CardContent>
        <CardActions
          style={{
            justifyContent: 'space-between',
            borderTop: '1px solid lightgrey',
          }}
        >
          <EditPatientButton patientId={patientInfo.Id} />
          <DeletePatientButton
            patientName={patientInfo.name}
            patientId={patientInfo.Id}
          />
        </CardActions>
      </Card>
    </div>
  );
};
