import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FeedbackIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g transform="translate(2.000000, 2.000000)">
        <path
          d="M17,2.84217094e-14 L3,2.84217094e-14 C1.34314575,2.84217094e-14 0,1.34314575 0,3 L0,18 L0.00571638955,18.1101203 C0.0849423085,18.8648139 0.97072903,19.2719532 1.6,18.8 L5.333,16 L17,16 C18.6568542,16 20,14.6568542 20,13 L20,3 C20,1.34314575 18.6568542,2.84217094e-14 17,2.84217094e-14 Z M3,2 L17,2 C17.5522847,2 18,2.44771525 18,3 L18,13 C18,13.5522847 17.5522847,14 17,14 L5,14 L4.87113107,14.0083371 C4.70091384,14.0304569 4.53847694,14.0961423 4.4,14.2 L2,16 L2,3 C2,2.44771525 2.44771525,2 3,2 Z"
          id="Rectangle"
          fill="#444444"
          fillRule="nonzero"
        ></path>
        <path
          d="M6,7 C6.55228475,7 7,7.44771525 7,8 C7,8.55228475 6.55228475,9 6,9 C5.44771525,9 5,8.55228475 5,8 C5,7.44771525 5.44771525,7 6,7 Z"
          id="Oval"
          fill="#444444"
          fillRule="nonzero"
        ></path>
        <path
          d="M10,7 C10.5522847,7 11,7.44771525 11,8 C11,8.55228475 10.5522847,9 10,9 C9.44771525,9 9,8.55228475 9,8 C9,7.44771525 9.44771525,7 10,7 Z"
          id="Oval-Copy"
          fill="#444444"
          fillRule="nonzero"
        ></path>
        <path
          d="M14,7 C14.5522847,7 15,7.44771525 15,8 C15,8.55228475 14.5522847,9 14,9 C13.4477153,9 13,8.55228475 13,8 C13,7.44771525 13.4477153,7 14,7 Z"
          id="Oval-Copy-2"
          fill="#444444"
          fillRule="nonzero"
        ></path>
      </g>
    </SvgIcon>
  );
};

export default FeedbackIcon;
