import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { doseParamsReducer } from '../reducers/doseParams/dose.params.reducer';
import {
  DoseParamsState,
  DoseParamsAction,
  DefaultDoseParams,
} from '../reducers/doseParams/dose.params.state';

const DoseParamsContext = createContext<DoseParamsState>({} as DoseParamsState);
const DoseParamsDispatch = createContext<Dispatch<DoseParamsAction>>(() => {});

export const DoseParamsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(doseParamsReducer, DefaultDoseParams);

  return (
    <DoseParamsContext.Provider value={state}>
      <DoseParamsDispatch.Provider value={dispatch}>
        {children}
      </DoseParamsDispatch.Provider>
    </DoseParamsContext.Provider>
  );
};

export const useDoseParams = (): DoseParamsState => {
  return useContext(DoseParamsContext);
};

export const useDoseParamsDispatch = () => {
  return useContext(DoseParamsDispatch);
};
