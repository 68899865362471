import { useEffect } from 'react';
import API from '../api/Api';
import { CardDrugDosageCalculator } from '../components/CardDrugDosageCalculator';

export const CalculatorPage = () => {
  useEffect(() => {
    API.trackEvent('drug_dosage_calculator');
  }, []);
  return <CardDrugDosageCalculator />;
};
