import { Country } from '../../types/country.type';
import { PersonType } from '../../types/PersonType.type';
import {
  VetProfileWithExpertise,
  Specialities,
  VetProfile,
} from '../../types/Profile.types';
import { RequestAction } from '../../types/request.action.type';

export type ProfileParamsArr = [
  VetProfileWithExpertise,
  Specialities[],
  Country[],
  PersonType[]
];

export type ProfileState = {
  isLoadingParams: boolean;
  errorParams?: string;
  vetProfile?: VetProfileWithExpertise;
  specialities: Specialities[];
  selectedSpecialitiesIds: number[];
  selectedAnimalTypeIds: number[];
  countries: Country[];
  personTypes: PersonType[];
  params: ProfileParamsArr | [];
};

export const DefaultProfileState: ProfileState = {
  isLoadingParams: false,
  countries: [],
  personTypes: [],
  specialities: [],
  selectedSpecialitiesIds: [],
  selectedAnimalTypeIds: [],
  params: [],
};

export type FetchParamsAction =
  | RequestAction<ProfileParamsArr>
  | {
      type: 'setSelectedSpeciality';
      checked: boolean;
      Id: number;
    }
  | {
      type: 'setSelectedAnimalType';
      checked: boolean;
      Id: number;
    };

export type UpdateProfileState = {
  isUpdatingProfile: boolean;
  errorProfile?: string;
  showNote: boolean;
};

export const DefaultUpdateProfileState: UpdateProfileState = {
  isUpdatingProfile: false,
  showNote: false,
};

export type UpdateProfileAction =
  | RequestAction<VetProfile[]>
  | { type: 'resetShowNote' };
