import React, { useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';
import { PasswordField } from '../registration/PasswordField';
import { Auth } from '@aws-amplify/auth';
import { Alert } from '@mui/material';

export default function ChangePasswordDialog({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const [values, setValues] = useState<{
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
  }>({
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const enableChange = useMemo(
    () =>
      !!values.oldPassword &&
      !!values.newPassword &&
      !!values.repeatPassword &&
      values.newPassword === values.repeatPassword,
    [values]
  );
  const changePassword = async () => {
    setErrorMessage('');
    const user = await Auth.currentAuthenticatedUser();
    try {
      await Auth.changePassword(user, values.oldPassword, values.newPassword);
      handleClose();
    } catch (e) {
      setErrorMessage((e as any).message);
    }
  };
  const handleChange = ({ name, value }: { name: string; value: string }) => {
    setValues({
      ...values,
      [name]: value,
    });
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="change-password-dialog-title"
        aria-describedby="change-password-dialog-description"
      >
        <DialogTitle id="change-password-dialog-title">
          Change password
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="change-password-dialog-description">
            Use your current password to change to a new password.
          </DialogContentText>
          <Grid container>
            {errorMessage ? (
              <Grid item xs={12}>
                <Alert severity="error">{errorMessage}</Alert>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <PasswordField
                label={'Old password'}
                type="password"
                name="oldPassword"
                value={values.oldPassword || ''}
                setValue={handleChange}
                validator={() => ''}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordField
                label={'New password'}
                type="password"
                name="newPassword"
                value={values.newPassword || ''}
                setValue={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordField
                label={'Repeat new password'}
                type="password"
                name="repeatPassword"
                value={values.repeatPassword || ''}
                setValue={handleChange}
                validator={(value: string) =>
                  value !== values.newPassword ? 'Does not match' : ''
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button
            disabled={!enableChange}
            color="primary"
            onClick={changePassword}
            variant="contained"
          >
            Change password
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
