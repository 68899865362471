import { IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import {
  generatePath,
  matchPath,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { ROUTE_HOME } from '../../config/routes.config';
import { usePatientInfoDispatch } from '../../contexts/CurrentPatientInfoContext';
import { useDiagnosesPageDispatch } from '../../contexts/DiagnosesPageContext';
import { useDosagesPageDispatch } from '../../contexts/DosagesPageContext';
import { useDrugProductsDispatch } from '../../contexts/DrugProductsContext';
import { useTreatmentsPageDispatch } from '../../contexts/TreatmentsPageContext';

import { Button } from '../Button';

export const ButtonTo = ({
  title,
  currentRoute,
  toRoute,
  handleClick,
  disabled = false,
  secondary = false,
  mobile = false,
  iconName,
  wait,
}: {
  title: string;
  currentRoute?: string;
  toRoute: string;
  handleClick?: () => void | Promise<void>;
  disabled?: boolean;
  secondary?: boolean;
  mobile?: boolean;
  iconName?: string;
  wait?: boolean;
}) => {
  const { pathname } = useLocation();
  const dispatchPatientInfo = usePatientInfoDispatch();
  const dispatchTreatments = useTreatmentsPageDispatch();
  const dispatchDiagnoses = useDiagnosesPageDispatch();
  const dispatchDosages = useDosagesPageDispatch();
  const dispatchDrugProducts = useDrugProductsDispatch();
  const history = useHistory();

  let patientId = null;
  let visitId = null;
  let toPath = ROUTE_HOME;

  if (toRoute !== ROUTE_HOME) {
    const currentUrlParams = matchPath<{
      patientId: string;
      visitId: string;
    }>(pathname, {
      path: currentRoute,
    });
    patientId = currentUrlParams?.params.patientId;
    visitId = currentUrlParams?.params.visitId;

    toPath =
      patientId && visitId
        ? generatePath(toRoute, {
            patientId: patientId.toString(),
            visitId: visitId.toString(),
          })
        : ROUTE_HOME;
  }

  const handleGoHome = async () => {
    if (handleClick) {
      await handleClick();
    }
    dispatchDiagnoses({ type: 'resetAll' });
    dispatchTreatments({ type: 'reset' });
    dispatchDosages({ type: 'reset' });
    dispatchDrugProducts({ type: 'resetAll' });
    dispatchPatientInfo({ type: 'resetPatientInfo' });
  };

  const runHandlersAndNavigate = async () => {
    if (!wait) history.push(toPath);
    const handler = toRoute === ROUTE_HOME ? handleGoHome : handleClick;
    if (handler) await handler();
    if (wait) history.push(toPath);
  };

  const renderIcon = () => {
    switch (iconName) {
      case 'home':
        return <Close />;
      case 'back':
        return <ArrowBackIos />;
      case 'next':
        return <ArrowForwardIos />;
      default:
        return null;
    }
  };

  if (mobile) {
    return (
      <IconButton
        color="primary"
        disabled={disabled}
        onClick={runHandlersAndNavigate}
        size="large"
      >
        {renderIcon()}
      </IconButton>
    );
  } else {
    return (
      <Button
        title={title}
        color="primary"
        variant="contained"
        disabled={disabled}
        onClick={runHandlersAndNavigate}
        style={
          secondary
            ? {
                color: '#01AA70',
                backgroundColor: 'white',
                border: '1px solid rgba(0, 0, 0, 0.2)',
                marginRight: '1em',
              }
            : undefined
        }
      >
        {title}
      </Button>
    );
  }
};
