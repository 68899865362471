import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import React from 'react';
import { DrugStrengthState } from '../../../types/DrugDosages.type';
import { ResultTextStrength } from './ResultTextStrength';
import { DrugSelectStrength } from './DrugSelectStrength';
import { CalculateButton } from '../SharedComponents/CalculateButton';
import { CalculatorRowTitleGridItem } from '../SharedComponents/CalculatorRowTitleGridItem';
import { numberInputProps } from '../../../config/constant.params';

export const DrugStrengthRow = ({
  state,
  handleInputChange,
  handleCalculateClick,
  mobile,
}: {
  state: DrugStrengthState;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCalculateClick: () => void;
  mobile: boolean;
}) => {
  return (
    <Grid container spacing={2}>
      <CalculatorRowTitleGridItem>
        CALCULATE STRENGTH
      </CalculatorRowTitleGridItem>
      <Grid item xs={8} sm={2}>
        <TextField
          name="numberOfUnits"
          label="Number of units"
          fullWidth
          variant="outlined"
          type="number"
          onChange={handleInputChange}
          value={state.numberOfUnits.value}
          error={state.numberOfUnits.error}
          inputProps={numberInputProps}
          autoComplete="off"
        />
      </Grid>
      <Grid item xs={8} sm={2}>
        <DrugSelectStrength
          value={state.unit.value}
          error={state.unit.error}
          handleChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={8} sm={2}>
        <TextField
          name="dose"
          label="Dose (mg/kg/day)"
          fullWidth
          variant="outlined"
          type="number"
          onChange={handleInputChange}
          value={state.dose.value}
          error={state.dose.error}
          inputProps={numberInputProps}
          autoComplete="off"
        />
      </Grid>
      <Grid item xs={8} sm={2}>
        <CalculateButton
          handleCalculateClick={handleCalculateClick}
          mobile={mobile}
        />
      </Grid>
      <Grid item xs={6} sm={2}>
        <TextField
          name="result"
          label="Result"
          fullWidth
          variant="outlined"
          type="number"
          disabled
          onChange={handleInputChange}
          value={state.result}
        />
      </Grid>
      <Grid item xs={6} sm={2}>
        <ResultTextStrength unit={state.unit.value} />
      </Grid>
    </Grid>
  );
};
