import {
  usePatientInfo,
  usePatientInfoDispatch,
} from '../contexts/CurrentPatientInfoContext';
import { useCallback, useEffect, useState } from 'react';
import API from '../api/Api';
import { useParams } from 'react-router-dom';
import { formatDateToRequest } from '../utils/dateUtils';
import { useWork } from '../hooks/useWork';
import { useAnimalTypesContext } from '../contexts/AnimalTypesContext';
import { Breed } from '../types/Breed.type';
import { PatientSummary } from '../components/patient-summary/PatientSummary';
import { add, sub } from 'date-fns';
import Loader from '../components/Loader';
import { UNDEFINED_ID } from '../config/constant.params';

enum FetchType {
  previous,
  today,
  future,
}

export const PatientSummaryPage = () => {
  const patientInfo = usePatientInfo();
  const [visitLinkClicked, setVisitLinkClicked] = useState(false);

  // Patient visit loading

  const [futureVisits, setFutureVisits] = useState([]);
  const [todayVisits, setTodayVisits] = useState([]);
  const [previousVisits, setPreviousVisits] = useState([]);
  const [visitDataLoading, setVisitDataLoading] = useState(false);
  const [visitDataLoaded, setVisitDataLoaded] = useState(false);
  const { patientId } = useParams<{ patientId: string | undefined }>();

  const fetchPatientVisits = useCallback(
    async (fetchType: FetchType) => {
      const todayDate = new Date();
      const queryObj: any = {
        modelName: 'PatientVisits',
        PatientId: patientId,
      };
      if (fetchType === FetchType.today) {
        queryObj.Date = formatDateToRequest(todayDate);
      } else if (fetchType === FetchType.future) {
        queryObj.DateFrom = formatDateToRequest(add(todayDate, { days: 1 }));
        queryObj.DateTo = formatDateToRequest(add(todayDate, { days: 100 }));
      } else if (fetchType === FetchType.previous) {
        queryObj.DateFrom = formatDateToRequest(sub(todayDate, { days: 100 }));
        queryObj.DateTo = formatDateToRequest(sub(todayDate, { days: 1 }));
      }
      const patientVisitsResponse: any = await API.getModel(queryObj);
      return patientVisitsResponse;
    },
    [patientId]
  );

  const initializeVisitData = useCallback(() => {
    if (!visitDataLoading) {
      setVisitDataLoading(true);
      const fetchVisitResponses = async () => {
        const visitResponses: any[] = await Promise.all([
          fetchPatientVisits(FetchType.future),
          fetchPatientVisits(FetchType.today),
          fetchPatientVisits(FetchType.previous),
        ]);
        setFutureVisits(visitResponses[0].body);
        setTodayVisits(visitResponses[1].body);
        setPreviousVisits(visitResponses[2].body);
        setVisitDataLoaded(true);
      };
      fetchVisitResponses();
    }
  }, [visitDataLoading, fetchPatientVisits]);

  useEffect(initializeVisitData, [initializeVisitData]);

  // Rest of data loading

  const { patient, getPatient } = useWork();
  const { animalTypesObj } = useAnimalTypesContext();
  const dispatchPatientInfo = usePatientInfoDispatch();

  const [patientLoaded, setPatientLoaded] = useState(false);
  const [breedLoaded, setBreedLoaded] = useState(false);
  const [animalTypeName, setAnimalTypeName] = useState<string | null>(null);
  const [breed, setBreed] = useState<Breed | null>(null);

  // Getting patient
  useEffect(() => {
    if (!patient && !patientLoaded) {
      setPatientLoaded(true);
      getPatient(parseInt(patientId!));
    }
  }, [getPatient, patient, patientId, patientLoaded]);

  // Getting animalName
  useEffect(() => {
    if (patient && animalTypesObj && !animalTypeName) {
      setAnimalTypeName(animalTypesObj[patient.AnimalTypeId].Name);
    }
  }, [patient, animalTypesObj, animalTypeName]);

  // Getting breedName
  useEffect(() => {
    const getBreed = async () => {
      if (patient && patient.BreedId && !breedLoaded) {
        setBreedLoaded(true);
        const breedResponse: any = await API.getModel({
          modelName: 'Breeds',
          id: patient.BreedId,
        });
        const responseBody: Breed = breedResponse.body[0];
        setBreed(responseBody);
        dispatchPatientInfo({
          type: 'setBreedName',
          breedName: responseBody.Name,
        });
      }
    };
    getBreed();
  }, [breedLoaded, dispatchPatientInfo, patient]);

  // Set patientInfo state
  useEffect(() => {
    if (patient) {
      dispatchPatientInfo({
        type: 'setPatientInfo',
        patient,
        animalTypeName: animalTypesObj[patient.AnimalTypeId].Name,
      });
    }
  }, [dispatchPatientInfo, patient, patientId, animalTypesObj]);

  // Add breed to patientInfo state
  useEffect(() => {
    if (breed && !breedLoaded)
      dispatchPatientInfo({
        type: 'setBreedName',
        breedName: breed.Name,
      });
  });

  if (!patient || !visitDataLoaded || patientInfo.Id === UNDEFINED_ID) {
    return <Loader showLoader={true} />;
  }

  return (
    <PatientSummary
      patientInfo={patientInfo}
      patient={patient}
      todayVisits={todayVisits}
      futureVisits={futureVisits}
      previousVisits={previousVisits}
      visitLinkClicked={visitLinkClicked}
      setVisitLinkClicked={setVisitLinkClicked}
    ></PatientSummary>
  );
};
