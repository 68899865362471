import {
  IntegrationState,
  IntegrationAction,
  IntegrationInitialState,
} from './integration.state';

export const integrationReducer = (
  state: IntegrationState,
  action: IntegrationAction
): IntegrationState => {
  switch (action.type) {
    case 'reset': {
      return { ...IntegrationInitialState };
    }

    case 'request': {
      return { isLoading: true, integrationConnectionInfo: undefined };
    }

    case 'success': {
      return {
        isLoading: false,
        integrationConnectionInfo:
          action.responseData.integrationConnectionInfo,
        loginLink: action.responseData.loginLink,
      };
    }

    case 'failure':
      return {
        isLoading: false,
        error: action.error,
        integrationConnectionInfo: undefined,
      };

    case 'setIntegrationInfo':
      return { ...state, integrationInfo: action.integrationInfo };

    case 'setIntegrationSource':
      return { ...state, integrationSource: action.integrationSource };

    default:
      return state;
  }
};
