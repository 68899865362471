import { DoseParam, DoseParamWithOrder } from '../../types/Dose.params.type';
import { RequestAction } from '../../types/request.action.type';

export type DoseParamsState = {
  treatmentDurationPrefixes: DoseParam[];
  doseTypes: DoseParamWithOrder[];
  dosageIntervals: DoseParamWithOrder[];
  treatmentDurations: DoseParamWithOrder[];
  summaryDrugUnits: DoseParamWithOrder[];
  summaryResultDrugUnits: DoseParamWithOrder[];
  isLoading: boolean;
  error?: string;
  notSpecifiedDurationIndex: number;
};

export type DoseParamsAction = RequestAction<
  DoseParam[] | DoseParamWithOrder[]
>;

export const DefaultDoseParams: DoseParamsState = {
  isLoading: false,
  treatmentDurationPrefixes: [],
  doseTypes: [],
  dosageIntervals: [],
  treatmentDurations: [],
  summaryDrugUnits: [],
  summaryResultDrugUnits: [],
  notSpecifiedDurationIndex: 0,
};
