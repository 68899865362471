import { useCallback, useLayoutEffect, useState } from 'react';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { SignUpParams } from '@aws-amplify/auth/lib-esm/types';
import { User } from '../types/user.type';
import { awsConfig } from '../config/aws.config';

const useAuthentication = () => {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [error, setError] = useState<string | undefined>(undefined);

  const validateIsAuthenticated = (user: {
    signInUserSession: { isValid: any; accessToken: { getExpiration: any } };
  }) => {
    if (
      !user ||
      !user.signInUserSession ||
      !user.signInUserSession.isValid ||
      !user.signInUserSession.accessToken ||
      !user.signInUserSession.accessToken.getExpiration
    ) {
      return false;
    }

    const session = user.signInUserSession;
    const isValid = session.isValid() || false;

    const sessionExpiry = new Date(session.accessToken.getExpiration() * 1000);
    const isExpired = new Date() > sessionExpiry;
    return isValid && !isExpired;
  };

  const refreshState = useCallback(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        const isAuthenticated = validateIsAuthenticated(user);
        setIsAuthenticated(isAuthenticated);
        setError(undefined);
      })
      .catch((err) => {
        setUser(null);
        setIsAuthenticated(false);
        if (typeof err === 'string') {
          setError(err);
        } else {
          setError('unknown error');
        }
      });
  }, []);

  useLayoutEffect(() => {
    refreshState();
  }, [refreshState]);

  const signOut = useCallback(async () => {
    try {
      await Auth.signOut();
      signOutSessionFromCognito();
    } catch (error) {
      setError(
        `While logout: ${typeof error === 'string' ? error : 'Unknown error'}`
      );
    } finally {
      refreshState();
    }
  }, [refreshState]);

  const signOutSessionFromCognito = () => {
    /* 
        This is a hacky solution to make sure users "session" is killed also in Cognito.
        Reason behind is that we need to make sure user needs to log in again after logging out.
        This is how we can handle only 1 active login for user at time.
        Cognito revokes all existing Refresh tokens when logging in via Post Authentication trigger.
        But when we handle sign out for a NotAuthorized API call Amplify Auth only resets the local storage.
        Thats why we need to make sure the logout endpoint is called.

        more information in this issue: https://github.com/aws-amplify/amplify-js/issues/3435
      */
    window.location.replace(
      'https://' +
        awsConfig.IDP_DOMAIN +
        '/logout?client_id=' +
        awsConfig.USER_POOL_CLIENT_ID +
        '&logout_uri=' +
        awsConfig.REDIRECT_SIGN_OUT
    );
  };

  const signIn = useCallback(() => {
    Auth.federatedSignIn().catch((err) => {
      setError(
        `While login: ${typeof err === 'string' ? err : 'Unknown error'}`
      );
    });
  }, []);

  const login = useCallback(
    ({ username, password, clientMetadata }: SignUpParams) => {
      Auth.signIn(username, password, clientMetadata)
        .then(() => refreshState())
        .catch((err) => {
          setError(
            `While login: ${typeof err === 'string' ? err : 'Unknown error'}`
          );
          console.log(err);
        });
    },
    [refreshState]
  );

  const loginCustomChallenge = useCallback(
    async (challenge: string) => {
      try {
        Auth.configure({
          authenticationFlowType: 'CUSTOM_AUTH',
        });

        const [email, code] = challenge.split(',');
        const user = await Auth.signIn(email);
        await Auth.sendCustomChallengeAnswer(user, code);
        await Auth.currentSession();
        refreshState();
      } catch (err: any) {
        setError(err);
      }
    },
    [refreshState]
  );

  const userInfo = user
    ? user.getSignInUserSession()?.getIdToken().decodePayload()
    : null;
  console.log('UserInfo', userInfo);
  const userData: User = {
    isAuthenticated,
    ...(userInfo && {
      userName: userInfo.name
        ? `${userInfo.name} ${userInfo.family_name}`
        : userInfo.email + '',
      userEmail: userInfo.email,
    }),
    ...(error && { error }),
  };

  return { user: userData, signIn, signOut, login, loginCustomChallenge };
};

export default useAuthentication;
