import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { visitsListReducer } from '../reducers/visitsList/visits.list.reducer';
import {
  VisitsListState,
  VisitsListAction,
  VisitsListDefault,
} from '../reducers/visitsList/visits.list.state';
import moment from 'moment';

const VisitsListContext = createContext<VisitsListState>({} as VisitsListState);
const VisitsListDispatchContext = createContext<Dispatch<VisitsListAction>>(
  () => {}
);

export const OtherVisitsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(visitsListReducer, {
    ...VisitsListDefault,
    fromDate: moment(),
    toDate: moment(),
    noVisitsText: '',
  });

  return (
    <VisitsListContext.Provider value={state}>
      <VisitsListDispatchContext.Provider value={dispatch}>
        {children}
      </VisitsListDispatchContext.Provider>
    </VisitsListContext.Provider>
  );
};

export const useOtherVisits = (): VisitsListState => {
  return useContext(VisitsListContext);
};

export const useOtherVisitsDispatch = () => {
  return useContext(VisitsListDispatchContext);
};
