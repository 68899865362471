import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { uiStateReducer } from '../reducers/uiState/uiState.reducer';
import {
  UiState,
  UiAction,
  DefaultUiState,
} from '../reducers/uiState/uiState.store';

const UiStateContext = createContext<UiState>({} as UiState);
const UiStateDispatchContext = createContext<Dispatch<UiAction>>(() => {});

export const UiStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(uiStateReducer, DefaultUiState);

  return (
    <UiStateContext.Provider value={state}>
      <UiStateDispatchContext.Provider value={dispatch}>
        {children}
      </UiStateDispatchContext.Provider>
    </UiStateContext.Provider>
  );
};

export const useUiState = (): UiState => {
  return useContext(UiStateContext);
};

export const useUiStateDispatch = () => {
  return useContext(UiStateDispatchContext);
};
