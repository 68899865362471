import React, { useEffect, useMemo } from 'react';
import { Card } from '../components/common/Card';
import { Grid, ButtonBase, ButtonBaseProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router';
import { QuickDiagnose } from '../components/quick-diagnose/QuickDiagnose';
import { PatientPage } from './PatientPage';
import { PatientPageProvider } from '../contexts/PatientPageContext';
import { AllPatientsPage } from './AllPatientsPage';
import API from '../api/Api';

const patientButtonStyles = makeStyles()((theme) => ({
  root: {
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  title: {
    fontSize: '120%',
    fontWeight: 'bold',
  },
  description: {},
  active: {
    backgroundColor: theme.palette.grey[200],
    borderColor: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
  },
}));

const PatientButton = ({
  title,
  description,
  isActive,
  ...rest
}: ButtonBaseProps & {
  title: string;
  description: string;
  isActive: boolean;
}) => {
  const { classes } = patientButtonStyles();
  return (
    <ButtonBase
      {...rest}
      className={clsx(classes.root, { [classes.active]: isActive })}
    >
      <p className={classes.title}>{title}</p>
      <p className={classes.description}>{description}</p>
    </ButtonBase>
  );
};

const TABS = {
  testpatient: 0,
  newpatient: 1,
  existingpatient: 2,
};

const DiagnoseHomePage = () => {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    API.trackEvent('diagnose_home_page');
  }, []);
  const activeTab = useMemo(() => {
    const params = new URLSearchParams(location.search.replace(/^\?/, ''));
    return params.get('activeTab') as keyof typeof TABS;
  }, [location]);
  return (
    <div>
      <Card title="CHOOSE PATIENT" pageView="drawerView" withoutMarginBottom>
        <Grid container spacing={3}>
          <Grid item sm={4} xs={12}>
            <PatientButton
              isActive={activeTab === 'testpatient'}
              title="Test patient"
              description="Great for quick diagnostics. Test patient data can't be saved."
              onClick={() => history.push({ search: 'activeTab=testpatient' })}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <PatientButton
              isActive={activeTab === 'newpatient'}
              title="New patient"
              description="Start by giving the patient signalment and details. Get more accurate diagnoses and visit saving option."
              onClick={() => history.push({ search: 'activeTab=newpatient' })}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <PatientButton
              isActive={activeTab === 'existingpatient'}
              title="Existing patient"
              description="Search for a patient and continue where you left off."
              onClick={() =>
                history.push({ search: 'activeTab=existingpatient' })
              }
            />
          </Grid>
        </Grid>
      </Card>

      {activeTab === 'testpatient' ? <QuickDiagnose /> : null}
      <PatientPageProvider>
        {activeTab === 'newpatient' ? <PatientPage /> : null}
        {activeTab === 'existingpatient' ? <AllPatientsPage isSelect /> : null}
      </PatientPageProvider>
    </div>
  );
};

export default DiagnoseHomePage;
