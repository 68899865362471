import React from 'react';
import Step from '@mui/material/Step';
import StepConnector from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { withStyles } from 'tss-react/mui';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckIcon from '@mui/icons-material/Check';

const IconConnector = withStyles(StepConnector, (theme, _params, classes) => {
  return {
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      [`& .${classes.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    completed: {
      [`& .${classes.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    disabled: {
      [`& .${classes.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    line: {
      borderTopWidth: 2,
      borderRadius: 1,
    },
  };
});

const useStepIconStyles = makeStyles()({
  root: {
    color: '#00cd87',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#00cd87',
  },
  circle: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: '#00cd87',
  },
  completed: {
    color: 'white',
    zIndex: 1,
    fontSize: 16,
    backgroundColor: '#00cd87',
    borderRadius: '50%',
  },
  current: {
    color: '#00cd87',
    zIndex: 1,
    fontSize: 24,
  },
});

const StepIcon = (
  props: StepIconProps,
  activeStep: number,
  index: number,
  stepDone: boolean
) => {
  const { classes } = useStepIconStyles();

  const { active, completed } = props;

  return (
    <div className={classes.root}>
      {completed || (active && stepDone) ? (
        <CheckIcon className={classes.completed} />
      ) : active ? (
        <FiberManualRecordIcon className={classes.current} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    marginTop: theme.spacing(1),
    marginRight: '10%',
    marginLeft: '5%',
  },
  root: {
    width: '80%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  stepper: {
    paddingTop: theme.spacing(1),
    paddingBottom: '0',
    backgroundColor: 'inherit',
  },
}));

export default function ProgressBar({
  steps,
  activeStep,
  stepDone,
}: {
  steps: string[];
  activeStep: number;
  stepDone: boolean;
}) {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<IconConnector />}
          className={classes.stepper}
        >
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={(props: StepIconProps) => {
                    return StepIcon(props, activeStep, index, stepDone);
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
    </div>
  );
}
