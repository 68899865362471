import React from 'react';

export const TemplatePage = ({
  title,
  error,
}: {
  title: string;
  error?: string;
}) => {
  if (error) {
    throw error;
  }
  return <h1>{title}</h1>;
};
