import React, { useState } from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { UNDEFINED_ID } from '../../config/constant.params';
import { DiseaseInfo } from './DiseaseInfo';
import { TreatmentsInfo } from './TreatmentsInfo';
import { TreatmentsList } from './TreatmentsList';
import { Treatment, TreatmentInfo } from '../../types/Diagnose.type';
import { useMediaQuery } from '@mui/material';
import { TreatmentsListMobile } from './TreamentsListMobile';
import { TreatmentProtocolCard } from '../diseases-and-treatments/TreatmentProtocolCard';

const sortByName = (arr: Treatment[]) => {
  return arr.sort((a, b) => {
    const arrA = a.TreatmentSet.Name.split(' ');
    const arrB = b.TreatmentSet.Name.split(' ');

    return parseInt(arrA[2]) > parseInt(arrB[2])
      ? 1
      : parseInt(arrB[2]) > parseInt(arrA[2])
      ? -1
      : a.Order > b.Order
      ? 1
      : b.Order > a.Order
      ? -1
      : 0;
  });
};

export const TreatmentsContent = ({
  canSelect = true,
  treatmentInfo,
}: {
  canSelect?: boolean;
  treatmentInfo: TreatmentInfo | null;
}) => {
  const [selectedProtocolId, setSelectedProtocolId] = useState(UNDEFINED_ID);

  const treatmentSetTreatments: Treatment[] | null = treatmentInfo
    ? treatmentInfo.Treatments.filter((treatment) => {
        return treatment.TreatmentSet.Id === selectedProtocolId;
      })
    : null;

  const mobile = useMediaQuery('(max-width:600px)');

  return (
    <AccordionDetails>
      {!treatmentInfo && (
        <Typography align="left">
          There is no treatments available for this disease yet.
        </Typography>
      )}
      {treatmentInfo &&
        (mobile ? (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={9}>
              <DiseaseInfo treatmentInfo={treatmentInfo} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TreatmentsListMobile
                canSelect={canSelect}
                treatments={[...sortByName(treatmentInfo.Treatments)]}
                selectedProtocolId={selectedProtocolId}
                setSelectedProtocolId={setSelectedProtocolId}
                treatmentSetTreatments={treatmentSetTreatments}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <TreatmentProtocolCard
                treatmentSet={{
                  Id: UNDEFINED_ID,
                  Name: 'General information',
                  Order: -1,
                }}
                selectedTreatments={[]}
                selected={selectedProtocolId === UNDEFINED_ID}
                onClick={() => setSelectedProtocolId(UNDEFINED_ID)}
              ></TreatmentProtocolCard>
              <TreatmentsList
                treatments={[...sortByName(treatmentInfo.Treatments)]}
                selectedProtocolId={selectedProtocolId}
                setSelectedProtocolId={setSelectedProtocolId}
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              {selectedProtocolId !== UNDEFINED_ID && (
                <TreatmentsInfo
                  treatmentSetTreatments={treatmentSetTreatments}
                  canSelect={canSelect}
                />
              )}
              {selectedProtocolId === UNDEFINED_ID && (
                <DiseaseInfo treatmentInfo={treatmentInfo} />
              )}
            </Grid>
          </Grid>
        ))}
    </AccordionDetails>
  );
};
