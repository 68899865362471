import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import { DrugUnit } from '../../../types/DrugDosages.type';

export const DrugSelectStrength = ({
  name = 'unit',
  value,
  error,
  handleChange,
  viewSize = 'medium',
}: {
  name?: string;
  value: number | null;
  error: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  viewSize?: 'medium' | 'small';
}) => {
  return (
    <FormControl variant="outlined" fullWidth size={viewSize}>
      <Select
        variant="outlined"
        value={value}
        onChange={handleChange as unknown as () => React.ChangeEvent}
        error={error}
        name={name}
      >
        <MenuItem selected value={DrugUnit['ml']}>
          ml
        </MenuItem>
        <MenuItem value={DrugUnit['tablet(s)']}>tablet(s)</MenuItem>
        <MenuItem value={DrugUnit['capsule(s)']}>capsule(s)</MenuItem>
      </Select>
    </FormControl>
  );
};
