import { InputBaseComponentProps } from '@mui/material';

export const UNDEFINED_ID = -1;
export const NO_VISITS_TEXT = 'No visits';
export const LOCALE_US = 'en-US';
export const numberInputProps: InputBaseComponentProps = {
  pattern: '[0-9]*',
  step: '0.01',
  inputMode: 'numeric',
  min: 0,
};

export const MG_UNIT = 'mg';
export const MCG_UNIT = 'mcg';
export const NUMBER_MCG_IN_MG = 1000;

export const ML_NAME = 'ml';
export const TABLET_NAME = 'tablet';
export const CAPSULE_NAME = 'capsule';
