import { Button } from '../Button';
import { Card } from '../common/Card';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import {
  ConfirmationFieldName,
  ConfirmationFormData,
} from '../../types/registration/confirmationForm.type';
import { TextField } from './TextField';
import { Typography } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  form: {
    '& .MuiTextField-root': {
      marginTop: theme.spacing(3),
      marginBottop: theme.spacing(1),
      width: '90%',
    },
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.primary.main,
  },
}));

export default function ConfirmationForm({
  setCode: setConfirmationCode,
  setUsername,
  userName: username,
  activationCode,
}: {
  setCode: (value: string | null) => void;
  setUsername: (value: string | null) => void;
  userName: string | undefined | null;
  activationCode: string | undefined;
}) {
  const { classes } = useStyles();

  const initialState = useMemo(() => {
    const ret = ConfirmationFormData;
    if (username) ret.Username.value = username;
    if (activationCode) ret.ConfirmationCode.value = activationCode;
    return ret;
  }, [username, activationCode]);

  const [state, setState] = useState(initialState);
  const [disabled, setDisable] = useState(true);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setConfirmationCode(state.ConfirmationCode.value);
    setUsername(state.Username.value);
  };

  useEffect(() => {
    const notValid = state.ConfirmationCode.value === '';

    setDisable(notValid);
  }, [state.ConfirmationCode.value]);

  const handleChange = ({
    name,
    value,
  }: {
    name: ConfirmationFieldName;
    value: string;
  }) => {
    setState((state) => {
      if (state[name].value !== value) {
        return { ...state, [name]: { ...state[name], value } };
      }
      return state;
    });
  };

  return (
    <Card title="REGISTER CONFIRMATION" pageView="noDrawerView">
      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        {username ? (
          <>
            <Typography>
              Great! Now, please check your email to verify your account.
              Verification email has been sent to{' '}
              {<span className={classes.highlight}>{username}</span>}.
            </Typography>
            <Typography>
              If you don’t get it in few minutes please check Junk folder.
            </Typography>
          </>
        ) : null}

        <Grid container className={classes.gridContainer}>
          {!username ? (
            <Grid item xs={12}>
              <TextField
                {...state.Username}
                name={'Username'}
                setValue={handleChange}
                currentValue={state.Username.value}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextField
              {...state.ConfirmationCode}
              name={'ConfirmationCode'}
              setValue={handleChange}
              currentValue={state.ConfirmationCode.value}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={disabled}
            >
              Verify
            </Button>
          </Grid>
        </Grid>
        <Typography>
          Contact{' '}
          {<span className={classes.highlight}>support@gekkovet.com</span>} if
          needed.
        </Typography>
      </form>
    </Card>
  );
}
