import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { useRequestData } from '../hooks/useRequestData';
import { animalTypeListReducer } from '../reducers/animalTypeList/animalType.list.reducer';
import {
  AnimalTypeListState,
  AnimalTypeListAction,
  AnimalTypesInitialState,
} from '../reducers/animalTypeList/animalType.list.state';
import { AnimalType } from '../types/AnimalType.type';
import { useAuthUser } from './AuthUserContext';

const AnimalTypesContext = createContext<AnimalTypeListState>(
  {} as AnimalTypeListState
);
const AnimalTypeDispatchContext = createContext<Dispatch<AnimalTypeListAction>>(
  () => {}
);

export const AnimalTypesProvider = ({ children }: PropsWithChildren<{}>) => {
  const {
    user: { isAuthenticated },
  } = useAuthUser();

  const [state, dispatch] = useReducer(
    animalTypeListReducer,
    AnimalTypesInitialState
  );

  const needTransport =
    isAuthenticated &&
    !state.error &&
    !state.isLoading &&
    state.animalTypes.length === 0;

  useRequestData<AnimalType[]>({
    needTransport,
    dispatch,
    method: 'get',
    params: { modelName: 'AnimalTypes', IsVisible: 1 },
  });

  return (
    <AnimalTypesContext.Provider value={state}>
      <AnimalTypeDispatchContext.Provider value={dispatch}>
        {children}
      </AnimalTypeDispatchContext.Provider>
    </AnimalTypesContext.Provider>
  );
};

export const useAnimalTypesContext = (): AnimalTypeListState => {
  return useContext(AnimalTypesContext);
};

export const useAnimalTypeDispatch = () => {
  return useContext(AnimalTypeDispatchContext);
};
