import { generatePath, Link } from 'react-router-dom';
import { ROUTE_CURRENT_PATIENT } from '../../../config/routes.config';
import { Button } from '../../Button';

export const EditPatientButton = ({ patientId }: { patientId: number }) => {
  const pathname = generatePath(ROUTE_CURRENT_PATIENT, {
    patientId: patientId.toString(),
  });
  return (
    <Button color="primary" component={Link} to={pathname}>
      EDIT
    </Button>
  );
};
