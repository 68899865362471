export type CurrentVisitIds = {
  patientId: number;
  visitId: number;
};

export type UiState = {
  redirectToCreatedVisit?: CurrentVisitIds;
};

export const DefaultUiState: UiState = {};

export type UiAction =
  | {
      type: 'setRedirectToCreatedVisit';
      currentVisitIds: CurrentVisitIds;
    }
  | {
      type: 'resetRedirectToCreatedVisit';
    };
