import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useDoseParams } from '../../contexts/DoseParamsContext';
import {
  DoseInstruction,
  DoseInstructionKeys,
} from '../../reducers/dosagesPage/dosages.page.state';
import { Card } from '../common/Card';
import { SelectDoseParam } from './SelectDoseParam';
import { numberInputProps } from '../../config/constant.params';

const useStyles = makeStyles()(() => ({
  title: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    lineHeight: '1.71',
    letterSpacing: '0.1px',
    textTransform: 'uppercase',
  },
  subTitle: {
    fontSize: '0.9em',
    fontWeight: 'bold',
    lineHeight: '1.6',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
  },
}));

const viewSize = 'medium';

export const CardDoseInstruction = ({
  treatmentName,
  drugAdministrationRouteName,
  doseInstruction,
  setDoseInstruction,
}: {
  treatmentName: string;
  drugAdministrationRouteName: string;
  doseInstruction: DoseInstruction;
  setDoseInstruction: (value: DoseInstruction) => void;
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    doseTypes,
    dosageIntervals,
    treatmentDurations,
    treatmentDurationPrefixes,
    summaryDrugUnits,
    summaryResultDrugUnits,
  } = useDoseParams();

  const setDoseInstructionIndex = (
    name: DoseInstructionKeys,
    value: number
  ) => {
    const newDoseInstruction: DoseInstruction = { ...doseInstruction };
    update(newDoseInstruction, name, value);
    setDoseInstruction(newDoseInstruction);
  };

  const setDoseInstructionValue = (
    name: DoseInstructionKeys,
    value: string
  ) => {
    const newDoseInstruction: DoseInstruction = { ...doseInstruction };
    update(newDoseInstruction, name, value);
    setDoseInstruction(newDoseInstruction);
  };

  function update<T extends DoseInstruction, K extends keyof T>(
    source: T,
    property: K,
    value: T[K]
  ) {
    source[property] = value;
  }

  const handleIndexChange = (name: string, index: number) => {
    setDoseInstructionIndex(name as DoseInstructionKeys, index);
  };

  const handleValueChange = (
    event: React.ChangeEvent<{ name: string; value: unknown }>
  ) => {
    const value = event.target.value as string;
    setDoseInstructionValue(event.target.name as DoseInstructionKeys, value);
  };

  return (
    <Card
      headerHidden
      pageView={matchesXS ? 'mobileView' : 'drawerView'}
      withoutMarginBottom
      outlined
      style={{
        paddingBottom: 0,
        paddingTop: 0,
      }}
    >
      <Typography className={classes.title}>
        Dosage instructions for patient
      </Typography>
      <Typography className={classes.subTitle}>
        This will be shown in summary. Edit if needed
      </Typography>
      <Grid container>
        <Grid container item xs={12} spacing={3} alignItems="center">
          <Grid item xs={12} sm={3}></Grid>
          <Grid item xs={12} sm={9}>
            <Typography style={{ padding: '1em 1em 1em 0' }}>
              {treatmentName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3} alignItems="center">
          <Grid item xs={12} sm={3}>
            <Typography style={{ textAlign: matchesXS ? 'left' : 'right' }}>
              Dose type
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <SelectDoseParam
              value={doseInstruction.doseTypeId}
              setValue={handleIndexChange}
              name="doseTypeId"
              list={doseTypes}
              viewSize={viewSize}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3} alignItems="center">
          <Grid item xs={12} sm={3}>
            <Typography style={{ textAlign: matchesXS ? 'left' : 'right' }}>
              Administration route
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography style={{ padding: '1em 1em 1em 0' }}>
              {drugAdministrationRouteName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3} alignItems="center">
          {!matchesXS && (
            <Grid item xs={12} sm={3}>
              <Typography style={{ textAlign: matchesXS ? 'left' : 'right' }}>
                Strength
              </Typography>
            </Grid>
          )}
          <Grid container item xs={12} sm={9} alignItems="center">
            <Grid item xs={12} sm={2}>
              <TextField
                name="strength"
                fullWidth
                variant="outlined"
                onChange={handleValueChange}
                value={doseInstruction.strength}
                type="number"
                error={false}
                inputProps={numberInputProps}
                autoComplete="off"
                size={viewSize}
                placeholder={matchesXS ? 'Strength' : undefined}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <SelectDoseParam
                value={doseInstruction.summaryDrugUnitId}
                setValue={handleIndexChange}
                name="summaryDrugUnitId"
                list={summaryDrugUnits}
                viewSize={viewSize}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={3}
          alignItems="center"
          style={matchesXS ? { marginTop: '10px' } : undefined}
        >
          {!matchesXS && (
            <Grid item xs={12} sm={3}>
              <Typography style={{ textAlign: matchesXS ? 'left' : 'right' }}>
                Number of units
              </Typography>
            </Grid>
          )}
          <Grid container item xs={12} sm={9} alignItems="center">
            <Grid item xs={12} sm={2}>
              <TextField
                name="numberOfUnits"
                fullWidth
                variant="outlined"
                onChange={handleValueChange}
                value={doseInstruction.numberOfUnits}
                type="number"
                error={false}
                inputProps={numberInputProps}
                autoComplete="off"
                size={viewSize}
                placeholder={matchesXS ? 'Number of units' : undefined}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <SelectDoseParam
                value={doseInstruction.summaryResultDrugUnitId}
                setValue={handleIndexChange}
                name="summaryResultDrugUnitId"
                list={summaryResultDrugUnits}
                viewSize={viewSize}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={3}
          alignItems="center"
          style={matchesXS ? { marginTop: '10px' } : undefined}
        >
          {!matchesXS && (
            <Grid item xs={12} sm={3}>
              <Typography style={{ textAlign: matchesXS ? 'left' : 'right' }}>
                Dosage interval
              </Typography>
            </Grid>
          )}
          <Grid container item xs={12} sm={9} alignItems="center">
            <Grid item xs={12} sm={2}>
              <TextField
                name="dosageInterval"
                fullWidth
                variant="outlined"
                onChange={handleValueChange}
                value={doseInstruction.dosageInterval}
                type="number"
                error={false}
                inputProps={{ ...numberInputProps, step: '1' }}
                autoComplete="off"
                size={viewSize}
                placeholder={matchesXS ? 'Dosage interval' : undefined}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectDoseParam
                value={doseInstruction.dosageIntervalId}
                setValue={handleIndexChange}
                name="dosageIntervalId"
                list={dosageIntervals}
                viewSize={viewSize}
                withEmptyLine
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={3}
          alignItems="center"
          style={matchesXS ? { marginTop: '10px' } : undefined}
        >
          {!matchesXS && (
            <Grid item xs={12} sm={3}>
              <Typography style={{ textAlign: matchesXS ? 'left' : 'right' }}>
                Treatment duration
              </Typography>
            </Grid>
          )}
          <Grid container item xs={12} sm={9} alignItems="center">
            <Grid item xs={12} sm={3}>
              <SelectDoseParam
                value={doseInstruction.treatmentDurationPrefixId}
                setValue={handleIndexChange}
                name="treatmentDurationPrefixId"
                list={treatmentDurationPrefixes}
                viewSize={viewSize}
                withEmptyLine
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                name="treatmentDuration"
                fullWidth
                variant="outlined"
                onChange={handleValueChange}
                value={doseInstruction.treatmentDuration}
                type="number"
                error={false}
                inputProps={{ ...numberInputProps, step: '1' }}
                autoComplete="off"
                size={viewSize}
                placeholder={matchesXS ? 'Treatment duration' : undefined}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectDoseParam
                value={doseInstruction.treatmentDurationId}
                setValue={handleIndexChange}
                name="treatmentDurationId"
                list={treatmentDurations}
                viewSize={viewSize}
                withEmptyLine
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
