import { FormData } from '../form.type';

export const confirmationFieldNames = ['ConfirmationCode', 'Username'];

export type ConfirmationFieldName = (typeof confirmationFieldNames)[number];

export type ConfirmationFieldType = 'text';

export const ConfirmationFormData: FormData<
  ConfirmationFieldName,
  ConfirmationFieldType
> = {
  ConfirmationCode: {
    type: 'text',
    label: 'Confirmation Code',
    value: '',
    required: true,
    validator: (value: string) =>
      value === '' ? 'Check your email for code' : '',
  },
  Username: {
    type: 'text',
    label: 'Email address',
    required: true,
    value: '',
    validator: (value: string) =>
      value === '' ? 'Please give your email address' : '',
  },
};
