import { DialogProps } from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React from 'react';
import { useDiagnosesPageDispatch } from '../../contexts/DiagnosesPageContext';
import DialogRemoveSymptom from './DialogRemoveSymptom';

export default function DeleteSymptom({ symptomId }: { symptomId: number }) {
  const dispatch = useDiagnosesPageDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteSymptom = () => {
    dispatch({ type: 'removeSymptom', symptomId });
    handleClose();
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <IconButton
        edge="end"
        aria-label="delete"
        style={{ paddingTop: '0.4em' }}
        onClick={handleClickOpen('paper')}
        size="large"
      >
        <DeleteForeverIcon />
      </IconButton>
      <DialogRemoveSymptom
        open={open}
        handleConfirm={deleteSymptom}
        handleReject={handleClose}
      />
    </>
  );
}
