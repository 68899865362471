import React from 'react';
import { useHistory } from 'react-router-dom';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';

import { Card } from './common/Card';

import { About } from '../components/About';
import { ROUTE_REGISTER } from '../config/routes.config';
import { useAuthUser } from '../contexts/AuthUserContext';

const useStyles = makeStyles()((theme: Theme) => ({
  buttons: {
    background: 'inherit',
  },
}));

export const NoAuthUserHome = () => {
  const { classes } = useStyles();
  const history = useHistory();
  const { signIn } = useAuthUser();

  return (
    <Card title="Welcome to GekkoVet" pageView="noDrawerView">
      <BottomNavigation
        value={3}
        onChange={(_event, newValue) => {
          if (newValue === 0) {
            history.push(ROUTE_REGISTER);
          } else {
            signIn();
          }
        }}
        showLabels
        className={classes.buttons}
      >
        <BottomNavigationAction
          label="Register"
          icon={<VpnKeyOutlinedIcon />}
        />
        <BottomNavigationAction label="Login" icon={<LockOpenIcon />} />
      </BottomNavigation>
      <About />
    </Card>
  );
};
