import { Typography, Button as MuiButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import FormControl from '@mui/material/FormControl';
import { Alert } from '@mui/material';
import React, {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useAnimalTypesContext } from '../../contexts/AnimalTypesContext';
import { useVeterinarianDispatch } from '../../contexts/VeterinarianContext';
import { useLocale } from '../../hooks/useLocale';
import { useRequestData } from '../../hooks/useRequestData';
import { updateProfileReducer } from '../../reducers/profile/profile.reducer';
import {
  DefaultUpdateProfileState,
  FetchParamsAction,
  ProfileState,
} from '../../reducers/profile/profile.state';
import { FormData } from '../../types/form.type';
import {
  ProfileBaseFieldName,
  profileBaseFieldNames,
  ProfileFieldType,
  profileBaseFormData,
  TExpertiseAction,
  VetProfile,
} from '../../types/Profile.types';
import { Button } from '../Button';
import { Card } from '../common/Card';
import Loader from '../Loader';
import ChangePasswordDialog from './ChangePasswordDialog';
import { ProfileExpertise } from './ProfileExpertise';
import { ProfilePerson } from './ProfilePerson';
import { ProfileSubscription } from './ProfileSubscription';

const useStyles = makeStyles()((theme) => ({
  actionButton: {
    minWidth: 150,
    alignSelf: 'flex-end',
    marginLeft: 20,
  },
  formControl: {
    minWidth: 120,
    margin: 0,
  },
}));

export const ProfileContent = ({
  vetProfile,
  specialities,
  countries,
  personTypes,
  selectedSpecialitiesIds,
  selectedAnimalTypeIds,
  dispatchProfile,
}: ProfileState & { dispatchProfile: React.Dispatch<FetchParamsAction> }) => {
  const { classes } = useStyles();
  const locale = useLocale();

  const [baseProfilestate, setBaseProfileState] = useState<
    FormData<ProfileBaseFieldName, ProfileFieldType>
  >(profileBaseFormData(undefined, locale));
  const [disabled, setDisable] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [isLoadingStripeIntegration, setIsLoadingStripeIntegration] =
    useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);

  const handleBaseProfileChange = ({
    name,
    value,
  }: {
    name: ProfileBaseFieldName;
    value: string;
  }) => {
    if (name === 'Country') {
      const data = value.split(',');
      setBaseProfileState((state) => {
        if (state![name].value !== value) {
          return {
            ...state,
            Country: { ...state!.Country, value: data[0] || '' },
            PhoneCode: { ...state!.PhoneCode, value: data[1] || '' },
          };
        }
        return state;
      });
    } else {
      setBaseProfileState((state) => {
        if (state![name].value !== value) {
          return { ...state, [name]: { ...state![name], value } };
        }
        return state;
      });
    }
    if (!isChanged) {
      setIsChanged(true);
    }
  };

  const [{ isUpdatingProfile, errorProfile, showNote }, dispatchUpdateProfile] =
    useReducer(updateProfileReducer, DefaultUpdateProfileState);
  const { animalTypes } = useAnimalTypesContext();
  const dispatchVeterinarian = useVeterinarianDispatch();

  const [profileData, setProfileData] = useState<{
    profile: VetProfile;
    animalTypes: Array<{ AnimalTypeId: number }>;
    specialities: Array<{ SpecialityId: number }>;
  } | null>(null);

  const cardRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (vetProfile) {
      setBaseProfileState(profileBaseFormData(vetProfile, locale));
    }
  }, [locale, vetProfile]);

  useEffect(() => {
    if (isUpdatingProfile) {
      setProfileData(null);
    }
  }, [isUpdatingProfile]);

  const needToUpdate =
    Boolean(profileData) && !isUpdatingProfile && !errorProfile;

  useRequestData<VetProfile[]>({
    needTransport: needToUpdate,
    dispatch: dispatchUpdateProfile,
    method: 'post',
    url: '/profile',
    data: profileData,
    dispatchOptions: {
      dispatch: dispatchVeterinarian,
      veterinarian: profileData?.profile,
    },
  });

  useEffect(() => {
    const checkFormValid = () => {
      // If some of the basic fields are empty return false
      if (
        profileBaseFieldNames.some(
          (name) =>
            baseProfilestate[name].required &&
            baseProfilestate[name].value === ''
        )
      ) {
        return false;
      }

      return true;
    };
    const notValid = checkFormValid();
    setDisable(!notValid);
  }, [baseProfilestate]);

  useEffect(() => {
    if (showNote) {
      cardRef!.current!.scrollIntoView({ block: 'end', behavior: 'smooth' });

      setTimeout(() => {
        setProfileData(null);
        dispatchUpdateProfile({ type: 'resetShowNote' });
      }, 3000);
    }
  }, [showNote]);

  const handleSave = () => {
    const profile = baseProfilestate
      ? {
          ...vetProfile,
          FirstName: baseProfilestate.FirstName.value,
          Name: baseProfilestate.Name.value,
          CountryId: parseInt(baseProfilestate.Country.value),
          VetNumber: baseProfilestate.VetNumber.value,
          PersonTypeId: baseProfilestate.PersonType.value,
        }
      : { ...vetProfile };
    const animalTypes = selectedAnimalTypeIds.map((Id) => ({
      AnimalTypeId: Id,
    }));
    const specialities = selectedSpecialitiesIds.map((Id) => ({
      SpecialityId: Id,
    }));

    setProfileData({
      profile: profile as VetProfile,
      animalTypes,
      specialities,
    });
  };

  const updateExpertise = (
    type: TExpertiseAction,
    checked: boolean,
    Id: number
  ) => {
    dispatchProfile({ type, checked, Id });
    if (!isChanged) {
      setIsChanged(true);
    }
  };

  return (
    <>
      <Card title="EDIT PROFILE" pageView="drawerView">
        <Typography
          variant="h4"
          align="left"
          style={{
            color: 'rgb(101, 101, 101)',
          }}
          ref={cardRef}
        >
          SUBSCRIPTION
        </Typography>
        <ProfileSubscription
          setIsLoadingStripeIntegration={setIsLoadingStripeIntegration}
        />
        <Typography
          variant="h4"
          align="left"
          style={{
            marginTop: '1em',
            marginBottom: '1em',
            color: 'rgb(101, 101, 101)',
          }}
        >
          PROFILE INFO
        </Typography>
        {showNote && (
          <Alert style={{ marginBottom: 20 }} severity="success">
            Profile updated
          </Alert>
        )}

        {vetProfile && (
          <Fragment>
            <ProfilePerson
              countryList={countries}
              personTypes={personTypes}
              profileState={vetProfile ? baseProfilestate : undefined}
              handleProfileChange={handleBaseProfileChange}
            />
            <div style={{ textAlign: 'left' }}>
              <MuiButton
                variant="contained"
                onClick={() => setChangePasswordOpen(!changePasswordOpen)}
              >
                Change password
              </MuiButton>
              <ChangePasswordDialog
                open={changePasswordOpen}
                handleClose={() => setChangePasswordOpen(false)}
              />
            </div>
          </Fragment>
        )}
        <Typography
          variant="h4"
          align="left"
          style={{
            color: 'rgb(101, 101, 101)',
            marginTop: '1em',
          }}
        >
          MY EXPERTISE AREAS
        </Typography>
        <Typography variant="h5" align="left">
          My expertise species
        </Typography>
        <ProfileExpertise
          items={animalTypes}
          selectedIds={selectedAnimalTypeIds}
          setIdChange={(checked, Id) =>
            updateExpertise('setSelectedAnimalType', checked, Id)
          }
        />
        <Typography variant="h5" align="left">
          My expertise therapy areas
        </Typography>
        <ProfileExpertise
          items={specialities}
          selectedIds={selectedSpecialitiesIds}
          setIdChange={(checked, Id) =>
            updateExpertise('setSelectedSpeciality', checked, Id)
          }
        />
        <Typography component="div" align="right">
          <FormControl variant="outlined" className={classes.formControl}>
            <Button
              title="Save"
              color="primary"
              variant="contained"
              className={classes.actionButton}
              onClick={handleSave}
              disabled={disabled || !isChanged}
            >
              SAVE
            </Button>
          </FormControl>
        </Typography>
      </Card>
      <Loader showLoader={isUpdatingProfile || isLoadingStripeIntegration} />
    </>
  );
};
