import Grid from '@mui/material/Grid';
import { Button, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import {
  formatPayload,
  getDefaultErrorState,
  getPayloadValidationResult,
} from '../utils/visitUtils';
import { Card } from './common/Card';
import {
  formatDbTimeStringToDate,
  hasValidationErrors,
} from '../utils/dateUtils';
import Loader from './Loader';
import { Patient } from '../types/Patient.type';
import { IntegrationVisit, PatientVisit } from '../types/PatientVisit.type';
import {
  usePatientInfo,
  usePatientInfoDispatch,
} from '../contexts/CurrentPatientInfoContext';
import { useAnimalTypesContext } from '../contexts/AnimalTypesContext';
import moment from 'moment';
import { DesktopDatePicker, DesktopTimePicker } from '@mui/x-date-pickers';

const initialState: StateType = {
  VisitReason: '',
  Info: '',
  Date: moment(),
  Time: moment(),
};

type StateType = {
  VisitReason: string;
  Info: string;
  Date?: moment.Moment | null;
  Time?: moment.Moment | null;
};

export const Visit = ({
  createVisit,
  updateVisit,
  patient,
  visit,
  visitSaving,
  setVisitSaving,
  isEditPage,
  integrationVisit,
  integrationVisitData,
}: {
  createVisit: any;
  updateVisit: any;
  patient: Patient | null;
  visit: PatientVisit | null;
  visitSaving: boolean;
  setVisitSaving: any;
  isEditPage: boolean;
  integrationVisit: boolean;
  integrationVisitData: IntegrationVisit | null;
}) => {
  const [payloadState, setPayloadState] = useState(initialState);

  const [errors, setErrors] = useState<any>({ errors: getDefaultErrorState() });
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);

  const { animalTypesObj } = useAnimalTypesContext();
  const { Id: patientId } = usePatientInfo();
  const dispatchPatientInfo = usePatientInfoDispatch();

  const setEditInitialState = useCallback(() => {
    if (visit) {
      setPayloadState({
        ...visit,
        Date: visit.Date ? moment(visit.Date) : null,
        Time: formatDbTimeStringToDate(visit.Time),
      });
    } else {
      setPayloadState({ ...initialState, Date: moment(), Time: moment() });
    }
  }, [visit, setPayloadState]);

  const setInitialStateFromIntegrationData = useCallback(() => {
    if (integrationVisit && integrationVisitData) {
      setPayloadState({
        ...initialState,
        VisitReason: integrationVisitData.VisitReason
          ? integrationVisitData.VisitReason
          : '',
        Info: integrationVisitData.Info ? integrationVisitData.Info : '',
        Date: integrationVisitData.Date
          ? moment(integrationVisitData.Date)
          : moment(),
        Time: integrationVisitData.Time
          ? moment(integrationVisitData.Time)
          : moment(),
      });
    }
  }, [integrationVisit, integrationVisitData, setPayloadState]);

  useEffect(setInitialStateFromIntegrationData, [
    setInitialStateFromIntegrationData,
  ]);

  useEffect(setEditInitialState, [setEditInitialState]);

  useEffect(() => {
    if (patient && patientId !== patient.Id) {
      dispatchPatientInfo({
        type: 'setPatientInfo',
        patient,
        animalTypeName: animalTypesObj[patient.AnimalTypeId].Name,
      });
    }
  }, [animalTypesObj, dispatchPatientInfo, patient, patientId]);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'VisitReason') {
      validateField(name, value);
    }
    setPayloadState({ ...payloadState, [name]: value });
  };

  const validateField = (name: string, value: any) => {
    if (isSubmitPressed) {
      const validationResult = getPayloadValidationResult({
        ...payloadState,
        [name]: value,
      });
      setErrors(validationResult);
    }
  };

  const onFormSubmit = (event: any) => {
    event.preventDefault();
    const payload = { ...payloadState };
    if (!isSubmitPressed) {
      setIsSubmitPressed(true);
    }
    const validationResult = getPayloadValidationResult(payload);
    if (hasValidationErrors(validationResult, getDefaultErrorState())) {
      setErrors(validationResult);
      return;
    }
    const formattedPayload = formatPayload(payload);
    setVisitSaving(true);
    if (isEditPage && visit) {
      updateVisit({ ...formattedPayload, Id: visit.Id });
    } else {
      if (integrationVisit) {
        formattedPayload.integrationData = {
          IntegrationType: integrationVisitData?.IntegrationType,
          IntegrationVisitId: integrationVisitData?.IntegrationVisitId,
        };
      }
      createVisit({
        ...formattedPayload,
        PatientId: patient?.Id,
        AreaSpecified: 0,
      });
    }
  };

  const handleDateInputChange = (value: moment.Moment | null) => {
    validateField('Date', value);
    setPayloadState({ ...payloadState, Date: value });
  };

  const handleTimeInputChange = (value: moment.Moment | null) => {
    validateField('Time', value);
    setPayloadState({ ...payloadState, Time: value });
  };

  if (isEditPage && (!patient || !visit)) {
    return <Loader showLoader={true} />;
  }

  if (integrationVisit && !integrationVisitData) {
    return <Loader showLoader={true} />;
  }

  return (
    <Card
      title={isEditPage ? 'EDIT VISIT' : 'ADD NEW VISIT'}
      pageView="drawerView"
      withoutMarginBottom
    >
      <Grid item container>
        <Grid item xs={1} sm={2} />
        <Grid item xs={10} sm={8}>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  name="VisitReason"
                  label="Visit reason"
                  required
                  fullWidth
                  error={errors.VisitReason}
                  variant="outlined"
                  onChange={handleInputChange}
                  value={payloadState.VisitReason}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="Info"
                  label="Info"
                  multiline
                  rows={10}
                  fullWidth
                  variant="outlined"
                  value={payloadState.Info}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DesktopDatePicker
                  value={payloadState.Date}
                  onChange={handleDateInputChange}
                  label="Date"
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ height: '100%' }}>
                <DesktopTimePicker
                  ampm={false}
                  value={payloadState.Time}
                  onChange={handleTimeInputChange}
                  label="Time"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  style={{ marginTop: '10px' }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={visitSaving}
                  onClick={(e) => onFormSubmit(e)}
                >
                  Save visit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={1} sm={2} />
        <Loader showLoader={visitSaving} />
      </Grid>
    </Card>
  );
};
