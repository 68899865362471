import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';

export const BreedsComboBox = ({
  handleChange,
  breedsInitData,
  disabled,
  value,
  handleOnInputValueChange,
  inputValue,
  helperText,
}: {
  handleChange: any;
  breedsInitData: any[];
  disabled: boolean;
  value: number;
  inputValue: string;
  handleOnInputValueChange: any;
  helperText: string | null;
}) => {
  // Sort breeds by name
  breedsInitData.sort((breedA, breedB) => {
    if (breedA.Name < breedB.Name) return -1;
    if (breedA.Name > breedB.Name) return 1;
    return 0;
  });

  const getBreedById = () => {
    const selectedBreed = breedsInitData.find((breed) => {
      return value === breed.Id;
    });
    return selectedBreed ? selectedBreed : -1;
  };

  return (
    <Autocomplete
      options={breedsInitData}
      getOptionLabel={(option) => (option.Name ? option.Name : '')}
      renderInput={(params) => (
        <TextField
          name="BreedId"
          {...params}
          variant="outlined"
          label="Type to search breeds"
          helperText={helperText}
        />
      )}
      value={getBreedById()}
      onChange={(e, value) => {
        handleChange(value === null ? -1 : value.Id);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        handleOnInputValueChange(newInputValue);
      }}
      disabled={disabled}
    />
  );
};
