import React, { useState } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {
  useOtherVisits,
  useOtherVisitsDispatch,
} from '../../contexts/OtherVisitsContext';
import { Button } from '../Button';
import { Card } from '../common/Card';
import { ListOtherVisitsContainer } from './ListOtherVisitsContainer';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflowY: 'hidden',
    alignItems: 'stretch',
  },
  container: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 1 auto',
    display: 'flex',
    marginTop: '1em',
  },
  list: {
    flex: '1 1 auto',
    overflowY: 'scroll',
  },
}));

export const CardOtherVisits = ({
  setVisitRowClicked,
}: {
  setVisitRowClicked: (clicked: boolean) => void;
}) => {
  const { classes } = useStyles();

  const { fromDate: savedFromDate, toDate: savedToDate } = useOtherVisits();
  const dispatchVisits = useOtherVisitsDispatch();
  const [fromDate, setFromDate] = useState<moment.Moment | null>(
    moment(savedFromDate!)
  );
  const [toDate, setToDate] = useState<moment.Moment | null>(
    moment(savedToDate!)
  );

  const [search, setSearch] = useState(false);

  const handleSearchVisits = () => {
    setSearch(true);
    if (!fromDate || !toDate) return;
    dispatchVisits({
      type: 'setDates',
      fromDate,
      toDate,
    });
  };

  return (
    <Card title="OTHER VISITS" pageView="drawerView" withoutMarginBottom>
      <Container className={classes.wrapper}>
        <Grid container className={classes.container} spacing={1}>
          <Grid container item className={classes.content} alignItems="center">
            <Grid item xs={12} sm={5}>
              <DesktopDatePicker
                value={fromDate}
                onChange={setFromDate}
                label="From date"
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <DesktopDatePicker
                value={toDate}
                onChange={setToDate}
                label="To date"
              />
            </Grid>
            <Grid item xs={12} sm={2} style={{ marginTop: '1em' }}>
              <Button
                title="Search visits"
                color="primary"
                variant="contained"
                disabled={toDate?.isBefore(fromDate)}
                onClick={handleSearchVisits}
              >
                Search
              </Button>
            </Grid>
          </Grid>

          <Grid container item className={classes.content}>
            <Grid item className={classes.list}>
              <ListOtherVisitsContainer
                search={search}
                resetSearch={() => setSearch(false)}
                setVisitRowClicked={setVisitRowClicked}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
};
