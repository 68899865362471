import React from 'react';
import Typography from '@mui/material/Typography';

import { TreatmentInfo } from '../../types/Diagnose.type';
import { TypographyVariant } from '@mui/material/styles';

const splitAndRemoveEmpty = (text: string) => {
  const splitted = text.split(/\r?\n/g);
  return splitted.filter((split) => split !== '');
};

const renderSplittedTexts = (
  splittedData: string[],
  variant: TypographyVariant
) => {
  return splittedData.map((text, i) => (
    <Typography variant={variant} style={{ marginBottom: '0.5em' }} key={i}>
      {text}
    </Typography>
  ));
};

export const splitAndRender = (
  text: string | null,
  variant: TypographyVariant = 'body1'
) => {
  if (!text) {
    return text;
  }
  const splitted = splitAndRemoveEmpty(text);
  return renderSplittedTexts(splitted, variant);
};

export const DiseaseInfo = ({
  treatmentInfo,
}: {
  treatmentInfo: TreatmentInfo;
}) => {
  return (
    <>
      <Typography variant="subtitle1" align="left">
        DISEASE INFO
      </Typography>
      <Typography align="left" paragraph>
        {splitAndRender(treatmentInfo.Info)}
      </Typography>
      {treatmentInfo.TreatmentInfo &&
        treatmentInfo.TreatmentInfo.length > 0 && (
          <Typography variant="subtitle1" align="left">
            GENERAL TREATMENT INFO
          </Typography>
        )}
      <Typography align="left" paragraph>
        {splitAndRender(treatmentInfo.TreatmentInfo)}
      </Typography>
      {treatmentInfo.FollowUp && treatmentInfo.FollowUp.length > 0 && (
        <Typography variant="subtitle1" align="left">
          FOLLOW UP
        </Typography>
      )}
      <Typography align="left" paragraph>
        {splitAndRender(treatmentInfo.FollowUp)}
      </Typography>
    </>
  );
};
