import React from 'react';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

export default function SuspectedConfirmedSwitch({
  Id,
  isConfirmed,
  style,
  handleSwitch,
}: {
  Id: number;
  isConfirmed: boolean;
  style?: any;
  handleSwitch: (Id: number, checked: boolean) => void;
}) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    handleSwitch(Id, event.target.checked);
  };

  return (
    <Typography component="div">
      <Grid
        component="label"
        container
        alignItems="center"
        spacing={1}
        style={{ ...style }}
        // prevent accordion from opening / closing
        onClick={(e: { stopPropagation: () => void }) => e.stopPropagation()}
      >
        <Grid item>Suspected</Grid>
        <Grid item>
          <Switch
            checked={isConfirmed}
            onChange={handleChange}
            name="confirmed"
            color="primary"
            size="small"
          />
        </Grid>
        <Grid item>Confirmed</Grid>
      </Grid>
    </Typography>
  );
}
