import React, { ChangeEvent } from 'react';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import { convertKgToLbs, convertLbsToKg } from '../../utils/insertPatientUtils';
import { NumberOfUnitsRow } from './DrugNumberOfUnits/NumberOfUnitsRow';
import { DrugAmountRow } from './DrugAmount/DrugAmountRow';
import { DrugStrengthRow } from './DrugStrength/DrugStrengthRow';
import {
  DrugAmountState,
  DrugStrengthState,
  NumberOfUnitsState,
  PatientWeightState,
} from '../../types/DrugDosages.type';

type DrugDosageProps = {
  setPatientWeight: (value: PatientWeightState) => void;
  patientWeight: PatientWeightState;
  setPatientWeightLbs: (value: PatientWeightState) => void;
  patientWeightLbs: PatientWeightState;
  numberOfUnitsState: NumberOfUnitsState;
  handleInputChangeNumberOfUnits: (
    event: ChangeEvent<HTMLInputElement>
  ) => void;
  handleCalculateClickNumberOfUnits: () => void;
  drugAmountState: DrugAmountState;
  handleInputChangeDrugAmount: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCalculateClickDrugAmount: () => void;
  drugStrengthState: DrugStrengthState;
  handleInputChangeDrugStrength: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCalculateClickDrugStrength: () => void;
};

export const DrugDosageCalculator = ({
  setPatientWeight,
  patientWeight,
  setPatientWeightLbs,
  patientWeightLbs,
  numberOfUnitsState,
  handleInputChangeNumberOfUnits,
  handleCalculateClickNumberOfUnits,
  drugAmountState,
  handleInputChangeDrugAmount,
  handleCalculateClickDrugAmount,
  drugStrengthState,
  handleInputChangeDrugStrength,
  handleCalculateClickDrugStrength,
  isCurrentPatient,
  mobile,
}: DrugDosageProps & { isCurrentPatient: boolean; mobile: boolean }) => {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={2}>
          <TextField
            name="patientWeight"
            label="Weight (kg)"
            fullWidth
            variant="outlined"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setPatientWeight({
                ...patientWeight,
                value: event.target.value,
              });
              setPatientWeightLbs({
                ...patientWeightLbs,
                value: convertKgToLbs(event.target.value),
              });
            }}
            value={patientWeight.value}
            error={patientWeight.error}
            type="number"
            disabled={isCurrentPatient}
          />
        </Grid>
        <Grid item xs={8} sm={2}>
          <TextField
            name="patientWeightLbs"
            label="Weight (lbs)"
            fullWidth
            variant="outlined"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setPatientWeightLbs({
                ...patientWeightLbs,
                value: event.target.value,
              });
              setPatientWeight({
                ...patientWeight,
                value: convertLbsToKg(event.target.value),
              });
            }}
            value={patientWeightLbs.value}
            error={patientWeight.error}
            type="number"
            disabled={isCurrentPatient}
          />
        </Grid>
      </Grid>
      <NumberOfUnitsRow
        state={numberOfUnitsState}
        handleInputChange={handleInputChangeNumberOfUnits}
        handleCalculateClick={handleCalculateClickNumberOfUnits}
        mobile={mobile}
      />
      <DrugAmountRow
        state={drugAmountState}
        handleInputChange={handleInputChangeDrugAmount}
        handleCalculateClick={handleCalculateClickDrugAmount}
        mobile={mobile}
      />
      <DrugStrengthRow
        state={drugStrengthState}
        handleInputChange={handleInputChangeDrugStrength}
        handleCalculateClick={handleCalculateClickDrugStrength}
        mobile={mobile}
      />
    </React.Fragment>
  );
};
