import { Button, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import API from '../../api/Api';
import { ROUTE_PAYMENT } from '../../config/routes.config';
import { useSubscriptionContext } from '../../contexts/SubscriptionContext';
import { useLocale } from '../../hooks/useLocale';
import {
  calcDifferenceToToday,
  formatDbDateStringToUi,
} from '../../utils/dateUtils';

const useStyles = makeStyles()((theme: Theme) => ({
  textDiv: {
    fontSize: '0.875rem',
    lineHeight: '1.43',
    marginBottom: '0.5em',
    color: 'grey',
  },
  subButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export const ProfileSubscription = ({
  setIsLoadingStripeIntegration,
}: {
  setIsLoadingStripeIntegration: (isLoading: boolean) => void;
}) => {
  const { classes } = useStyles();
  const locale = useLocale();
  const { subscription } = useSubscriptionContext();

  if (!subscription) {
    return null;
  }
  const handleOpenCustomerPortal = async () => {
    setIsLoadingStripeIntegration(true);
    try {
      if (subscription.id) {
        const res: any = await API.createStripePortalSession(subscription.id);
        const url = res.body.url as string;
        window.location.assign(url);
      }
    } catch (e: any) {
      console.log(e);
    } finally {
      setIsLoadingStripeIntegration(false);
    }
  };

  return (
    <>
      <Typography component="div" align="left" className={classes.textDiv}>
        {!subscription.hasSubscription && subscription.status === 0 ? (
          <Fragment>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={ROUTE_PAYMENT}
              className={classes.subButton}
            >
              Activate subscription
            </Button>
            <Typography>
              You have no active subscription but you can still edit your
              account information.
            </Typography>
          </Fragment>
        ) : subscription.status === 3 ? (
          <Fragment>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={ROUTE_PAYMENT}
              className={classes.subButton}
            >
              Activate subscription
            </Button>
            <Typography>
              {calcDifferenceToToday(subscription.validUntil) >= 0
                ? calcDifferenceToToday(subscription.validUntil) === 0
                  ? 'You are on your last day of the trial subscription'
                  : `You have ${
                      calcDifferenceToToday(subscription.validUntil) + 1
                    } days
              until your trial subscription ends`
                : 'You have no active subscription but you can still edit your account information.'}
            </Typography>
          </Fragment>
        ) : (
          <>
            <Typography>
              Your subscription is valid until:{' '}
              {formatDbDateStringToUi(subscription.validUntil, locale)}
            </Typography>
            <Typography className={classes.textDiv}>
              Please wait until your subscription runs out to renew it
            </Typography>
          </>
        )}
      </Typography>
      {subscription.status === 1 && (
        <>
          <Typography align="left" className={classes.textDiv}>
            The application will check the subscription status monthly via
            Stripe. If you have an active Stripe code which is valid for
            multiple months, the subscription is automatically renewed monthly
            without a charge as long as the code is valid. If you cancel your
            subscription, you will lose the rest of the free of charge months
            enabled by the code.
          </Typography>
          <Typography align="left">
            <Button
              title="Manage billing"
              variant="contained"
              onClick={handleOpenCustomerPortal}
            >
              Manage billing
            </Button>
          </Typography>
        </>
      )}
    </>
  );
};
