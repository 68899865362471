import { ROUTE_PROFILE_INFO, ROUTE_SUPPORT } from '../../config/routes.config';
import { MenuItemListProps } from './menuItem.types';

export const ITEM_SPACING = 3;
export const DRAWER_WIDTH = 240;

export const menuItems: MenuItemListProps = [
  {
    title: 'My profile',
    to: ROUTE_PROFILE_INFO,
  },
  {
    title: 'Support',
    to: ROUTE_SUPPORT,
  },
];
