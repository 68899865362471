import { Divider, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { Fragment, useRef, useState } from 'react';
import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../../contexts/DiagnosesPageContext';
import { useSymptoms } from '../../contexts/SymptomsContext';
import { DiseaseSymptom } from '../../types/Diagnose.type';
import { SelectSymptomButton } from './SelectSymptomButton';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { HtmlTooltip } from '../common/HtmlToolTip';
import { Sources } from '../common/Sources';
import SymptomsWithAddedInfoTooltip from './SymptomsWithAddedInfoTooltip';

export default function DialogSymptoms({
  title,
  symptomsList,
  showAddButton,
  updateDifferentials,
}: {
  title: string;
  symptomsList: DiseaseSymptom[];
  showAddButton: boolean;
  updateDifferentials?: () => void;
}) {
  const { symptoms, symptomsObj } = useSymptoms();
  const { selectedSymptomsObj } = useDiagnosesPage();
  const dispatch = useDiagnosesPageDispatch();
  const mobile = useMediaQuery('(max-width:600px)');

  const addedInfoDiseaseSymptoms = symptomsList.some(
    (symptom) => symptom.ReferenceText !== null
  );
  const [open, setOpen] = useState(false);
  const previousSelectedSymptomsObj = useRef<{
    [x: string]: boolean;
  }>(selectedSymptomsObj);

  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    const jsonPrevious = JSON.stringify(previousSelectedSymptomsObj.current);
    const jsonCurrent = JSON.stringify(selectedSymptomsObj);
    previousSelectedSymptomsObj.current = selectedSymptomsObj;
    if (jsonPrevious !== jsonCurrent && updateDifferentials) {
      updateDifferentials();
    }
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleAddSymptom = (symptomId: number) => {
    dispatch({ type: 'addSymptom', symptomId });
  };

  const handleRemoveSymptom = (symptomId: number) => {
    dispatch({ type: 'removeSymptom', symptomId });
  };

  return (
    <div>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Button
          color="primary"
          onClick={handleClickOpen('paper')}
          style={{ textAlign: 'left' }}
        >
          {title}
        </Button>
        {addedInfoDiseaseSymptoms && <SymptomsWithAddedInfoTooltip />}
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        maxWidth={showAddButton ? 'lg' : 'md'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        disableEscapeKeyDown={showAddButton ? true : false}
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent dividers={mobile ? false : scroll === 'paper'}>
          {symptomsList.length === 0 ? (
            'No symptoms in this category'
          ) : (
            <Grid container spacing={3}>
              {showAddButton && (
                <Grid item>
                  <Typography variant="subtitle1">
                    {
                      symptomsList.filter(
                        (x) => x.Symptom.Id in selectedSymptomsObj
                      ).length
                    }
                    {' out of ' + symptomsList.length + ' ' + title + ' chosen'}
                  </Typography>
                </Grid>
              )}
              {symptomsList.map((diseaseSymptom) => {
                const selected =
                  showAddButton &&
                  diseaseSymptom.Symptom.Id in selectedSymptomsObj;
                return (
                  <Fragment key={diseaseSymptom.Id}>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={12} sm={5}>
                        <Typography
                          variant="body1"
                          style={{
                            whiteSpace: 'pre-line',
                            fontWeight: selected ? 'bold' : 'normal',
                          }}
                        >
                          {
                            symptoms[symptomsObj[diseaseSymptom.Symptom.Id]]
                              .combinedName
                          }
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        {diseaseSymptom.ReferenceText && (
                          <Fragment>
                            <HtmlTooltip
                              title={
                                <Typography color="inherit">
                                  Additional information
                                </Typography>
                              }
                            >
                              <NotificationImportantIcon
                                style={{ fill: '#00cd87', margin: '0.5em' }}
                              />
                            </HtmlTooltip>
                            <div>
                              <Typography
                                variant="body2"
                                style={{
                                  whiteSpace: 'pre-line',
                                  fontWeight: selected ? 'bold' : 'normal',
                                }}
                              >
                                {diseaseSymptom.ReferenceText}
                              </Typography>
                              <Sources
                                sources={
                                  diseaseSymptom.Source_DiseaseSymptoms_AddedInfos
                                }
                                marginBottom={false}
                              />
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Grid item xs={6} sm={showAddButton ? 2 : 3}>
                        <Typography
                          variant="body2"
                          style={
                            mobile
                              ? {
                                  textAlign: 'left',
                                  fontWeight: selected ? 'bold' : 'normal',
                                  marginTop: '15px',
                                }
                              : {
                                  textAlign: 'right',
                                  fontWeight: selected ? 'bold' : 'normal',
                                }
                          }
                        >
                          {diseaseSymptom.Incidence.Name}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={2} style={{ textAlign: 'right' }}>
                        {showAddButton && (
                          <SelectSymptomButton
                            selected={selected}
                            symptomId={diseaseSymptom.Symptom.Id}
                            addSymptom={handleAddSymptom}
                            deleteSymptom={handleRemoveSymptom}
                          />
                        )}
                      </Grid>
                      {mobile && (
                        <Grid item xs={12} style={{ marginTop: '10px' }}>
                          <Divider />
                        </Grid>
                      )}
                    </Grid>
                  </Fragment>
                );
              })}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
