import axios from 'axios';
import React, { useEffect, useReducer } from 'react';
import { useAuthUser } from '../../contexts/AuthUserContext';
import { defaultUrl, UrlParams } from '../../hooks/useRequestData';
import { profileReducer } from '../../reducers/profile/profile.reducer';
import {
  DefaultProfileState,
  ProfileParamsArr,
} from '../../reducers/profile/profile.state';
import Loader from '../Loader';
import { ProfileContent } from './ProfileContent';

export function fetchData({
  url = defaultUrl,
  params,
}: {
  url?: string;
  params?: UrlParams;
}) {
  const req = axios.request({
    method: 'get',
    url,
    ...(params && { params }),
  });

  return req;
}

export const ProfileContainer = () => {
  const [profileState, dispatchProfile] = useReducer(
    profileReducer,
    DefaultProfileState
  );
  const { signOut } = useAuthUser();

  const needToFetch =
    !profileState.vetProfile &&
    !profileState.isLoadingParams &&
    !profileState.errorParams;

  useEffect(() => {
    async function fetchAllData() {
      try {
        dispatchProfile({
          type: 'request',
        });

        const responseData = await axios.all([
          fetchData({ url: 'profile' }),
          fetchData({ params: { modelName: 'Specialities' } }),
          fetchData({ params: { modelName: 'Countries' } }),
          fetchData({ params: { modelName: 'PersonTypes' } }),
        ]);

        const result = responseData.map((response, index) =>
          index === 0 ? response.data : response.data.flat()
        );

        dispatchProfile({
          type: 'success',
          responseData: result as ProfileParamsArr,
        });
      } catch (err: any) {
        console.log(err);
        // Check if api is returning NotAuthorizedException and sign user out
        if (err.code === 'NotAuthorizedException') {
          await signOut();
        } else {
          dispatchProfile({
            type: 'failure',
            error: err.message,
          });
        }
      }
    }
    if (needToFetch) {
      fetchAllData();
    }
  }, [needToFetch, signOut]);

  if (profileState.errorParams) {
    dispatchProfile({ type: 'reset' });
    throw profileState.errorParams;
  }

  return (
    <>
      <ProfileContent {...profileState} dispatchProfile={dispatchProfile} />
      <Loader showLoader={profileState.isLoadingParams} />
    </>
  );
};
