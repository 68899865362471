import { Reducer } from 'react';
import {
  ProfileState,
  FetchParamsAction,
  DefaultProfileState,
  UpdateProfileAction,
  UpdateProfileState,
} from './profile.state';

export const profileReducer: Reducer<ProfileState, FetchParamsAction> = (
  state,
  action
): ProfileState => {
  switch (action.type) {
    case 'reset': {
      return { ...DefaultProfileState };
    }

    case 'request': {
      return { ...DefaultProfileState, isLoadingParams: true };
    }

    case 'failure': {
      return { ...DefaultProfileState, errorParams: action.error };
    }

    case 'success': {
      const vetProfile = action.responseData[0];
      const specialities = action.responseData[1];
      const countries = action.responseData[2];
      const personTypes = action.responseData[3];

      const selectedSpecialitiesIds: number[] = vetProfile.Specialities.map(
        (item) => item.Id
      );
      const selectedAnimalTypeIds: number[] = vetProfile.AnimalTypes.map(
        (item) => item.Id
      );

      return {
        ...DefaultProfileState,
        params: action.responseData,
        vetProfile,
        specialities,
        countries,
        personTypes,
        selectedSpecialitiesIds,
        selectedAnimalTypeIds,
      };
    }

    case 'setSelectedSpeciality': {
      const newSelectedSpecialitiesIds = [...state.selectedSpecialitiesIds];
      const index = state.selectedSpecialitiesIds.indexOf(action.Id);
      if (action.checked && index === -1) {
        newSelectedSpecialitiesIds.push(action.Id);
      } else if (!action.checked && index > -1) {
        newSelectedSpecialitiesIds.splice(index, 1);
      }

      return { ...state, selectedSpecialitiesIds: newSelectedSpecialitiesIds };
    }

    case 'setSelectedAnimalType': {
      const newSelectedAnimalTypeIds = [...state.selectedAnimalTypeIds];
      const index = state.selectedAnimalTypeIds.indexOf(action.Id);
      if (action.checked && index === -1) {
        newSelectedAnimalTypeIds.push(action.Id);
      } else if (!action.checked && index > -1) {
        newSelectedAnimalTypeIds.splice(index, 1);
      }

      return { ...state, selectedAnimalTypeIds: newSelectedAnimalTypeIds };
    }

    default:
      return state;
  }
};

export const updateProfileReducer: Reducer<
  UpdateProfileState,
  UpdateProfileAction
> = (state, action): UpdateProfileState => {
  switch (action.type) {
    case 'reset': {
      const newState = { ...state };
      if ('errorProfile' in newState) {
        delete newState.errorProfile;
      }
      return { ...newState, showNote: false };
    }
    case 'request': {
      return { ...state, isUpdatingProfile: true, showNote: false };
    }
    case 'failure': {
      return {
        ...state,
        isUpdatingProfile: false,
        errorProfile: action.error,
      };
    }
    case 'success': {
      action.options.dispatch({
        type: 'updateVeterinarian',
        veterinarian: action.options.veterinarian,
      });
      return { ...state, isUpdatingProfile: false, showNote: true };
    }
    case 'resetShowNote': {
      return { ...state, showNote: false };
    }
    default:
      return state;
  }
};
