import React, { Fragment, useEffect, useRef } from 'react';
import { Alert, useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../../contexts/DiagnosesPageContext';
import { Card } from '../common/Card';
import DiagnoseAccordionList from './DiagnoseAccordionList';
import { ProbabilityTooltip } from './ProbabilityTooltip';

const useStyles = makeStyles()((theme: Theme) => ({
  subtitle: {
    marginBottom: 0,
  },
}));

export default function CardDiagnoses({
  highlightBreedId,
  isFocused,
  updateDifferentials,
}: {
  highlightBreedId?: number | undefined;
  isFocused: boolean;
  updateDifferentials: () => void;
}) {
  const { classes } = useStyles();
  const { diagnoses, dontSelect, selectedSymptoms } = useDiagnosesPage();
  const dispatchDiagnoses = useDiagnosesPageDispatch();
  const mobile = useMediaQuery('(max-width:600px)');

  const ref = useRef<HTMLDivElement>(null);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    event.stopPropagation();
    dispatchDiagnoses({ type: 'setDontSelectDiagnoses', checked });
  };

  useEffect(() => {
    if (diagnoses && isFocused) {
      ref.current?.scrollIntoView();
    }
  }, [diagnoses, isFocused]);

  return (
    <div ref={ref}>
      <Card title="DIAGNOSES" pageView="drawerView" maxWidth>
        {selectedSymptoms.length === 0 && (
          <Typography align="left">
            Select symptoms to search for possible diagnoses
          </Typography>
        )}
        {selectedSymptoms.length > 0 && !diagnoses && (
          <Typography align="left">
            Click <b>Search differentials</b> button to see the list of possible
            diagnoses
          </Typography>
        )}
        {diagnoses && diagnoses.length === 0 && (
          <Typography align="left">
            No potential diagnoses found. Try marking only the most relevant
            symptoms as &quot;required&quot; or selecting
            &quot;unspecified&quot; symptom instead of a more detailed symptom
            to get more potential diagnoses.
          </Typography>
        )}
        {diagnoses && diagnoses?.length > 0 && (
          <Grid container>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
              <Typography variant="h4">
                {diagnoses.length} potential diagnose(s) found
              </Typography>
              {diagnoses.length === 100 ? (
                <Alert severity="info">
                  Showing 100 most probable diagnoses. Please mark some findings
                  as &quot;required&quot; to see the most relevant diagnoses.
                </Alert>
              ) : null}
              <Typography variant="subtitle1" className={classes.subtitle}>
                Here you can click to open the diagnoses for more information
                and related symptoms that can also be added there. Once you have
                selected the diagnose(s), you may continue to treatments.
              </Typography>
            </Grid>
            <Grid container item xs={12} style={{ textAlign: 'left' }}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dontSelect}
                      onChange={handleChange}
                      name="checkedDontSelect"
                      color="primary"
                    />
                  }
                  label="I don't want to select diagnoses yet"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              {mobile ? (
                <Fragment>
                  <Grid
                    item
                    xs={12}
                    style={{
                      textAlign: 'left',
                      marginTop: '15px',
                      marginBottom: '15px',
                    }}
                  >
                    Order: Most relevant
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={2} style={{ textAlign: 'left' }}>
                    Probability:
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Grid
                    item
                    xs={3}
                    sm={1}
                    style={{ textAlign: 'left', fontSize: '0.875rem' }}
                  >
                    Probability: <ProbabilityTooltip />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ textAlign: 'left', fontSize: '0.875rem' }}
                  >
                    Order: Most relevant
                  </Grid>
                </Fragment>
              )}
            </Grid>
            <Grid item xs={12}>
              <DiagnoseAccordionList
                highlightBreedId={highlightBreedId}
                updateDifferentials={updateDifferentials}
              />
            </Grid>
          </Grid>
        )}
      </Card>
    </div>
  );
}
