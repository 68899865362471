import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { currentPatientInfoReducer } from '../reducers/currentPatientInfo/current.patient.info.reducer';
import {
  CurrentPatientInfoState,
  CurrentPatientInfoAction,
  CurrentPatientInfoDefault,
} from '../reducers/currentPatientInfo/current.patient.info.state';

const CurrentPatientInfoContext = createContext<CurrentPatientInfoState>(
  {} as CurrentPatientInfoState
);
const CurrentPatientInfoDispatch = createContext<
  Dispatch<CurrentPatientInfoAction>
>(() => {});

export const CurrentPatientInfoProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(
    currentPatientInfoReducer,
    CurrentPatientInfoDefault
  );

  return (
    <CurrentPatientInfoContext.Provider value={state}>
      <CurrentPatientInfoDispatch.Provider value={dispatch}>
        {children}
      </CurrentPatientInfoDispatch.Provider>
    </CurrentPatientInfoContext.Provider>
  );
};

export const usePatientInfo = (): CurrentPatientInfoState => {
  return useContext(CurrentPatientInfoContext);
};

export const usePatientInfoDispatch = () => {
  return useContext(CurrentPatientInfoDispatch);
};
