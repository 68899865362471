import React from 'react';
import SubsequentDisease from './SubsequentDisease';
import { useTreatmentsPage } from '../../contexts/TreatmentsPageContext';
import { useDiagnosesPage } from '../../contexts/DiagnosesPageContext';

export const SubsequentDiseasesList = ({
  canSelect,
}: {
  canSelect: boolean;
}) => {
  const { subsequentTreatmentInfos, selectedSubsequentDiseases } =
    useTreatmentsPage();
  const { selectedDiagnoses } = useDiagnosesPage();

  if (!subsequentTreatmentInfos) {
    if (canSelect) {
      return null;
    } else {
      return <>No subsequent conditions</>;
    }
  }

  const subsequentElements: JSX.Element[] = [];
  subsequentTreatmentInfos.forEach((treatmentInfo) => {
    if (
      selectedDiagnoses.indexOf(treatmentInfo.Id) === -1 &&
      (canSelect
        ? selectedSubsequentDiseases.indexOf(treatmentInfo.Id) > -1
        : selectedSubsequentDiseases.indexOf(treatmentInfo.Id) === -1)
    ) {
      subsequentElements.push(
        <SubsequentDisease
          key={treatmentInfo.Id}
          treatmentInfo={treatmentInfo}
          canSelect={canSelect}
        />
      );
    }
  });

  return <>{subsequentElements}</>;
};
