import React, { PropsWithChildren, useContext } from 'react';
import { SignUpParams } from '@aws-amplify/auth/lib-esm/types';
import useAuthentication from '../hooks/useAuthentication';
import { User } from '../types/user.type';
import { SubscriptionProvider } from './SubscriptionContext';

const AuthUserContext = React.createContext<{
  user: User;
  signIn: () => void;
  signOut: () => void;
  login: ({ username, password, clientMetadata }: SignUpParams) => void;
  loginCustomChallenge: (challenge: string) => void;
}>({
  user: { isAuthenticated: false },
  signIn: () => {},
  signOut: () => {},
  login: ({ username, password, clientMetadata }: SignUpParams) => {},
  loginCustomChallenge: (challenge: string) => {},
});

export const AuthUserProvider = ({ children }: PropsWithChildren<{}>) => {
  const value = useAuthentication();

  return (
    <AuthUserContext.Provider value={value}>
      <SubscriptionProvider>{children}</SubscriptionProvider>
    </AuthUserContext.Provider>
  );
};

export const useAuthUser = () => {
  return useContext(AuthUserContext);
};

export const PMSIntegrationUserProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const user: User = {
    isAuthenticated: true,
    userName: 'pmsintegrationuser',
    userEmail: '',
  };
  const value = {
    signIn: () => {},
    signOut: () => {},
    login: () => {},
    loginCustomChallenge: () => {},
    user,
  };

  return (
    <AuthUserContext.Provider value={value}>
      {children}
    </AuthUserContext.Provider>
  );
};
