import {
  Symptom,
  SymptomType,
  SymptomTypeCategory,
} from '../../types/Symptom.type';
import {
  findParentObj,
  getCombinedName,
  getSubSymptomId,
  sortCategories,
  sortSymptomsByName,
  sortTypes,
} from '../../utils/symptomsUtils';
import {
  SymptomsState,
  SymptomsAction,
  SymptomsDefault,
  ParentSymptom,
  SymptomOption,
} from './symptoms.state';

type SymptomOrTypeOrCategory =
  | Symptom[]
  | SymptomType[]
  | SymptomTypeCategory[];

const determineCategory = (
  toBeDetermined: SymptomOrTypeOrCategory
): toBeDetermined is SymptomTypeCategory[] => {
  if ((toBeDetermined as SymptomTypeCategory[])[0].Order) {
    return true;
  }
  return false;
};

const determineSymptomType = (
  toBeDetermined: SymptomOrTypeOrCategory
): toBeDetermined is SymptomType[] => {
  if ((toBeDetermined as SymptomType[])[0].SymptomTypeCategoryId) {
    return true;
  }
  return false;
};

const determineSymptom = (
  toBeDetermined: SymptomOrTypeOrCategory
): toBeDetermined is Symptom[] => {
  if ((toBeDetermined as Symptom[])[0].TypeId) {
    return true;
  }
  return false;
};

export const symptomsListReducer = (
  state: SymptomsState,
  action: SymptomsAction
): SymptomsState => {
  switch (action.type) {
    case 'reset': {
      return { ...SymptomsDefault };
    }

    case 'request': {
      return { ...state, isLoading: true };
    }

    case 'success': {
      if (determineSymptom(action.responseData)) {
        const symptoms = action.responseData;

        const symptomsObj: { [x: string]: number } = {};
        const symptomsByType = { ...state.symptomsByType };

        symptoms.forEach((symptom: Symptom, index: number) => {
          const name = getCombinedName(
            action.responseData as Symptom[],
            symptom,
            ''
          );

          symptom.combinedName = `${
            state.categories[
              state.categoriesObj[
                state.symptomTypes[state.symptomTypesObj[symptom.TypeId]]
                  .SymptomTypeCategoryId
              ]
            ].Name
          }: ${
            state.symptomTypes[state.symptomTypesObj[symptom.TypeId]].Name
          }\n${name}`;

          symptomsObj[symptom.Id] = index;
          if (symptom.MainSymptomId === null) {
            if (!symptomsByType[symptom.TypeId].children) {
              symptomsByType[symptom.TypeId].children = {};
            }
            symptomsByType[symptom.TypeId].children![symptom.Id] = {
              Name: symptom.Name,
            };
          }
        });

        symptoms.forEach((symptom: Symptom) => {
          if (symptom.MainSymptomId) {
            const parentObj: ParentSymptom | null = findParentObj(
              symptoms,
              symptomsObj,
              symptomsByType[symptom.TypeId].children!,
              symptom.MainSymptomId
            );

            if (parentObj) {
              if (!parentObj[symptom.MainSymptomId].children) {
                parentObj[symptom.MainSymptomId].children = {};
              }
              if (!parentObj[symptom.MainSymptomId].children![symptom.Id])
                parentObj[symptom.MainSymptomId].children![symptom.Id] = {
                  Name: symptom.Name,
                };
            }
          }
        });

        const subSymptomIds: number[] = [];
        Object.entries(symptomsByType).forEach(([key, value]) => {
          getSubSymptomId(parseInt(key), subSymptomIds, value);
        });

        let subSymptomOptions: SymptomOption[] = [];
        subSymptomIds.forEach((Id) => {
          if (symptoms[symptomsObj[Id]]) {
            subSymptomOptions.push({
              Id,
              Name: symptoms[symptomsObj[Id]].combinedName!,
            });
          }
        });
        subSymptomOptions = sortSymptomsByName(
          subSymptomOptions,
          state.symptomTypesObj
        );

        return {
          ...state,
          isLoading: false,
          symptoms,
          symptomsObj,
          symptomsByType,
          subSymptomOptions,
        };
      } else if (determineSymptomType(action.responseData)) {
        const symptomTypes = sortTypes(action.responseData);

        const symptomTypesObj: { [x: string]: number } = {};
        const symptomsByType: ParentSymptom = {};

        symptomTypes.forEach((type: SymptomType, index: number) => {
          symptomTypesObj[type.Id] = index;

          symptomsByType[type.Id] = {
            Name: `${
              state.categories[state.categoriesObj[type.SymptomTypeCategoryId]]
                .Name || 'unknown categoryId' + type.SymptomTypeCategoryId
            }: ${type.Name}`,
          };
        });

        return {
          ...state,
          isLoading: false,
          symptomTypes,
          symptomTypesObj,
          symptomsByType,
        };
      } else if (determineCategory(action.responseData)) {
        const categories = sortCategories(action.responseData);

        const categoriesObj: { [x: string]: number } = {};
        categories.forEach((category: SymptomTypeCategory, index: number) => {
          categoriesObj[category.Id] = index;
        });

        return {
          ...state,
          isLoading: false,
          categories,
          categoriesObj,
        };
      } else {
        return {
          ...state,
          error: 'Wrong data type was fetched.',
        };
      }
    }

    case 'failure':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
