import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  Symptom,
  SymptomType,
  SymptomTypeCategory,
} from '../../types/Symptom.type';

import {
  getSymptomTypeName,
  getSymptomTypeCategoryName,
} from '../../utils/summaryUtils';
import { splitSymptomNameInTwoLines } from '../../utils/symptomsUtils';

const useStyles = makeStyles()((theme: Theme) => ({
  symptomTopic: {
    fontFamily: 'Arial',
    fontSize: '10px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.6',
    letterSpacing: '1.5px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));

export default function SummarySymptomRow({
  symptom,
  categories,
  symptomTypes,
  mobile,
}: {
  symptom: Symptom;
  categories: SymptomTypeCategory[];
  symptomTypes: SymptomType[];
  mobile: boolean;
}) {
  const { classes } = useStyles();

  const nameLines = splitSymptomNameInTwoLines(symptom.combinedName!);
  return (
    <Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {mobile ? (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography align="left" className={classes.symptomTopic}>
                TYPE
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="left">
                {getSymptomTypeName(symptom, symptomTypes)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="left" className={classes.symptomTopic}>
                CATEGORY
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="left">
                {getSymptomTypeCategoryName(symptom, categories, symptomTypes)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="left" className={classes.symptomTopic}>
                SYMPTOM
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              <Typography align="left">{nameLines.secondLine}</Typography>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item xs={12} sm={4}>
              <Typography align="left" className={classes.symptomTopic}>
                TYPE
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="left" className={classes.symptomTopic}>
                CATEGORY
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="left" className={classes.symptomTopic}>
                SYMPTOM
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="left">
                {getSymptomTypeName(symptom, symptomTypes)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="left">
                {getSymptomTypeCategoryName(symptom, categories, symptomTypes)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="left">{nameLines.secondLine}</Typography>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
}
