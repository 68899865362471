/* eslint-disable react/react-in-jsx-scope */
export const About = () => (
  <p>
    Visit{' '}
    <a href="https://www.gekkovet.com/vet" target="_blank" rel="noreferrer">
      www.gekkovet.com/vet
    </a>{' '}
    for more information
  </p>
);
