import React from 'react';
import {
  Button as GeneralButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import {
  ROUTE_NEW_VISIT,
  ROUTE_PATIENT_SUMMARY,
} from '../../config/routes.config';
import { useAnimalTypesContext } from '../../contexts/AnimalTypesContext';
import { Patient } from '../../types/Patient.type';
import { useWorkContext } from '../../contexts/WorkContext';
import { usePatientInfoDispatch } from '../../contexts/CurrentPatientInfoContext';
import { useDiagnosesPageDispatch } from '../../contexts/DiagnosesPageContext';
import { useTreatmentsPageDispatch } from '../../contexts/TreatmentsPageContext';
import { useDosagesPageDispatch } from '../../contexts/DosagesPageContext';
import { useDrugProductsDispatch } from '../../contexts/DrugProductsContext';

type ButtonTitle = 'View' | 'Add visit' | 'Choose';

const Button = ({
  buttonTitle,
  patient,
  matchesSM,
}: {
  buttonTitle: ButtonTitle;
  patient: Patient;
  matchesSM: boolean;
}) => {
  const { setPatient, setVisit } = useWorkContext();
  const { animalTypesObj } = useAnimalTypesContext();
  const dispatchPatientInfo = usePatientInfoDispatch();
  const dispatchDiagnoses = useDiagnosesPageDispatch();
  const dispatchTreatments = useTreatmentsPageDispatch();
  const dispatchDosages = useDosagesPageDispatch();
  const dispatchDrugProducts = useDrugProductsDispatch();

  const handleButtonClick = () => {
    setVisit(null);
    setPatient(patient);
    dispatchPatientInfo({
      type: 'setPatientInfo',
      patient,
      animalTypeName: animalTypesObj[patient.AnimalTypeId].Name,
    });
    dispatchDiagnoses({ type: 'resetAll' });
    dispatchTreatments({ type: 'reset' });
    dispatchDosages({ type: 'reset' });
    dispatchDrugProducts({ type: 'resetAll' });
  };

  const pathname = generatePath(
    buttonTitle === 'View' ? ROUTE_PATIENT_SUMMARY : ROUTE_NEW_VISIT,
    {
      patientId: patient.Id.toString(),
    }
  );

  if (matchesSM) {
    return (
      <IconButton
        edge="end"
        aria-label={buttonTitle}
        component={RouterLink}
        to={pathname}
        style={{ margin: 0, padding: 0 }}
        onClick={handleButtonClick}
        size="large"
      >
        {buttonTitle === 'View' ? <SearchIcon /> : <EditIcon />}
      </IconButton>
    );
  }

  return (
    <GeneralButton
      title={buttonTitle}
      color="primary"
      variant="outlined"
      component={RouterLink}
      to={pathname}
      onClick={handleButtonClick}
    >
      {buttonTitle}
    </GeneralButton>
  );
};

export const ListAllPatients = ({
  list,
  isSelect,
}: {
  list: Array<Patient>;
  isSelect?: boolean;
}) => {
  const { animalTypesObj } = useAnimalTypesContext();

  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  if (list.length === 0) {
    return (
      <Typography variant="h5" gutterBottom>
        You don&apos;t have any patients
      </Typography>
    );
  }

  return (
    <List dense={false}>
      {list.map((patient) => {
        return (
          <ListItem
            key={patient.Id}
            style={{
              padding: 0,
              borderBottom: '1px solid lightgrey',
            }}
          >
            <ListItemAvatar>
              <ListItemText
                style={{
                  overflowWrap: matchesSM ? 'anywhere' : undefined,
                  minWidth: matchesSM ? undefined : '100px',
                }}
                primary={patient.NameOrId}
                secondary={
                  animalTypesObj[patient.AnimalTypeId?.toString()]?.Name || ''
                }
              />
            </ListItemAvatar>
            <ListItemText
              style={{
                overflowWrap: 'anywhere',
              }}
              primary={patient.Identifier}
            />
            {!isSelect ? (
              <ListItemAvatar
                style={{
                  marginRight: matchesSM ? '1px' : '0.5em',
                  minWidth: matchesSM ? '2em' : 'inherit',
                }}
              >
                <Button
                  patient={patient}
                  buttonTitle="View"
                  matchesSM={matchesSM}
                />
              </ListItemAvatar>
            ) : null}
            <ListItemAvatar
              style={{
                marginRight: 0,
                minWidth: matchesSM ? '2em' : 'inherit',
              }}
            >
              <Button
                patient={patient}
                buttonTitle={isSelect ? 'Choose' : 'Add visit'}
                matchesSM={matchesSM}
              />
            </ListItemAvatar>
          </ListItem>
        );
      })}
    </List>
  );
};
