import { Breed } from '../../types/Breed.type';
import { RequestAction } from '../../types/request.action.type';

export type BreedsState = {
  breeds: Breed[];
  isLoading: boolean;
  error?: string;
};

export type BreedsAction = RequestAction<Breed[]>;

export const BreedsInitialState: BreedsState = {
  isLoading: false,
  breeds: [],
};
