import { getTwoDecimalFormatter } from '../../utils/numberUtils';

const formatter = getTwoDecimalFormatter();

export const calculateNumberOfUnitsResult = (
  patientWeight: number,
  strength: number,
  dose: number
) => {
  return formatter.format((patientWeight * dose) / strength);
};

export const calculateAmountResult = (
  patientWeight: number,
  strength: number,
  numberOfUnits: number
) => {
  return formatter.format((numberOfUnits / patientWeight) * strength);
};

export const calculateStrengthResult = (
  patientWeight: number,
  numberOfUnits: number,
  dose: number
) => {
  return formatter.format((patientWeight * dose) / numberOfUnits);
};
