import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  box: {
    backgroundColor: 'white',
    maxWidth: '650px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: '90%',
  },
  highlight: {
    paddingTop: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));

export const ErrorInfo = ({
  error,
  resetError,
}: {
  error: Error;
  resetError: () => void;
}) => {
  return <ErrorInfoComponent error={error} resetError={resetError} />;
};

const ErrorInfoComponent = ({
  error,
  resetError,
}: {
  error: Error;
  resetError: () => void;
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.box}>
      <pre>
        {typeof error === 'string'
          ? error
          : error.message
          ? error.message
          : 'Unknown error'}
      </pre>

      <Button variant="contained" color="primary" onClick={resetError}>
        Try again
      </Button>
      <p>
        Contact{' '}
        {<span className={classes.highlight}>support@gekkovet.com</span>} if
        needed.
      </p>
    </Box>
  );
};
