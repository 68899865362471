import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { patientPageReducer } from '../reducers/patientPage/patientPageReducer';
import {
  PatientPageState,
  PatientPageAction,
} from '../reducers/patientPage/patientPageState';

const PatientPageContext = createContext<PatientPageState>(
  {} as PatientPageState
);
const PatientPageDispatchContext = createContext<Dispatch<PatientPageAction>>(
  () => {}
);

export const PatientPageProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(patientPageReducer, {
    isSaving: false,
    breeds: [],
  });

  return (
    <PatientPageContext.Provider value={state}>
      <PatientPageDispatchContext.Provider value={dispatch}>
        {children}
      </PatientPageDispatchContext.Provider>
    </PatientPageContext.Provider>
  );
};

export const usePatientPageState = (): PatientPageState => {
  return useContext(PatientPageContext);
};

export const usePatientPageDispatch = () => {
  return useContext(PatientPageDispatchContext);
};
