import React from 'react';
import {
  useTreatmentsPage,
  useTreatmentsPageDispatch,
} from '../../contexts/TreatmentsPageContext';
import SuspectedConfirmedSwitch from '../diagnoses/SuspectedConfirmedSwitch';

export default function SubsequentDiseaseConfirmSwitch({
  diagnoseId,
  style,
}: {
  diagnoseId: number;
  style?: any;
}) {
  const { confirmedSubsequentDiseases } = useTreatmentsPage();
  const dispatchPageTreatments = useTreatmentsPageDispatch();

  const isConfirmed = confirmedSubsequentDiseases.indexOf(diagnoseId) > -1;

  const handleChange = (diseaseId: number, checked: boolean) => {
    dispatchPageTreatments({
      type: 'setSubsequentDiseaseConfirmed',
      checked,
      diseaseId,
    });
  };

  return (
    <SuspectedConfirmedSwitch
      Id={diagnoseId}
      handleSwitch={handleChange}
      isConfirmed={isConfirmed}
      style={style}
    />
  );
}
