import { Container } from '@mui/material';
import logo from '../assets/images/gekko_logo.png';
import PmsDiagnoses from './PmsDiagnoses';
import { PatientListProvider } from '../contexts/PatientListContext';
import { AppProvider } from '../contexts/Context';
import { DiagnosesPageProvider } from '../contexts/DiagnosesPageContext';

const Layout = () => {
  return (
    <Container maxWidth="lg">
      <AppProvider>
        <PatientListProvider>
          <DiagnosesPageProvider>
            <PmsDiagnoses />
          </DiagnosesPageProvider>
        </PatientListProvider>
      </AppProvider>
      <footer
        style={{
          backgroundColor: '#00cd87',
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <a href="https://www.gekkovet.com" target="_blank" rel="noreferrer">
          <img style={{ maxHeight: '3em' }} src={logo} alt="GekkoVet" />
        </a>
      </footer>
    </Container>
  );
};

export default Layout;
