import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTE_VISIT_DIAGNOSIS,
  ROUTE_VISIT_DOSAGES,
  ROUTE_VISIT_SUMMARY,
  ROUTE_VISIT_TREATMENTS,
} from '../config/routes.config';
import { useDiagnoseRouter } from '../hooks/useDiagnoseRouter';

import { DiagnosesPage } from '../pages/DiagnosesPage';
import { DosagesPage } from '../pages/DosagesPage';
import { SummaryPage } from '../pages/SummaryPage';
import { TreatmentsPage } from '../pages/TreatmentsPage';

export const DiagnoseRouter = () => {
  useDiagnoseRouter();
  return (
    <Switch>
      <Route exact path={ROUTE_VISIT_SUMMARY}>
        <SummaryPage />
      </Route>
      <Route exact path={ROUTE_VISIT_DOSAGES}>
        <DosagesPage />
      </Route>
      <Route exact path={ROUTE_VISIT_TREATMENTS}>
        <TreatmentsPage />
      </Route>
      <Route exact path={ROUTE_VISIT_DIAGNOSIS}>
        <DiagnosesPage />
      </Route>

      <Redirect to={ROUTE_VISIT_DIAGNOSIS} />
    </Switch>
  );
};
