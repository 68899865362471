import { GeographicalArea } from '../../types/GeographicalArea.type';
import { RequestAction } from '../../types/request.action.type';

export type GAListState = {
  geographicalAreas: GeographicalArea[];
  geographicalAreasObj: { [x: string]: number };
  isLoading: boolean;
  error?: string;
};

export type GAListAction = RequestAction<GeographicalArea[]>;

export const GAListDefault: GAListState = {
  isLoading: false,
  geographicalAreas: [],
  geographicalAreasObj: {},
};
