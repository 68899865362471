import React, { useEffect, useState } from 'react';
import {
  useOtherVisits,
  useOtherVisitsDispatch,
} from '../../contexts/OtherVisitsContext';
import { usePatientListState } from '../../contexts/PatientListContext';
import { useLocale } from '../../hooks/useLocale';
import { useRequestData } from '../../hooks/useRequestData';

import { PatientVisit } from '../../types/PatientVisit.type';
import {
  formatDateToRequest,
  formatDbDateStringToUi,
  formatDbTimeStringToUi,
} from '../../utils/dateUtils';
import { VisitRowProps, ListTodayVisits } from '../home/ListTodayVisits';
import Loader from '../Loader';

export const ListOtherVisitsContainer = ({
  search,
  resetSearch,
  setVisitRowClicked,
}: {
  search: boolean;
  resetSearch: () => void;
  setVisitRowClicked: (clicked: boolean) => void;
}) => {
  const locale = useLocale();
  const { fromDate, toDate, noVisitsText } = useOtherVisits();
  const { patientsObj } = usePatientListState();
  const [otherVisitsRows, setTodayVisitsRows] = useState<Array<VisitRowProps>>(
    []
  );
  const {
    error: errorVisits,
    visits,
    isLoading: isLoadingVisits,
  } = useOtherVisits();
  const dispatchVisits = useOtherVisitsDispatch();

  useEffect(() => {
    if (search && !isLoadingVisits && !errorVisits && Boolean(patientsObj)) {
      dispatchVisits({ type: 'reset' });
    }
  }, [dispatchVisits, errorVisits, isLoadingVisits, patientsObj, search]);

  const fromDateStr = formatDateToRequest(fromDate!);
  const toDateStr = formatDateToRequest(toDate!);

  useRequestData<PatientVisit[]>({
    needTransport:
      !errorVisits &&
      !isLoadingVisits &&
      search &&
      !visits &&
      Boolean(patientsObj),
    dispatch: dispatchVisits,
    method: 'get',
    params: {
      modelName: 'PatientVisits',
      ...(fromDateStr !== toDateStr
        ? { DateFrom: fromDateStr, DateTo: toDateStr }
        : { Date: fromDateStr }),
    },
    dispatchOptions: locale,
  });

  useEffect(() => {
    if (patientsObj && visits) {
      const visitsRows: Array<VisitRowProps> = visits.map((visit) => {
        return {
          Date: formatDbDateStringToUi(visit.Date, locale),
          Time: formatDbTimeStringToUi(visit.Time, locale),
          PatientName:
            patientsObj[visit.PatientId] &&
            patientsObj[visit.PatientId].NameOrId // TODO: remove this when DB is cleaned
              ? patientsObj[visit.PatientId].NameOrId!
              : 'Unknown',
          VisitReason: visit.VisitReason,
          PatientId: visit.PatientId.toString(),
          VisitId: visit.Id.toString(),
        };
      });
      setTodayVisitsRows(visitsRows);
    }
  }, [locale, patientsObj, visits]);

  useEffect(() => {
    if (isLoadingVisits && search) {
      resetSearch();
    }
  }, [isLoadingVisits, resetSearch, search]);

  if (errorVisits) {
    dispatchVisits({ type: 'reset' });
    throw errorVisits;
  }

  if (!patientsObj || isLoadingVisits) {
    return <Loader showLoader={true} />;
  }

  return (
    <ListTodayVisits
      list={otherVisitsRows}
      showDate
      noVisitsText={noVisitsText}
      setVisitRowClicked={setVisitRowClicked}
    />
  );
};
