import { DrugSubstanceDose_Phase } from '../../types/DrugDosages.type';

export const getDose = (phase: DrugSubstanceDose_Phase) => {
  const doseMin = phase.Dose;
  const doseMax = phase.DoseMax;
  const drugUnit = phase.DrugUnit.Name;
  if (!doseMax) {
    return `${doseMin} ${drugUnit}`;
  }
  return `${doseMin} - ${doseMax} ${drugUnit}`;
};

export const getInterval = (phase: DrugSubstanceDose_Phase) => {
  const dosageInterval = phase.DosageIntervals.Name;
  const dosageIntervalMin = phase.DosageInterval;
  const dosageIntervalMax = phase.DosageIntervalMax;
  if (dosageIntervalMin && !dosageIntervalMax) {
    return `${dosageIntervalMin} ${dosageInterval}`;
  }
  if (dosageIntervalMin && dosageIntervalMax) {
    return `${dosageIntervalMin} - ${dosageIntervalMax} ${dosageInterval}`;
  }
  return `${dosageInterval}`;
};

export const getDuration = (phase: DrugSubstanceDose_Phase) => {
  const treatmentDuration = phase.TreatmentDurations.Name;
  const treatmentDurationMin = phase.TreatmentDuration;
  const treatmentDurationMax = phase.TreatmentDurationMax;
  const prefix = phase.TreatmentDurationPrefix
    ? `${phase.TreatmentDurationPrefix.Name} `
    : '';
  if (treatmentDurationMin && !treatmentDurationMax) {
    return `${prefix}${treatmentDurationMin} ${treatmentDuration}`;
  }
  if (treatmentDurationMin && treatmentDurationMax) {
    return `${prefix}${treatmentDurationMin} - ${treatmentDurationMax} ${treatmentDuration}`;
  }
  // There seems to be a case where phase.TreatmentDurations.Name is not available
  // with value 'not specified', then use hardcoded value
  return treatmentDuration ? `${prefix}${treatmentDuration}` : 'not specified';
};
