import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { symptomsListReducer } from '../reducers/symptomsList/symptoms.list.reducer';
import {
  SymptomsAction,
  SymptomsDefault,
  SymptomsState,
} from '../reducers/symptomsList/symptoms.state';

const SymptomsContext = createContext<SymptomsState>({} as SymptomsState);
const SymptomsDispatchContext = createContext<Dispatch<SymptomsAction>>(
  () => {}
);

export const SymptomsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(symptomsListReducer, SymptomsDefault);

  return (
    <SymptomsContext.Provider value={state}>
      <SymptomsDispatchContext.Provider value={dispatch}>
        {children}
      </SymptomsDispatchContext.Provider>
    </SymptomsContext.Provider>
  );
};

export const useSymptoms = (): SymptomsState => {
  return useContext(SymptomsContext);
};

export const useSymptomsDispatch = () => {
  return useContext(SymptomsDispatchContext);
};
