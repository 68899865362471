import { useMediaQuery } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';
import {
  useDosagesPage,
  useDosagesPageDispatch,
} from '../../contexts/DosagesPageContext';
import { useTreatmentsPage } from '../../contexts/TreatmentsPageContext';
import {
  CalculatedDose,
  DefaultCalculatedDose,
  DefaultDoseInstruction,
} from '../../reducers/dosagesPage/dosages.page.state';
import { DoseAlternative } from '../../types/DrugDosages.type';
import { Button } from '../Button';
import { DosageContainer } from './DosageContainer';

const styles = (theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#EAEAE9',
  },
  closeButton: {
    position: 'absolute' as any,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    backgroundColor: '#EAEAE9',
  },
});

export type DialogTitleProps = {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
};

const DialogTitle = withStyles((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}, styles);

const DialogContent = withStyles(MuiDialogContent, (theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: 'none',
    backgroundColor: '#F8F7F7',
  },
}));

const DialogActions = withStyles(MuiDialogActions, (theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#F8F7F7',
  },
}));

export const DosageCalculatorPopup = ({
  title,
  treatmentId,
  drugSubstanceId,
  doseAlternative,
  diseaseName,
}: {
  title: string;
  treatmentId: number;
  drugSubstanceId: number;
  doseAlternative: DoseAlternative;
  diseaseName: string;
}) => {
  const { weight, animalTypeName } = usePatientInfo();
  const { selectedTreatmentsObj } = useTreatmentsPage();

  const { selectedDrugDosages, deletedDrugAlternative } = useDosagesPage();
  const dispatchDosages = useDosagesPageDispatch();
  const mobile = useMediaQuery('(max-width:600px)');

  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');

  const isSelectedDoseAlternative =
    treatmentId in selectedDrugDosages &&
    drugSubstanceId in selectedDrugDosages[treatmentId] &&
    doseAlternative.Id in selectedDrugDosages[treatmentId][drugSubstanceId];

  const emptyCalculatedDosages = doseAlternative.DrugSubstanceDose_Phases.map(
    (dosePhase) => {
      return {
        ...DefaultCalculatedDose,
        dosePhaseId: dosePhase.Id,
        weight,
        doseInstruction: {
          ...DefaultDoseInstruction,
          doseTypeId: dosePhase.DoseType.Id,
        },
      };
    }
  );

  const savedCalculatedDoses = useRef<CalculatedDose[]>(
    isSelectedDoseAlternative
      ? selectedDrugDosages[treatmentId][drugSubstanceId][doseAlternative.Id]
      : emptyCalculatedDosages
  );

  const [currentCalculatedDoses, setCurrentCalculatedDoses] = useState<
    CalculatedDose[]
  >(savedCalculatedDoses.current);

  useEffect(() => {
    if (
      isSelectedDoseAlternative &&
      JSON.stringify(
        selectedDrugDosages[treatmentId][drugSubstanceId][doseAlternative.Id]
      ) !== JSON.stringify(savedCalculatedDoses.current)
    ) {
      savedCalculatedDoses.current =
        selectedDrugDosages[treatmentId][drugSubstanceId][doseAlternative.Id];
    }
  }, [
    doseAlternative.Id,
    drugSubstanceId,
    isSelectedDoseAlternative,
    selectedDrugDosages,
    treatmentId,
  ]);
  const [reload, setReload] = useState(false);

  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setCurrentCalculatedDoses(savedCalculatedDoses.current);
    setReload(true);
    setOpen(false);
  };

  const handleSaveAndClose = () => {
    dispatchDosages({
      type: 'setDrugDosageSelect',
      treatmentId,
      drugSubstanceId,
      doseAlternativeId: doseAlternative.Id,
      checked: true,
      calculatedDoses: [...currentCalculatedDoses],
    });

    savedCalculatedDoses.current = [...currentCalculatedDoses];
    setOpen(false);
  };

  const handleClearAll = () => {
    setCurrentCalculatedDoses([...emptyCalculatedDosages]);
    setReload(true);
  };

  useEffect(() => {
    if (
      deletedDrugAlternative &&
      deletedDrugAlternative.treatmentId === treatmentId &&
      deletedDrugAlternative.drugSubstanceId === drugSubstanceId &&
      deletedDrugAlternative.doseAlternativeId === doseAlternative.Id
    ) {
      savedCalculatedDoses.current = [...emptyCalculatedDosages];
      setCurrentCalculatedDoses(savedCalculatedDoses.current);
      dispatchDosages({
        type: 'resetDeletedDrugAlternative',
      });
    }
  }, [
    deletedDrugAlternative,
    dispatchDosages,
    doseAlternative.Id,
    drugSubstanceId,
    emptyCalculatedDosages,
    treatmentId,
  ]);

  const handleCalculatedDose = (
    calculatedDose: CalculatedDose,
    index: number
  ) => {
    const newCalculatedDoses = [...currentCalculatedDoses];
    newCalculatedDoses.splice(index, 1, calculatedDose);

    setCurrentCalculatedDoses(newCalculatedDoses);
  };

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        onClick={handleClickOpen('paper')}
        style={{ fontWeight: title === 'Chosen' ? 'bold' : 'normal' }}
      >
        {title}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        maxWidth="lg"
        fullScreen={mobile}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        disableEscapeKeyDown
      >
        <DialogTitle id="scroll-dialog-title" onClose={handleClose}>
          EDIT DOSAGE
        </DialogTitle>
        <DialogContent
          dividers={scroll === 'paper'}
          style={
            mobile ? { paddingLeft: '0px', paddingRight: '0px' } : undefined
          }
        >
          {doseAlternative.DrugSubstanceDose_Phases.map((dosePhase, index) => {
            return (
              <DosageContainer
                key={dosePhase.Id}
                animalTypeName={animalTypeName}
                treatmentName={selectedTreatmentsObj[treatmentId].Name}
                drugSubstanceId={drugSubstanceId}
                dosePhase={dosePhase}
                calculatedDose={currentCalculatedDoses[index]}
                index={index}
                setCalculatedDose={handleCalculatedDose}
                diseaseName={diseaseName}
                reload={reload}
                resetReload={() => {
                  if (reload) setReload(false);
                }}
                mobile={mobile}
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClearAll}
            variant="contained"
            style={{
              padding: '0.5em 0.8em',
              marginRight: '1em',
              backgroundColor: 'white',
              color: '#00cd87',
              fontWeight: 'bold',
            }}
          >
            Clear all
          </Button>
          <Button
            onClick={handleSaveAndClose}
            variant="contained"
            color="primary"
            style={{
              padding: '0.5em 0.8em',
              fontWeight: 'bold',
            }}
          >
            Save and close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
