import React, { forwardRef, useMemo } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { ListItem, ListItemText, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { MenuItemLinkProps } from './menuItem.types';

const useStyles = makeStyles()((theme: Theme) => ({
  text: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: '0.1px',
    color: 'rgba(0, 0, 0, 0.6)',
    paddingTop: '2px',
  },
  listItem: {
    paddingTop: '1%',
    paddingBottom: '1%',
  },
}));

export const MenuItemLink = (
  props: MenuItemLinkProps & {
    handleClose?: (e: React.MouseEvent) => void;
  }
) => {
  const { title, to, handleClose } = props;

  const { classes } = useStyles();

  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<RouterLinkProps, 'to'>>(function renderedLink(
        itemProps,
        ref
      ) {
        return <RouterLink to={to} ref={ref} {...itemProps} />;
      }),
    [to]
  );

  return (
    <ListItem
      button
      onClick={handleClose}
      component={renderLink}
      alignItems="flex-start"
      className={classes.listItem}
    >
      <ListItemText primary={title} className={classes.text} />
    </ListItem>
  );
};

export default MenuItemLink;
