import React, { useLayoutEffect } from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import { useAuthUser } from '../contexts/AuthUserContext';
import { useLocation } from 'react-router-dom';
import Loader from '../components/Loader';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '90%',
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export const Login = () => {
  const { classes } = useStyles();
  const { signIn } = useAuthUser();
  const { state } = useLocation();

  useLayoutEffect(() => {
    if (signIn) signIn();
  }, [signIn]);

  return (
    <>
      {state && (
        <Paper className={classes.root}>
          <h3>Thank you! Your email has been confirmed successfully.</h3>
        </Paper>
      )}
      <Loader showLoader={true} />
    </>
  );
};
