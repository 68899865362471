import { Button } from '@mui/material';

export const CalculateButton = ({
  handleCalculateClick,
  mobile,
}: {
  handleCalculateClick: () => void;
  mobile: boolean;
}) => {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: mobile ? 'flex-start' : 'center',
      }}
    >
      <Button color="primary" variant="outlined" onClick={handleCalculateClick}>
        Calculate
      </Button>
    </div>
  );
};
