import { useEffect, useState } from 'react';

const useApi = (state: any, _dispatch: any) => {
  const [selected, setSelected] = useState(false);
  const dispatch = (action: any) => {
    action.dispatch = _dispatch;
    _dispatch(action);
  };
  useEffect(() => {
    if (!selected) {
      setSelected(() => {
        return true;
      });
    }
  }, [selected]);
  return { state, dispatch };
};

export default useApi;
