import React from 'react';
import { PaymentForm } from '../components/payment/PaymentForm';
import { PaymentFormPilot } from '../components/payment/PaymentFormPilot';
import { useIsPilotEnviroment } from '../hooks/useIsPilotEnviroment';

export const PaymentPage = () => {
  const pilotEnviroment = useIsPilotEnviroment();

  return <>{pilotEnviroment ? <PaymentFormPilot /> : <PaymentForm />}</>;
};
