import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import {
  getTreatmentName,
  getTreatmentType,
  getDrugProductName,
} from '../../utils/summaryUtils';
import { Treatment } from '../../types/Diagnose.type';
import { CalculatedDose } from '../../reducers/dosagesPage/dosages.page.state';
import { DoseParam, DoseParamWithOrder } from '../../types/Dose.params.type';
import { DrugProduct } from '../../types/DrugProduct.type';
import { TreatmentType } from '../diseases-and-treatments/types';
import { UNDEFINED_ID } from '../../config/constant.params';

const useStyles = makeStyles()((theme: Theme) => ({
  treatmentTopic: {
    fontFamily: 'Arial',
    fontSize: '10px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.6',
    letterSpacing: '1.5px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: theme.spacing(1),
  },
  noDosagesInfo: {
    fontFamily: 'Arial',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.6',
    letterSpacing: '1.5px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: theme.spacing(1),
  },
  treatmentNumber: {
    fontFamily: 'Arial',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.6',
    letterSpacing: '1.5px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: theme.spacing(2),
  },
  doseContainer: {
    marginTop: theme.spacing(1),
  },
}));

export default function SummarySymptomRow({
  treatment,
  index,
  selectedDrugDosages,
  treatmentDurationPrefixes,
  doseTypes,
  dosageIntervals,
  treatmentDurations,
  summaryDrugUnits,
  summaryResultDrugUnits,
  drugProducts,
  mobile,
}: {
  treatment: Treatment;
  index: number;
  selectedDrugDosages: {
    [treatmentId: number]: {
      [drugSubstanceId: number]: {
        [doseAlternativeId: number]: CalculatedDose[];
      };
    };
  };
  treatmentDurationPrefixes: DoseParam[];
  doseTypes: DoseParamWithOrder[];
  dosageIntervals: DoseParamWithOrder[];
  treatmentDurations: DoseParamWithOrder[];
  summaryDrugUnits: DoseParamWithOrder[];
  summaryResultDrugUnits: DoseParamWithOrder[];
  drugProducts: { [x: number]: DrugProduct[] };
  mobile: boolean;
}) {
  const { classes } = useStyles();
  return (
    <Grid>
      <Typography align="left" className={classes.treatmentNumber}>
        Treatment {index + 1}
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={4}>
          <Typography align="left" className={classes.treatmentTopic}>
            TYPE
          </Typography>
        </Grid>
        {mobile ? (
          <Grid item xs={12} sm={4}>
            <Typography align="left">{getTreatmentType(treatment)}</Typography>
          </Grid>
        ) : (
          <Grid item xs={12} sm={4}>
            <Typography align="left" className={classes.treatmentTopic}>
              {treatment.Type === TreatmentType.Drug
                ? 'DRUG SUBSTANCE'
                : 'NAME'}
            </Typography>
          </Grid>
        )}
        {mobile ? (
          <Grid item xs={12} sm={4}>
            <Typography align="left" className={classes.treatmentTopic}>
              {treatment.Type === TreatmentType.Drug
                ? 'DRUG SUBSTANCE'
                : 'NAME'}
            </Typography>
          </Grid>
        ) : treatment.Type === TreatmentType.Drug &&
          drugProducts[treatment.DrugSubtance.Id]?.length > 0 ? (
          <Grid item xs={12} sm={4}>
            <Typography align="left" className={classes.treatmentTopic}>
              PRODUCT
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12} sm={4}></Grid>
        )}

        {mobile ? (
          <Grid item xs={12} sm={4}>
            <Typography align="left">{getTreatmentName(treatment)}</Typography>
          </Grid>
        ) : (
          <Grid item xs={12} sm={4}>
            <Typography align="left">{getTreatmentType(treatment)}</Typography>
          </Grid>
        )}

        {mobile ? (
          treatment.Type === TreatmentType.Drug &&
          drugProducts[treatment.DrugSubtance.Id]?.length > 0 ? (
            <Grid item xs={12} sm={4}>
              <Typography align="left" className={classes.treatmentTopic}>
                PRODUCT
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12} sm={4}></Grid>
          )
        ) : (
          <Grid item justifyContent="center" xs={12} sm={4}>
            <Typography align="left">{getTreatmentName(treatment)}</Typography>
          </Grid>
        )}

        {treatment.Type === TreatmentType.Drug &&
        drugProducts[treatment.DrugSubtance.Id]?.length > 0 ? (
          <Grid item xs={12} sm={4}>
            <Typography align="left">
              {getDrugProductName(treatment, selectedDrugDosages, drugProducts)}
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12} sm={4}></Grid>
        )}
      </Grid>
      {treatment.Type === TreatmentType.Drug &&
        GetDosages(
          treatment,
          selectedDrugDosages,
          treatmentDurationPrefixes,
          doseTypes,
          dosageIntervals,
          summaryDrugUnits,
          summaryResultDrugUnits,
          treatmentDurations
        )}
      <Grid item xs={12} sm={12}>
        <Typography align="left" className={classes.treatmentTopic}>
          INFO
        </Typography>
        <Typography align="left">{treatment.Info}</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography align="left" className={classes.treatmentTopic}>
          FOLLOW UP
        </Typography>
        <Typography align="left">
          {treatment.FollowUp ? treatment.FollowUp : '-'}
        </Typography>
      </Grid>
    </Grid>
  );
}

const GetDosages = (
  treatment: Treatment,
  selectedDrugDosages: {
    [treatmentId: number]: {
      [drugSubstanceId: number]: {
        [doseAlternativeId: number]: CalculatedDose[];
      };
    };
  },
  treatmentDurationPrefixes: DoseParam[],
  doseTypes: DoseParamWithOrder[],
  dosageIntervals: DoseParamWithOrder[],
  summaryDrugUnits: DoseParamWithOrder[],
  summaryResultDrugUnits: DoseParamWithOrder[],
  treatmentDurations: DoseParamWithOrder[]
) => {
  const { classes } = useStyles();
  const dosages =
    selectedDrugDosages[treatment.Id]?.[treatment.DrugSubtance.Id];
  if (!dosages) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        className={classes.doseContainer}
      >
        <Typography align="left" className={classes.noDosagesInfo}>
          No dosages chosen for this treatment
        </Typography>
      </Grid>
    );
  }
  const dosageElements: JSX.Element[] = [];

  for (const key in dosages) {
    if (Object.prototype.hasOwnProperty.call(dosages, key)) {
      dosages[key].forEach((dose) => {
        dosageElements.push(
          <Grid
            key={dose.dosePhaseId}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            className={classes.doseContainer}
          >
            <Grid item xs={12} sm={12}>
              <Typography align="left" className={classes.treatmentTopic}>
                {'Alternative ' + (dosageElements.length + 1)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="left" className={classes.treatmentTopic}>
                Dose type
              </Typography>
              <Typography align="left">
                {dose.doseInstruction.doseTypeId === UNDEFINED_ID
                  ? ''
                  : doseTypes.find(
                      (dt) => dt.Id === dose.doseInstruction.doseTypeId
                    )?.Name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="left" className={classes.treatmentTopic}>
                Administration route
              </Typography>
              <Typography align="left">
                {dose.drugAdministrationRoute}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="left" className={classes.treatmentTopic}>
                Strength
              </Typography>
              <Typography align="left">
                {dose.doseInstruction.strength +
                  ' ' +
                  (dose.doseInstruction.summaryDrugUnitId === UNDEFINED_ID
                    ? ''
                    : summaryDrugUnits.find(
                        (sdu) =>
                          sdu.Id === dose.doseInstruction.summaryDrugUnitId
                      )?.Name)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="left" className={classes.treatmentTopic}>
                Number of units
              </Typography>
              <Typography align="left">
                {dose.doseInstruction.numberOfUnits +
                  ' ' +
                  (dose.doseInstruction.summaryResultDrugUnitId === UNDEFINED_ID
                    ? ''
                    : summaryResultDrugUnits.find(
                        (srdu) =>
                          srdu.Id ===
                          dose.doseInstruction.summaryResultDrugUnitId
                      )?.Name)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="left" className={classes.treatmentTopic}>
                Dose interval
              </Typography>
              <Typography align="left">
                {dose.doseInstruction.dosageInterval +
                  ' ' +
                  (dose.doseInstruction.dosageIntervalId === UNDEFINED_ID
                    ? ''
                    : dosageIntervals.find(
                        (di) => di.Id === dose.doseInstruction.dosageIntervalId
                      )?.Name)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="left" className={classes.treatmentTopic}>
                Treatment duration
              </Typography>
              <Typography align="left">
                {(dose.doseInstruction.treatmentDurationPrefixId ===
                UNDEFINED_ID
                  ? ''
                  : treatmentDurationPrefixes.find(
                      (tdp) =>
                        tdp.Id ===
                        dose.doseInstruction.treatmentDurationPrefixId
                    )?.Name) +
                  ' ' +
                  dose.doseInstruction.treatmentDuration +
                  ' ' +
                  (dose.doseInstruction.treatmentDurationId === UNDEFINED_ID
                    ? ''
                    : treatmentDurations.find(
                        (td) =>
                          td.Id === dose.doseInstruction.treatmentDurationId
                      )?.Name)}
              </Typography>
            </Grid>
          </Grid>
        );
      });
    }
  }
  return <>{dosageElements}</>;
};
