import React from 'react';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import theme from './theme/Theme';
import { BaseLayout } from './layouts/BaseLayout';
import { AppProvider } from './contexts/Context';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

function App() {
  return (
    <BrowserRouter>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <StyledEngineProvider>
          <AppProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <BaseLayout />
            </LocalizationProvider>
          </AppProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
