import React from 'react';
import Typography from '@mui/material/Typography';

export const DrugDosageInfo = ({
  drugDosageInfo,
}: {
  drugDosageInfo: string | null;
}) => {
  if (!drugDosageInfo) {
    return null;
  }

  return (
    <Typography
      align="left"
      style={{
        whiteSpace: 'pre-line',
        paddingTop: '1em',
        paddingBottom: '1em',
      }}
    >
      {drugDosageInfo}
    </Typography>
  );
};
