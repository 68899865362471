import Grid from '@mui/material/Grid';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTE_NEW_PATIENT } from '../../config/routes.config';
import { Button } from '../Button';
import { Card } from '../common/Card';
import { ListAllPatientsContainer } from './ListAllPatientsContainer';

export const CardAllPatients = ({
  isSelect,
  hideAddPatient,
}: {
  isSelect?: boolean;
  hideAddPatient?: boolean;
}) => {
  return (
    <Card title="ALL PATIENTS" pageView="drawerView" withoutMarginBottom>
      {!hideAddPatient ? (
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <Button
            title="New patient"
            color="primary"
            variant="contained"
            component={RouterLink}
            to={ROUTE_NEW_PATIENT}
          >
            Add new patient
          </Button>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <ListAllPatientsContainer isSelect={isSelect} />
      </Grid>
    </Card>
  );
};
