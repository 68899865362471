import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

export const NeuteredCheckbox = ({
  neutered,
  handleChange,
}: {
  neutered: number;
  handleChange: any;
}) => {
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            name="Neutered"
            checked={!!neutered}
            onChange={handleChange}
          />
        }
        label="Animal is neutered"
      />
    </FormGroup>
  );
};
