import React, { useState, ChangeEvent } from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Draggable from 'react-draggable';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Alert from '@mui/material/Alert';

import Loader from '../Loader';
import { patternEmail } from '../../utils/validators';

const useStyles = makeStyles()((theme: Theme) => ({
  emailTextField: {
    marginleft: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  emailButton: {
    marginTop: theme.spacing(2),
  },
}));

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#summary-send-email-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function SummaryEmailDialog({
  getSummaryString,
}: {
  getSummaryString: () => void;
}) {
  const { classes } = useStyles();
  const [summaryEmailDialogOpen, setSummaryEmailDialogOpen] = useState(false);

  const [emailState, setEmailState] = useState({
    recipient: '',
    emailBody: getSummaryString(),
    subject: '',
    senderName: '',
  });
  const [alertState, setAlertState] = useState({
    message: '',
    severity: '',
    showAlert: false,
  });

  const [sendingEmail, setSendingEmail] = useState(false);
  const [recipientError, setRecipientError] = useState(false);

  const [subjectError, setSubjectError] = useState(false);

  const handleClickOpen = () => {
    setEmailState({ ...emailState, emailBody: getSummaryString() });
    setSummaryEmailDialogOpen(true);
  };

  const handleClose = () => {
    setSummaryEmailDialogOpen(false);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    if (name === 'recipient') {
      setRecipientError(validateEmail(value));
    }

    if (name === 'subject') {
      setSubjectError(value === '');
    }
    setEmailState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const validateEmail = (email: string) => {
    const re = patternEmail;
    return !re.test(email);
  };

  const sendEmail = () => {
    const emailUrl = 'sendEmail';
    setSendingEmail(true);
    axios.post(emailUrl, { ...emailState, emailTemplate: 'summary' }).then(
      (result: any) => {
        setSendingEmail(false);
        setAlertState({
          severity: 'success',
          message: 'Email sent succesfully',
          showAlert: true,
        });
        setTimeout(() => {
          setAlertState({ severity: '', message: '', showAlert: false });
        }, 5000);
      },
      (error) => {
        setSendingEmail(false);
        setAlertState({
          severity: 'error',
          message: 'Could not send email',
          showAlert: true,
        });
        setTimeout(() => {
          setAlertState({ severity: '', message: '', showAlert: false });
        }, 5000);
        console.error(error);
      }
    );
  };

  return (
    <div>
      <Button
        className={classes.emailButton}
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        Send summary as email
      </Button>
      <Dialog
        open={summaryEmailDialogOpen}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="summary-send-email-dialog"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle style={{ cursor: 'move' }} id="summary-send-email-dialog">
          Send summary as email
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Here you can send visit summary as email just fill out the fields.
          </DialogContentText>
          {alertState.showAlert && (
            <Alert
              severity={alertState.severity === 'error' ? 'error' : 'success'}
            >
              {alertState.message}
            </Alert>
          )}

          <TextField
            id="recipient"
            name="recipient"
            label="Email recipient"
            fullWidth
            className={classes.emailTextField}
            variant="outlined"
            error={recipientError}
            value={emailState.recipient}
            helperText={recipientError ? 'Not valid email address' : ''}
            onChange={(e) => handleChange(e)}
          />
          <TextField
            id="senderName"
            name="senderName"
            label="Email sender"
            placeholder="Ie: John Doe"
            fullWidth
            variant="outlined"
            className={classes.emailTextField}
            value={emailState.senderName}
            onChange={(e) => handleChange(e)}
          />
          <TextField
            id="subject"
            name="subject"
            label="Email subject"
            fullWidth
            variant="outlined"
            className={classes.emailTextField}
            value={emailState.subject}
            error={subjectError}
            helperText={subjectError ? 'Subject should be defined' : ''}
            onChange={(e) => handleChange(e)}
          />
          <TextField
            id="emailBody"
            name="emailBody"
            label="Email body"
            fullWidth
            variant="outlined"
            multiline
            rows={20}
            className={classes.emailTextField}
            value={emailState.emailBody}
            onChange={(e) => handleChange(e)}
          />
        </DialogContent>
        <Loader showLoader={sendingEmail} />
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={subjectError || recipientError}
            onClick={sendEmail}
            color="primary"
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
