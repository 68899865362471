import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';

const NO_CONTENT = 204;
//const UNAUTHORIZED = 401;

const processResponse = (res: any) => {
  if (res.status === NO_CONTENT) {
    const response = Object.assign({}, res, { data: {} });
    return response;
  }
  return res;
};

const errorResponse = (e: any) => {
  const response = Object.assign({}, { status: 404 }, { data: { error: e } });
  return response;
};

const handleResponse = (response: any, jsonResponse: any) => {
  const json = _.isEmpty(jsonResponse) ? {} : jsonResponse;
  const { status } = response;
  const { errors } = Object.assign({}, json);
  const resp = {
    status,
    body: jsonResponse,
    errors,
    headers: response.headers,
  };
  return resp;
};

const APILayer = {
  async request(url: any, reqInit: any, options = {}) {
    const init = Object.assign({}, reqInit);
    try {
      const resp = await axios({
        url,
        ...init,
        timeout: 30000,
      });
      processResponse(resp);
      return handleResponse(resp, resp.data);
    } catch (e) {
      console.log(e);
      return errorResponse(e);
    }
  },

  getParams(queryParams = {}) {
    return queryParams;
  },

  async getRequest(path: any, queryParams: any, options = {}) {
    const getData = {
      method: 'GET',
      params: this.getParams(queryParams),
      paramsSerializer: (params: any) => {
        return qs.stringify(params, {
          arrayFormat: 'brackets',
        });
      },
    };
    return this.request(path, getData, options);
  },

  async postRequest(path: any, body: any, options = {}) {
    const postData = {
      method: 'POST',
      data: body,
      params: this.getParams(),
      paramsSerializer: (params: any) => {
        return qs.stringify(params, {
          arrayFormat: 'brackets',
        });
      },
    };
    return this.request(path, postData, options);
  },

  async putRequest(path: any, body: any, options = {}) {
    const putData = {
      method: 'PUT',
      data: body,
      params: this.getParams(),
      paramsSerializer: (params: any) => {
        return qs.stringify(params, {
          arrayFormat: 'brackets',
        });
      },
    };
    return this.request(path, putData, options);
  },
};

export default APILayer;
