import React, { useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import CardDiagnoses from '../components/diagnoses/CardDiagnoses';
import { DiagnosesFloatingActionBar } from '../components/diagnoses/DiagnosesFloatingActionBar';
import Loader from '../components/Loader';
import { CardAddedSymptomsContainer } from '../components/symptoms/CardAddedSymptomsContainer';
import { CardSymptoms } from '../components/symptoms/CardSymptoms';
import { ROUTE_VISIT_DIAGNOSIS } from '../config/routes.config';
import { usePatientInfo } from '../contexts/CurrentPatientInfoContext';
import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../contexts/DiagnosesPageContext';
import { useSymptoms, useSymptomsDispatch } from '../contexts/SymptomsContext';
import { useRequestData } from '../hooks/useRequestData';
import { MainContent } from '../layouts/MainContent';
import { Diagnose } from '../types/Diagnose.type';
import {
  Symptom,
  SymptomType,
  SymptomTypeCategory,
} from '../types/Symptom.type';
import { getTestPatient } from '../config/testPatients';
import API from '../api/Api';
import ReactJoyride, { CallBackProps, Step } from 'react-joyride';
import {
  useVeterinarian,
  useVeterinarianDispatch,
} from '../contexts/VeterinarianContext';
import UIState from '../types/UIState.type';
import { VetProfile } from '../types/Profile.types';

export type DiagnosesFilterOptions = {
  typicallyYoungAnimals: boolean;
  typicallyGeriatricAnimals: boolean;
  relevantGeographicalAreas: { GeographicalAreaId: number }[];
};

export const DefaultDiagnosesFilterOptions = {
  typicallyYoungAnimals: false,
  typicallyGeriatricAnimals: false,
  relevantGeographicalAreas: [],
};

export const DiagnosesPage = () => {
  useEffect(() => {
    API.trackEvent('visit_diagnoses_page');
  }, []);
  const {
    isLoading: isLoadingData,
    error: errorData,
    categories,
    symptomTypes,
    symptoms,
  } = useSymptoms();
  const veteriarianDispatch = useVeterinarianDispatch();
  const [reloadVeterinarian, setReloadVeterinarian] = useState(false);
  const { veterinarian, isLoading: isLoadingVeterinarian } = useVeterinarian();
  const [skipJoyride, setSkipJoyride] = useState(false);
  useEffect(() => {
    if (!isLoadingVeterinarian) return;
    setReloadVeterinarian(false);
  }, [isLoadingVeterinarian]);
  const symptom_steps: Step[] = useMemo(
    () => [
      {
        content: 'Hello! Start diagnosing by adding symptoms.',
        target: '#symptom-container',
        placement: 'bottom',
        disableBeacon: true,
      },
      {
        placement: 'bottom',
        content: (
          <>
            <p style={{ textAlign: 'left' }}>
              Tick this box to see only those differentials that include this
              symptom.
            </p>
            <p style={{ textAlign: 'left' }}>
              <small>
                Tip: Tick the main symptoms as required to get more relevant
                suggestions.
              </small>
            </p>
            <p style={{ textAlign: 'left' }}>
              <label>
                <input
                  type="checkbox"
                  onChange={() => setSkipJoyride(!skipJoyride)}
                />{' '}
                Don&apos;t show this again.
              </label>
            </p>
          </>
        ),
        target: '.symptom-required-checkbox',
        disableBeacon: true,
      },
    ],
    [skipJoyride, setSkipJoyride]
  );
  const [joyrideSteps] = useState<Step[]>(symptom_steps);
  const [currentStep, setCurrentStep] = useState(0);
  const [joyrideActive, setJoyrideActive] = useState(true);
  const uiState = useMemo(() => {
    if (!veterinarian)
      return { diagnoses_page: { skip_joyride: true } } as UIState;
    const state = JSON.parse(veterinarian.UIStorage || '{}');
    return state as UIState;
  }, [veterinarian]);

  useEffect(() => {
    setJoyrideActive(!uiState.diagnoses_page?.skip_joyride);
  }, [uiState]);

  const dispatchSymptoms = useSymptomsDispatch();
  const { isLoading, error, selectedSymptomsObj, selectedSymptoms } =
    useDiagnosesPage();
  const dispatchPageDiagnoses = useDiagnosesPageDispatch();
  const { breedId, isTestPatient, Id: testPatientId } = usePatientInfo();

  useEffect(() => {
    if (uiState.diagnoses_page?.skip_joyride) return;
    if (currentStep === 1 && selectedSymptoms.length > 0) {
      setJoyrideActive(true);
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('resize'));
        document.dispatchEvent(new CustomEvent('scroll'));
      }, 500);
    }
  }, [selectedSymptoms, currentStep, uiState]);

  const [filterOptions, setFilterOptions] = useState<DiagnosesFilterOptions>(
    DefaultDiagnosesFilterOptions
  );
  const [needToUpdate, setNeedToUpdate] = useState(false);

  const { pathname } = useLocation();

  const currentUrlParams = matchPath<{
    patientId: string;
    visitId: string;
  }>(pathname, {
    path: ROUTE_VISIT_DIAGNOSIS,
  });
  const patientId = currentUrlParams?.params.patientId;

  const searchDifferentials = (options: DiagnosesFilterOptions) => {
    setFilterOptions(options);

    setNeedToUpdate(true);
  };

  const updateDifferentials = () => {
    setNeedToUpdate(true);
    API.trackEvent('diagnoses_update_differentials');
  };

  useEffect(() => {
    if (isLoading) {
      setNeedToUpdate(false);
    }
  }, [isLoading]);

  useRequestData<VetProfile[]>({
    needTransport: reloadVeterinarian,
    dispatch: veteriarianDispatch,
    method: 'get',
    params: { modelName: 'Veterinarians' },
  });

  useRequestData<Diagnose[]>({
    needTransport: needToUpdate && !isLoading,
    dispatch: dispatchPageDiagnoses,
    method: 'post',
    url: '/diagnoses',
    data: {
      options: filterOptions,
      patient: isTestPatient
        ? getTestPatient(testPatientId)
        : { Id: parseInt(patientId!) },
      symptoms: Object.entries(selectedSymptomsObj).map(([Id, Mandatory]) => ({
        Id: parseInt(Id),
        ...(Mandatory && { Mandatory }),
      })),
    },
    dispatchOptions: categories,
  });

  useEffect(() => {
    if (!skipJoyride || !veterinarian) return;
    if (uiState.diagnoses_page?.skip_joyride) return;
    API.saveVeterinarian({
      ...veterinarian,
      UIStorage: JSON.stringify({
        diagnoses_page: {
          skip_joyride: true,
        },
      }),
    }).then(() => {
      setReloadVeterinarian(true);
    });
  }, [skipJoyride, veterinarian, uiState]);

  const needCategories =
    !errorData && !isLoadingData && categories.length === 0;

  const needTypes =
    !errorData &&
    !isLoadingData &&
    categories.length > 0 &&
    symptomTypes.length === 0;

  const needSymptoms =
    !errorData &&
    !isLoadingData &&
    categories.length > 0 &&
    symptomTypes.length > 0 &&
    symptoms.length === 0;

  useRequestData<SymptomTypeCategory[]>({
    needTransport: needCategories,
    dispatch: dispatchSymptoms,
    method: 'get',
    params: { modelName: 'SymptomTypeCategories' },
  });

  useRequestData<SymptomType[]>({
    needTransport: needTypes,
    dispatch: dispatchSymptoms,
    method: 'get',
    params: { modelName: 'SymptomTypes' },
  });

  useRequestData<Symptom[]>({
    needTransport: needSymptoms,
    dispatch: dispatchSymptoms,
    method: 'get',
    params: { modelName: 'Symptoms' },
  });

  if (errorData) {
    dispatchSymptoms({ type: 'reset' });
    throw errorData;
  }

  if (
    categories.length === 0 ||
    symptomTypes.length === 0 ||
    symptoms.length === 0
  ) {
    return <Loader showLoader={true} />;
  }

  if (error) {
    dispatchPageDiagnoses({ type: 'reset' });
    throw error;
  }

  const updateJoyride: (data: CallBackProps) => void = ({ action }) => {
    switch (action) {
      case 'close':
        setJoyrideActive(false);
        if (currentStep === 0) setCurrentStep(currentStep + 1);
        break;
    }
  };

  return (
    <>
      <ReactJoyride
        run={joyrideActive}
        steps={joyrideSteps}
        callback={updateJoyride}
        stepIndex={currentStep}
        hideBackButton={true}
        scrollOffset={350}
      />
      <MainContent id="diagnose-container">
        <CardSymptoms id="symptom-container" />
        <CardAddedSymptomsContainer
          setDiagnosesFilterOptions={searchDifferentials}
        />
        <CardDiagnoses
          highlightBreedId={breedId}
          isFocused={!needToUpdate}
          updateDifferentials={updateDifferentials}
        />
        <Loader showLoader={isLoading} />
        <DiagnosesFloatingActionBar />
      </MainContent>
    </>
  );
};
