import React, { Reducer, useCallback, useEffect, useReducer } from 'react';
import Hidden from '@mui/material/Hidden';
import { matchPath, useLocation } from 'react-router-dom';

import ProgressBar from './ProgressBar';
import {
  ROUTE_CURRENT_PATIENT,
  ROUTE_CURRENT_VISIT_SAVED,
  ROUTE_DIAGNOSE_HOME,
  ROUTE_NEW_PATIENT,
  ROUTE_NEW_VISIT,
  ROUTE_VISIT_DIAGNOSIS,
  ROUTE_VISIT_DOSAGES,
  ROUTE_VISIT_SUMMARY,
  ROUTE_VISIT_TREATMENTS,
} from '../config/routes.config';
import { usePatientInfo } from '../contexts/CurrentPatientInfoContext';

interface PatientParams {
  patientId: string;
}

type Step = {
  activeStep: number;
  isStepDone?: boolean;
};

type StepAction =
  | { type: 'step'; activeStep: number; isStepDone?: boolean }
  | { type: 'name'; name: string };

type StepState = Step & { name: string };

const initialState: StepState = {
  name: 'New patient',
  activeStep: 0,
  isStepDone: false,
};

const stepStateReducer: Reducer<StepState, StepAction> = (
  state,
  action
): StepState => {
  switch (action.type) {
    case 'name': {
      return {
        name: action.name,
        activeStep: state.activeStep,
        isStepDone: state.isStepDone,
      };
    }
    case 'step': {
      return {
        name: state.name,
        activeStep: action.activeStep,
        isStepDone: action.isStepDone || false,
      };
    }
    default:
      return state;
  }
};

export const PatientNavigation = () => {
  const [state, dispatch] = useReducer(stepStateReducer, initialState);
  const steps = [
    state.name,
    'Visit',
    'Diagnosis',
    'Treatment',
    'Dosage',
    'Summary',
  ];

  const { pathname } = useLocation();
  const { name } = usePatientInfo();

  const matchStep = useCallback(() => {
    const stepCreatePatient = matchPath(pathname, {
      path: ROUTE_NEW_PATIENT,
    });
    const stepDiagnoseHome = matchPath(pathname, {
      path: ROUTE_DIAGNOSE_HOME,
    });

    if (stepCreatePatient?.isExact) {
      dispatch({ type: 'name', name: initialState.name });
    } else {
      const stepCurrentPatient = matchPath<PatientParams>(pathname, {
        path: ROUTE_CURRENT_PATIENT,
      });

      if (name) {
        dispatch({ type: 'name', name });
      } else if (state.name === initialState.name) {
        dispatch({ type: 'name', name: 'Patient' });
      }

      const stepSummary = matchPath(pathname, {
        path: ROUTE_VISIT_SUMMARY,
      });

      if (stepSummary?.isExact) {
        dispatch({ type: 'step', activeStep: 5 });
      } else {
        const stepDosages = matchPath(pathname, {
          path: ROUTE_VISIT_DOSAGES,
        });

        if (stepDosages?.isExact) {
          dispatch({ type: 'step', activeStep: 4 });
        } else {
          const stepTreatments = matchPath(pathname, {
            path: ROUTE_VISIT_TREATMENTS,
          });

          if (stepTreatments?.isExact) {
            dispatch({ type: 'step', activeStep: 3 });
          } else {
            const stepDiagnosis = matchPath(pathname, {
              path: ROUTE_VISIT_DIAGNOSIS,
            });

            if (stepDiagnosis?.isExact) {
              dispatch({ type: 'step', activeStep: 2 });
            } else {
              const stepVisitCreated = matchPath(pathname, {
                path: ROUTE_CURRENT_VISIT_SAVED,
              });

              if (stepVisitCreated?.isExact) {
                dispatch({ type: 'step', activeStep: 1, isStepDone: true });
              } else {
                const stepVisit = matchPath(pathname, {
                  path: ROUTE_NEW_VISIT,
                });
                if (stepVisit) {
                  dispatch({ type: 'step', activeStep: 1 });
                } else {
                  if (stepCurrentPatient) {
                    if (
                      stepCurrentPatient?.isExact &&
                      stepCurrentPatient.params.patientId === 'select'
                    ) {
                      dispatch({ type: 'step', activeStep: -1 });
                    } else {
                      dispatch({ type: 'step', activeStep: 0 });
                    }
                  } else if (stepDiagnoseHome) {
                    dispatch({ type: 'step', activeStep: 0 });
                  } else {
                    dispatch({ type: 'step', activeStep: -1 });
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [name, pathname, state.name]);

  useEffect(() => {
    matchStep();
  }, [matchStep]);

  if (state.activeStep === -1) {
    return null;
  }

  return (
    <Hidden smDown>
      <ProgressBar
        activeStep={state.activeStep}
        stepDone={state.isStepDone || false}
        steps={steps}
      />
    </Hidden>
  );
};
