import React from 'react';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Theme, useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { SideNavigation } from './SideNavigation';
import { DRAWER_WIDTH } from './NavigationConfig';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CSSObject } from 'tss-react';

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of MemberExpression.
const useStyles = makeStyles()((theme: Theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
      [theme.breakpoints.down('lg')]: {
        minHeight: '100%',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: 'calc(100vh - 64px - 20px)',
      },
      boxShadow: '0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
  },
  toolbar: theme.mixins.toolbar as CSSObject,
  drawerPaper: {
    width: DRAWER_WIDTH,
    backgroundColor: '#f5f3f2',
    color: theme.palette.common.black,
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
}));

export const DrawerNav = ({
  isOpen,
  toggleNavOpen,
}: {
  isOpen: boolean;
  toggleNavOpen: () => void;
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const drawer = <SideNavigation {...(matches && { toggleNavOpen })} />;

  return (
    <nav className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={isOpen}
          onClose={toggleNavOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <IconButton
            onClick={toggleNavOpen}
            className={classes.closeMenuButton}
            size="large"
          >
            <CloseIcon />
          </IconButton>

          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.toolbar} />
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};
