import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
// import { ReactComponent as Dashboard } from './dashboard_24px.svg';

const DashboardIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g transform="translate(1.000000, 1.000000)">
        <path
          d="M19,0 L3,0 C1.34314575,0 0,1.34314575 0,3 L0,19 C0,20.6568542 1.34314575,22 3,22 L19,22 C20.6568542,22 22,20.6568542 22,19 L22,3 C22,1.34314575 20.6568542,0 19,0 Z M3,2 L19,2 C19.5522847,2 20,2.44771525 20,3 L20,19 C20,19.5522847 19.5522847,20 19,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,3 C2,2.44771525 2.44771525,2 3,2 Z"
          id="Rectangle"
          fill="#444444"
          fillRule="nonzero"
        ></path>
        <path
          d="M17,5 C17.5522847,5 18,5.44771525 18,6 L18,11 C18,11.5522847 17.5522847,12 17,12 L10,12 C9.44771525,12 9,11.5522847 9,11 L9,6 C9,5.44771525 9.44771525,5 10,5 L17,5 Z M16,7 L11,7 L11,10 L16,10 L16,7 Z"
          id="Rectangle"
          fill="#444444"
          fillRule="nonzero"
        ></path>
        <path
          d="M17,14 C17.5522847,14 18,14.4477153 18,15 L18,16 C18,16.5522847 17.5522847,17 17,17 L10,17 C9.44771525,17 9,16.5522847 9,16 L9,15 C9,14.4477153 9.44771525,14 10,14 L17,14 Z"
          id="Rectangle-Copy"
          fill="#444444"
          fillRule="nonzero"
        ></path>
        <path
          d="M6,0 L3,0 C1.34314575,0 0,1.34314575 0,3 L0,19 C0,20.6568542 1.34314575,22 3,22 L6,22 C6.55228475,22 7,21.5522847 7,21 L7,1 C7,0.44771525 6.55228475,0 6,0 Z M5,2 L5,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,3 L2.00672773,2.88337887 C2.06449284,2.38604019 2.48716416,2 3,2 L5,2 Z"
          id="Rectangle-Copy-2"
          fill="#444444"
          fillRule="nonzero"
        ></path>
      </g>
    </SvgIcon>
  );
};

export default DashboardIcon;
