import React from 'react';
import Grid from '@mui/material/Grid';
import { DrugSubstanceDose_Phase } from '../../types/DrugDosages.type';
import {
  getDose,
  getDuration,
  getInterval,
} from '../DrugDosageTable/DrugDosageTableUtils';

export const RecommendedDosage = ({
  dosePhase,
}: {
  dosePhase: DrugSubstanceDose_Phase;
}) => {
  return (
    <>
      <Grid item xs={12} style={{ textAlign: 'left', fontWeight: 'bold' }}>
        RECOMMENDED DOSAGE
      </Grid>
      <Grid item xs={4} style={{ textAlign: 'right', paddingRight: '1em' }}>
        Administration route
      </Grid>
      <Grid item xs={8} style={{ textAlign: 'left' }}>
        {dosePhase.DrugAdministrationRoute.Name}
      </Grid>
      <Grid item xs={4} style={{ textAlign: 'right', paddingRight: '1em' }}>
        Dose
      </Grid>
      <Grid item xs={8} style={{ textAlign: 'left' }}>
        {getDose(dosePhase)}
      </Grid>
      <Grid item xs={4} style={{ textAlign: 'right', paddingRight: '1em' }}>
        Dosage interval
      </Grid>
      <Grid item xs={8} style={{ textAlign: 'left' }}>
        {getInterval(dosePhase)}
      </Grid>
      <Grid item xs={4} style={{ textAlign: 'right', paddingRight: '1em' }}>
        Treatment duration
      </Grid>
      <Grid item xs={8} style={{ textAlign: 'left', paddingRight: '1em' }}>
        {getDuration(dosePhase)}
      </Grid>
    </>
  );
};
