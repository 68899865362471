import React from 'react';
import {
  useTreatmentsPage,
  useTreatmentsPageDispatch,
} from '../../contexts/TreatmentsPageContext';
import { Treatment } from '../../types/Diagnose.type';
import { TreatmentCard } from '../diseases-and-treatments/TreatmentCard';

export const TreatmentsInfo = ({
  treatmentSetTreatments,
  canSelect,
}: {
  treatmentSetTreatments: Treatment[] | null;
  canSelect: boolean;
}) => {
  const { selectedTreatments } = useTreatmentsPage();
  const dispatchTreatmentsPage = useTreatmentsPageDispatch();

  const toggleSelect = (treatment: Treatment, checked: boolean) => {
    dispatchTreatmentsPage({
      type: 'setTreatmentSelected',
      checked,
      treatment,
    });
  };

  if (!treatmentSetTreatments) {
    return null;
  }

  return (
    <>
      {treatmentSetTreatments.map((treatment) => {
        return (
          <TreatmentCard
            key={treatment.Id}
            treatment={treatment}
            {...(canSelect && {
              checked: selectedTreatments.indexOf(treatment.Id) > -1,
              toggleSelect,
            })}
          />
        );
      })}
    </>
  );
};
