import {
  CalculationUnit,
  DrugMgPerUnit,
  DrugUnit,
} from '../types/DrugDosages.type';

export const determineDrugMgPerUnit = (
  toBeDetermined: CalculationUnit
): toBeDetermined is DrugMgPerUnit => {
  if (
    (toBeDetermined as DrugMgPerUnit) === DrugMgPerUnit['mg/ml'] ||
    (toBeDetermined as DrugMgPerUnit) === DrugMgPerUnit['mg/tablet'] ||
    (toBeDetermined as DrugMgPerUnit) === DrugMgPerUnit['mg/capsule']
  ) {
    return true;
  }
  return false;
};

export const determineUnit = (
  toBeDetermined: CalculationUnit
): toBeDetermined is DrugUnit => {
  if (
    (toBeDetermined as DrugUnit) === DrugUnit['ml'] ||
    (toBeDetermined as DrugUnit) === DrugUnit['tablet(s)'] ||
    (toBeDetermined as DrugUnit) === DrugUnit['capsule(s)']
  ) {
    return true;
  }
  return false;
};
