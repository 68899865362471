import { Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { DrugDosageTable } from '../DrugDosageTable/TableComponents';
import {
  DoseAlternative,
  DrugSubstanceInfo,
} from '../../types/DrugDosages.type';

export const DosagesInfo = ({
  drugSubstanceInfo,
  setCalculatorDoseAlternative,
  mobile,
  drugSubstanceName,
}: {
  drugSubstanceInfo: DrugSubstanceInfo;
  setCalculatorDoseAlternative: (doseAlternative: DoseAlternative) => void;
  mobile: boolean;
  drugSubstanceName?: string;
}) => {
  return (
    <Fragment>
      {drugSubstanceInfo.DrugSubstance_Doses.length > 0 ? (
        <DrugDosageTable
          drugSubstanceInfo={drugSubstanceInfo}
          drugSubstanceName={drugSubstanceName}
          dosageInfoDefaultExpanded={true}
          mobile={mobile}
          isDiseasesAndTreatments={true}
          setCalculatorDoseAlternative={setCalculatorDoseAlternative}
        />
      ) : (
        <Typography>Treatment has no doses</Typography>
      )}
    </Fragment>
  );
};
