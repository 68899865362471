import APILayer from './ApiLayer';
import endpoints from '../config/constants';
import {
  IntegrationData,
  ProvetIntegrationInfo,
} from '../types/Integration.type';
import { VetProfile } from '../types/Profile.types';

export interface Api {
  getModel: string;
  createPatient: string;
  createVisit: string;
}
const API = {
  createPmsIntegrationSymptomsRequest(data: { anamnesis: string }) {
    return new Promise((resolve, reject) => {
      const resp = APILayer.postRequest('/pms-integration/symptoms', data, {});
      resolve(resp);
      return resp;
    });
  },
  getPmsIntegrationSymptomsResult(run_id: string, thread_id: string) {
    // use run_id and thread_id in query string to get the result
    return new Promise((resolve, reject) => {
      const url = `/pms-integration/symptoms?run_id=${run_id}&thread_id=${thread_id}`;
      const resp = APILayer.getRequest(url, {}, {});
      resolve(resp);
      return resp;
    });
  },
  trackEvent(event_name: string, event_data?: string, options = {}) {
    return new Promise((resolve, reject) => {
      const resp = APILayer.postRequest(
        endpoints.trackEventEndpoint(),
        { event_name, event_data },
        options
      );
      resolve(resp);
      return resp;
    });
  },
  saveVeterinarian(data: VetProfile) {
    return APILayer.postRequest('/profile', { profile: data });
  },
  getModel(data: any, options = {}) {
    return new Promise((resolve, reject) => {
      const resp = APILayer.getRequest(
        endpoints.getModelEndpoint(),
        data,
        options
      );
      resolve(resp);
      return resp;
    });
  },
  createPatient(body: any, options = {}) {
    return new Promise((resolve, reject) => {
      const resp = APILayer.postRequest(
        endpoints.getPatientEndpoint(),
        body,
        options
      );
      resolve(resp);
      return resp;
    });
  },
  async getDiseaseInfos(animalTypeId: string) {
    const work = (offset = 0) =>
      new Promise((resolve, reject) => {
        const resp = APILayer.getRequest(
          endpoints.getDiseaseInfosEndpoint() + '/' + animalTypeId,
          { offset }
        );
        resolve(resp);
        return resp;
      });
    let current;
    const initial = (current = await work(0));
    const hasMore = (res: any) => {
      const headers = (res as any).headers as { [key: string]: string };
      return (
        Number(headers['pagination-count']) >
        Number(headers['pagination-page']) * Number(headers['pagination-limit'])
      );
    };
    while (hasMore(current)) {
      const headers = (current as any).headers as { [key: string]: string };
      current = await work(
        Number(headers['pagination-page']) * Number(headers['pagination-limit'])
      );
      (initial as any).body.push(...(current as any).body);
    }
    return initial;
  },
  getDiseaseInfoDetails(detailId: string) {
    return new Promise((resolve, reject) => {
      const resp = APILayer.getRequest(
        endpoints.getDiseasesInfoDetailsEndpoint() + '/' + detailId,
        {}
      );
      resolve(resp);
      return resp;
    });
  },
  getTreatments(diseaseId: number) {
    return new Promise((resolve, reject) => {
      const resp = APILayer.getRequest(
        endpoints.getTreatmentsEndpoint() +
          '?animalTypeDiseaseIds=' +
          String(diseaseId),
        {}
      );
      resolve(resp);
      return resp;
    });
  },
  updatePatient(body: any, options = {}, patientId: string) {
    return new Promise((resolve, reject) => {
      const resp = APILayer.putRequest(
        endpoints.getPatientEndpoint() + '/' + patientId,
        body,
        options
      );
      resolve(resp);
      return resp;
    });
  },
  createVisit(body: any, options = {}) {
    return new Promise((resolve, reject) => {
      const resp = APILayer.postRequest(
        endpoints.getVisitsEndpoint(),
        body,
        options
      );
      resolve(resp);
      return resp;
    });
  },
  updateVisit(body: any, options = {}, visitId: string) {
    return new Promise((resolve, reject) => {
      const resp = APILayer.putRequest(
        endpoints.getVisitsEndpoint() + '/' + visitId,
        body,
        options
      );
      resolve(resp);
      return resp;
    });
  },
  getDrugSubstances(animalTypeId: string) {
    return new Promise((resolve, reject) => {
      const resp = APILayer.getRequest(
        endpoints.getDrugSubstancesEndpoint() +
          `?animalTypeId=${animalTypeId || ''}`,
        {}
      );
      resolve(resp);
      return resp;
    });
  },
  getDrugSubstancesInfos(animalTypeId: string, drugSubstanceId: string) {
    return new Promise((resolve, reject) => {
      const resp = APILayer.getRequest(
        endpoints.getDrugSubstancesInfoEndpoint() +
          `/?animalTypeId=${animalTypeId}&drugSubstanceId=${drugSubstanceId}`,
        {}
      );
      resolve(resp);
      return resp;
    });
  },
  getTreatmentDosages(
    treatmentId: string,
    animalTypeId: string,
    drugSubstanceId: string
  ) {
    return new Promise((resolve, reject) => {
      const resp = APILayer.getRequest(
        endpoints.getDrugSubstancesInfoEndpoint() +
          `/?animalTypeId=${animalTypeId}&drugSubstanceIds=${drugSubstanceId}&treatmentIds=${treatmentId}`,
        {}
      );
      resolve(resp);
      return resp;
    });
  },
  getIntegrationPatient(
    integrationSource: string,
    integrationConnectionInfo: ProvetIntegrationInfo,
    integrationInfo: IntegrationData
  ) {
    return new Promise((resolve, reject) => {
      const resp = APILayer.postRequest(endpoints.getIntegrationPatientInfo(), {
        integrationSource,
        integrationInfo,
        integrationConnectionInfo,
      });
      resolve(resp);
      return resp;
    });
  },
  getIntegrationVisit(
    integrationSource: string,
    integrationConnectionInfo: ProvetIntegrationInfo,
    integrationInfo: IntegrationData
  ) {
    return new Promise((resolve, reject) => {
      const resp = APILayer.postRequest(endpoints.getIntegrationVisitInfo(), {
        integrationSource,
        integrationInfo,
        integrationConnectionInfo,
      });
      resolve(resp);
      return resp;
    });
  },

  createStripePortalSession(subscriptionId: string) {
    return new Promise((resolve, reject) => {
      const resp = APILayer.postRequest(
        endpoints.createStripePortalSessionEndpoint(),
        { subscriptionId }
      );
      resolve(resp);
      return resp;
    });
  },
};

export default API;
