import React, { Fragment, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from 'tss-react/mui';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FieldProps, ExtraFieldProps } from '../../types/form.type';
import FormHelperText from '@mui/material/FormHelperText';
import {
  validateAtLeastOneNumber,
  validateLength,
  validateUpperAndLowerCaseLetter,
} from '../../utils/validators';
import { TextField } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  field: {
    margin: theme.spacing(1),
    width: '90%',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    [theme.breakpoints.only('xs')]: {
      marginLeft: '0',
    },
  },
  errorTextField: {
    color: theme.palette.error.main,
    fontSize: 14,
    whiteSpace: 'pre-line',
  },
}));

export function PasswordField<T extends string>({
  name,
  label,
  helperText = ' ',
  validator,
  currentValue = '',
  errorText,
  setValue,
  error,
  required,
}: FieldProps<T> & ExtraFieldProps<T> & { name: T }) {
  const { classes } = useStyles();
  const [state, setState] = useState(() => ({
    value: currentValue,
    error: '',
    showPassword: false,
  }));

  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const error = validator ? validator(newValue) : '';

    setState({ ...state, value: newValue, error });
    if (newValue !== currentValue) {
      setValue({ name, value: error === '' ? newValue : '' });
    }
  };

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const handleBlur = () => {
    const error = validator ? validator(state.value) : '';
    setState((state) => ({
      ...state,
      error,
    }));
    if (state.value !== currentValue) {
      setValue({ name, value: error === '' ? state.value : '' });
    }
  };

  const errorHelperText = () => {
    if (errorText) return errorText;
    return (
      <Fragment>
        <FormHelperText
          id="password-helper-text"
          className={classes.errorTextField}
        >
          Password needs to have:
          {<br />}
          {!validateLength(state.value) && '- 8 characters \n'}
          {!validateUpperAndLowerCaseLetter(state.value) &&
            '- One uppercase and one lowercase letter \n'}
          {!validateAtLeastOneNumber(state.value) && '- One number \n'}
        </FormHelperText>
      </Fragment>
    );
  };

  return (
    <TextField
      className={`${classes.field} ${classes.textField}`}
      id={name}
      size="small"
      style={{ width: '100%' }}
      required={required}
      type={state.showPassword ? 'text' : 'password'}
      value={state.value}
      onChange={handleChange()}
      onBlur={handleBlur}
      error={state.error === '' ? error : true}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              size="large"
            >
              {state.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      label={label}
      helperText={error ? errorHelperText() : helperText}
    />
  );
}
