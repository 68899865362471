import { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { CardSymptoms } from '../components/symptoms/CardSymptoms';
import { QuestionMarkOutlined } from '@mui/icons-material';
import fallbacks from './provet-fallback';
import { useLocation } from 'react-router-dom';
import GekkoSpinner from '../assets/spinners/GekkoSpinner';
import { DiagnosesPage } from '../pages/DiagnosesPage';
import { useDiagnosesPageDispatch } from '../contexts/DiagnosesPageContext';
import { usePatientInfoDispatch } from '../contexts/CurrentPatientInfoContext';
import { TestPatient } from '../types/Patient.type';

type ExtractedSymptom = {
  id: number;
  reason: string;
  name: string;
};

/*const anamnesis = `
MUSKA, Kissa, Steriloitu naaras

Kotiutusohjeet:

Muskan ruokahalu on pikkuhiljaa heikentynyt. Muska on allerginen ja reagoi ainakin naudan ja sianlihalle. Muska syö tällä hetkellä prednisolonia 5 mg päivässä.

Muskan hampaissa on hieman hammaskiveä, eikä selviä hammassyöpymävaurioita näy. Myöskään kielen alla tai kitalaessa ei ole vaurioita/haavaumia. Mahdollisesti Muskalle kuitenkin on kehittymässä hampaaseen syöpymävaurio, koska Muska on nyt syönyt juuri kuivamuonaa huonommin.

Muska jännittää hieman vatsan tunnustelua. Vatsaontelon ultraäänitutkimuksessa virtsarakon seinämä ja sisältö ovat normaalit. Munuaisten koko on normaalia hieman pienempi, mutta rakenne ja muoto ovat vielä normaalit. Munuaisten kaikuisuus on hieman lisääntynyt, mutta Muskan ikäiselle kissalle munuaisten ultraäänilöydökset eivät vaikuta merkittäviltä. Pernan ja maksan rakenne ja kaikuisuus oat normaalit. Sappirakossa Muskalla on hieman voimakaskaikuisempaa sakkaa, mutta Muskan sappitiehyet eivät vaikuta laajentuneen. Haiman alueella ei ole reaktiota. Muskan suolisto näyttää näkyvin osin rauhalliselta, eikä suolten seinämissä ole paksuuntumia. Myöskään imusolmukkeet eivät ole suurentuneet.

Verenpaineet ovat klinikkaolosuhteissa kohtalaisesti koholla (valkotakkiefekti). verenpaine on kuitenkin hyvä kontrolloida 1-2 kk kuluttua.
`;

const fallback_string = `[ { "id": 2491, "name": "Dental (tooth) abnormality", "reason": "Muskan hampaissa on hieman hammaskiveä, eikä selviä hammassyöpymävaurioita näy." }, { "id": 328, "name": "Abdominal pain - Unspecified", "reason": "Muska jännittää hieman vatsan tunnustelua." }, { "id": 999, "name": "Mucous membranes abnormal - Grey/'muddy'", "reason": "Verenpaineet ovat klinikkaolosuhteissa kohtalaisesti koholla (valkotakkiefekti)." } ]`;
*/
const PmsDiagnoses = () => {
  const [showFullAnamnesis, setShowFullAnamnesis] = useState(false);
  const [showAddSymptoms, setShowAddSymptoms] = useState(false);
  const [symptomsLoading, setSymptomsLoading] = useState(false);
  const [anamnesis, setAnamnesis] = useState('');
  const [continueToDiagnose, setContinueToDiagnose] = useState(false);
  const dispatchPatientInfo = usePatientInfoDispatch();
  const dispatchDiagnosesPage = useDiagnosesPageDispatch();
  const [extractedSymptoms, setExtractedSymptoms] = useState<
    ExtractedSymptom[]
  >([]);
  const [selectedSymptoms, setSelectedSymptoms] = useState<number[]>([]);
  const { search } = useLocation();

  useEffect(() => {
    if (!continueToDiagnose) return;
    const animalTypeId = 1;
    const patient: TestPatient = {
      Id: 1,
      NameOrId: 'Patient name',
      AnimalTypeId: animalTypeId,
      GenderId: 1,
      Neutered: 0,
      Weight: '15',
      BreedId: 1,
      PetOwnerId: -1,
      DateOfBirth: '2021-01-01',
      Identifier: '',
      TestPatient: true,
    };
    dispatchPatientInfo({
      type: 'setPatientInfo',
      patient,
      animalTypeName: '',
    });
    for (const symptomId of selectedSymptoms) {
      console.log('dispatching', symptomId);
      dispatchDiagnosesPage({
        type: 'addSymptom',
        symptomId,
      });
    }
  }, [
    continueToDiagnose,
    dispatchPatientInfo,
    selectedSymptoms,
    dispatchDiagnosesPage,
  ]);
  useEffect(() => {
    setSelectedSymptoms(extractedSymptoms.map((symptom) => symptom.id));
  }, [extractedSymptoms]);
  useEffect(() => {
    // parse consultation_id from search
    const searchParams = new URLSearchParams(search);
    const search_consultation_id = searchParams.get('consultation_id');
    setSymptomsLoading(true);
    setTimeout(() => {
      const consultation = fallbacks.find(
        ({ consultation_id }) =>
          Number(search_consultation_id) === consultation_id
      );
      setExtractedSymptoms(JSON.parse(consultation?.llm_response || '[]'));
      setAnamnesis(consultation?.anamnesis || '');
      setSymptomsLoading(false);
    }, 3000);
    /*
    api
      .createPmsIntegrationSymptomsRequest({ anamnesis })
      .then(async (response: any) => {
        const { run_id, thread_id } = response.body;
        let resp = (await api.getPmsIntegrationSymptomsResult(
          run_id,
          thread_id
        )) as any;
        while (
          resp.body.status === 'in_progress' ||
          resp.body.status === 'queued'
        ) {
          await new Promise((resolve) => setTimeout(resolve, 5000));
          resp = (await api.getPmsIntegrationSymptomsResult(
            run_id,
            thread_id
          )) as any;
        }
        setSymptomsLoading(false);
        if (resp.body.status === 'failed') {
          const data = JSON.parse(fallback_string);
          setExtractedSymptoms(data);
          return;
        }
        const text = resp.body.data[0].content[0].text.value
          .replace(/^```json/, '')
          .replace(/```$/, '');

        const data = JSON.parse(text);
        setExtractedSymptoms(data);
      });
      */
    /*setTimeout(() => {
      const data = JSON.parse(fallback_string);
      setExtractedSymptoms(data);
    }, 5000);*/
  }, [search]);
  return (
    <div>
      <Typography variant="h4">Search for differential diagnoses</Typography>
      <Typography variant="subtitle1">
        This tool will analyze the clinical notes and offer a list of possible
        differential diagnoses based on the symptoms.
      </Typography>
      {symptomsLoading ? (
        <div style={{ textAlign: 'center', padding: '3em' }}>
          <GekkoSpinner />
        </div>
      ) : continueToDiagnose ? (
        <DiagnosesPage />
      ) : (
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Typography variant="h5">Clinical notes</Typography>
            <Typography
              component="pre"
              fontStyle={'italic'}
              fontSize={'80%'}
              onClick={() => setShowFullAnamnesis(!showFullAnamnesis)}
            >
              {showFullAnamnesis ? anamnesis : anamnesis.substring(0, 100)}
              {anamnesis.length > 100 && !showFullAnamnesis ? '...' : ''}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Typography variant="h5">Symptoms</Typography>
            <Typography variant="subtitle1">
              Following symptoms were extracted from the clinical notes
              provided. Select applicable symptoms to start diagnosing.
            </Typography>
            {symptomsLoading ? 'Loading...' : null}
            {!symptomsLoading && extractedSymptoms.length === 0 ? (
              'No symptoms found'
            ) : (
              <Table size="small">
                <TableBody>
                  {extractedSymptoms.map((symptom) => (
                    <TableRow key={symptom.id}>
                      <TableCell>
                        <Checkbox
                          checked={selectedSymptoms.some(
                            (s) => s === symptom.id
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedSymptoms([
                                ...selectedSymptoms,
                                symptom.id,
                              ]);
                            } else {
                              setSelectedSymptoms(
                                selectedSymptoms.filter((s) => s !== symptom.id)
                              );
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {symptom.name}
                      </TableCell>
                      <TableCell style={{ textAlign: 'left', width: '100%' }}>
                        <Tooltip title={symptom.reason}>
                          <Button>
                            <QuestionMarkOutlined />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            <Modal
              onClose={() => setShowAddSymptoms(false)}
              open={showAddSymptoms}
            >
              <CardSymptoms />
            </Modal>
          </Grid>
          <Grid item sm={12}>
            <Divider />
            <p>
              <Button
                onClick={() => setContinueToDiagnose(true)}
                variant="outlined"
                color="primary"
              >
                Continue
              </Button>
            </p>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default PmsDiagnoses;
