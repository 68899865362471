import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Fragment, useState } from 'react';
import {
  ROUTE_HOME,
  ROUTE_VISIT_DOSAGES,
  ROUTE_VISIT_SUMMARY,
} from '../../config/routes.config';
import { useWorkHistory } from '../../hooks/useWorkHistory';
import { ButtonTo } from '../common/ButtonTo';
import { FloatingActionBar } from '../FloatingActionBar';
import { Button } from '../Button';
import { useIntegrationContext } from '../../contexts/IntegrationContext';
import axios from 'axios';
import useAuthUser from '../../hooks/useAuthentication';
import { Loader } from '../Loader';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';

export const SummaryFloatingActionBar = ({
  integrationCase = false,
  getSummaryString,
}: {
  integrationCase: boolean;
  getSummaryString: () => void;
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const { exportState } = useWorkHistory();
  const mobile = useMediaQuery('(max-width:600px)');

  const { isTestPatient } = usePatientInfo();

  const { signOut } = useAuthUser();

  const { integrationConnectionInfo, integrationInfo, integrationSource } =
    useIntegrationContext();

  const handleSave = () => {
    if (!isTestPatient) {
      exportState();
    }
  };

  const handleIntegrationCaseClose = async () => {
    try {
      setShowLoader(true);
      await exportState();

      const data = {
        integrationConnectionInfo,
        integrationInfo,
        integrationSource,
        postData: { summaryString: getSummaryString() },
      };

      // Send summary info to the Service this user came from.
      await axios.post('/integration/summary', data);

      signOut();
      window.close();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      {showLoader && <Loader showLoader={true} />}
      <FloatingActionBar>
        <Grid item xs={12} container spacing={3}>
          {mobile ? (
            <Fragment>
              <Grid item xs={4}>
                {integrationCase ? (
                  <Typography align="right">
                    <Button
                      onClick={handleIntegrationCaseClose}
                      title="Send summary & return"
                      color="primary"
                      variant="contained"
                    >
                      Send summary & return
                    </Button>
                  </Typography>
                ) : (
                  <Typography align="right">
                    <ButtonTo
                      handleClick={handleSave}
                      title={isTestPatient ? 'Close' : 'Save & close'}
                      toRoute={ROUTE_HOME}
                      iconName="home"
                      mobile={mobile}
                    />
                  </Typography>
                )}
              </Grid>
              <Grid item xs={8}>
                <Typography align="right">
                  <ButtonTo
                    currentRoute={ROUTE_VISIT_SUMMARY}
                    handleClick={handleSave}
                    secondary
                    title="Back to dosages"
                    toRoute={ROUTE_VISIT_DOSAGES}
                    iconName="back"
                    mobile={mobile}
                  />
                </Typography>
              </Grid>
            </Fragment>
          ) : (
            <Fragment>
              <Grid item xs={12} sm={10}>
                <Typography align="left">
                  <ButtonTo
                    currentRoute={ROUTE_VISIT_SUMMARY}
                    handleClick={handleSave}
                    secondary
                    title="Back to dosages"
                    toRoute={ROUTE_VISIT_DOSAGES}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                {integrationCase ? (
                  <Typography align="right">
                    <Button
                      onClick={handleIntegrationCaseClose}
                      title="Send summary & return"
                      color="primary"
                      variant="contained"
                    >
                      Send summary & return
                    </Button>
                  </Typography>
                ) : (
                  <Typography align="right">
                    <ButtonTo
                      handleClick={handleSave}
                      title={isTestPatient ? 'Close' : 'Save & close'}
                      toRoute={ROUTE_HOME}
                    />
                  </Typography>
                )}
              </Grid>
            </Fragment>
          )}
        </Grid>
      </FloatingActionBar>
    </Fragment>
  );
};
