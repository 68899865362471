import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DiseasesIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g transform="translate(1.000000, 1.000000)">
        <path
          d="M19,4 C20.6568542,4 22,5.34314575 22,7 L22,19 C22,20.6568542 20.6568542,22 19,22 L3,22 C1.34314575,22 0,20.6568542 0,19 L0,7 C0,5.34314575 1.34314575,4 3,4 L19,4 Z M19,6 L3,6 C2.48716416,6 2.06449284,6.38604019 2.00672773,6.88337887 L2,7 L2,19 C2,19.5128358 2.38604019,19.9355072 2.88337887,19.9932723 L3,20 L19,20 C19.5128358,20 19.9355072,19.6139598 19.9932723,19.1166211 L20,19 L20,7 C20,6.48716416 19.6139598,6.06449284 19.1166211,6.00672773 L19,6 Z"
          id="Rectangle"
          fill="#4444444"
          fillRule="nonzero"
        ></path>
        <path
          d="M19,4 C20.6568542,4 22,5.34314575 22,7 L22,11 L0,11 L0,7 C0,5.34314575 1.34314575,4 3,4 L19,4 Z M19,6 L3,6 C2.48716416,6 2.06449284,6.38604019 2.00672773,6.88337887 L2,7 L2,9 L20,9 L20,7 C20,6.48716416 19.6139598,6.06449284 19.1166211,6.00672773 L19,6 Z"
          id="Rectangle"
          fill="#444444"
          fillRule="nonzero"
        ></path>
        <path
          d="M15,0 C16.1045695,0 17,0.8954305 17,2 L17,4 C17,5.1045695 16.1045695,6 15,6 L7,6 C5.8954305,6 5,5.1045695 5,4 L5,2 C5,0.8954305 5.8954305,0 7,0 L15,0 Z M15,2 L7,2 L7,4 L15,4 L15,2 Z"
          id="Rectangle"
          fill="#444444"
          fillRule="nonzero"
        ></path>
        <path
          d="M11.5,12 C12.0522847,12 12.5,12.4477153 12.5,13 L12.5,14 L13.5,14 C14.0522847,14 14.5,14.4477153 14.5,15 L14.5,16 C14.5,16.5522847 14.0522847,17 13.5,17 L12.5,17 L12.5,18 C12.5,18.5522847 12.0522847,19 11.5,19 L10.5,19 C9.94771525,19 9.5,18.5522847 9.5,18 L9.5,17 L8.5,17 C7.94771525,17 7.5,16.5522847 7.5,16 L7.5,15 C7.5,14.4477153 7.94771525,14 8.5,14 L9.5,14 L9.5,13 C9.5,12.4477153 9.94771525,12 10.5,12 L11.5,12 Z"
          id="Path-3"
          fill="#444444"
          fillRule="nonzero"
        ></path>
      </g>
    </SvgIcon>
  );
};

export default DiseasesIcon;
