import { Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';

export const CalculatorRowTitle = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Typography
      style={{
        textAlign: 'left',
        fontSize: '10px',
        lineHeight: '16px',
        letterSpacing: '1.5px',
        fontWeight: 'bold',
      }}
    >
      {children}
    </Typography>
  );
};
