import { AnimalType } from '../../types/AnimalType.type';
import {
  AnimalTypeListState,
  AnimalTypeListAction,
  AnimalTypesInitialState,
} from './animalType.list.state';

export const animalTypeListReducer = (
  state: AnimalTypeListState,
  action: AnimalTypeListAction
): AnimalTypeListState => {
  switch (action.type) {
    case 'reset': {
      return { ...AnimalTypesInitialState };
    }

    case 'request': {
      return { isLoading: true, animalTypes: [], animalTypesObj: {} };
    }

    case 'success': {
      const animalTypesObj: { [x: string]: AnimalType } = {};
      action.responseData.forEach((patient: AnimalType) => {
        animalTypesObj[patient.Id] = patient;
      });

      return {
        isLoading: false,
        animalTypes: action.responseData,
        animalTypesObj,
      };
    }

    case 'failure':
      return {
        isLoading: false,
        error: action.error,
        animalTypes: [],
        animalTypesObj: {},
      };

    default:
      return state;
  }
};
