import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { dosagesPageReducer } from '../reducers/dosagesPage/dosages.page.reducer';
import {
  DosagesPageState,
  DosagesPageAction,
  DosagesPageDefault,
} from '../reducers/dosagesPage/dosages.page.state';

const DosagesPageContext = createContext<DosagesPageState>(
  {} as DosagesPageState
);
const DosagesPageDispatchContext = createContext<Dispatch<DosagesPageAction>>(
  () => {}
);

export const DosagesPageProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(dosagesPageReducer, DosagesPageDefault);

  return (
    <DosagesPageContext.Provider value={state}>
      <DosagesPageDispatchContext.Provider value={dispatch}>
        {children}
      </DosagesPageDispatchContext.Provider>
    </DosagesPageContext.Provider>
  );
};

export const useDosagesPage = (): DosagesPageState => {
  return useContext(DosagesPageContext);
};

export const useDosagesPageDispatch = () => {
  return useContext(DosagesPageDispatchContext);
};
