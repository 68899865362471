import { Fragment } from 'react';
import { Typography } from '@mui/material';
import { Card } from '../components/common/Card';

const VIDEO_URL = 'https://www.youtube.com/embed/mMbuxe8ioJs';

export const TutorialPage = () => {
  return (
    <Fragment>
      <Card title="TUTORIAL" pageView="drawerView">
        <Typography variant="subtitle1">
          Check out the GekkoVet tutorial and see how it works
        </Typography>
        <iframe
          allowFullScreen
          frameBorder={0}
          src={VIDEO_URL}
          style={{ height: '500px', width: '70%' }}
          title="tutorial-video"
        />
      </Card>
    </Fragment>
  );
};
