import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import React from 'react';
import DialogRemoveSymptom from '../symptoms/DialogRemoveSymptom';

export const SelectSymptomButton = ({
  selected,
  symptomId,
  addSymptom,
  deleteSymptom,
}: {
  selected: boolean;
  symptomId: number;
  addSymptom: (symptomId: number) => void;
  deleteSymptom: (symptomId: number) => void;
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickButton = () => {
    if (selected) {
      setOpen(true);
    } else {
      addSymptom(symptomId);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeselect = () => {
    handleClose();
    deleteSymptom(symptomId);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Button
        title={'Add'}
        variant="outlined"
        color="primary"
        size="small"
        startIcon={
          selected ? (
            <DoneIcon style={{ margin: '0', padding: '0' }} />
          ) : undefined
        }
        onClick={handleClickButton}
      >
        {selected ? 'Added' : 'Add'}
      </Button>
      {selected && (
        <DialogRemoveSymptom
          open={open}
          handleConfirm={handleDeselect}
          handleReject={handleClose}
        />
      )}
    </>
  );
};
