import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { patientListReducer } from '../reducers/patientList/patient.list.reducer';
import {
  PatientListState,
  PatientListAction,
  InitialPatientSearchOptions,
} from '../reducers/patientList/patient.list.state';
import { CurrentPatientInfoProvider } from './CurrentPatientInfoContext';
import { DiagnosesPageProvider } from './DiagnosesPageContext';
import { DosagesPageProvider } from './DosagesPageContext';
import { DrugProductsProvider } from './DrugProductsContext';
import { OtherVisitsProvider } from './OtherVisitsContext';
import { TreatmentsPageProvider } from './TreatmentsPageContext';
import { VisitsListProvider } from './VisitsListContext';

const PatientListContext = createContext<PatientListState>(
  {} as PatientListState
);
const PatientListDispatchContext = createContext<Dispatch<PatientListAction>>(
  () => {}
);

export const PatientListProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(patientListReducer, {
    isLoading: false,
    searchOptions: InitialPatientSearchOptions,
  });

  return (
    <PatientListContext.Provider value={state}>
      <PatientListDispatchContext.Provider value={dispatch}>
        <VisitsListProvider>
          <CurrentPatientInfoProvider>
            <DiagnosesPageProvider>
              <OtherVisitsProvider>
                <TreatmentsPageProvider>
                  <DosagesPageProvider>
                    <DrugProductsProvider>{children}</DrugProductsProvider>
                  </DosagesPageProvider>
                </TreatmentsPageProvider>
              </OtherVisitsProvider>
            </DiagnosesPageProvider>
          </CurrentPatientInfoProvider>
        </VisitsListProvider>
      </PatientListDispatchContext.Provider>
    </PatientListContext.Provider>
  );
};

export const usePatientListState = (): PatientListState => {
  return useContext(PatientListContext);
};

export const usePatientListDispatch = () => {
  return useContext(PatientListDispatchContext);
};
