import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { NoAuthUserHome } from '../components/NoAuthUserHome';
import {
  ROUTE_AUTH,
  ROUTE_INTEGRATION_AUTH,
  ROUTE_INTEGRATION_LOGIN,
  ROUTE_HOME,
  ROUTE_REGISTER,
  ROUTE_INTEGRATION,
} from '../config/routes.config';
import { IntegrationAuthentication } from '../pages/IntegrationAuthentication';
import { IntegrationLogin } from '../pages/IntegrationLogin';

import { Login } from '../pages/Login';
import { RegistrationPage } from '../pages/RegistrationPage';

export const NoAuthLayout = () => {
  const location = useLocation();
  return (
    <Switch>
      <Route exact path={ROUTE_AUTH}>
        <Login />
      </Route>
      <Route exact path={ROUTE_INTEGRATION_AUTH}>
        <IntegrationAuthentication />
      </Route>
      <Route exact path={ROUTE_INTEGRATION_LOGIN}>
        <IntegrationLogin />
      </Route>
      <Route exact path={ROUTE_REGISTER}>
        <RegistrationPage />
      </Route>
      <Route exact path={ROUTE_HOME}>
        <NoAuthUserHome />
      </Route>
      {!location.pathname.includes(ROUTE_INTEGRATION) && (
        <Redirect to={ROUTE_HOME}></Redirect>
      )}
    </Switch>
  );
};
