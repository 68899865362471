import React from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import { withStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DosageContent } from './DosageContent';
import { Treatment } from '../../types/Diagnose.type';
import {
  useDrugProducts,
  useDrugProductsDispatch,
} from '../../contexts/DrugProductsContext';
import { useRequestData } from '../../hooks/useRequestData';
import { DrugProduct } from '../../types/DrugProduct.type';
import { usePatientInfo } from '../../contexts/CurrentPatientInfoContext';
import { splitProtocolNameInTwoLines } from '../../utils/treatmentsUtils';

const useStyles = makeStyles()({
  drugName: {
    fontWeight: 'bold',
  },
  diseaseName: {
    letterSpacing: '1.15px',
    color: '#6E6E6E',
    fontSize: '12px',
    fontWeight: 'bold',
  },
});

const Accordion = withStyles(MuiAccordion, (_theme, _params, classes) => ({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    [`&.${classes.expanded}`]: {
      margin: 'auto',
    },
  },
  expanded: {},
}));

export const DosageAccordion = ({
  treatment,
  diseaseName,
}: {
  treatment: Treatment;
  diseaseName: string;
}) => {
  const { classes } = useStyles();
  const { AnimalTypeId } = usePatientInfo();
  const { drugProductsObj, error, isLoading } = useDrugProducts();
  const mobile = useMediaQuery('(max-width:600px)');

  const dispatchDrugProducts = useDrugProductsDispatch();
  const drugSubstanceId = treatment.DrugSubtance.Id;
  useRequestData<DrugProduct[]>({
    needTransport:
      !error && !isLoading && !(drugSubstanceId in drugProductsObj),
    dispatch: dispatchDrugProducts,
    method: 'get',
    url: '/drug-products',
    params: {
      drugSubstanceId,
      animalTypeId: AnimalTypeId,
    },
    dispatchOptions: drugSubstanceId,
  });

  if (error) {
    dispatchDrugProducts({ type: 'reset' });
    throw error;
  }

  const { title } = splitProtocolNameInTwoLines(treatment.TreatmentSet.Name);

  return (
    <Accordion square TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label={`Expand treatment ${treatment.Id}`}
        aria-controls={`dosage-content-${treatment.Id}`}
        id={`dosage-header-${treatment.Id}`}
      >
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12}>
            <Typography className={classes.diseaseName} align="left">
              {diseaseName.toUpperCase()}
            </Typography>
            <Typography className={classes.drugName} align="left">
              {treatment.Name}
            </Typography>
            <Typography align="left" style={{ color: '#6E6E6E' }}>
              From {title}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <DosageContent
        treatment={treatment}
        diseaseName={diseaseName}
        mobile={mobile}
      />
    </Accordion>
  );
};
