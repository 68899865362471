import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import React from 'react';
import { CalculatedDose } from '../../reducers/dosagesPage/dosages.page.state';
import { DiseaseInfo } from '../../types/Diagnose.type';
import { DoseAlternative } from '../../types/DrugDosages.type';
import { DosageContainer } from '../dosages/DosageContainer';

export const CalculatorDialog = ({
  open,
  handleClose,
  doseAlternative,
  animalTypeName,
  treatmentName,
  drugSubstanceId,
  diseaseInfo,
  calculatorState,
  handleCalculatedDose,
  reload,
  setReload,
  mobile,
}: {
  open: boolean;
  handleClose: () => void;
  doseAlternative: DoseAlternative;
  animalTypeName: string;
  treatmentName: string;
  drugSubstanceId: number;
  diseaseInfo?: DiseaseInfo;
  calculatorState: {
    chosenDoseAlternative: DoseAlternative | null;
    currentCalculatedDoses: CalculatedDose[];
  };
  handleCalculatedDose: (calculatedDose: CalculatedDose, index: number) => void;
  reload: boolean;
  setReload: (boolean: boolean) => void;
  mobile: boolean;
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth={'lg'}
    >
      <DialogTitle id="scroll-dialog-title">Calculator</DialogTitle>
      <DialogContent>
        {doseAlternative?.DrugSubstanceDose_Phases.map((dosePhase, index) => {
          return (
            <DosageContainer
              key={dosePhase.Id}
              animalTypeName={animalTypeName}
              treatmentName={treatmentName}
              drugSubstanceId={drugSubstanceId}
              dosePhase={dosePhase}
              calculatedDose={calculatorState.currentCalculatedDoses[index]}
              index={index}
              setCalculatedDose={handleCalculatedDose}
              reload={reload}
              resetReload={() => {
                if (reload) setReload(false);
              }}
              mobile={mobile}
              showDoseInstruction={false}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
