export const patternEmail = new RegExp(/\S+@\S+\.\S+/);

const patternPassword = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})'
);

const patternAtLeastUpperCaseLowerCase = new RegExp('^(?=.*[a-z])(?=.*[A-Z])');
const patternAtLeastOneNumber = new RegExp('^(?=.*[0-9])');
const patternSpecialCharacter = new RegExp('^(?=.*[!?":,.€%@#$%^&*])');
const patternLenght = new RegExp('^(?=.{8,})');

export const validateUpperAndLowerCaseLetter = (value: string) =>
  patternAtLeastUpperCaseLowerCase.test(value);

export const validateAtLeastOneNumber = (value: string) =>
  patternAtLeastOneNumber.test(value);

export const validateSpecialCharacter = (value: string) =>
  patternSpecialCharacter.test(value);

export const validateLength = (value: string) => patternLenght.test(value);

export const validatePassword = (value: string) =>
  value.length === 0 ? 'required' : patternPassword.test(value) ? '' : 'wrong';

export const validateEmail = (value: string) =>
  value.length === 0
    ? 'required'
    : patternEmail.test(value)
    ? ''
    : 'Not valid email';

const patternName = new RegExp(/(.*[a-z]){2}/i); // at least 2 letters

export const validateName = (value: string) =>
  value.length === 0
    ? 'required'
    : patternName.test(value)
    ? ''
    : 'Name too short';

export const validateCountry = (value: string) =>
  value.length === 0 ? 'required' : '';

export const validateVatId = (value: string) =>
  value.length === 0 ? 'required' : '';

export const validatePersonType = (value: string) =>
  isNaN(Number(value)) ? 'required' : '';
