import { Patient } from '../../types/Patient.type';
import {
  PatientListState,
  PatientListAction,
  InitialPatientSearchOptions,
} from './patient.list.state';

const sortByName = (arr: Patient[]) => {
  return arr.sort((a, b) => {
    // Fix for invalid values for database
    // Name is required in UI, but not in DB
    const aName = a.NameOrId ? a.NameOrId.toLowerCase() : '';
    const bName = b.NameOrId ? b.NameOrId.toLowerCase() : '';
    return aName > bName ? 1 : bName > aName ? -1 : 0;
  });
};

export const patientListReducer = (
  state: PatientListState,
  action: PatientListAction
): PatientListState => {
  switch (action.type) {
    case 'reset': {
      return { isLoading: false, searchOptions: state.searchOptions };
    }

    case 'request': {
      return { isLoading: true, searchOptions: state.searchOptions };
    }

    case 'success': {
      const patientsObj: { [x: string]: Patient } = {};
      action.responseData.forEach((patient: Patient) => {
        patientsObj[patient.Id] = patient;
      });
      const patients = sortByName(action.responseData);

      return {
        isLoading: false,
        patients,
        patientsObj,
        searchOptions: state.searchOptions,
      };
    }

    case 'failure':
      return {
        isLoading: false,
        error: action.error,
        searchOptions: state.searchOptions,
      };

    case 'add': {
      if (!state.patients) {
        return state;
      }
      if (
        !state.patientsObj ||
        action.patient.Id.toString() in state.patientsObj
      ) {
        return state;
      }

      const patientsObj = { ...state.patientsObj };
      patientsObj[action.patient.Id] = action.patient;

      const patients = sortByName([...state.patients, action.patient]);
      return {
        ...state,
        patients,
        patientsObj,
      };
    }

    case 'update': {
      if (!state.patients) {
        return state;
      }

      const patients = state.patients?.map((patient) => {
        if (patient.Id === action.patient.Id) {
          return { ...action.patient };
        }
        return patient;
      });
      const patientsObj = {
        ...state.patientsObj,
        [action.patient.Id]: action.patient,
      };

      return {
        ...state,
        patients,
        patientsObj,
      };
    }

    case 'delete': {
      if (!state.patients) {
        return state;
      }

      const patients = [
        ...state.patients.filter((patient) => patient.Id !== action.patientId),
      ];

      const patientId = action.patientId.toString();
      const patientsObj = {
        ...state.patientsObj,
      };
      if (patientId in patientsObj) {
        delete patientsObj[patientId];
      }

      return {
        ...state,
        patients,
        patientsObj,
      };
    }

    case 'setSearchOptions':
      return { ...state, searchOptions: action.searchOptions };

    case 'resetSearchOptions':
      return { ...state, searchOptions: InitialPatientSearchOptions };

    default:
      return state;
  }
};
