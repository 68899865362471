import { Button } from '@mui/material';
import React, { Reducer, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATIENTS } from '../../../config/routes.config';
import { useOtherVisitsDispatch } from '../../../contexts/OtherVisitsContext';
import { usePatientListDispatch } from '../../../contexts/PatientListContext';
import { useVisitsListDispatch } from '../../../contexts/VisitsListContext';
import { useRequestData } from '../../../hooks/useRequestData';
import { RequestAction } from '../../../types/request.action.type';
import Loader from '../../Loader';
import { DialogDeletePatient } from './DialogDeletePatient';

type DeletePatientState = {
  open: boolean;
  deleting?: boolean;
  confirmed?: boolean;
  deleted?: boolean;
  error?: string;
};

type DeletePatientAction =
  | RequestAction<null>
  | { type: 'openConfirmation' }
  | { type: 'closeConfirmation' }
  | { type: 'confirmedDelete' };

const initialState: DeletePatientState = {
  open: false,
};

const deletePatientStateReducer: Reducer<
  DeletePatientState,
  DeletePatientAction
> = (state, action): DeletePatientState => {
  switch (action.type) {
    case 'openConfirmation': {
      return {
        open: true,
      };
    }
    case 'closeConfirmation': {
      return {
        open: false,
      };
    }
    case 'confirmedDelete': {
      return {
        ...initialState,
        confirmed: true,
      };
    }
    case 'request': {
      return {
        ...initialState,
        deleting: true,
      };
    }
    case 'failure': {
      return {
        ...initialState,
        error: action.error,
      };
    }
    case 'success': {
      return {
        ...initialState,
        deleted: true,
      };
    }
    case 'reset': {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export const DeletePatientButton = ({
  patientName,
  patientId,
}: {
  patientName: string;
  patientId: number;
}) => {
  const history = useHistory();
  const dispatchPatientList = usePatientListDispatch();
  const dispatchTodayVisits = useVisitsListDispatch();
  const dispatchOtherVisits = useOtherVisitsDispatch();
  const [{ deleting, open, confirmed, deleted, error }, dispatch] = useReducer(
    deletePatientStateReducer,
    initialState
  );

  const handleClickButton = () => {
    dispatch({ type: 'openConfirmation' });
  };

  const handleClose = () => {
    dispatch({ type: 'closeConfirmation' });
  };

  const handleDeletePatient = () => {
    dispatch({ type: 'confirmedDelete' });
  };

  useRequestData<null>({
    needTransport: Boolean(confirmed && !deleting),
    dispatch,
    url: `/patients/${patientId}`,
    method: 'delete',
  });

  if (error) {
    dispatch({ type: 'reset' });
    throw error;
  }

  useEffect(() => {
    if (deleted) {
      dispatchPatientList({
        type: 'delete',
        patientId,
      });
      dispatchTodayVisits({
        type: 'deleteVisitsForPatientId',
        patientId,
      });
      dispatchOtherVisits({
        type: 'deleteVisitsForPatientId',
        patientId,
      });

      history.push(ROUTE_PATIENTS);
    }
  }, [
    dispatchPatientList,
    dispatchTodayVisits,
    history,
    patientId,
    deleted,
    dispatchOtherVisits,
  ]);

  return (
    <>
      <Button
        color="secondary"
        size="small"
        style={{ color: '#FF5A32' }}
        onClick={handleClickButton}
      >
        ARCHIVE
      </Button>
      <DialogDeletePatient
        patientName={patientName}
        open={open}
        handleConfirm={handleDeletePatient}
        handleReject={handleClose}
      />
      <Loader showLoader={Boolean(deleting)} />
    </>
  );
};
