import React, { useMemo } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { SymptomOption } from '../../reducers/symptomsList/symptoms.state';
import { Select } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    width: '100%',
    textAlign: 'left',
    maxWidth: '90vw',
  },
  menuItem: {
    whiteSpace: 'break-spaces',
    '&::before': {
      visibility: 'hidden',
      display: 'inline-block',
      content: '"✓ "',
    },
  },
  selectedMenuItem: {
    backgroundColor: '#00cd87 !important',
    color: '#fff',
    '&::before': {
      visibility: 'visible',
    },
  },
}));

export const SelectSymptomByType = ({
  id,
  setId,
  options,
  label,
  labelId,
  multiple = false,
}: {
  id: string[] | string;
  setId: (value: string[] | string) => void;
  options: SymptomOption[];
  label: string;
  labelId: string;
  multiple?: boolean;
}) => {
  const { classes } = useStyles();

  const symptomsComponents = useMemo(() => {
    const optionItems: JSX.Element[] = [
      <MenuItem key={'none'} value="none" disabled>
        {label}
      </MenuItem>,
    ];

    options.forEach((element) => {
      optionItems.push(
        <MenuItem
          key={element.Id}
          value={element.Id}
          classes={{
            selected: classes.selectedMenuItem,
          }}
          className={classes.menuItem}
        >
          {typeof element.Name === 'string'
            ? element.Name.replaceAll(': ', ':\n')
            : element.Name}
        </MenuItem>
      );
    });
    return optionItems;
  }, [classes.menuItem, label, options, classes.selectedMenuItem]);

  const handleChange = (event: { target: { value: string | string[] } }) => {
    if (multiple) {
      setId((event.target.value as string[]).filter((i) => i !== 'none'));
    } else {
      setId(event.target.value as string);
    }
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id={labelId}></InputLabel>
      <Select
        variant="outlined"
        labelId={labelId}
        id={`${labelId}-outlined`}
        value={id || (multiple ? [] : '')}
        onChange={handleChange}
        multiple={multiple}
      >
        {symptomsComponents}
      </Select>
    </FormControl>
  );
};
