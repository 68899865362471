import { useMediaQuery } from '@mui/material';
import { set } from 'lodash';
import React, {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { DrugDosageCalculator } from './DrugDosageCalculator/DrugDosageCalculator';
import {
  calculateAmountResult,
  calculateNumberOfUnitsResult,
  calculateStrengthResult,
} from './DrugDosageCalculator/DrugDosageCalculatorUtils';
import {
  drugAmountInitialState,
  drugStrengthInitialState,
  getWeightInitialState,
  numberOfUnitsInitialState,
} from './DrugDosageCalculator/initialStates';
import {
  CalculationType,
  CalculationUnit,
  DrugAmountState,
  DrugMgPerUnit,
  DrugStrengthState,
  DrugUnit,
  NumberOfUnitsState,
  PatientWeightState,
} from '../types/DrugDosages.type';
import {
  CalculatedValues,
  DefaultWithDrugMgPerUnit,
  DefaultWithDrugUnit,
} from '../types/DrugDosageTable.type';
import { convertKgToLbs } from '../utils/insertPatientUtils';
import { Card } from './common/Card';
import { CalculatorTitle } from './dosages/CalculatorTitle';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const CardDrugDosageCalculator = ({
  headerHidden = false,
  reload = false,
  currentPatientWeight = '',
  currentNumberOfUnits,
  currentDrugAmount,
  currentDrugStrength,
  forwardCalculation,
}: {
  headerHidden?: boolean;
  reload?: boolean;
  currentPatientWeight?: string;
  currentNumberOfUnits?: CalculatedValues<DrugMgPerUnit>;
  currentDrugAmount?: CalculatedValues<DrugMgPerUnit>;
  currentDrugStrength?: CalculatedValues<DrugUnit>;
  forwardCalculation?: (
    value: CalculatedValues<CalculationUnit>,
    calculationType: CalculationType
  ) => void;
}) => {
  const [patientWeight, setPatientWeight] = useState<PatientWeightState>(
    getWeightInitialState(currentPatientWeight)
  );
  const [patientWeightLbs, setPatientWeightLbs] = useState<PatientWeightState>(
    getWeightInitialState(
      currentPatientWeight !== ''
        ? convertKgToLbs(currentPatientWeight)
        : currentPatientWeight
    )
  );
  const [numberOfUnitsState, setNumberOfUnitsState] =
    useState<NumberOfUnitsState>(
      numberOfUnitsInitialState(
        currentNumberOfUnits || DefaultWithDrugMgPerUnit
      )
    );
  const [drugAmountState, setDrugAmountState] = useState<DrugAmountState>(
    drugAmountInitialState(currentDrugAmount || DefaultWithDrugMgPerUnit)
  );
  const [drugStrengthState, setDrugStrengthState] = useState<DrugStrengthState>(
    drugStrengthInitialState(currentDrugStrength || DefaultWithDrugUnit)
  );

  const mobile = useMediaQuery('(max-width:600px)');

  useLayoutEffect(() => {
    if (reload) {
      setNumberOfUnitsState(
        numberOfUnitsInitialState(
          currentNumberOfUnits || DefaultWithDrugMgPerUnit
        )
      );
      setDrugAmountState(
        drugAmountInitialState(currentDrugAmount || DefaultWithDrugMgPerUnit)
      );
      setDrugStrengthState(
        drugStrengthInitialState(currentDrugStrength || DefaultWithDrugUnit)
      );
    }
  }, [currentDrugAmount, currentDrugStrength, currentNumberOfUnits, reload]);

  const handleInputChangeNumberOfUnits = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const newState = set({ ...numberOfUnitsState }, name + '.value', value);
    setNumberOfUnitsState(newState);
    window.dataLayer.push({
      event: 'drugCalculator',
      method: 'numberOfUnits',
    });
    if (
      name === 'mgPerUnit' &&
      numberOfUnitsState.result !== '' &&
      forwardCalculation
    ) {
      const numberOfUnits = {
        strength: newState.strength.value,
        dose: newState.dose.value,
        result: newState.result,
        unit: newState.mgPerUnit.value,
      };
      forwardCalculation(numberOfUnits, 'numberOfUnits');
    }
  };

  const handleInputChangeDrugAmount = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const newState = set({ ...drugAmountState }, name + '.value', value);
    setDrugAmountState(newState);
    window.dataLayer.push({
      event: 'drugCalculator',
      method: 'amountOfDrug',
    });
    if (
      name === 'mgPerUnit' &&
      drugAmountState.result !== '' &&
      forwardCalculation
    ) {
      const drugAmount = {
        strength: newState.strength.value,
        dose: newState.numberOfUnits.value,
        result: newState.result,
        unit: newState.mgPerUnit.value,
      };
      forwardCalculation(drugAmount, 'drugAmount');
    }
  };

  const handleInputChangeDrugStrength = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const newState = set({ ...drugStrengthState }, name + '.value', value);
    setDrugStrengthState(newState);
    window.dataLayer.push({
      event: 'drugCalculator',
      method: 'drugStrength',
    });
    if (
      name === 'unit' &&
      numberOfUnitsState.result !== '' &&
      forwardCalculation
    ) {
      const drugStrength = {
        strength: newState.numberOfUnits.value,
        dose: newState.dose.value,
        result: newState.result,
        unit: newState.unit.value,
      };
      forwardCalculation(drugStrength, 'drugStrength');
    }
  };

  const handleCalculateClickNumberOfUnits = () => {
    const newNumberOfUnitsState = { ...numberOfUnitsState };
    const newPatientWeight = { ...patientWeight };

    newPatientWeight.error = patientWeight.validator(patientWeight.value);
    newNumberOfUnitsState.strength.error =
      numberOfUnitsState.strength.validator(numberOfUnitsState.strength.value);
    newNumberOfUnitsState.dose.error = numberOfUnitsState.dose.validator(
      numberOfUnitsState.dose.value
    );

    if (
      !newNumberOfUnitsState.strength.error &&
      !newNumberOfUnitsState.dose.error &&
      !newPatientWeight.error
    ) {
      const result = calculateNumberOfUnitsResult(
        Number(patientWeight.value),
        Number(numberOfUnitsState.strength.value),
        Number(numberOfUnitsState.dose.value)
      );
      newNumberOfUnitsState.result = result;

      if (forwardCalculation) {
        const numberOfUnits = {
          strength: numberOfUnitsState.strength.value,
          dose: numberOfUnitsState.dose.value,
          result,
          unit: numberOfUnitsState.mgPerUnit.value,
        };
        forwardCalculation(numberOfUnits, 'numberOfUnits');
      }
    }

    setPatientWeight(newPatientWeight);
    setNumberOfUnitsState(newNumberOfUnitsState);
  };

  const handleCalculateClickDrugAmount = () => {
    const newDrugAmountState = { ...drugAmountState };
    const newPatientWeight = { ...patientWeight };

    newPatientWeight.error = patientWeight.validator(patientWeight.value);
    newDrugAmountState.strength.error = newDrugAmountState.strength.validator(
      newDrugAmountState.strength.value
    );
    newDrugAmountState.numberOfUnits.error =
      newDrugAmountState.numberOfUnits.validator(
        newDrugAmountState.numberOfUnits.value
      );

    if (
      !newDrugAmountState.strength.error &&
      !newDrugAmountState.numberOfUnits.error &&
      !newPatientWeight.error
    ) {
      const result = calculateAmountResult(
        Number(patientWeight.value),
        Number(drugAmountState.strength.value),
        Number(drugAmountState.numberOfUnits.value)
      );
      newDrugAmountState.result = result;

      if (forwardCalculation) {
        const drugAmount = {
          strength: drugAmountState.strength.value,
          dose: drugAmountState.numberOfUnits.value,
          result,
          unit: drugAmountState.mgPerUnit.value,
        };

        forwardCalculation && forwardCalculation(drugAmount, 'drugAmount');
      }
    }

    setPatientWeight(newPatientWeight);
    setDrugAmountState(newDrugAmountState);
  };

  const handleCalculateClickDrugStrength = () => {
    const newDrugStrengthState = { ...drugStrengthState };
    const newPatientWeight = { ...patientWeight };

    newPatientWeight.error = patientWeight.validator(patientWeight.value);
    newDrugStrengthState.dose.error = newDrugStrengthState.dose.validator(
      newDrugStrengthState.dose.value
    );
    newDrugStrengthState.numberOfUnits.error =
      newDrugStrengthState.numberOfUnits.validator(
        newDrugStrengthState.numberOfUnits.value
      );

    if (
      !newDrugStrengthState.dose.error &&
      !newDrugStrengthState.numberOfUnits.error &&
      !newPatientWeight.error
    ) {
      const result = calculateStrengthResult(
        Number(patientWeight.value),
        Number(drugStrengthState.numberOfUnits.value),
        Number(drugStrengthState.dose.value)
      );
      newDrugStrengthState.result = result;

      if (forwardCalculation) {
        const drugStrength = {
          strength: drugStrengthState.numberOfUnits.value,
          dose: drugStrengthState.dose.value,
          result,
          unit: drugStrengthState.unit.value,
        };
        forwardCalculation(drugStrength, 'drugStrength');
      }
    }

    setPatientWeight(newPatientWeight);
    setDrugStrengthState(newDrugStrengthState);
  };

  useEffect(() => {
    if (currentNumberOfUnits) {
      setNumberOfUnitsState(numberOfUnitsInitialState(currentNumberOfUnits));
    }
  }, [currentNumberOfUnits]);

  return (
    <Card
      headerHidden={headerHidden}
      {...(!headerHidden && { title: 'CALCULATE DOSAGES' })}
      pageView={mobile ? 'mobileView' : 'drawerView'}
      style={{
        paddingBottom: 0,
        ...(forwardCalculation && { paddingTop: '2em' }),
      }}
      {...(forwardCalculation && { outlined: true })}
    >
      {headerHidden && <CalculatorTitle />}
      <DrugDosageCalculator
        setPatientWeight={setPatientWeight}
        patientWeight={patientWeight}
        setPatientWeightLbs={setPatientWeightLbs}
        patientWeightLbs={patientWeightLbs}
        numberOfUnitsState={numberOfUnitsState}
        handleInputChangeNumberOfUnits={handleInputChangeNumberOfUnits}
        handleCalculateClickNumberOfUnits={handleCalculateClickNumberOfUnits}
        drugAmountState={drugAmountState}
        handleInputChangeDrugAmount={handleInputChangeDrugAmount}
        handleCalculateClickDrugAmount={handleCalculateClickDrugAmount}
        drugStrengthState={drugStrengthState}
        handleInputChangeDrugStrength={handleInputChangeDrugStrength}
        handleCalculateClickDrugStrength={handleCalculateClickDrugStrength}
        isCurrentPatient={currentPatientWeight !== ''}
        mobile={mobile}
      />
    </Card>
  );
};
