import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import {
  useDiagnosesPage,
  useDiagnosesPageDispatch,
} from '../../contexts/DiagnosesPageContext';
import DeleteSymptom from './DeleteSymptom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSymptoms } from '../../contexts/SymptomsContext';
import { splitSymptomNameInTwoLines } from '../../utils/symptomsUtils';

export const AddedSymptoms = () => {
  const { selectedSymptoms, selectedSymptomsObj } = useDiagnosesPage();
  const { symptoms, symptomsObj } = useSymptoms();
  const dispatch = useDiagnosesPageDispatch();

  const handleMandatoryChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    Id: number
  ) => {
    event.stopPropagation();
    dispatch({
      type: 'setSymptomMandatory',
      symptomId: Id,
      checked,
    });
  };

  if (selectedSymptoms.length === 0) {
    return <Typography align="left">No symptoms are added</Typography>;
  }

  return (
    <>
      {selectedSymptoms
        .filter((Id) => !!symptoms[symptomsObj[Id]])
        .map((Id, index) => {
          const nameLines = splitSymptomNameInTwoLines(
            symptoms[symptomsObj[Id]].combinedName!
          );

          return (
            <Grid
              key={index}
              container
              style={{
                paddingTop: '0.5em',
                paddingBottom: '0.5em',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              }}
              alignItems="center"
              item
              xs={12}
            >
              <Grid item xs={10} style={{ textAlign: 'left' }}>
                <Typography
                  style={{
                    fontFamily: 'Arial',
                    textTransform: 'uppercase',
                    fontSize: '10px',
                    fontWeight: 'bold',
                    lineHeight: '1.6',
                    letterSpacing: '1.5px',
                    opacity: '0.6',
                  }}
                >
                  {nameLines.firstLine}
                </Typography>
                <Typography style={{ whiteSpace: 'pre-line' }}>
                  {nameLines.secondLine}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Checkbox
                  className="symptom-required-checkbox"
                  checked={selectedSymptomsObj[Id] || false}
                  onChange={(event, checked) =>
                    handleMandatoryChecked(event, checked, Id)
                  }
                  name="mustInclude"
                  color="primary"
                />
              </Grid>
              <Grid item xs={1}>
                <DeleteSymptom symptomId={Id} />
              </Grid>
            </Grid>
          );
        })}
    </>
  );
};
