import { VetProfile } from '../../types/Profile.types';
import { RequestAction } from '../../types/request.action.type';

export type VeterinarianState = {
  veterinarian?: VetProfile;
  isLoading: boolean;
  error?: string;
};

export const DefaultVeterinarianState: VeterinarianState = {
  isLoading: false,
};

export type VeterinarianAction =
  | RequestAction<VetProfile[]>
  | {
      type: 'updateVeterinarian';
      veterinarian: VetProfile;
    };
