import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import API from '../api/Api';
import { Patient } from '../components/Patient';
import {
  ROUTE_CURRENT_VISIT_SAVED,
  ROUTE_NEW_VISIT,
  ROUTE_PATIENTS,
} from '../config/routes.config';
import { useWorkContext } from '../contexts/WorkContext';
import {
  usePatientPageDispatch,
  usePatientPageState,
} from '../contexts/PatientPageContext';
import { usePatientListDispatch } from '../contexts/PatientListContext';
import { Patient as PatientType } from '../types/Patient.type';
import { useIntegrationContext } from '../contexts/IntegrationContext';
import { useUiState, useUiStateDispatch } from '../contexts/uiState.context';

export const PatientPage = () => {
  const dispatch = usePatientPageDispatch();
  const { breeds, isSaving } = usePatientPageState();
  const history = useHistory();
  const { patientId } = useParams<{ patientId: string | undefined }>();
  const {
    patient: workPatient,
    integrationPatient: integrationPatientData,
    setPatient: setWorkPatient,
    getPatient: getWorkPatient,
    getIntegrationPatient,
  } = useWorkContext();
  const [loadedPatient, setLoadedPatient] = useState(false);
  const [integrationPatient, setIntegrationPatient] = useState(false);
  const [patientSaved, setPatientSaved] = useState(false);
  const dispatchPatientList = usePatientListDispatch();
  const { redirectToCreatedVisit } = useUiState();
  const dispatchUiState = useUiStateDispatch();

  const { integrationConnectionInfo, integrationInfo, integrationSource } =
    useIntegrationContext();

  const isEditPage = patientId ? true : false;

  const patient = useMemo(() => {
    if (workPatient && isEditPage) {
      return { ...workPatient };
    }
    return null;
  }, [isEditPage, workPatient]);

  const fetchPatientOnInit = useCallback(() => {
    if (isEditPage && !loadedPatient) {
      setLoadedPatient(true);
      getWorkPatient(Number(patientId));
    } else if (
      integrationConnectionInfo &&
      integrationInfo &&
      integrationSource &&
      !isEditPage
    ) {
      setLoadedPatient(true);
      setIntegrationPatient(true);
      getIntegrationPatient(
        integrationSource,
        integrationConnectionInfo,
        integrationInfo
      );
    }
  }, [
    isEditPage,
    loadedPatient,
    setLoadedPatient,
    getWorkPatient,
    patientId,
    getIntegrationPatient,
    integrationConnectionInfo,
    integrationInfo,
    integrationSource,
  ]);

  const fetchBreedsByAnimalTypeId = useCallback(
    async (animalTypeId: number) => {
      const breedsResponse: any = await API.getModel({
        modelName: 'Breeds',
        AnimalTypeId: animalTypeId,
      });
      dispatch({ type: 'setBreeds', payload: breedsResponse.body });
    },
    [dispatch]
  );

  const createPatient = async (payload: object) => {
    const patientResponse: any = await API.createPatient(payload);
    await API.trackEvent('create_patient');
    setWorkPatient(patientResponse.body);
    dispatchPatientList({
      type: 'add',
      patient: patientResponse.body as PatientType,
    });
    setPatientSaved(true);
  };

  const setPatientSaving = (saving: boolean) => {
    dispatch({ type: 'setSaving', payload: saving });
  };

  const redirectAfterCreate = useCallback(() => {
    if (workPatient !== null && patientSaved && !isEditPage) {
      const path = generatePath(ROUTE_NEW_VISIT, {
        patientId: String(workPatient.Id),
      });
      history.push(path);
    }
  }, [workPatient, patientSaved, history, isEditPage]);

  const redirectAfterUpdate = useCallback(() => {
    if (workPatient !== null && patientSaved && isEditPage) {
      if (redirectToCreatedVisit) {
        const path = generatePath(ROUTE_CURRENT_VISIT_SAVED, {
          patientId: redirectToCreatedVisit.patientId.toString(),
          visitId: redirectToCreatedVisit.visitId.toString(),
        });
        dispatchUiState({ type: 'resetRedirectToCreatedVisit' });
        history.push(path);
      } else {
        history.push(ROUTE_PATIENTS);
      }
    }
  }, [
    dispatchUiState,
    history,
    isEditPage,
    patientSaved,
    redirectToCreatedVisit,
    workPatient,
  ]);

  const updatePatient = async (payload: PatientType) => {
    const patientResponse: any = await API.updatePatient(
      payload,
      {},
      patientId as string
    );
    dispatchPatientList({
      type: 'update',
      patient: patientResponse.body as PatientType,
    });
    setWorkPatient(patientResponse.body);
    setPatientSaved(true);
  };

  const preloadBreedsForEdit = useCallback(() => {
    if (isEditPage && workPatient && workPatient.AnimalTypeId) {
      fetchBreedsByAnimalTypeId(workPatient.AnimalTypeId);
    }
  }, [workPatient, fetchBreedsByAnimalTypeId, isEditPage]);

  useEffect(redirectAfterCreate, [redirectAfterCreate]);
  useEffect(redirectAfterUpdate, [redirectAfterUpdate]);
  useEffect(fetchPatientOnInit, [fetchPatientOnInit]);
  useEffect(preloadBreedsForEdit, [preloadBreedsForEdit]);

  return (
    <div>
      <Patient
        patient={patient}
        integrationPatientData={integrationPatientData}
        isEditPage={isEditPage}
        createPatient={createPatient}
        updatePatient={updatePatient}
        breedsData={breeds}
        integrationPatient={integrationPatient}
        patientSaving={isSaving}
        fetchBreedsByAnimalTypeId={fetchBreedsByAnimalTypeId}
        setPatientSaving={setPatientSaving}
      ></Patient>
    </div>
  );
};
