import React, { useEffect, useMemo, useState } from 'react';
import { Card } from '../components/common/Card';
import { Alert } from '@mui/lab';
import eyeLogo from '../assets/images/gekko_eye_logo.png';
import eyeLogoAnimated from '../assets/images/gekko_eye_spinning.gif';
import ChatMessage from '../components/chat/ChatMessage';
import { makeStyles } from 'tss-react/mui';
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { AccountCircle, Send } from '@mui/icons-material';
import useWebSocket from 'react-use-websocket';

type ChatMessageType = {
  message: string;
  from: string;
  processing?: boolean;
};

const INITIAL_MESSAGE = {
  message:
    "Hello, I'm Gekko - an AI assisted robot. I will help you query the Gekko Databases for symptoms, diagnoses and treatments.",
  from: '__gekko__',
};

const useStyles = makeStyles()({
  sendBar: {},
  chatInput: {
    width: '100%',
    margin: '0.2em',
  },
});

const ChatPage = () => {
  const [messages, setMessages] = useState<ChatMessageType[]>([
    INITIAL_MESSAGE,
  ]);
  const { classes } = useStyles();
  const [draftMessage, setDraftMessage] = useState('');

  const chat_id = useMemo(() => Date.now(), []);
  const token = sessionStorage.getItem('gekko/token');
  console.log('token', token);

  const { lastJsonMessage, sendJsonMessage } = useWebSocket(
    `wss://jxy2zdx8u1.execute-api.eu-west-1.amazonaws.com/default?chat_id=${chat_id}&token=${token}`
  );

  useEffect(() => {
    if (!lastJsonMessage) return;
    setMessages((msgs) =>
      msgs
        .filter((msg) => !msg.processing)
        .concat(lastJsonMessage as ChatMessageType)
    );
  }, [lastJsonMessage]);

  const submitHandler: React.FormEventHandler<HTMLFormElement> = useMemo(() => {
    return (ev) => {
      ev.preventDefault();
      setMessages(
        messages.concat(
          {
            from: 'human',
            message: draftMessage,
          },
          {
            from: '__gekko__',
            message: 'Please wait..',
            processing: true,
          }
        )
      );
      sendJsonMessage({
        action: 'send_message',
        message: draftMessage,
      });
      setDraftMessage('');
    };
  }, [draftMessage, messages, sendJsonMessage]);

  return (
    <Card pageView="drawerView" title="GekkoChat">
      <Alert severity="warning">This is an upcoming feature.</Alert>

      <div style={{ padding: '2em' }}>
        {messages.map((msg, idx) => (
          <ChatMessage
            color={msg.from === '__gekko__' ? '#00cd87' : '#eee'}
            direction={msg.from === '__gekko__' ? 'left' : 'right'}
            key={idx}
            avatar={
              msg.from === '__gekko__'
                ? msg.processing
                  ? eyeLogoAnimated
                  : eyeLogo
                : undefined
            }
            from={msg.from === '__gekko__' ? 'Gekko' : 'Me'}
            message={msg.message}
            processing={msg.processing}
          />
        ))}
      </div>

      <Grid className={classes.sendBar} container>
        <Grid item style={{ flexGrow: '1' }}>
          <form onSubmit={submitHandler}>
            <TextField
              className={classes.chatInput}
              label="Type your message here"
              variant="filled"
              value={draftMessage}
              onChange={(ev) => setDraftMessage(ev.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit" edge="end">
                      <Send />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ChatPage;
