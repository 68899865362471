const data = [
  {
    consultation_id: 43,
    anamnesis: `7:01 General
    Physical exam: bradycardia, obtunded, weak/thready pulses, prolonged skin tent, gums light pink and tacky, CRT 2-3s, abdomen soft/nonpainful; otherwise unremarkable exam
    6:56 General
    History: Lethargic today, vomited several times then collapsed
    PMH: sensitive stomach/ chronic vomiting/diarrhea - several times per month, signs are self-limiting. Has seen rDVM for it, O never approved workup because it always responded to cerenia/metronidazole/bland diet
    Meds: none; O has cerenia and metronidazole at home DIet: RC GI
    6:45 Triage Notes STAT triage
    History of sensitive stomach
    IV catheter 20g RF cephalic
    5ml 25% dextrose IV
    NormR 150ml bolus Supplemental heating Monitoring: ECG, BP, HR, RR, T`,
    llm_response: `[
      {
          "id": 351,
          "name": "Vomiting Unspecified",
          "reason": "vomited several times"
      },
      {
          "id": 502,
          "name": "Collapsed",
          "reason": "then collapsed"
      },
      {
          "id": 780,
          "name": "Weakness Unspecified",
          "reason": "Lethargic today"
      },
      {
          "id": 253,
          "name": "Cachexia",
          "reason": "sensitive stomach/ chronic vomiting/diarrhea - several times per month"
      },
      {
          "id": 344,
          "name": "Mucous membranes abnormal Pale",
          "reason": "gums light pink"
      },
      {
          "id": 438,
          "name": "Skin turgor slow",
          "reason": "prolonged skin tent"
      }
  ]`,
  },
  {
    consultation_id: 42,
    anamnesis: `Presenting problem/ request: lame on HL
    History: Was missing on Monday night and when he came back in he went straight upstairs
    and was curled up at the bottom of cat stand - abnormal behaviour for him. Walking low on
    his haunches.
    Following morning walked down stairs seeming a bit better.
    No change in e/d. Goes outdoors to toilet so o not sure about stools/urine and in multicat
    household.
    Has been kept indoors the past couple of days.
    Has been weight bearing. Not obvious when going upstairs but will occasionally skip on L HL
    when walking on flat surfaces.
    No yelping or crying.
    Not on any meds currently.
    Physical exam:
    BAR, very affectionate
    T 38.2C
    Couldn't ausc heart due to purring
    LNs all WNLs
    RR a bit fast
    Slight wobbly walking, unsteady on HLs, weight bearing on all 4 limbs
    Seemed painful upon palpation lumbar spine
    Good range of movement both hind limbs and no objection to palpation, no lumps, bumps,
    swellings or marks, no bruising, no scuffing nails
    R quadriceps muscle perhaps slightly thickened
    Quite a full bladder, abdominal palp otherwise NAD
    Problem list:
    Lame right HL`,
    llm_response: `[
      {
        "id": 588,
        "name": "Lameness",
        "reason": "Lame right HL"
      },
      {
        "id": 587,
        "name": "Instability",
        "reason": "Slight wobbly walking, unsteady on HLs, weight bearing on all 4 limbs"
      },
      {
        "id": 1740,
        "name": "Muscular hypertrophy",
        "reason": "R quadriceps muscle perhaps slightly thickened"
      },
      {
        "id": 1558,
        "name": "Palpation pain",
        "reason": "Seemed painful upon palpation lumbar spine"
      }
    ]`,
  },
  {
    consultation_id: 41,
    llm_response: `[
    {
      "id": 351,
      "name": "Vomiting Unspecified",
      "reason": "Has had vomiting and diarrhoea. A few episodes each day, then would stop, then start again a few days later."
    },
    {
      "id": 354,
      "name": "Vomiting Chronic",
      "reason": "Has had vomiting and diarrhoea. A few episodes each day, then would stop, then start again a few days later."
    },
    {
      "id": 401,
      "name": "Diarrhea",
      "reason": "Has had vomiting and diarrhoea. A few episodes each day, then would stop, then start again a few days later."
    }
  ]`,
    anamnesis: `Presenting Complaint: Vomiting and Diarrhoea

  History
  
  General History:
  
  Has been healthy otherwise. Behaviour seemed off for a little while but routine had changed. 
  
  Specific History:
  
  
  About 2 weeks ago changed diet and has had vomiting and diarrhoea. A few episodes each day, then would stop, then start again a few days later. Once she went back to original diet a week later problems resolved.
  Has had history of vomiting and diarrhoea with diet changes in the past. 
  
  Current Medications:
  
  None
  
  Clinical Exam
  
  
  General: Weight: 17.25
  T (C) if indicated:
  
  HR (bpm): 80
  
  PR (bpm): 80
  
  RR (breaths/min): NAD
  
  Resp character: NAD
  
  Skin/hair coat: dew claw of right forepaw missing 
  
  Gait: NAD
  
  Face/Head:
  
  Eyes: NAD 
  
  Nose: NAD 
  
  Ears: NAD 
  
  Mouth/dentition: 
  
  SM LNs: NAD 
  
  Neck: NAD 
  
  Thorax:
  
  
  Cardiac auscultation: NAD 
  
  Lung field auscultation: NAD
  
   Back/spine: NAD
  
  Abdomen:
  
  Abdo palp: NAD 
  
  Limbs:
  
  Prescap LNs: NAD
  
  Popliteal LNs: NAD
  
  Bones/joints: NAD
  
  Nails: missing right front dewclaw Interdigital skin: NAD
  
  Other lesions:Anal glands were very full`,
  },
];

export default data;
