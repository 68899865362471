import { TreatmentType } from '../components/diseases-and-treatments/types';
import { Treatment } from '../types/Diagnose.type';

export const getTreatmentTitle = (treatment: Treatment) => {
  let name = treatment.Name;
  if (treatment.Type === TreatmentType.Drug) {
    name = `Drug${
      treatment.TreatmentOptionality
        ? ', ' + treatment.TreatmentOptionality.Name
        : ''
    }`;
  } else if (treatment.Type === TreatmentType.Procedure) {
    name = 'Procedure';
  } else if (treatment.Type === TreatmentType.OtherTreatment) {
    name = 'Other Treatment';
  }
  return name.toUpperCase();
};

export const splitProtocolNameInTwoLines = (fullName: string) => {
  const arrNames = fullName.split(' ');
  let title = fullName;
  let subTitle = '';
  let start = 0;
  if (parseInt(arrNames[2]) > 0) {
    title = arrNames[0] + ' ' + arrNames[1] + ' ' + arrNames[2];
    start = 3;
  } else {
    title = arrNames[0] + ' ' + arrNames[1];
    start = 2;
  }
  for (let index = start; index < arrNames.length; index++) {
    subTitle = subTitle + ' ' + arrNames[index];
  }
  subTitle = subTitle.replace('(', '').replace(')', '').toUpperCase().trim();
  return { title, subTitle };
};

export const addOrRemoveNumberInArray = (
  arr: number[],
  item: number,
  beAdded: boolean
): [boolean, number[]] => {
  const changedArray = [...arr];
  const index = changedArray.indexOf(item);
  let changes = false;
  if (beAdded && index === -1) {
    changedArray.push(item);
    changes = true;
  } else if (!beAdded && index > -1) {
    changedArray.splice(index, 1);
    changes = true;
  }
  return [changes, changedArray];
};
