import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { TemplatePage } from '../pages/TemplatePage';
import {
  ROUTE_CALCULATOR,
  ROUTE_DISEASES_BREEDS,
  ROUTE_DISEASES_ORGANS,
  ROUTE_DISEASES_SIGNALMENT,
  ROUTE_DRUGS,
  ROUTE_TREATMENTS,
} from '../config/routes.config';
import { DiseasesAndTreatmentsPage } from '../pages/DiseasesAndTreatmentsPage';
import { DrugSubstancesPage } from '../pages/DrugSubstancesPage';
import { CalculatorPage } from '../pages/CalculatorPage';

export const ToolsRouter = () => {
  return (
    <Switch>
      <Route path={ROUTE_DISEASES_BREEDS}>
        <TemplatePage title="Diseases by breed" />
      </Route>
      <Route path={ROUTE_DISEASES_SIGNALMENT}>
        <TemplatePage title="Diseases by signalment" />
      </Route>
      <Route path={ROUTE_DISEASES_ORGANS}>
        <TemplatePage title="Diseases by organ" />
      </Route>
      <Route path={ROUTE_TREATMENTS}>
        <DiseasesAndTreatmentsPage />
      </Route>
      <Route path={ROUTE_DRUGS}>
        <DrugSubstancesPage />
      </Route>
      <Route path={ROUTE_CALCULATOR}>
        <CalculatorPage />
      </Route>
      <Redirect to={ROUTE_DISEASES_BREEDS} />
    </Switch>
  );
};
