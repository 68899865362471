import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PaymentErrorPage } from '../pages/PaymentErrorPage';
import { PaymentSuccessPage } from '../pages/PaymentSuccessPage';

import {
  ROUTE_PAYMENT,
  ROUTE_PAYMENT_SUCCESS,
  ROUTE_PAYMENT_ERROR,
  ROUTE_PROFILE,
} from '../config/routes.config';
import { ProfileRouter } from '../routers/ProfileRouter';
import { PaymentPage } from '../pages/PaymentPage';
import {
  useSubscriptionContext,
  useSubscriptionDispatch,
} from '../contexts/SubscriptionContext';

export const NoSubscriptionLayout = () => {
  const { error: errorSubscription } = useSubscriptionContext();
  const dispatchSubscription = useSubscriptionDispatch();

  useEffect(() => {
    if (errorSubscription) {
      dispatchSubscription({ type: 'reset' });
    }
  }, [errorSubscription, dispatchSubscription]);

  return (
    <Switch>
      <Route exact path={ROUTE_PAYMENT}>
        <PaymentPage />
      </Route>
      <Route exact path={ROUTE_PAYMENT_SUCCESS}>
        <PaymentSuccessPage />
      </Route>
      <Route exact path={ROUTE_PAYMENT_ERROR}>
        <PaymentErrorPage />
      </Route>
      <Route path={ROUTE_PROFILE}>
        <ProfileRouter />
      </Route>
      <Redirect to={ROUTE_PAYMENT} />
    </Switch>
  );
};
