import { UiAction, UiState } from './uiState.store';

export const uiStateReducer = (state: UiState, action: UiAction): UiState => {
  switch (action.type) {
    case 'setRedirectToCreatedVisit': {
      return {
        ...state,
        redirectToCreatedVisit: action.currentVisitIds,
      };
    }

    case 'resetRedirectToCreatedVisit': {
      const newState = { ...state };
      if ('redirectToCreatedVisit' in newState) {
        delete newState.redirectToCreatedVisit;
      }
      return newState;
    }

    default:
      return state;
  }
};
