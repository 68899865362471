import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DialogRemoveSymptom({
  open,
  handleConfirm,
  handleReject,
}: {
  open: boolean;
  handleConfirm: () => void;
  handleReject: () => void;
}) {
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleReject}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="simple-dialog-title">Confirm delete</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this symptom?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReject} color="primary" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          color="secondary"
          autoFocus
          variant="contained"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
