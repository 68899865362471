import { DrugProduct } from '../../types/DrugProduct.type';
import {
  DrugProductsState,
  DrugProductsAction,
  DrugProductsDefault,
} from './drugProducts.state';

const sortByStrength = (arr: DrugProduct[]) => {
  return arr.sort((a, b) => {
    const aStrength = +a.Strength;
    const bStrength = +b.Strength;
    return aStrength > bStrength ? 1 : bStrength > aStrength ? -1 : 0;
  });
};

export const drugProductsReducer = (
  state: DrugProductsState,
  action: DrugProductsAction
): DrugProductsState => {
  switch (action.type) {
    case 'reset': {
      const newState = { ...state };
      if ('error' in newState) {
        delete newState.error;
      }

      return newState;
    }

    case 'request': {
      return {
        ...state,
        isLoading: true,
      };
    }

    case 'success': {
      const drugProductsObj = { ...state.drugProductsObj };
      drugProductsObj[action.options as number] = sortByStrength(
        action.responseData
      );

      return {
        isLoading: false,
        drugProductsObj,
      };
    }

    case 'failure':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case 'resetAll': {
      return { ...DrugProductsDefault };
    }

    case 'import': {
      return { ...action.drugProducts, isLoading: false };
    }

    default:
      return state;
  }
};
