import { GeographicalArea } from '../../types/GeographicalArea.type';
import {
  GAListState,
  GAListAction,
  GAListDefault,
} from './geographicalAreas.list.state';

export const gaListReducer = (
  state: GAListState,
  action: GAListAction
): GAListState => {
  switch (action.type) {
    case 'reset': {
      return { ...GAListDefault };
    }

    case 'request': {
      return {
        isLoading: true,
        geographicalAreas: [],
        geographicalAreasObj: {},
      };
    }

    case 'success': {
      const geographicalAreasObj = { ...GAListDefault.geographicalAreasObj };
      action.responseData.forEach((ga: GeographicalArea, index) => {
        geographicalAreasObj[ga.Id] = index;
      });

      return {
        isLoading: false,
        geographicalAreas: action.responseData,
        geographicalAreasObj,
      };
    }

    case 'failure':
      return {
        isLoading: false,
        error: action.error,
        geographicalAreas: [],
        geographicalAreasObj: {},
      };

    default:
      return state;
  }
};
