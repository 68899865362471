import React, { useCallback, useState } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  Theme,
} from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { NavItemContainerProps } from './navigation.types';
import { NavItemLink } from './NavItemLink';
import { useMatchUrl } from '../../hooks/useMatchUrl';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginTop: '0',
    marginBottom: '0',
    paddingLeft: '9.2%',
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingRight: '27.7%',
    '&$selected': {
      backgroundColor: 'rgba(207, 207, 207, 0.4)',
    },
  },
  selected: {},
  icon: {
    minWidth: '35px',
  },
  expandIcon: {
    marginTop: '4px',
    minWidth: '35px',
    marginLeft: '85%',
  },
  text: {
    color: '#444444',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: '0.1px',
    paddingTop: '2px',
    paddingLeft: '8%',
  },
}));

export const NavItemContainer = (
  props: NavItemContainerProps & {
    toggleNavOpen?: () => void;
  }
) => {
  const { icon, title, children, toggleNavOpen } = props;
  const [urlList, setSetUrlList] = useState<{ [x: string]: boolean }>(
    () => ({})
  );

  const selected = useMatchUrl(urlList);

  const { classes } = useStyles();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const addUrl = useCallback(
    (url: string) => {
      if (!(url in urlList)) {
        setSetUrlList({ ...urlList, [url]: true });
      }
    },
    [urlList]
  );

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        classes={{ root: classes.root, selected: classes.selected }}
        selected={selected}
      >
        {icon ? (
          <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
        ) : null}
        <ListItemText primary={title} className={classes.text} />
        {open ? (
          <ExpandMore className={classes.expandIcon} />
        ) : (
          <KeyboardArrowRightIcon className={classes.expandIcon} />
        )}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((item) => {
            if ('to' in item) {
              addUrl(item.to);
              return (
                <NavItemLink
                  key={item.title}
                  {...item}
                  toggleNavOpen={toggleNavOpen}
                />
              );
            } else if ('children' in item) {
              return (
                <NavItemContainer
                  key={item.title}
                  {...item}
                  toggleNavOpen={toggleNavOpen}
                />
              );
            }

            return null;
          })}
        </List>
      </Collapse>
    </>
  );
};
